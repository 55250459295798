import { createContext, useContext, useEffect, useMemo } from "react";
import { useState, useRef } from "react";

const EditorContext = createContext();

export const EditorProvider = ({ children }) => {
  const fabricRef = useRef(null);
  const [objects, setObjects] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [fabricCanvas, setFabricCanvas] = useState("");

  const value = useMemo(
    () => ({
      fabricRef,
      objects,
      setObjects,
      imageURL,
      setImageURL,
      fabricCanvas,
      setFabricCanvas,
    }),
    [
      fabricRef,
      objects,
      setObjects,
      imageURL,
      fabricCanvas,
      setFabricCanvas,
      setImageURL,
    ]
  );

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};

export const useEditor = () => {
  return useContext(EditorContext);
};
