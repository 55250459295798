import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Button,
  Modal,
  Input,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import Image from "../utils/images/docspawn_lp.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const LandingPage = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const setDirectory = (directory) => {
    navigate("/" + directory);
  };

  const [showSignupBox, setShowSignupBox] = useState(false);

  const handleOpen = () => setShowSignupBox(true);
  const handleClose = () => setShowSignupBox(false);

  const [signupEmail, setSignupEmail] = useState("");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        minHeight: "500px",
        minWidth: "300px",
        overflowX: "hidden",
        position: "relative",
        backgroundImage:
          "linear-gradient(to bottom, #ffffff, #f3f0f8, #eae1ef, #e3d1e4, #dec1d7)",
      }}
    >
      {setShowSignupBox && (
        <Box>
          <Modal open={showSignupBox} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "6px",
                  p: 3,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "700", color: theme.palette.heading.main }}
                >
                  Sign up for FREE
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", color: theme.palette.heading.main }}
                >
                  and start using DocSpawn in seconds!
                </Typography>
                {/* <Box > */}
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  placeholder="Enter your work email"
                  onChange={(e) => setSignupEmail(e.target.value)}
                />
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2, py: 1.2 }}
                  onClick={() => {
                    dispatch({
                      type: "SET_EMAIL_FOR_SIGNUP",
                      payload: signupEmail,
                    });
                    setDirectory("signup");
                  }}
                  disabled={signupEmail?.length < 1}
                >
                  Next
                </Button>
                {/* </Box> */}
                <Box sx={{ width: "100%", mt: 1 }}>
                  <Typography>
                    Or already have an account?{" "}
                    <Button
                      onClick={() => {
                        setDirectory("login");
                      }}
                    >
                      Login
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: "700",
          fontSize: "3rem",
          mt: "7%",
          maxWidth: "50%",
          color: theme.palette.heading.main,
        }}
      >
        {props?.t("Generate large number of complex documents instantly")}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontWeight: "400",
          fontSize: "1.5rem",
          mt: 3,
          maxWidth: "60%",
          color: theme.palette.heading.main,
        }}
      >
        {props?.t(
          "An application that help you transform your data into powerful documents in minutes"
        )}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          mt: "5%",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            // setDirectory("login")
            setShowSignupBox(true);
          }}
        >
          {props?.t("Try for free")}
        </Button>
        <Typography variant="p" sx={{ color: theme.palette.para.main }}>
          {props?.t("14 day free trial, no credit card required")}
        </Typography>
      </Box>
      <Box sx={{}}>
        <img
          src={Image}
          style={{
            width: "80vw",
            marginTop: "100px",

            minHeight: "500px",
          }}
        />
      </Box>
    </Box>
  );
};

export default withTranslation()(LandingPage);
