import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withTranslation } from "react-i18next";

const Tab = (props) => {
  let {
    step,
    templateCreationStep,
    i,
    isEditing,
    setTemplateCreationStep,
    clickability,
  } = props;
  const theme = useTheme();

  useEffect(() => {
    console.log("is editing", isEditing);
  }, [isEditing]);
  return (
    <Box
      sx={{
        display: "flex",
        // gap: "10px",
        border:
          i + 1 <= templateCreationStep
            ? `1px solid ${theme.palette.primary.main}`
            : "1px solid lightgray",
        alignItems: "center",
        height: "max-content",
        borderRadius: "5px",
        transition: "all 0.6s ease",
        cursor: isEditing && "pointer",
      }}
      onClick={() => {
        if (
          isEditing &&
          clickability
          // && i > 0
        )
          setTemplateCreationStep(i + 1);
      }}
    >
      <Typography
        variant="body2"
        sx={{
          backgroundColor:
            i + 1 <= templateCreationStep ? theme.palette.primary.main : "gray",
          color: "#fff",
          padding: "5px 12px",
          // borderRadius: "4px",
          transition: "all 0.6s ease",
          // border: "2px solid black",
        }}
      >
        {1 + i}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          padding: "5px 0",
          paddingRight: "10px",
          background: "#fff",
          borderRadius: "5px",
          // border: "2px solid black",
          pl: 1,
          color:
            i + 1 <= templateCreationStep
              ? theme.palette.primary.main
              : theme.palette.para.main,
          transition: "all 0.6s ease",
        }}
      >
        {props?.t(step)}
      </Typography>
    </Box>
  );
};

export default withTranslation()(Tab);
