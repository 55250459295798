import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";
import { Transforms } from "slate";
import { api } from "../../../../axios/api";

// const TimestampField = (props) => {
//   const { t } = props;
//   const [selectedOption, setSelectedOption] = useState("");
//   const [showFixDateInput, setShowFixDateInput] = useState(false);
//   const [showFixTimeInput, setShowFixTimeInput] = useState(false);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//     setShowFixDateInput(option === "date");
//     setShowFixTimeInput(option === "time");
//   };

//   const dateFormats = [
//     { value: "MM/DD/YYYY", description: "Month/Day/Year" },
//     { value: "YYYY/MM/DD", description: "Year/Month/Day" },
//     // Add more date formats as needed
//   ];

//   const timeFormats = [
//     { value: "HH:mm:ss", description: "Hour:Minute:Second" },
//     { value: "hh:mm A", description: "Hour:Minute AM/PM" },
//     // Add more time formats as needed
//   ];

//   const renderDateOptions = () => (
//     <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
//       <Typography variant="body2" sx={{ color: "#000" }}>
//         {t("Select Date format")}:
//       </Typography>
//       <Select
//         onChange={(e) => setFixDateValue(e.target.value)}
//         value={fixDateValue}
//         sx={{ height: "2.5rem" }}
//       >
//         {dateFormats.map((format) => (
//           <MenuItem key={format.value} value={format.value}>
//             <Tooltip title={<Typography>{format.description}</Typography>}>
//               <span>{format.value}</span>
//             </Tooltip>
//           </MenuItem>
//         ))}
//       </Select>
//     </Box>
//   );

//   const renderTimeOptions = () => (
//     <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
//       <Typography variant="body2" sx={{ color: "#000" }}>
//         {t("Select Time format")}:
//       </Typography>
//       <Select
//         onChange={(e) => setFixTimeValue(e.target.value)}
//         value={fixTimeValue}
//         sx={{ height: "2.5rem" }}
//       >
//         {timeFormats.map((format) => (
//           <MenuItem key={format.value} value={format.value}>
//             <Tooltip title={<Typography>{format.description}</Typography>}>
//               <span>{format.value}</span>
//             </Tooltip>
//           </MenuItem>
//         ))}
//       </Select>
//     </Box>
//   );

//   return (
//     <Box sx={{ display: "inline", position: "relative" }}>
//       <Button
//         variant="outlined"
//         size="small"
//         sx={{
//           mt: -1,
//           opacity: "1!important",
//           border: "none !important",
//           background: "#dea7d188 !important",
//           textTransform: "capitalize",
//         }}
//         onClick={() => handleButtonClick()}
//       >
//         insert timestamp
//       </Button>
//       {showFixDateInput ? renderDateOptions() : null}
//       {showFixTimeInput ? renderTimeOptions() : null}
//     </Box>
//   );
// };

// export default TimestampField;

// const dateFormats = [
//   {
//     value: "MM/DD/YY",
//     description: "Month-Day-Year with leading zeros (02/17/2009)",
//   },
//   {
//     value: "DD/MM/YY",
//     description: "Day-Month-Year with leading zeros (17/02/2009)",
//   },
//   {
//     value: "YY/MM/DD",
//     description: "Year-Month-Day with leading zeros (2009/02/17)",
//   },
//   {
//     value: "Month D, Yr",
//     description:
//       "Month name-Day-Year with no leading zeros (February 17, 2009)",
//   },
//   {
//     value: "M/D/YY",
//     description: "Month-Day-Year with no leading zeros (2/17/2009)",
//   },
//   {
//     value: "D/M/YY",
//     description: "Day-Month-Year with no leading zeros (17/2/2009)",
//   },
//   {
//     value: "YY/M/D",
//     description: "Year-Month-Day with no leading zeros (2009/2/17)",
//   },
//   {
//     value: " bM/bD/YY",
//     description:
//       "Month-Day-Year with spaces instead of leading zeros ( 2/17/2009)",
//   },
//   {
//     value: " bD/bM/YY",
//     description:
//       "Day-Month-Year with spaces instead of leading zeros (17/ 2/2009)",
//   },
//   {
//     value: "YY/ bM/bD",
//     description:
//       "Year-Month-Day with spaces instead of leading zeros (2009/ 2/17)",
//   },
//   {
//     value: "MMDDYY",
//     description: "Month-Day-Year with no separators (02172009)",
//   },
//   {
//     value: "DDMMYY",
//     description: "Day-Month-Year with no separators (17022009)",
//   },
//   {
//     value: "YYMMDD",
//     description: "Year-Month-Day with no separators (20090217)",
//   },
//   {
//     value: "MonDDYY",
//     description: "Month abbreviation-Day-Year with leading zeros (Feb172009)",
//   },
//   {
//     value: "DDMonYY",
//     description: "Day-Month abbreviation-Year with leading zeros (17Feb2009)",
//   },
//   {
//     value: "YYMonDD",
//     description: "Year-Month abbreviation-Day with leading zeros (2009Feb17)",
//   },
//   {
//     value: "day/YY",
//     description: "Day of year-Year (48/2009)",
//   },
//   {
//     value: "YY/day",
//     description: "Year-Day of Year (2009/48)",
//   },
//   {
//     value: "D Month, Yr",
//     description: "Day-Month name-Year (17 February, 2009)",
//   },
//   {
//     value: "Yr, Month D",
//     description: "Year-Month name-Day (2009, February 17)",
//   },
//   {
//     value: "Mon-DD-YYYY",
//     description:
//       "Month abbreviation, Day with leading zeros, Year (Feb 17, 2009)",
//   },
//   {
//     value: "DD-Mon-YYYY",
//     description:
//       "Day with leading zeros, Month abbreviation, Year (17 Feb, 2009)",
//   },
//   {
//     value: "YYYYY-Mon-DD",
//     description:
//       "Year, Month abbreviation, Day with leading zeros (2009, Feb 17)",
//   },
//   {
//     value: "Mon DD, YYYY",
//     description:
//       "Month abbreviation, Day with leading zeros, Year (Feb 17, 2014)",
//   },
//   {
//     value: "DD Mon, YYYY",
//     description:
//       "Day with leading zeros, Month abbreviation, Year (17 Feb, 2014)",
//   },
//   {
//     value: "YYYY, Mon DD",
//     description:
//       "Year, Month abbreviation, Day with leading zeros (2014, Feb 17)",
//   },
// ];

// const timeFormats = [
//   {
//     value: "HH:MM:SS",
//     description:
//       'Time is based on a 24 hour system. This is frequently referred to as "military time". The 24 hour system is the default format.Example: 14:18:23',
//   },
//   {
//     value: "HH:MM:SS XM",
//     description:
//       "Time is based on a 12 hour system. AM or PM is given. Example: 02:18:23 PM",
//   },
//   {
//     value: "HH:MM",
//     description:
//       "Time is based on a 24 hour system. Seconds are not given.Example: 14:18",
//   },
//   {
//     value: "HH:MM XM",
//     description:
//       "Time is based on a 12 hour system. Seconds are not given. AM or PM is given.Example: 02:18 PM",
//   },
// ];

const TimestampField = (props) => {
  const [enabledDateFormats, setEnabledDateFormats] = useState([]);
  const [enabledTimeFormats, setEnabledTimeFormats] = useState([]);

  useEffect(() => {
    api
      .get("/formats/date")
      .then((res) => {
        // console.log("res?.data all formats", res?.data);
        let allFormats = res?.data;
        api
          .get("/formats/enabled/date")
          .then((res) => {
            // console.log("res?.data enabled ids", res?.data);
            let enabledIds = res?.data;
            setEnabledDateFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  useEffect(() => {
    api
      .get("/formats/time")
      .then((res) => {
        // console.log("res?.data all formats", res?.data);
        let allFormats = res?.data;
        api
          .get("/formats/enabled/time")
          .then((res) => {
            let enabledIds = res?.data;
            setEnabledTimeFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const { editor, emailTemplateOptions, t } = props;
  const [showTimestampForm, setShowTimestampForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showFixDateInput, setShowFixDateInput] = useState(false);
  const [showFixTimeInput, setShowFixTimeInput] = useState(false);
  const [fixDateValue, setFixDateValue] = useState("");
  const [fixTimeValue, setFixTimeValue] = useState("");

  const handleTimestampFormSubmit = () => {
    // Handle initial form submission logic here
    setShowTimestampForm(false); // Hide the initial form
  };

  const handleButtonClick = () => {
    // Handle button click logic here
    // You can implement additional functionality if needed
    setShowTimestampForm(true); // Show the initial form again
    setShowFixDateInput(false); // Hide the date input
    setShowFixTimeInput(false); // Hide the time input
    setSelectedOption(""); // Reset the selected option
  };

  const insertTimeToTemplate = () => {
    const { selection } = editor;
    const textNode = { text: `\${at(${fixTimeValue})}` };

    Transforms.insertNodes(editor, textNode, { at: selection });

    setShowFixTimeInput(false);
  };
  const insertDateToTemplate = () => {
    const { selection } = editor;
    const textNode = { text: `\${at(${fixDateValue})}` };

    Transforms.insertNodes(editor, textNode, { at: selection });

    setShowFixDateInput(false);
  };

  return (
    <Box sx={{ display: "inline", position: "relative" }}>
      <Button
        variant="outlined"
        size="small"
        sx={{
          mt: -1,
          opacity: "1!important",
          border: "none !important",
          background: "#009ee266 !important",
          textTransform: "capitalize",
        }}
        onClick={() => handleButtonClick()}
      >
        insert timestamp
      </Button>
      {(showFixDateInput || showFixTimeInput || showTimestampForm) && (
        <Box
          onMouseLeave={() => {
            setShowFixDateInput(false);
            setShowFixTimeInput(false);
            setShowTimestampForm(false);
          }}
          sx={{
            position: "absolute",
            left: "40px",
            backgroundColor: "white",
            padding: "6px 10px",
            border: "1px solid lightgray",
            // height: "fit-content",
            height: "80px",
            width: "max-content",
            zIndex: "1",
            background: "#fff",
          }}
        >
          {showTimestampForm && !showFixDateInput && !showFixTimeInput ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography variant="body2" sx={{ color: "#000" }}>
                {t("Select an option")}:
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    opacity: "1!important",
                  }}
                  onClick={() => {
                    setShowFixDateInput(true);
                    setShowFixTimeInput(false);
                    setShowTimestampForm(false);
                  }}
                >
                  {t("Date")}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    opacity: "1!important",
                  }}
                  onClick={() => {
                    setShowFixTimeInput(true);
                    setShowFixDateInput(false);
                    setShowTimestampForm(false);
                  }}
                >
                  {t("Time")}
                </Button>
              </Box>

              {/* <Button
            variant="outlined"
            size="small"
            sx={{ fontSize: "0.8rem", fontWeight: "600" }}
            onClick={() => handleTimestampFormSubmit()}
          >
            {t("Submit")}
          </Button> */}
            </Box>
          ) : null}
          {showFixDateInput && !showFixTimeInput && !showTimestampForm ? (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                // alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ color: "#000" }}>
                {t("Select Date format")}:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Select
                  size="small"
                  onChange={(e) => setFixDateValue(e.target.value)}
                  value={fixDateValue}
                  sx={{ height: "1.5rem", fontSize: "0.9rem" }}
                >
                  {enabledDateFormats?.map((format) => (
                    <MenuItem key={format.format} value={format.format}>
                      <Tooltip
                      // title={<Typography>{format.description}</Typography>}
                      >
                        <span>{format.format}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  size="small"
                  sx={{ opacity: "1 !important" }}
                  onClick={() => insertDateToTemplate()}
                >
                  insert
                </Button>
              </Box>
            </Box>
          ) : null}
          {showFixTimeInput && !showTimestampForm && !showFixDateInput ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                // alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ color: "#000" }}>
                {t("Select Time format")}:
              </Typography>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Select
                  onChange={(e) => setFixTimeValue(e.target.value)}
                  value={fixTimeValue}
                  sx={{ height: "1.5rem", fontSize: "0.9rem" }}
                >
                  {enabledTimeFormats?.map((format) => (
                    <MenuItem key={format.format} value={format.format}>
                      <Tooltip
                      // title={<Typography>{format.description}</Typography>}
                      >
                        <span>{format.format}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  size="small"
                  sx={{ opacity: "1 !important" }}
                  onClick={() => insertTimeToTemplate()}
                >
                  {" "}
                  insert
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(TimestampField);
