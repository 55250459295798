import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Modal,
  Input,
  FormControlLabel,
  Radio,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { api } from "../../../axios/api";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const Checkboxes = (props) => {
  const theme = useTheme();
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [uncheckedOptions, setUncheckedOptions] = useState([]);
  const [optionType, setOptionType] = useState("checked");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileURL, setUploadedFileURL] = useState("");

  const [addModalOpen, setAddModalOpen] = useState(false);
  const handleAddModalOpen = () => setAddModalOpen(true);
  const handleAddModalClose = () => setAddModalOpen(false);

  const handleRadioChange = (event) => {
    setOptionType(event.target.value);
  };
  const getCheckBoxes = () => {
    api
      .get("/checkboxes")
      .then((res) => {
        let checked = [];
        let unchecked = [];
        if (res?.data?.length > 0) {
          let ops = res?.data;
          ops?.forEach((op) => {
            if (op?.option_type == "checked") {
              checked.push(op);
            }
            if (op?.option_type == "unchecked") {
              unchecked.push(op);
            }
          });
          setCheckedOptions(checked);
          setUncheckedOptions(unchecked);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getCheckBoxes();
  }, []);
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.log(error);
      setIsUploading(false);

      return null;
    }
  };
  const handleOptionUploadInput = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    let file = e.target.files[0];

    let URL = await uploadFileToBackend(file);

    setUploadedFileURL(URL);
    setIsUploading(false);
  };

  const uploadOption = () => {
    let obj = {
      option_type: optionType,
      option_url: uploadedFileURL,
    };

    api
      .post("/checkboxes/add", obj)
      .then((res) => {
        getCheckBoxes();
        toast.success("added successfully!");
        // if (res?.data?.option_type == "checked")
        //   setCheckedOptions([...checkedOptions, res?.data]);
        // if (res?.data?.option_type == "unchecked")
        //   setUncheckedOptions([...uncheckedOptions, res?.data]);
        setAddModalOpen(false);
        setUploadedFileURL("");
      })
      .catch((err) => {
        // console.log("error>", err);
      });
  };

  const deleteOption = (op) => {
    api
      .delete("/checkboxes/" + op?.id)
      .then((res) => {
        toast?.success("deleted successfully!");
        // getCheckBoxes();
        if (op?.option_type == "checked")
          setCheckedOptions(checkedOptions?.filter((o) => o?.id != op?.id));
        if (op?.option_type == "unchecked")
          setUncheckedOptions(uncheckedOptions?.filter((o) => o?.id != op?.id));
      })
      .catch((err) => {
        // console.log("error in deleetion", err);
      });
  };
  return (
    <Box>
      <ToastContainer />
      <Modal
        open={addModalOpen}
        // onClose={handleAddModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "sticky",
              top: "-10px",
              left: "100%",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={handleAddModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {props?.t("Add checkboxes options")}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: "600" }}>
                {props?.t("Type")}:
              </Typography>
              <Box>
                <FormControlLabel
                  control={<Radio />}
                  label={props?.t("Checked")}
                  value="checked"
                  checked={optionType === "checked"}
                  onChange={handleRadioChange}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={props?.t("Unchecked")}
                  value="unchecked"
                  checked={optionType === "unchecked"}
                  onChange={handleRadioChange}
                />
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: "600" }}>
                {props?.t("Select option image to upload")}:
              </Typography>
              <Input
                variant="outlined"
                type="file"
                sx={{ mt: 1 }}
                onChange={handleOptionUploadInput}
              />
              {isUploading ? (
                <Box sx={{ p: 1 }}>
                  <CircularProgress />
                </Box>
              ) : (
                uploadedFileURL && (
                  <Box sx={{ my: 1 }}>
                    <img
                      src={uploadedFileURL}
                      style={{ width: "50px", height: "auto" }}
                    />
                  </Box>
                )
              )}
            </Box>
            {optionType && uploadedFileURL && (
              <Button
                variant="contained"
                sx={{ transform: "translateX(-50%)", left: "50%" }}
                onClick={() => uploadOption()}
              >
                {props?.t("Upload")}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
        {props?.t("Add Checkbox images here for checked and unchecked options")}
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={handleAddModalOpen}
        sx={{ textTransform: "inherit", mt: 2 }}
      >
        {props?.t("Add new option")}
      </Button>

      <Grid container sx={{ p: 2, mt: 2 }} gap={2}>
        <Grid item xs={5} sx={{}}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", color: theme.palette.heading.main }}
          >
            {props?.t("Checked options")}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {checkedOptions?.map((op, i) => (
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  // borderBottom: "1px solid lightgray",
                  mb: 2,
                  gap: "20px",
                }}
              >
                <Typography sx={{ color: theme.palette.heading.main }}>
                  {i + 1}.
                </Typography>
                <img
                  src={op?.option_url}
                  alt={i}
                  style={{ width: "40px", height: "auto" }}
                />
                {i == 0 && <Typography>({props?.t("empty")})</Typography>}
                {!op?.is_default && (
                  // <IconButton onClick={() => deleteOption(op)}>
                  //   <DeleteOutlineIcon />
                  // </IconButton>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ color: "red" }}
                    onClick={() => deleteOption(op)}
                  >
                    {props?.t("remove")}
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={5} sx={{}}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", color: theme.palette.heading.main }}
          >
            {props?.t("Unchecked options")}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {uncheckedOptions?.map((op, i) => (
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                  gap: "20px",
                }}
              >
                <Typography sx={{ color: theme.palette.heading.main }}>
                  {i + 1}.
                </Typography>

                <img
                  src={op?.option_url}
                  alt={i}
                  style={{ width: "40px", height: "auto" }}
                />
                {i == 0 && <Typography>({props?.t("empty")})</Typography>}
                {!op?.is_default && (
                  // <IconButton onClick={() => deleteOption(op)}>
                  //   <DeleteOutlineIcon />
                  // </IconButton>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ color: "red" }}
                    onClick={() => deleteOption(op)}
                  >
                    {props?.t("remove")}
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default withTranslation()(Checkboxes);
