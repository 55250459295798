import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import FileUploader from "./TemplateCreation/GeneralInformation/FileUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse"; // CSV parsing library
import { api } from "../axios/api";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CreateIcon from "@mui/icons-material/Create";
import FinalForm from "./TemplateCreation/FormEditor.js/FinalForm";
import * as XLSX from "xlsx"; // Excel parsing library
import { withTranslation } from "react-i18next";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import QRCode from "react-qr-code";
import QRCodeGenerator from "./QRCodeGenerator";
import { CopyToClipboard } from "react-copy-to-clipboard";

function isObjectEmpty(obj) {
  for (const key in obj) {
    if (obj[key] !== "") {
      return false;
    }
  }
  return true;
}

// This is needed to load worker from an external file in create-react-app environment
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UseTemplateTab = (props) => {
  const { t } = useTranslation();

  let {
    data,
    draftView,
    type,
    templates,
    setTemplates,
    isFromHome,
    isMobile,
    fromArchived,
    setShowAllFormsData,
    fromFormValues,
    setTemplateForAllFormData,
  } = props;
  const [numPages, setNumPages] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [refreshIt, setRefresIt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUseCaseOne, setIsUseCaseOne] = useState(false);
  useEffect(() => {
    if (data?.use_cases?.includes("useCase1")) {
      setIsUseCaseOne(true);
    }
  }, [data]);

  useEffect(() => {
    // console.log("template data at use template tab", data);
    // Render the first page to create the thumbnail
    setNumPages(1);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      toast.error(t("unable to cntinue at the moment!"));
      return null;
    }
  };

  const validateFile = async () => {
    if (fileData) {
      setIsLoading(true);
      try {
        const templateFields = data?.selected_columns_from_csv;
        const fileS3Url = await uploadFileToBackend(fileData);
        fetch(fileS3Url)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            const fileType = fileData.name.split(".").pop();

            if (fileType === "csv") {
              // Parse CSV file using PapaParse
              const csvText = new TextDecoder().decode(data);
              Papa.parse(csvText, {
                complete: (results) => {
                  const parsedData = results.data;
                  const filteredData = parsedData.filter(
                    (entry) => !isObjectEmpty(entry)
                  );
                  processAndDispatch(filteredData, templateFields, fileS3Url);
                },
                header: true,
              });
            } else if (["xls", "xlsx"].includes(fileType)) {
              // Parse Excel file using xlsx
              const workbook = XLSX.read(data, { type: "array" });
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
              });

              // Assuming the first row in the Excel sheet contains headers
              const [headers, ...dataRows] = jsonData;

              // Map data rows to objects with keys based on headers
              const formattedData = dataRows.map((row) => {
                const rowData = {};
                headers.forEach((header, index) => {
                  rowData[header] = row[index];
                });
                return rowData;
              });

              processAndDispatch(formattedData, templateFields, fileS3Url);
            } else {
              // console.error("Unsupported file type:", fileType);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            // console.error("Error fetching or parsing file:", error);
            setRefresIt(true);
            setIsLoading(false);
          });
      } catch (err) {
        // console.error("Error processing file:", err);
        toast.error(t("Choose an appropriate data file!"));
        setRefresIt(true);
        setIsLoading(false);
      }
    }
  };

  const processAndDispatch = (fileData, templateFields, fileS3Url) => {
    const keysOfFile = Object.keys(fileData[0]);

    // Convert both arrays to lowercase
    const lowercaseArray1 = templateFields
      ?.filter((f) => f?.length > 0)
      .map((str) => str.toLowerCase());
    const lowercaseArray2 = keysOfFile
      ?.filter((f) => f?.length > 0)
      .map((str) => str.toLowerCase());

    const allStringsPresent = lowercaseArray1.every((str) =>
      lowercaseArray2.includes(str)
    );

    if (allStringsPresent) {
      dispatch({
        type: "SET_FORM_VALUES_TEMPLATE_TO_OPEN",
        payload: null,
      });
      dispatch({
        type: "SET_TEMPLATE_TO_OPEN",
        payload: {
          templateData: data,
          fileURL: fileS3Url,
          fileJSON: fileData,
        },
      });
      dispatch({
        type: "SET_Directory",
        payload: "useTemplate",
      });
    } else {
      toast.error(
        t("Not all keys in templates are present in the imported file.")
      );
      setRefresIt(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fileData && setIsLoading(true);
    fileData && validateFile();
  }, [fileData]);
  const deleteTemplate = (id) => {
    // toast.success(t("template deleted successfully!"));
    toast.success("template moved to archive!");
    api
      .delete("/templates/" + id)
      .then((res) => {
        // toast.success(t("template deleted successfully!"));
        setTemplates(templates?.filter((d) => d?.id != id));
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(t("unable to delete the template"));
      });
  };

  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const openForm = () => {
    setShowTemplateForm(true);
  };

  const [totalGeneratedDocuments, setTotalGeneratedDocuments] = useState(0);
  useEffect(() => {
    if (data?.id) {
      api
        .get(`/users/generatedDocumentsOfTemplate/${data?.id}`)
        .then(async (res) => {
          let tDoc = 0;
          res?.data?.forEach(
            (d) => (tDoc = tDoc + d?.generated_documents?.length)
          );
          setTotalGeneratedDocuments(tDoc);
        })
        .catch((err) => console.log(err));
    }
  }, [data]);

  const [showShareModal, setShowShareModal] = useState(false);
  const handleOpen = () => setShowShareModal(true);
  const handleClose = () => setShowShareModal(false);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleArchiveOpen = () => setShowArchiveModal(true);
  const handleArchiveClose = () => setShowArchiveModal(false);

  const [checkArchiveText, setCheckArchiveText] = useState("");

  const restoreTemplate = (id) => {
    // toast.success(t("template deleted successfully!"));

    api
      .put("/templates/restore/" + id)
      .then((res) => {
        toast.success(t("template restored successfully!"));
        setTemplates(templates?.filter((d) => d?.id != id));
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(t("unable to restore the template"));
      });
  };
  const permanentlyDeleteTemplate = (id) => {
    // toast.success(t("template deleted successfully!"));

    api
      .delete("/templates/delete-permanently/" + id)
      .then((res) => {
        toast.success(t("template deleted successfully!"));
        setTemplates(templates?.filter((d) => d?.id != id));
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(t("unable to delete the template"));
      });
  };

  const [showPDeletionModal, setShowPDeletionModal] = useState(false);
  const handlePDeletionOpen = () => setShowPDeletionModal(true);
  const handlePDeletionClose = () => setShowPDeletionModal(false);

  const [checkPDeletionText, setCheckPDeletionText] = useState("");

  return (
    <Box>
      <ToastContainer />
      {showTemplateForm && (
        <FinalForm
          isPreview={showTemplateForm}
          setIsPreview={setShowTemplateForm}
          formTitle={data?.complete_form_structure?.form_title}
          formDescription={data?.complete_form_structure?.form_description}
          allFormFields={data?.complete_form_structure?.all_form_fields}
          canvasAddedFields={data?.canvas_added_fields}
          canvasObjects={data?.canvas_objects_for_output}
          enabled={true}
          templateData={data}
        />
      )}
      <Modal
        open={showShareModal}
        // onClose={handleClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {props?.t("Share form to anyone")}
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {props?.t(
                "Every time a new form is submitted, the corresponding credit amount will be deducted from your account"
              )}
            </Typography>

            {/* <Typography sx={{ mt: 2, color: theme.palette.primary.main }}>
              URL:{" "}
              {`${process.env.REACT_APP_Frontend_URL}/form-to-doc/public/${data?.unique_identification_string}`}
            </Typography> */}
            <CopyToClipboard
              text={`${process.env.REACT_APP_Frontend_URL}/form-to-doc/public/${data?.unique_identification_string} `}
              onCopy={() => toast.success("Copied successfully!")}
            >
              <Button
                startIcon={<ContentCopyIcon />}
                sx={{
                  textTransform: "inherit",
                  marginLeft: "50%",
                  transform: "translateX(-50%)",
                  my: 1,
                }}
              >
                {props?.t("Copy Url to clipboard")}
              </Button>
            </CopyToClipboard>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Typography variant="h6" sx={{ mt: 2 }}>
                Share QR Code
              </Typography> */}
              <QRCodeGenerator
                qrString={`${process.env.REACT_APP_Frontend_URL}/form-to-doc/public/${data?.unique_identification_string}`}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showArchiveModal}
        // onClose={handleArchiveClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => {
              handleArchiveClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <ArchiveIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography variant="h6">
                {props?.t("Move to Archive")}: {data?.template_name}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              {props?.t(`This template will be archived. You can restore or delete it in
              the settings menu. The documents and data generated from this
              template will still be available in the "Document library" and/or
              the "Data library"`)}
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: theme.palette.heading.main, fontWeight: "500" }}
              >
                {props?.t("Type template name to proceed")}:{" "}
              </Typography>
              <TextField
                fullWidth
                onChange={(e) => setCheckArchiveText(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowArchiveModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={checkArchiveText !== data?.template_name}
                onClick={() => deleteTemplate(data?.id)}
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showPDeletionModal}

        // onClose={handlePDeletionClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => {
              handlePDeletionClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <DeleteOutlineIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography variant="h6">
                {props?.t("Delete permanently")}: {data?.template_name}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              {props?.t(
                "This template will be deleted permanently.The documents and data generated from this template will also be deleted permanently."
              )}
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: theme.palette.heading.main, fontWeight: "500" }}
              >
                {props?.t("Type template name to proceed")}:{" "}
              </Typography>
              <TextField
                fullWidth
                onChange={(e) => setCheckPDeletionText(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowPDeletionModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={checkPDeletionText !== data?.template_name}
                onClick={() => permanentlyDeleteTemplate(data?.id)}
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "600",
            color: theme.palette.heading.main,
            textTransform: "uppercase",
          }}
        >
          {data?.template_name}
          <Typography
            variant="body2"
            sx={{ color: theme.palette.primary.main, fontWeight: "600" }}
          >
            {" "}
            {data?.template_status == "draft" ? " (draft)" : ""}
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: theme.palette.para.main, mb: 1 }}
        >
          {props?.t("Documents Created")} {totalGeneratedDocuments}
          {/* {data?.documents_created} */}
        </Typography>
        {!isFromHome && (
          <>
            {" "}
            {!fromFormValues && (
              <>
                {" "}
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "400", color: theme.palette.para.main }}
                >
                  {props?.t("Created on")}{" "}
                  {new Date(data?.created_on)?.toDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "400", color: theme.palette.para.main }}
                >
                  {props?.t("Modified on")}{" "}
                  {new Date(data?.modified_on)?.toDateString()}
                </Typography>
              </>
            )}
            {fromArchived ? (
              <Box sx={{ mb: 2 }}>
                <Button
                  // color="secondary"
                  size="small"
                  sx={{ mt: 1, textTransform: "capitalize" }}
                  variant="contained"
                  onClick={() => {
                    restoreTemplate(data?.id);
                  }}
                >
                  {props?.t("restore")}
                </Button>
                <Button
                  // color="delete"
                  size="small"
                  variant="outlined"
                  sx={{
                    mt: 1,
                    textTransform: "inherit",
                    ml: 1,
                    // color: "#fff",
                  }}
                  // variant="contained"
                  // onClick={() => deleteTemplate(data?.id)}
                  onClick={() => setShowPDeletionModal(true)}
                >
                  {props?.t("Delete permanently")}
                </Button>
              </Box>
            ) : fromFormValues ? (
              <Box sx={{ mb: 2 }}>
                <Button
                  // color="secondary"
                  size="small"
                  sx={{ mt: 1, textTransform: "capitalize" }}
                  variant="contained"
                  onClick={() => {
                    setTemplateForAllFormData(data);
                    setShowAllFormsData(true);
                  }}
                >
                  See Forms Data
                </Button>
              </Box>
            ) : (
              <Box sx={{ mb: 2 }}>
                <Button
                  // color="secondary"
                  size="small"
                  sx={{ mt: 1, textTransform: "capitalize" }}
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: "SET_TEMPLATE_TO_OPEN",
                      payload: data,
                    });
                    dispatch({
                      type: "SET_Directory",
                      payload: "templateCreation",
                    });
                  }}
                >
                  {data?.template_status == "draft"
                    ? props?.t("Complete")
                    : props?.t("Edit")}
                </Button>
                <Button
                  // color="delete"
                  size="small"
                  variant="outlined"
                  sx={{
                    mt: 1,
                    textTransform: "inherit",
                    ml: 1,
                    // color: "#fff",
                  }}
                  // variant="contained"
                  // onClick={() => deleteTemplate(data?.id)}
                  onClick={() => setShowArchiveModal(true)}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.8rem", fontWeight: "500", py: 0.4 }}
                  >
                    {props?.t("Move to archive")}
                  </Typography>
                </Button>
                {data?.are_third_parties_allowed &&
                  !fromArchived &&
                  !fromFormValues && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleOpen}
                      sx={{
                        mt: 1,
                        textTransform: "capitalize",
                        ml: 1,
                        // color: "#fff",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "0.8rem", fontWeight: "500", py: 0.3 }}
                      >
                        {props?.t("Share")}
                      </Typography>
                    </Button>
                  )}
              </Box>
            )}
          </>
        )}
      </Box>

      {isLoading && (
        <Box sx={{ width: "100%", p: 1 }}>
          <LinearProgress />
        </Box>
      )}
      {/* {isMobile && isUseCaseOne && (
        <Button
          startIcon={<CreateIcon />}
          size="small"
          variant="contained"
          sx={{ mb: 1 }}
          onClick={() => openForm()}
        >
          Fill up a form
        </Button>
      )} */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", gap: "20px" },
        }}
      >
        <Box
          sx={{
            maxHeight: "180px",
            overflowY: "hidden",
            overflowX: "hidden",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            //   width: draftView == "2" ? "100%" : { md: "max-content", xs: "100%" },
            width: "max-content",
            maxWidth: "250px",
          }}
        >
          <Document
            file={data?.background_url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Loading PDF..."
            error="Failed to load PDF."
          >
            <Page pageNumber={1} width={140} />
          </Document>
        </Box>

        {data?.template_status !== "draft" &&
          !fromArchived &&
          !fromFormValues && (
            <Box>
              {isUseCaseOne ? (
                <Box
                  sx={{
                    width: "150px",
                    height: "100%",
                    // display: { xs: "none", sm: "flex" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid lightgray",
                    // background: theme?.palette.accent.main,
                    cursor: "pointer",
                  }}
                  onClick={() => openForm()}
                >
                  <CreateIcon sx={{ color: theme.palette.primary.main }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1rem",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {props?.t("Fill up a form")}
                  </Typography>
                </Box>
              ) : (
                <FileUploader
                  type="data"
                  setFile={setFileData}
                  refreshIt={refreshIt}
                  isUseTemplateTab={true}
                />
              )}
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default withTranslation()(UseTemplateTab);
