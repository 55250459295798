import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import FinalForm from "../components/TemplateCreation/FormEditor.js/FinalForm";
import { api } from "../axios/api";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ToastContainer, toast } from "react-toastify";
import UseTemplate from "./UseTemplate";
import BackImage from "../utils/images/thirsPartFormBackground.png";
const ThirdPartyFormFilling = () => {
  const params = useParams();
  console.log("params.formUniqueString", params?.formUniqueString);
  const [templateData, setTemplateData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingType, setLoadingType] = useState("");
  const theme = useTheme();
  const recaptchaRef = useRef();
  const [showOutputRecap, setShowOutputRecap] = useState();
  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    if (params?.formUniqueString) {
      api
        .get("/templates/byUniqueString/" + params?.formUniqueString)
        .then((res) => {
          if (res?.data) {
            setLoading(false);
            setTemplateData(res?.data);
          }
        })
        .catch((err) => {
          // console.log("err", err);
          setLoading(false);
        });
    }
  }, [params?.formUniqueString]);
  const [showTemplateForm, setShowTemplateForm] = useState(true);
  const openForm = () => {
    setShowTemplateForm(true);
  };

  const [generatedUrl, setGeneratedUrl] = useState("");

  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        oveflowY: "scroll",
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "whitesmoke",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={BackImage}
        style={{
          zIndex: 1,
          width: "auto",
          top: 0,
          left: 0,
          minWidth: "100vw",
          left: "50%",
          transform: "translateX(-50%)",

          minHeight: "100vh",
          height: "auto",
          position: "absolute",
        }}
      />
      <ToastContainer />
      {showOutputRecap ? (
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              maxWidth: "95vw",
              width: "max-content",
              bgcolor: "background.paper",

              boxShadow: 24,
              maxHeight: "95vh",
              overflowY: "auto",
              p: 4,
            }}
          >
            <UseTemplate isThirdParty={true} />
          </Box>
        </Modal>
      ) : loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {templateData?.id && (
            <FinalForm
              recaptchaRef={recaptchaRef}
              isPreview={showTemplateForm}
              setIsPreview={setShowTemplateForm}
              formTitle={templateData?.complete_form_structure?.form_title}
              formDescription={
                templateData?.complete_form_structure?.form_description
              }
              allFormFields={
                templateData?.complete_form_structure?.all_form_fields
              }
              canvasAddedFields={templateData?.canvas_added_fields}
              canvasObjects={templateData?.canvas_objects_for_output}
              enabled={true}
              templateData={templateData}
              isThirdParty={true}
              setThirdPartyFormValues={setFormValues}
              setShowOutputRecap={setShowOutputRecap}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ThirdPartyFormFilling;
