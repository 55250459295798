import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Lists from "../components/Settings/Lists";
import Checkboxes from "../components/Settings/Checkboxes";
import TimeFormats from "../components/Settings/TimeFormats";
import DateFormats from "../components/Settings/DateFormats";
import Archived from "../components/Settings/Archived";

const Settings = (props) => {
  const theme = useTheme();
  // const [showSettingOf, setShowSettingOf] = useState("lists");
  const [showLists, setShowLists] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showArchivedTemplates, setShowArchivedTemplates] = useState(false);
  return (
    <Container
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            padding: { xs: "20px 10px", sm: "20px 20px" },
            paddingTop: "20px",
          }}
        >
          <Typography variant="h4" sx={{ mt: 1, mb: 1, fontWeight: "600" }}>
            {props?.t("Settings")}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mt: 0,
              mb: 4,
              fontWeight: "500",
              color: theme.palette.para.main,
            }}
          >
            {props?.t("Modify the resources for your templates")}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box
              sx={{
                background: "#fff",
                p: 2,
                transition: "all 0.3s ease",
                // height: "max-content",
              }}
            >
              <Box
                sx={{
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                  cursor: "pointer",
                }}
                onClick={() =>
                  // setShowSettingOf(showSettingOf == "lists" ? "" : "lists")
                  setShowLists(!showLists)
                }
              >
                {" "}
                <Typography variant="h6">{props?.t("Lists")}</Typography>
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() =>
                    // setShowSettingOf(showSettingOf == "lists" ? "" : "lists")
                    setShowLists(!showLists)
                  }
                >
                  <ExpandCircleDownIcon />
                </IconButton>
              </Box>
              {showLists && <Lists />}
            </Box>
            <Box sx={{ background: "#fff", p: 2, borderRadius: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">{props?.t("Checkboxes")}</Typography>
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() => setShowCheckboxes(!showCheckboxes)}
                >
                  <ExpandCircleDownIcon />
                </IconButton>
              </Box>
              {showCheckboxes && <Checkboxes />}
            </Box>
            <Grid container gap={4}>
              <Grid item xs={12} sm={5.7}>
                {" "}
                <Box
                  sx={{
                    background: "#fff",
                    p: 2,
                    borderRadius: "4px",
                  }}
                >
                  {" "}
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() =>
                      // setShowSettingOf(
                      //   showSettingOf == "timeFormats" ? "" : "timeFormats"
                      // )
                      setShowTime(!showTime)
                    }
                  >
                    {" "}
                    <Typography variant="h6">
                      {props?.t("Time formats")}
                    </Typography>
                    <IconButton sx={{ color: theme.palette.primary.main }}>
                      <ExpandCircleDownIcon />
                    </IconButton>
                  </Box>
                  {showTime && <TimeFormats />}
                </Box>
              </Grid>
              <Grid item xs={12} sm={5.9}>
                {" "}
                <Box
                  sx={{
                    background: "#fff",
                    p: 2,
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() =>
                      // setShowSettingOf(
                      //   showSettingOf == "dateFormats" ? "" : "dateFormats"
                      // )
                      setShowDate(!showDate)
                    }
                  >
                    <Typography variant="h6">
                      {props?.t("Date formats")}
                    </Typography>
                    <IconButton sx={{ color: theme.palette.primary.main }}>
                      <ExpandCircleDownIcon />
                    </IconButton>
                  </Box>
                  {showDate && <DateFormats />}
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ background: "#fff", p: 2, borderRadius: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {props?.t("Archived templates")}
                </Typography>
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() =>
                    setShowArchivedTemplates(!showArchivedTemplates)
                  }
                >
                  <ExpandCircleDownIcon />
                </IconButton>
              </Box>
              {showArchivedTemplates && <Archived />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default withTranslation()(Settings);
