import DashboardLayout from "../Layout/DashboardLayout";
import DashboardContainer from "../container/DashboardContainer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/try-it");
    }
  }, []);
  return (
    <DashboardLayout>
      {(selectedItem) => <DashboardContainer selectedItem={selectedItem} />}
    </DashboardLayout>
  );
};

export default Dashboard;
