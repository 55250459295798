import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Templates from "../Pages/Templates";
import TemplateCreation from "../Pages/TemplateCreation";
import { useSelector } from "react-redux";
import UseTemplate from "../Pages/UseTemplate";
import ManageAccounts from "../Pages/ManageAccounts";
import Home from "../Pages/Home";
import GeneratedDocuments from "../Pages/GeneratedDocuments";
import Settings from "../Pages/Settings";
import DataLibrary from "../Pages/DataLibrary";
import AdminCoupon from "../Pages/AdminCoupon";

const DashboardContent = ({ selectedItem }) => {
  const theme = useTheme();

  const directory = useSelector((state) => state.directory);

  const renderPage = () => {
    switch (directory) {
      case "templates":
        return <Templates />;
      case "templateCreation":
        return <TemplateCreation />;
      case "useTemplate":
        return (
          <>
            {" "}
            {/* <Templates /> */}
            <UseTemplate />
          </>
        );
      case "manageAccounts":
        return <ManageAccounts />;
      case "documentsLibrary":
        return <GeneratedDocuments />;
      case "settings":
        return <Settings />;
      case "dataLibrary":
        return <DataLibrary />;
      case "signup-coupon":
        return <AdminCoupon />;
      default:
        return <Home />;
    }
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.backgroundSecondary.main }}>
      {renderPage()}
    </Box>
  );
};

export default DashboardContent;
