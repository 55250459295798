// import { Text } from "slate";
// import { getBlock, getMarked } from "./SlateUtilityFunctions.js";
// import ReactDOMServer from "react-dom/server";

// const { renderToStaticMarkup } = ReactDOMServer;

// export const serialize = (node) => {
//   if (Text.isText(node)) {
//     let string = getMarked(node, node.text);
//     string = renderToStaticMarkup(string);
//     return string;
//   }
//   const children = node.children.map((n) => serialize(n)).join("");

//   let block = getBlock({ children, element: node });
//   block = renderToStaticMarkup(block);

//   return block;
// };

// export const serializer = (editorValue) => {
//   if (editorValue.length > 0) {
//     return editorValue.map((n) => serialize(n)).join("");
//   }
// };

// export const deserializer = (body) => {
//   console.log(body);
// };
/************************************* */
// import { Text } from "slate";
// import { getBlock, getMarked } from "./SlateUtilityFunctions.js";
// import ReactDOMServer from "react-dom/server";

// const { renderToStaticMarkup } = ReactDOMServer;

// export const serialize = (node) => {
//   if (Text.isText(node)) {
//     let string = getMarked(node, node.text);
//     string = renderToStaticMarkup(string);
//     // Replace newline characters with <br> tags
//     return string.replace(/\n/g, "<br>");
//   }

//   const children = node.children.map((n) => serialize(n)).join("");

//   let block = getBlock({ children, element: node });
//   block = renderToStaticMarkup(block);

//   // If the block is empty, add a <br> tag
//   if (children.length === 0) {
//     return "<div><br></div>";
//   }

//   return block;
// };

// export const serializer = (editorValue) => {
//   if (editorValue.length > 0) {
//     return editorValue.map((n) => serialize(n)).join("");
//   }
// };

// export const deserializer = (body) => {
//   console.log(body);
// };
/********************************************* */
import { Text, Element as SlateElement } from "slate";
import { getBlock, getMarked } from "./SlateUtilityFunctions.js";
import ReactDOMServer from "react-dom/server";

const { renderToStaticMarkup } = ReactDOMServer;

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = getMarked(node, node.text);
    string = renderToStaticMarkup(string);
    // Replace newline characters with <br> tags
    return string.replace(/\n/g, "<br>");
  }

  if (SlateElement.isElement(node) && node.type === "button") {
    // Serialize button node with styles
    return renderToStaticMarkup(
      <div
        style={{
          background: "#b8379b",
          padding: "8px",
          color: "#ffffff",
          fontSize: "1rem",
          width: "max-content",
          margin: "10px 0",
          borderRadius: "4px",
        }}
        // {...attributes}
      >
        <a
          href="url-for-downloading-files-from email"
          style={{ textDecoration: "none", color: "white" }}
          // aria-disabled
        >
          {node.children.map((n) => serialize(n))}
        </a>
      </div>
      // <div>
      //   <button
      //     style={{
      //       background: "#b8379b",

      //       padding: "10px",
      //       marginTop: "10px",
      //       marginBottom: "10px",
      //       color: "#fff",
      //       fontSize: "1.2rem",
      //     }}
      //   >
      //     {node.children.map((n) => serialize(n))}
      //   </button>
      // </div>
    );
  }
  if (SlateElement.isElement(node) && node.type === "image") {
    // Serialize image node with styles
    return renderToStaticMarkup(
      <img
        src={node.url}
        alt={node.alt}
        style={{ width: "60%", height: "auto" }}
      />
    );
  }
  if (SlateElement.isElement(node) && node.type === "table") {
    const rows = node.children.map((row) => {
      const cells = row.children.map((cell) => {
        return `<td style="border: 1px solid black;padding:10px;">${cell.children
          .map((n) => serialize(n))
          .join("")}</td>`;
      });

      return `<tr>${cells.join("")}</tr>`;
    });

    return `<table style="border-collapse: collapse; width: 100%;">${rows.join(
      ""
    )}</table>`;
  }

  const children = node.children.map((n) => serialize(n)).join("");

  let block = getBlock({ children, element: node });
  block = renderToStaticMarkup(block);

  // If the block is empty, add a <br> tag
  if (children.length === 0) {
    return "<div><br></div>";
  }

  return block;
};

// import { Text, Element } from "slate";
// import { getBlock, getMarked } from "./SlateUtilityFunctions.js";

// export const serialize = (node) => {
//   if (Text.isText(node)) {
//     return getMarked(node, node.text);
//   }

//   const children = node.children.map((n) => serialize(n));

//   return <Element {...node}>{children}</Element>;
// };

// import ReactDOMServer from "react-dom/server";
// import { serialize } from "./utils/serializer";

// export const serializer = (editorValue) => {
//   if (editorValue.length > 0) {
//     return editorValue
//       .map((n) => ReactDOMServer.renderToStaticMarkup(serialize(n)))
//       .join("");
//   }
// };
