import React, { useEffect, useState } from "react";
import { api } from "../../../axios/api";
import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, collapseToast, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";

const TimeFormats = (props) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [formats, setFormats] = useState([]);
  const [enabledFormats, setEnabledFormats] = useState([]);
  const [isSetToUpdate, setIsSetToUpdate] = useState(false);

  useEffect(() => {
    api
      .get("/formats/enabled/time")
      .then((res) => {
        setEnabledFormats(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    api
      .get("/formats/time")
      .then((res) => {
        setFormats(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isSetToUpdate) {
      api
        .put("/formats/update-enabled/time", { idsArray: enabledFormats })
        .then((res) => {
          toast.success("updated successfully!");

          setIsSetToUpdate(false);
        })
        .catch((err) => {
          // console.log("error", err);
          setIsSetToUpdate(false);
        });
    }
  }, [isSetToUpdate]);
  return (
    <Box>
      <ToastContainer />
      <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
        {props?.t("Enable or disable formats to use in template editor values")}
      </Typography>
      <Box sx={{ my: 1 }}>
        <Box>
          {formats?.map((format, i) => (
            <Box key={i} sx={{ mb: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={enabledFormats?.includes(format?.id)}
                  // disabled
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEnabledFormats([...enabledFormats, format?.id]);
                      setIsSetToUpdate(true);
                    } else {
                      if (enabledFormats?.length < 2) {
                        toast.error(props?.t("you cannot disable all options"));
                      } else {
                        setEnabledFormats(
                          enabledFormats?.filter((f) => f !== format?.id)
                        );
                        setIsSetToUpdate(true);
                      }
                    }
                  }}
                />
                <Typography>{format.format}</Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{ ml: 4.8, mt: -1, color: theme.palette.para.main }}
              >
                {format.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(TimeFormats);
