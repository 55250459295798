import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { api } from "../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";

// This is needed to load worker from an external file in create-react-app environment
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFThumbnail = (props) => {
  let { data, draftView, type, setDraftTemplates, draftTemplates } = props;
  const [numPages, setNumPages] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    // Render the first page to create the thumbnail
    setNumPages(1);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const deleteTemplate = (id) => {
    api
      .delete("/templates/" + id)
      .then((res) => {
        toast.success("template deleted successfully!");
        setDraftTemplates(draftTemplates?.filter((d) => d?.id != id));
      })
      .catch((err) => {
        // console.log("error", err);
        toast?.error("unable to delete the template");
      });
  };
  return (
    <Grid
      container
      direction={draftView == "3" ? "row" : "column"}
      // alignItems="center"
      spacing={1}
    >
      <ToastContainer />
      <Grid
        item
        sx={{
          maxHeight: "180px",
          overflowY: "hidden",
          overflowX: "hidden",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          width: draftView == "2" ? "100%" : { md: "max-content", xs: "100%" },
          maxWidth: "250px",
        }}
      >
        <Document
          file={data?.background_url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
          error="Failed to load PDF."
        >
          <Page pageNumber={1} width={140} />
        </Document>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", color: theme.palette.heading.main }}
        >
          {data?.template_name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: theme.palette.para.main, mb: 1 }}
        >
          {props?.t("Documents Created")} {data?.documents_created}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: theme.palette.para.main }}
        >
          {props?.t("Created on")} {new Date(data?.created_on)?.toDateString()}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: theme.palette.para.main }}
        >
          {props?.t("Modified on")}{" "}
          {new Date(data?.modified_on)?.toDateString()}
        </Typography>
        {type == "template" ? (
          <Button
            sx={{ mt: 1, textTransform: "capitalize" }}
            variant="contained"
            onClick={() => {
              dispatch({
                type: "SET_TEMPLATE_TO_OPEN",
                payload: data,
              });
              dispatch({
                type: "SET_Directory",
                payload: "templateCreation",
              });
            }}
          >
            {props?.t("Use this")}
          </Button>
        ) : (
          <>
            <Button
              color="secondary"
              size="small"
              sx={{ mt: 1, textTransform: "capitalize" }}
              variant="contained"
              onClick={() => {
                dispatch({
                  type: "SET_TEMPLATE_TO_OPEN",
                  payload: data,
                });
                dispatch({
                  type: "SET_Directory",
                  payload: "templateCreation",
                });
              }}
            >
              {props?.t("Edit")}
            </Button>
            <Button
              color="delete"
              size="small"
              sx={{ mt: 1, textTransform: "capitalize", ml: 1, color: "#fff" }}
              variant="contained"
              onClick={() => deleteTemplate(data?.id)}
            >
              Delete
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(PDFThumbnail);
