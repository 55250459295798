import { useState, useEffect } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
// import { Language as LanguageIcon } from "@mui/icons-material";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";
import engFlag from "../utils/images/flags/united-states.png";
import gerFlag from "../utils/images/flags/france.png";
import { Globe as LanguageIcon } from "react-feather";
import { useTheme } from "@mui/material/styles";

//i18n
import i18n from "../i18n";
import languages from "./languages";

const LanguageDropdown = () => {
  const theme = useTheme();
  const [selectedLang, setSelectedLang] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeLang, setActiveLang] = useState({});

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
    setActiveLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mr: { sm: 3 } }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Typography
          sx={{
            color: theme.palette.heading.main,
            display: { xs: "none", sm: "flex" },
          }}
        >
          {get(languages, `${selectedLang}.label`)}
        </Typography>
        <IconButton
          onClick={handleMenuOpen}
          size="small"
          sx={{ color: theme.palette.primary.main, marginRight: "-10px" }}
        >
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Header Language"
            className="me-1"
            height="30"
          />
          {/* <LanguageIcon size={28} strokeWidth={1.9} /> */}
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {map(Object.keys(languages), (key) => (
          <MenuItem
            key={key}
            onClick={() => {
              changeLanguageAction(key);
              setActiveLang(languages[key]);
            }}
            sx={{
              fontWeight: selectedLang === key ? "bold" : "regular",
              display: "flex",
              gap: "10px",
            }}
          >
            <img
              src={get(languages, `${key}.flag`)}
              alt="Header Language"
              className="me-1"
              height="12"
            />
            <Typography sx={{ color: "#000" }}>
              {get(languages, `${key}.label`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default withTranslation()(LanguageDropdown);
