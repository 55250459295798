import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const initialState = {
  user: localStorage.getItem("docspawn_user")
    ? JSON.parse(localStorage.getItem("docspawn_user"))
    : null,
  token: localStorage.getItem("docspawn_token")
    ? JSON.parse(localStorage.getItem("docspawn_token"))
    : null,
  directory: "",
  selectedInput: "data_field",
  formValuesOfSelectedTemplate: {},
  templateToOpen: {},
  emailForSignup: "",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_Directory":
      return {
        ...state,
        directory: action.payload,
      };
    case "SET_TEMPLATE_TO_OPEN":
      return {
        ...state,
        templateToOpen: action.payload,
      };
    case "SET_FORM_VALUES_TEMPLATE_TO_OPEN":
      return {
        ...state,
        formValuesOfSelectedTemplate: action.payload,
      };
    case "SET_EMAIL_FOR_SIGNUP":
      return {
        ...state,
        emailForSignup: action.payload,
      };

    default:
      return state;
  }
}

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
