import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  useMediaQuery,
} from "@mui/material";
import { ToastContainer, collapseToast, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { api } from "../axios/api";
import { Document, Page, pdfjs } from "react-pdf";
import { withTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const GeneratedDocuments = (props) => {
  const [numPages, setNumPages] = useState(null);
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("all");
  const [generatedDocuments, setGeneratedDocuments] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [docsToView, setDocsToView] = useState([]);
  const handleOpen = () => setViewAll(true);
  const handleClose = () => setViewAll(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOption, setSortOption] = useState("newer-first");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const screenVersion = isSmallScreen ? "mobile" : "desktop";

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  const handleChangeSorting = (event) => {
    setSortOption(event.target.value);
  };
  useEffect(() => {
    api
      .get(`/users/generatedDocuments/${user?.email_address}/all`)
      .then((res) => {
        if (filter == "all") {
          setGeneratedDocuments(res?.data);
        }
        if (filter == "form-to-doc") {
          setGeneratedDocuments(
            res?.data?.filter((g) =>
              g?.template?.use_cases?.includes("useCase1")
            )
          );
        }
        if (filter == "data-to-doc") {
          setGeneratedDocuments(
            res?.data?.filter((g) =>
              g?.template?.use_cases?.includes("useCase2")
            )
          );
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [user, filter]);
  useEffect(() => {
    toast.warn("Do not forget to Allow your browser for Multiple Downloads!");
  }, []);

  const downloadAll = (urls) => {
    urls.forEach((url, index) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const a = document.createElement("a");
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `file_${index + 1}.pdf`; // Adjust the file name as needed
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          // console.error(`Error downloading file ${index + 1}:`, error)
        });
    });
  };

  const sortedDocuments = [...generatedDocuments].sort((a, b) => {
    const dateA = new Date(a.generated_on);
    const dateB = new Date(b.generated_on);

    if (sortOption === "newer-first") {
      return dateB - dateA;
    } else if (sortOption === "older-first") {
      return dateA - dateB;
    }

    return 0; // No sorting if filter is not recognized
  });

  const [docsToViewCanvasWidth, SetDocsToViewCanvasWidth] = useState(0);
  const [docsToViewCanvasHeight, SetDocsToViewCanvasHeight] = useState(0);

  return (
    <Container
      sx={{
        minHeight: "100vh",
      }}
    >
      <ToastContainer />
      <Modal
        open={viewAll}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            width: { xs: "95vw", sm: "80vw" },
            bgcolor: "background.paper",
            background: theme.palette.backgroundSecondary.main,
            boxShadow: 24,
            minHeight: "80vh",
            maxHeight: { xs: "90vh", sm: "90vh" },
            overflowY: "auto",
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "20px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {props?.t("Generated Documents")}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                downloadAll(docsToView);
                setViewAll(false);
              }}
            >
              {props?.t("Download All")}
            </Button>
            <Typography></Typography>

            {docsToView?.map((url, index) => (
              <Box key={index} sx={{ width: "max-content" }}>
                <Box
                  sx={{
                    maxHeight:
                      screenVersion == "mobile" ? 300 : docsToViewCanvasHeight,

                    overflowY: "hidden",
                    overflowX: "hidden",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

                    width:
                      screenVersion == "mobile" ? 250 : docsToViewCanvasWidth,
                    maxWidth:
                      screenVersion == "mobile" ? 250 : docsToViewCanvasWidth,
                  }}
                >
                  <Document file={url}>
                    <Page
                      pageNumber={pageNumber}
                      // width={
                      //   generatedDocuments[index]?.template
                      //     ?.canvas_width_on_frontend
                      // }
                      width={
                        screenVersion == "mobile" ? 250 : docsToViewCanvasWidth
                      }
                    />
                  </Document>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          padding: { xs: "20px 10px", sm: "20px 20px" },
          paddingTop: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1,
            mb: 1,
            fontWeight: "600",
            fontSize: { xs: "1.1rem", sm: "2rem" },
          }}
        >
          {props?.t("Documents library")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 0,
            mb: 4,
            fontWeight: "500",
            color: theme.palette.para.main,
            fontSize: { xs: "1rem", sm: "1.3rem" },
          }}
        >
          {props?.t("Remaining credits")}:{" "}
          {user?.account?.total_credits - user?.account?.credits_used}/
          {user?.account?.total_credits}
        </Typography>

        {/* Dropdown filter */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: { xs: 3, sm: 0 },
          }}
        >
          <FormControl sx={{ minWidth: 120, marginBottom: 3 }}>
            <InputLabel
              htmlFor="sort-by"
              sx={{ color: theme.palette.primary.main }}
            >
              {props?.t("Sort By")}
            </InputLabel>
            <Select
              size={screenVersion == "mobile" ? "small" : "large"}
              value={sortOption}
              onChange={handleChangeSorting}
              label="Sort By"
              inputProps={{ id: "sort-by" }}
            >
              <MenuItem value="newer-first">{props?.t("Newer First")}</MenuItem>
              <MenuItem value="older-first">{props?.t("Older First")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120, marginBottom: 3 }}>
            <InputLabel
              htmlFor="filter-by"
              sx={{ color: theme.palette.primary.main }}
            >
              {props?.t("Filter By")}
            </InputLabel>
            <Select
              size={screenVersion == "mobile" ? "small" : "large"}
              value={filter}
              onChange={handleChangeFilter}
              label="Filter By"
              inputProps={{ id: "filter-by" }}
            >
              <MenuItem value="all">{props?.t("All")}</MenuItem>
              {/* <MenuItem value="current-month">
                {props?.t("Current Month")}
              </MenuItem> */}
              <MenuItem value="form-to-doc">{props?.t("Form to Doc")}</MenuItem>
              <MenuItem value="data-to-doc">{props?.t("Data to Doc")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box>
        {/* Table to display generated documents */}
        <TableContainer sx={{ background: "white", borderRadius: "4px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: theme.palette.accent.main }}>
                <TableCell
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {props?.t("Date")}
                </TableCell>
                <TableCell
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {props?.t("Type")}
                </TableCell>
                <TableCell
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {props?.t("Template name")}
                </TableCell>
                <TableCell
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {props?.t("No. of Documents")}
                </TableCell>
                <TableCell
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {props?.t("Action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDocuments?.map((doc, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                    {new Date(doc.generated_on)?.toLocaleString()}{" "}
                  </TableCell>

                  <TableCell sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                    {doc?.template?.use_cases?.includes("useCase1")
                      ? "Form to Doc"
                      : "Data to Doc"}
                  </TableCell>

                  <TableCell sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        textTransform: "uppercase",
                      }}
                    >
                      {doc?.template?.template_name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                    {doc?.generated_documents.length}
                  </TableCell>
                  <TableCell sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <IconButton
                        onClick={() => {
                          setViewAll(true);
                          setDocsToView(doc?.generated_documents);
                          SetDocsToViewCanvasWidth(
                            doc?.template?.canvas_width_on_frontend
                          );
                          SetDocsToViewCanvasHeight(
                            doc?.template?.canvas_height_on_frontend
                          );
                        }}
                      >
                        <RemoveRedEyeIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => downloadAll(doc?.generated_documents)}
                      >
                        <FileDownloadIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default withTranslation()(GeneratedDocuments);
