import React, { useState, useEffect } from "react";

import AES from "crypto-js/aes";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Avatar,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import CircularProgress from "@mui/material/CircularProgress";
import { withTranslation } from "react-i18next";

const VerifyAccount = (props) => {
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  //   const user = useSelector((state) => state.user);
  //   useEffect(() => {
  //     console.log("user", user);
  //   }, [user]);
  const { userId, uniqueString } = useParams();
  useEffect(() => {
    api
      .post("/verifyAccount", { userId, uniqueString })
      .then((res) => {
        setIsAccountVerified(true);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log("error", error);
        // toast.error('')

        setIsLoading(false);
        if (error.response.status === 409) {
          // Handle "Your Account is UnAuthorized" error
          setIsAccountVerified(true);
          toast.success(props?.t("Your Account is already verified"));
        } else {
          // Handle other errors
          setIsAccountVerified(false);
          toast.error(props?.t("Unable to verify your account. Try again"));
        }
      });
  }, [userId, uniqueString]);

  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          //   backgroundColor: theme.palette.brandColorOne.main,
          //   backgroundColor: theme.palette.accent.main,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="sm">
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box
              sx={{
                // backdropFilter: "blur(5px)",
                // backgroundColor: "rgba(255, 255, 255, 0.5)",
                backgroundColor: "#fff",
                p: 4,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
                <PersonIcon />
              </Avatar> */}
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <Box
                  sx={{
                    margin: "20px 0 30px 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "2.2rem",
                      fontWeight: "400",
                      fontFamily: "roboto, sans-serif",
                    }}
                  >
                    DocSpawn
                  </Typography> */}
                  <img
                    src="https://docspawn-bucket-1.s3.eu-central-1.amazonaws.com/Logo.png"
                    style={{ height: "auto", width: "200px" }}
                  />
                </Box>
              </Box>
              <Typography component="h1" variant="h5" color="textPrimary">
                {props?.t("Account Verification")}
              </Typography>
              {isAccountVerified ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{
                        color: "green",
                        marginTop: "20px",
                        marginBottom: "10px",
                        border: "1px solid lightgray",
                        width: "50px",
                        height: "50px",
                        padding: "10px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography>{props?.t("Account Verified")}</Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "20px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      {props?.t("Go to the login page")}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CancelTwoToneIcon
                      sx={{
                        color: "red",
                        marginTop: "20px",
                        marginBottom: "10px",
                        //   border: "1px solid lightgray",
                        width: "100px",
                        height: "100px",
                        padding: "10px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography>
                      {props?.t("Unable to verify your account")}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          )}{" "}
        </Container>
      </Box>
    </Box>
  );
};

export default withTranslation()(VerifyAccount);
