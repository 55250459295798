import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Modal,
  TextField,
  Input,
} from "@mui/material";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import AddCardIcon from "@mui/icons-material/AddCard";

import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddAccountForm from "../components/Admin/AddAccountForm";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { api, setAuthToken } from "../axios/api";
import { setUser, setToken } from "../actions/user";
import { useNavigate } from "react-router-dom";

const ManageAccounts = (props) => {
  const theme = useTheme();
  const [accounts, setAccounts] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchAccounts = async () => {
    api
      .get("/admin/accounts")
      .then((res) => {
        // console.log("accounts", accounts);
        setAccounts(
          res?.data?.filter(
            (r) => r?.email_address != "admin@beta.docspawn.app"
          )
        );
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  useEffect(() => {
    setAccounts([]);
    fetchAccounts();
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);

  const [showPDeletionModal, setShowPDeletionModal] = useState(false);
  const handlePDeletionOpen = () => setShowPDeletionModal(true);
  const handlePDeletionClose = () => setShowPDeletionModal(false);
  const [accountToDelete, setAccountToDelete] = useState();
  const [checkPDeletionText, setCheckPDeletionText] = useState("");
  const deleteAccountPermanently = () => {
    if (accountToDelete?.email_address) {
      api
        .put(
          "/admin/delete-account/" + accountToDelete?.email_address?.trim(),
          { email_address: accountToDelete?.email_address }
        )
        .then((res) => {
          console.log(res?.data);
          console.log("account to delete", accountToDelete);
          toast.success("Account permanently deleted");
          setShowPDeletionModal(false);
          fetchAccounts();
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Unable to delete at the moment!");
          setShowPDeletionModal(false);
        });
    }
  };

  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const handleadCreditOpen = () => setShowAddCreditModal(true);
  const handleadCreditClose = () => setShowAddCreditModal(false);
  const [accountToEdit, setAccountToEdit] = useState();
  const [newCredits, setNewCredits] = useState(0);
  const addCredits = () => {
    if (accountToEdit?.email_address && newCredits) {
      console.log("account to edit", accountToEdit);

      api
        .put("/admin/update-account/", {
          email_address: accountToEdit?.email_address,
          credits_to_add: newCredits,
        })
        .then((res) => {
          console.log(res?.data);
          console.log("account to update", accountToEdit);
          toast.success("Account updated ");
          setShowAddCreditModal(false);
          fetchAccounts();
          setNewCredits(0);
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Unable to update at the moment!");
          setShowAddCreditModal(false);
          setNewCredits(0);
        });
    }
  };

  const [showTransferModalModal, setShowTransferModalModal] = useState(false);
  const handletransferModalOpen = () => setShowTransferModalModal(true);
  const handletransferModalClose = () => setShowTransferModalModal(false);
  const [transferToEmail, setTransferToEmail] = useState("");
  function validateEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex pattern
    return emailRegex.test(email);
  }
  const [emailError, setEmailError] = useState("");

  const transferAccount = () => {
    if (validateEmail(transferToEmail)) {
      setEmailError("");
    } else {
      return setEmailError("Error: enter correct email");
    }
    if (accountToEdit?.email_address && transferToEmail) {
      console.log("account to edit", accountToEdit);
      let body = {
        currentEmail: accountToEdit?.email_address,
        newEmail: transferToEmail,
      };
      console.log("body", body);

      // return;

      api
        .put("/admin/transfer-account/", body)
        .then(async (res) => {
          toast.success("Account updated ");

          setShowTransferModalModal(false);
          fetchAccounts();
          setShowTransferModalModal("");
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Unable to update at the moment!");
          setShowTransferModalModal(false);
          setTransferToEmail("");
        });
    }
  };

  const [showLoginAsUSerModal, setShowLoginAsUSerModal] = useState(false);
  const handleLoginAsUSerOpen = () => setShowLoginAsUSerModal(true);
  const handleLoginAsUSerClose = () => setShowLoginAsUSerModal(false);

  const loginAsUser = () => {
    if (accountToEdit?.email_address) {
      console.log("account to edit", accountToEdit);

      api
        .post("/admin/login-to-user-account", {
          email_address: accountToEdit?.email_address,
        })
        .then((res) => {
          console.log(res?.data);
          let { token } = res?.data;
          setAuthToken(token);
          dispatch(setToken(token));
          setTimeout(() => {
            api
              .get("/user-info/me")
              .then((res) => {
                dispatch(setUser(res?.data));
              })
              .catch((err) => {
                // console.log("error while fetching user info", err);
              });
          }, 1000);
          if (token) {
            setTimeout(() => {
              // navigate("/");
              window.location.reload();
            }, 2000);
          }

          toast.success("Logged in");
          setShowLoginAsUSerModal(false);
          fetchAccounts();
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Unable to login at the moment!");
          setShowLoginAsUSerModal(false);
        });
    }
  };

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Modal open={showLoginAsUSerModal} onClose={handleLoginAsUSerClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <MoveDownIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", mb: 1 }}
              >
                Login to user account: {accountToEdit?.email_address}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              As an admin you can login to user account. You will be completely
              logged in using user original credentials.
            </Typography>

            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowLoginAsUSerModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={() => loginAsUser(accountToEdit?.email_address)}
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={showTransferModalModal} onClose={handletransferModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <MoveDownIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", mb: 1 }}
              >
                {props?.t("Transferring Account")}:{" "}
                {accountToEdit?.email_address}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              This account will be transferred permanently. It will not be
              accessed using preious email. In this process, no data related to
              account will be deleted or modified.
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: theme.palette.heading.main, fontWeight: "500" }}
              >
                Write an email to which you want to transfer this account:
              </Typography>
              <TextField
                type="email"
                fullWidth
                onChange={(e) => setTransferToEmail(e.target.value)}
              />
              {emailError && (
                <Typography color="error">{emailError}</Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowTransferModalModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={!transferToEmail}
                onClick={() => transferAccount(accountToEdit?.email_address)}
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={showPDeletionModal} onClose={handlePDeletionClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <DeleteOutlineIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", mb: 1 }}
              >
                {props?.t("Delete permanently")}:{" "}
                {accountToDelete?.email_address}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              This account will be deleted permanently.The templates, documents
              and data generated for this account will also be deleted
              permanently.
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: theme.palette.heading.main, fontWeight: "500" }}
              >
                Type account email to proceed:
              </Typography>
              <TextField
                fullWidth
                onChange={(e) => setCheckPDeletionText(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowPDeletionModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  // checkPDeletionText?.trim() !==
                  // accountToDelete?.email_address?.trim()
                  checkPDeletionText !== accountToDelete?.email_address
                }
                onClick={() =>
                  deleteAccountPermanently(accountToDelete?.email_address)
                }
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={showAddCreditModal} onClose={handleadCreditClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 4,
          }}
        >
          <Box>
            <Box
              sx={
                {
                  //  display: "flex", alignItems: "center", gap: "5px"
                }
              }
            >
              <AddCardIcon
                sx={{
                  color: theme.palette.primary.main,
                  // background: theme.palette.accent.main,
                  fontSize: "2rem",
                  mb: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#000", mb: 1 }}
              >
                Add credits to: {accountToEdit?.email_address}{" "}
              </Typography>
            </Box>

            <Typography variant="body2">
              Total credits: {" " + accountToEdit?.total_credits}
            </Typography>
            <Typography variant="body2">
              Credits used: {" " + accountToEdit?.credits_used}
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: theme.palette.heading.main, fontWeight: "500" }}
              >
                Set how many credits to add:
              </Typography>
              <Input
                type="number"
                fullWidth
                value={newCredits}
                onChange={(e) => setNewCredits(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "5px", mt: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowAddCreditModal(false)}
              >
                {props?.t("Cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={!newCredits}
                onClick={() => addCredits(accountToEdit?.email_address)}
              >
                {props?.t("Confirm")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          {props?.t("Accounts")}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
          }}
          size="small"
        >
          {showAddForm ? props?.t("View accounts") : props?.t("Add accounts")}
        </Button>
      </Box>

      {showAddForm ? (
        <AddAccountForm
          editOptions={editOptions}
          setShowAddForm={setShowAddForm}
        />
      ) : (
        <>
          <Box sx={{ marginTop: "20px" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50px",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {props?.t("Email")}
                        </TableCell>
                        {/* <TableCell>Customer ID</TableCell> */}

                        {/* <TableCell
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {props?.t("Status")}
                        </TableCell> */}
                        <TableCell
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {props?.t("Total Credits")}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {props?.t("Credits Used")}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {props?.t("Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accounts?.map((contact, i) => (
                        <NormalUser
                          key={i}
                          contact={contact}
                          i={i}
                          accounts={accounts}
                          setAccounts={setAccounts}
                          setShowAddForm={setShowAddForm}
                          setEditOptions={setEditOptions}
                          t={props?.t}
                          setShowPDeletionModal={setShowPDeletionModal}
                          setAccountToDelete={setAccountToDelete}
                          setAccountToEdit={setAccountToEdit}
                          setShowAddCreditModal={setShowAddCreditModal}
                          setShowTransferModal={setShowTransferModalModal}
                          setShowLoginAsUSerModal={setShowLoginAsUSerModal}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

const NormalUser = ({
  contact,
  i,
  accounts,
  setAccounts,
  setEditOptions,
  setShowAddForm,
  t,
  setAccountToDelete,
  setShowPDeletionModal,
  setAccountToEdit,
  setShowAddCreditModal,
  keyTO,
  setShowTransferModal,
  setShowLoginAsUSerModal,
}) => {
  const theme = useTheme();
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (!contact?.isAuthorized) {
      setIsBlocked(true);
    }
  }, [contact]);
  const setAuthorization = (option) => {
    api
      .put("/admin/account/authorization", {
        email_address: contact?.email_address,
        isAuthorized: option,
      })
      .then((res) => {
        toast.success("Updated successfully");
        setIsBlocked(!option);
      })
      .catch((err) => {
        toast.error("unable to do!");
      });
  };

  const editSupplier = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };

  const deleteAccount = (accountToDelete) => {
    // console.log("account to dleetes", accountToDelete);
    // toast.success("account deleted successfully");
    setAccountToDelete(accountToDelete);
    setShowPDeletionModal(true);
  };
  return (
    <TableRow
      key={contact?.id}
      style={{
        backgroundColor: i % 2 == 0 ? "transparent" : "transparent",
        padding: "10px",
      }}
    >
      <TableCell sx={{ width: "50px", padding: "8px 20px" }}>
        {contact?.email_address}
      </TableCell>
      {/* <TableCell sx={{ padding: "8px 20px" }}>
        {!isBlocked ? "Authorized" : "Unauthorized"}
      </TableCell> */}
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.total_credits}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.credits_used}
      </TableCell>

      <TableCell sx={{ padding: "8px 20px" }}>
        <Box sx={{ display: "flex", gap: "5px", flexDirection: "row" }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setAccountToEdit(contact);
              setShowTransferModal(true);
            }}
            sx={{ textTransform: "inherit" }}
          >
            Transfer account
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setAccountToEdit(contact);
              setShowAddCreditModal(true);
            }}
            sx={{ textTransform: "inherit" }}
          >
            Add credits
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setAccountToEdit(contact);
              setShowLoginAsUSerModal(true);
            }}
            sx={{ textTransform: "inherit" }}
          >
            Login as this user
          </Button>
          {isBlocked ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setAuthorization(true);
              }}
              sx={{ textTransform: "inherit" }}
              // sx={{ background: theme.palette.accent.main }}
            >
              {t("Unblock")}
            </Button>
          ) : (
            <>
              {" "}
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setAuthorization(false);
                }}
                sx={{ textTransform: "inherit" }}
              >
                {t("Block")}
              </Button>
            </>
          )}
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={() => deleteAccount(contact)}
            sx={{ textTransform: "inherit" }}
          >
            Delete
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(ManageAccounts);
