import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { useEditor } from "../hooks/useEdtior";
import Navbar from "../components/Navbar";
import { useDispatch } from "react-redux";
import TemplateCreationHeader from "../components/TemplateCreation/Header";
import GeneralInformation from "../components/TemplateCreation/GeneralInformation";
import DataSelection from "../components/TemplateCreation/DataSelection";
import TemplateEditor from "../components/TemplateCreation/TemplateEditor";
import OutputManagement from "../components/TemplateCreation/OutputManagement";
import CircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse"; // CSV parsing library
import * as XLSX from "xlsx"; // Excel parsing library
import "react-toastify/dist/ReactToastify.css";
import FormEditor from "../components/TemplateCreation/FormEditor.js";
import { withTranslation } from "react-i18next";

const TemplateCreation = (props) => {
  const { fabricRef, setFabricCanvas, setObjects, setImageURL } = useEditor();
  const theme = useTheme();
  const [templateOpenendFabricCanvas, setTOFC] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const templateToOpen = useSelector((state) => state.templateToOpen);
  const [superProcessing, setSuperProcessing] = useState(true);
  const [isComebackToEdit, setIsComebackToEdit] = useState(false);
  const [canvasAddedFields, setCanvasAddedFields] = useState([]);
  const [currentTemplateId, setCurrentTemplateId] = useState("");

  const [showFormEditor, setShowFormEditor] = useState(false);

  const [allFormFields, setAllFormFields] = useState([]);

  const [templateCreationSteps, setTemplateCreationSteps] = useState([
    "General Information",
    "Data Selection",
    "Template Editor",
    // "Document Preview",
    "Delivery Options",
  ]);
  const [canvasObjectsForOutput, setCanvasObjectsForOutput] = useState();
  const [isUseCaseOne, setIsUseCaseOne] = useState(false); //was false
  const [templateCreationStep, setTemplateCreationStep] = useState(
    !templateToOpen?.id
      ? 1
      : templateToOpen?.saved_till_step == 1 ||
        templateToOpen?.saved_till_step == "1"
      ? 2
      : parseInt(templateToOpen?.saved_till_step)
  ); //was 1
  const [hasSetUseCaseOne, setHasSetUseCaseOne] = useState(false); //was false
  const [completeFormStructure, setCompleteFormStructure] = useState({});
  const [generalInformation, setGeneralInformation] = useState({});

  const [selectedDataforTemplateEditor, setSelectedDataForTemplateEditor] =
    useState("");
  const [preFilledCanvasObjects, setPreFilledCanvasObjects] = useState([]);
  const [emailTemplateProps, setEmailTemplateProps] = useState({});

  const [CSVFileJSON, SetCSVFileJSON] = useState([]);
  function isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj[key] !== "") {
        return false;
      }
    }
    return true;
  }
  useEffect(() => {
    if (
      hasSetUseCaseOne
      // &&
      // templateCreationStep == "2"
    ) {
      setShowFormEditor(true);
      setTemplateCreationSteps([
        "General Information",
        // "Data Selection",
        "Template Editor",
        "Form Editor",
        "Delivery Options",
      ]);
      setIsUseCaseOne(true);
    } else {
      setShowFormEditor(false);
      setTemplateCreationSteps([
        "General Information",
        "Data Selection",
        "Template Editor",
        // "Form Editor",
        "Delivery Options",
      ]);
      setIsUseCaseOne(false);
    }
  }, [generalInformation, templateCreationStep, hasSetUseCaseOne]);
  // useEffect(() => {
  //   if (generalInformation?.dataSetCSVFile) {
  //     fetch(generalInformation?.dataSetCSVFile)
  //       .then((response) => response.text())
  //       .then((csvText) => {
  //         Papa.parse(csvText, {
  //           complete: (results) => {
  //             const parsedData = results.data;
  //             const filteredData = parsedData.filter(
  //               (entry) => !isObjectEmpty(entry)
  //             );
  //             console.log("CSVFileJSON", filteredData);
  //             SetCSVFileJSON(filteredData);
  //           },
  //           header: true,
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching or parsing CSV:", error);
  //       });
  //   }
  // }, [generalInformation]);
  useEffect(() => {
    if (generalInformation?.dataSetCSVFile) {
      fetch(generalInformation?.dataSetCSVFile)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          const fileType = generalInformation.dataSetCSVFile.split(".").pop();

          if (fileType === "csv") {
            // Parse CSV file using PapaParse
            const csvText = new TextDecoder().decode(data);
            Papa.parse(csvText, {
              complete: (results) => {
                const parsedData = results.data;
                const filteredData = parsedData.filter(
                  (entry) => !isObjectEmpty(entry)
                );

                SetCSVFileJSON(filteredData);
              },
              header: true,
            });
          } else if (["xls", "xlsx"].includes(fileType)) {
            // Parse Excel file using xlsx
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Assuming the first row in the Excel sheet contains headers
            const [headers, ...dataRows] = jsonData;

            // Map data rows to objects with keys based on headers
            const formattedData = dataRows.map((row) => {
              const rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              return rowData;
            });

            SetCSVFileJSON(formattedData);
          } else {
            // console.error("Unsupported file type:", fileType);
          }
        })
        .catch((error) => {
          // console.error("Error fetching or parsing file:", error);
        });
    }
  }, [generalInformation]);

  useEffect(() => {
    setCurrentTemplateId("");
    if (templateToOpen) {
      setCurrentTemplateId(templateToOpen?.id);
      if (templateToOpen?.use_cases?.includes("useCase1")) {
        setIsUseCaseOne(true);
        setHasSetUseCaseOne(true);
        setAllFormFields(
          templateToOpen?.complete_form_structure?.all_form_fields
            ? templateToOpen?.complete_form_structure?.all_form_fields
            : []
        );
        setCompleteFormStructure(
          templateToOpen?.complete_form_structure
            ? templateToOpen?.complete_form_structure
            : { all_form_fields: [] }
        );
      }
      setEmailTemplateProps(templateToOpen?.email_template_props);
      setGeneralInformation({
        templateName: templateToOpen?.template_name,
        templateBackgroundFile: templateToOpen?.background_url,
        selectedUseCases: templateToOpen?.use_cases,
        dataSetCSVFile: templateToOpen?.dataset_url,
      });
      setSelectedDataForTemplateEditor(
        templateToOpen?.selected_columns_from_csv
      );
      setCanvasAddedFields(templateToOpen?.canvas_added_fields);
      setCanvasObjectsForOutput(templateToOpen?.canvas_objects_for_output);
      setIsComebackToEdit(true);
      setPreFilledCanvasObjects(templateToOpen?.pre_filled_canvas_objects);
      if (templateToOpen?.canvas_url) {
        fetch(templateToOpen?.canvas_url)
          .then((response) => response.json())
          .then((data) => {
            // Store the JSON data in the component's state

            // setJsonData(data);

            setFabricCanvas(JSON.stringify(data));
            setImageURL(data?.backgroundImage);
            setObjects(data?.objects);

            setTOFC(data);

            //tempory hide
            // setPreFilledCanvasObjects(data?.objects);
            // setPreFilledCanvasObjects(
            //   templateToOpen?.canvas_objects_for_output?.map((x) => x?.element)
            // );

            // setIsComebackToEdit(true);
          })
          .catch((error) => {
            // console.error("Error fetching JSON data:", error);
          });
      }
      setTemplateCreationStep(
        // templateToOpen?.template_status == "draft" ? 3 : 4
        // templateToOpen?.use_cases?.includes("useCase1") ? 2 : 3
        !templateToOpen
          ? 1
          : templateToOpen?.saved_till_step == 1 ||
            templateToOpen?.saved_till_step == "1"
          ? 2
          : parseInt(templateToOpen?.saved_till_step)
      );
      setSuperProcessing(false);
    } else {
      setSuperProcessing(false);
    }
  }, [templateToOpen]);

  useEffect(() => {
    // console.log("templateOpenendFabricCanvas", templateOpenendFabricCanvas);
    if (templateToOpen && CSVFileJSON && templateOpenendFabricCanvas) {
      setSuperProcessing(false);
    }
  }, [templateToOpen, CSVFileJSON, templateOpenendFabricCanvas]);

  return (
    <Container
      sx={{
        // backgroundColor: theme.palette.background.main,
        minHeight: "100vh",
      }}
    >
      {/* <Navbar /> */}
      <Box sx={{ padding: "20px ", paddingTop: "20px" }}>
        <Typography variant="h5" sx={{ mt: 1, mb: 5, fontWeight: "600" }}>
          {props?.t("Generate PDF Documents in minutes")}
        </Typography>
        {superProcessing ? (
          <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {" "}
            <TemplateCreationHeader
              templateCreationStep={templateCreationStep}
              templateCreationSteps={templateCreationSteps}
              setTemplateCreationStep={setTemplateCreationStep}
              isEditing={templateToOpen?.id ? true : false}
              isUseCaseOne={isUseCaseOne}
            />
            {templateCreationStep == "1" && (
              <GeneralInformation
                setTemplateCreationStep={setTemplateCreationStep}
                generalInformation={generalInformation}
                setGeneralInformation={setGeneralInformation}
                isUseCaseOne={isUseCaseOne}
                setHasSetUseCaseOne={setHasSetUseCaseOne}
                currentTemplateId={currentTemplateId}
                setCurrentTemplateId={setCurrentTemplateId}
                isEditing={templateToOpen?.id ? true : false}
              />
            )}
            {hasSetUseCaseOne ? (
              <>
                {templateCreationStep == "2" && (
                  <>
                    <TemplateEditor
                      setTemplateCreationStep={setTemplateCreationStep}
                      generalInformation={generalInformation}
                      selectedDataforTemplateEditor={
                        selectedDataforTemplateEditor
                      }
                      CSVFileJSON={CSVFileJSON}
                      setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                      preFilledCanvasObjects={
                        isComebackToEdit ? preFilledCanvasObjects : null
                      }
                      canvasAddedFields={
                        isComebackToEdit ? canvasAddedFields : null
                      }
                      setCanvasAddedFields={setCanvasAddedFields}
                      setIsComebackToEdit={setIsComebackToEdit}
                      isTemplateOpened={
                        templateToOpen?.template_name ? true : false
                      }
                      isUseCaseOne={isUseCaseOne}
                      setAllFormFields={setAllFormFields}
                      allFormFields={allFormFields}
                      currentTemplateId={currentTemplateId}
                      setCurrentTemplateId={setCurrentTemplateId}
                    />
                  </>
                )}
                {templateCreationStep == "3" && (
                  <FormEditor
                    setTemplateCreationStep={setTemplateCreationStep}
                    generalInformation={generalInformation}
                    selectedDataforTemplateEditor={
                      selectedDataforTemplateEditor
                    }
                    CSVFileJSON={CSVFileJSON}
                    setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                    preFilledCanvasObjects={
                      // isComebackToEdit ? preFilledCanvasObjects : null
                      preFilledCanvasObjects
                    }
                    canvasAddedFields={
                      // isComebackToEdit ?
                      canvasAddedFields
                      //  : null
                    }
                    setCanvasAddedFields={setCanvasAddedFields}
                    setIsComebackToEdit={setIsComebackToEdit}
                    isTemplateOpened={
                      templateToOpen?.template_name ? true : false
                    }
                    allFormFields={allFormFields}
                    setAllFormFields={setAllFormFields}
                    setCompleteFormStructure={setCompleteFormStructure}
                    completeFormStructure={completeFormStructure}
                    currentTemplateId={currentTemplateId}
                    setCurrentTemplateId={setCurrentTemplateId}
                  />
                )}
              </>
            ) : (
              <>
                {" "}
                {templateCreationStep == "2" && (
                  <>
                    {CSVFileJSON?.length > 0 ? (
                      <DataSelection
                        setTemplateCreationStep={setTemplateCreationStep}
                        setSelectedDataForTemplateEditor={
                          setSelectedDataForTemplateEditor
                        }
                        CSVFileJSON={CSVFileJSON}
                        currentTemplateId={currentTemplateId}
                        setCurrentTemplateId={setCurrentTemplateId}
                        templateToOpen={templateToOpen}
                      />
                    ) : (
                      <Box
                        sx={{
                          padding: "50px",
                          display: "flex",
                          justifyContent: "center",
                          background: theme.palette.white.main,
                          mt: 4,
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                  </>
                )}
                {templateCreationStep == "3" && (
                  <TemplateEditor
                    setTemplateCreationStep={setTemplateCreationStep}
                    generalInformation={generalInformation}
                    selectedDataforTemplateEditor={
                      selectedDataforTemplateEditor
                    }
                    CSVFileJSON={CSVFileJSON}
                    setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                    preFilledCanvasObjects={
                      isComebackToEdit ? preFilledCanvasObjects : null
                    }
                    canvasAddedFields={
                      isComebackToEdit ? canvasAddedFields : null
                    }
                    setCanvasAddedFields={setCanvasAddedFields}
                    setIsComebackToEdit={setIsComebackToEdit}
                    isTemplateOpened={
                      templateToOpen?.template_name ? true : false
                    }
                    currentTemplateId={currentTemplateId}
                    setCurrentTemplateId={setCurrentTemplateId}
                  />
                )}
              </>
            )}
            {templateCreationStep == "4" && (
              <OutputManagement
                setTemplateCreationStep={setTemplateCreationStep}
                generalInformation={generalInformation}
                selectedDataforTemplateEditor={selectedDataforTemplateEditor}
                CSVFileJSON={CSVFileJSON}
                setIsComebackToEdit={setIsComebackToEdit}
                canvasAddedFields={canvasAddedFields}
                isTemplateOpenedIsDraft={
                  templateToOpen?.template_status == "draft" ? true : false
                }
                isTemplateOpened={templateToOpen?.template_name ? true : false}
                templateOpenendFabricCanvas={templateOpenendFabricCanvas}
                canvasObjectsForOutput={canvasObjectsForOutput}
                preFilledCanvasObjects={preFilledCanvasObjects}
                templateToOpen={templateToOpen}
                completeFormStructure={completeFormStructure}
                isUseCaseOne={isUseCaseOne}
                emailTemplateProps={emailTemplateProps}
                setEmailTemplateProps={setEmailTemplateProps}
                currentTemplateId={currentTemplateId}
                setCurrentTemplateId={setCurrentTemplateId}
                forceEntriesCheckForForm={
                  templateToOpen?.force_entries_check_for_form
                    ? templateToOpen?.force_entries_check_for_form
                    : false
                }
                areThirdPartiesAllowed={
                  templateToOpen?.are_third_parties_allowed
                    ? templateToOpen?.are_third_parties_allowed
                    : false
                }
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default withTranslation()(TemplateCreation);
