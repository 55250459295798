import engFlag from "../utils/images/flags/united-states.png";
import frFlag from "../utils/images/flags/france.png";

const languages = {
  fr: {
    label: "French",
    flag: frFlag,
  },

  en: {
    label: "English",
    flag: engFlag,
  },
};

export default languages;
