import "./App.css";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Login from "./Pages/Login";

import { useEffect, useState } from "react";
import Dashboard from "./Pages/Dashboard";
import LandingPage from "./Pages/LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import "./i18n";
import EmailEditingPlay from "./Pages/EmailEditingPlay";

import he from "he";
import StepperTester from "./Pages/Stepper";
import ThirdPartyFormFilling from "./Pages/ThirdPartyFormFilling";
import VerifyAccount from "./Pages/VerifyAccount";
import RecoverPassword from "./Pages/RecoverPassword";

function App() {
  const theme = useTheme();
  const [htmlString, setHtmlString] = useState("");

  return (
    <Box className="App">
      <Router>
        <Routes>
          <Route
            path="/email-editor"
            element={
              <Box sx={{ display: "flex" }}>
                {" "}
                <Box sx={{ flex: "0.5" }}>
                  <EmailEditingPlay setHtmlString={setHtmlString} />
                </Box>
                <Box sx={{ p: 3, border: "3px solid red", flex: "0.5" }}>
                  <h1>Output</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: he.decode(
                        he.decode(
                          he.decode(
                            he.decode(
                              he.decode(he.decode(he.decode(htmlString)))
                            )
                          )
                        )
                      ),
                    }}
                  />
                </Box>
              </Box>
            }
          />
          <Route path="/try-it" element={<LandingPage />} />
          <Route path="/stepper" element={<StepperTester />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/form-to-doc/public/:formUniqueString"
            element={<ThirdPartyFormFilling />}
          />
          <Route path="/signup" element={<Login />} />
          <Route path="/forgot-password" element={<Login />} />
          <Route path="/admin" element={<Login />} />
          <Route exact path="/linkedin" element={<LinkedInCallback />} />
          <Route
            path="/reset-password/:userId/:uniqueString"
            element={<RecoverPassword />}
          />
          <Route
            path="/verify-account/:userId/:uniqueString"
            element={<VerifyAccount />}
          />
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
