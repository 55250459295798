import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact, useSlate } from "slate-react";
import Toolbar from "./Toolbar/Toolbar";
import { getMarked, getBlock } from "./utils/SlateUtilityFunctions.js";
import withLinks from "./plugins/withLinks.js";
import withTables from "./plugins/withTable.js";
import withEmbeds from "./plugins/withEmbeds.js";
import withEquation from "./plugins/withEquation.js";
import "./Editor.css";
import CodeToText from "./Elements/CodeToText/CodeToText";
import { serialize } from "./utils/serializer";
import { Box, Input } from "@mui/material";
import { Transforms, Range, Editor } from "slate";

// const Element = (props) => {
//   console.log("props element", props);
//   return getBlock(props);
// };
const Element = (props) => {
  const { attributes, children, element } = props;
  const editor = useSlate();

  if (element.type === "button") {
    // Apply button styles
    return (
      <div
        style={{
          background: "#009ee2",
          padding: "8px",
          color: "#ffffff",
          fontSize: "1rem",
          width: "max-content",
          borderRadius: "4px",
        }}
        {...attributes}
        onClick={(e) => {
          e.preventDefault();
          // Handle button click as needed
          // You may want to add specific behavior for button clicks
          // For now, let's just remove the button node on click
          // try {
          //   Transforms.removeNodes(editor, { at: editor.selection });
          // } catch (err) {
          //   console.log("error", err);
          // }
          const handleButtonClick = () => {
            const buttonId = "url-for-downloading-docspawn-email-docs";

            const [buttonNodeEntry] = [
              ...Editor.nodes(editor, {
                match: (n) => n.id === buttonId,
                universal: true,
              }),
            ];

            if (buttonNodeEntry) {
              const [, buttonPath] = buttonNodeEntry;
              const [parentNode, parentPath] = Editor.parent(
                editor,
                buttonPath
              );

              // Remove the button node
              Transforms.removeNodes(editor, { at: buttonPath });

              // If the parent becomes empty, remove the parent as well
              if (Editor.isEmpty(editor, parentNode)) {
                Transforms.removeNodes(editor, { at: parentPath });
              }
            }
          };
        }}
      >
        <a
          href="url-for-downloading-files-from email"
          style={{ textDecoration: "none", color: "white" }}
          // aria-disabled
        >
          {children}
        </a>
      </div>
    );
  }

  // Render other elements as before
  // return <div {...attributes}>{children}</div>;
  return getBlock(props);
};
const Leaf = ({ attributes, children, leaf }) => {
  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
};
const SlateEditor = ({
  setHtmlString,
  emailTemplateOptions,
  setStateOfEmailBodyEditor,
  stateOfEmailBodyEditor,
}) => {
  const editor = useMemo(
    () =>
      withEquation(
        withHistory(
          withEmbeds(withTables(withLinks(withReact(createEditor()))))
        )
      ),
    []
  );
  const [value, setValue] = useState(
    stateOfEmailBodyEditor?.length > 0
      ? stateOfEmailBodyEditor
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]
  );
  useEffect(
    () => {
      // return;
      if (emailTemplateOptions?.emailEditorForOption == 1) {
        if (
          emailTemplateOptions?.emailTemplateProps?.adminEmailOptions
            ?.stateOfEmailBodyEditor
        ) {
          // set value of editor here
          setValue(
            emailTemplateOptions?.emailTemplateProps?.adminEmailOptions
              ?.stateOfEmailBodyEditor
          );
        } else {
          setValue([
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ]);
        }
      } else if (emailTemplateOptions?.emailEditorForOption == 2) {
        if (
          emailTemplateOptions?.emailTemplateProps?.datasetEmailOptions
            ?.stateOfEmailBodyEditor
        ) {
          // set value of editor here
          setValue(
            emailTemplateOptions?.emailTemplateProps?.datasetEmailOptions
              ?.stateOfEmailBodyEditor
          );
        } else {
          setValue([
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ]);
        }
      } else if (emailTemplateOptions?.emailEditorForOption == 3) {
        if (
          emailTemplateOptions?.emailTemplateProps?.manualEmailsOptions
            ?.stateOfEmailBodyEditor
        ) {
          // set value of editor here
          setValue(
            emailTemplateOptions?.emailTemplateProps?.manualEmailsOptions
              ?.stateOfEmailBodyEditor
          );
        } else {
          setValue([
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ]);
        }
      }
    },
    [
      // emailTemplateOptions
    ]
  );
  // const [htmlString, setHtmlString] = useEffect("");
  useEffect(() => {
    let htmlV = []; // Initialize as an array
    if (value?.length > 0) {
      value?.forEach((v) => {
        htmlV.push(serialize(v));
      });
    }

    const joinedHTML = htmlV.join(""); // Join all strings

    setHtmlString(joinedHTML);
  }, [value]);

  const handleEditorChange = (newValue) => {
    setValue(newValue);

    setStateOfEmailBodyEditor(newValue);
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const [htmlAction, setHtmlAction] = useState({
    showInput: false,
    html: "",
    action: "",
    location: "",
  });
  const handleCodeToText = (partialState) => {
    setHtmlAction((prev) => ({
      ...prev,
      ...partialState,
    }));
  };
  // Function to handle empty line button click
  // const handleDownloadFileAddClick = (e) => {
  //   e.preventDefault();
  //   // Insert a space at the current selection
  //   const { selection } = editor;
  //   const spaceText = { text: "\n" };
  //   Transforms.insertNodes(editor, spaceText, { at: selection });
  // };
  // const handleDownloadFileAddClick = (e) => {
  //   e.preventDefault();

  //   // Insert a button node with a specific ID at the current selection
  //   const { selection } = editor;
  //   const buttonNode = {
  //     type: "button",
  //     id: "url-for-downloading-docspawn-email-docs",
  //     children: [{ text: "Click to Download" }], // You can customize the button text
  //   };
  //   Transforms.insertNodes(
  //     editor,
  //     {
  //       type: "paragraph",
  //       children: [{ text: "" }],
  //     },
  //     { at: selection }
  //   );
  //   Transforms.insertNodes(editor, buttonNode, { at: selection });

  //   // Check if the button's text is empty, and if so, remove the button node
  //   const [buttonNodeEntry] = [
  //     ...Editor.nodes(editor, {
  //       match: (n) => n.type === "button",
  //       universal: true,
  //     }),
  //   ];

  //   if (buttonNodeEntry) {
  //     const [, buttonPath] = buttonNodeEntry;
  //     const buttonText = buttonPath[buttonPath.length - 1].children[0].text;

  //     if (!buttonText.trim()) {
  //       Transforms.removeNodes(editor, { at: buttonPath });
  //     }
  //   }
  // };
  const handleDownloadFileAddClick = (e) => {
    e.preventDefault();

    // Insert a button node with a specific ID at the current selection
    const { selection } = editor;
    // console.log("selection", selection);
    const buttonNode = {
      type: "button",
      id: "url-for-downloading-docspawn-email-docs",
      children: [{ text: "Click to Download" }], // You can customize the button text
    };

    Transforms.insertNodes(editor, buttonNode, { at: selection });
  };
  const handleAddText = (e) => {
    e.preventDefault();
    const { selection } = editor;
    // console.log("selection", selection);
    Transforms.insertNodes(
      editor,
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      { at: selection }
      // { at: Editor.end(editor, []) }
    );
  };
  return (
    <Slate editor={editor} value={value} onChange={handleEditorChange}>
      <Toolbar
        handleCodeToText={handleCodeToText}
        handleDownloadFileAddClick={handleDownloadFileAddClick}
        handleAddText={handleAddText}
        emailTemplateOptions={emailTemplateOptions}
      />
      <Box
        // className="editor-wrapper"
        // style={{ border: "1px solid #f3f3f3", padding: "0 10px" }}
        sx={{ p: 0 }}
      >
        <Editable
          style={{ background: "#fff", padding: "10px", minHeight: "500px" }}
          placeholder="Write something"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Box>
      {htmlAction.showInput && (
        <CodeToText {...htmlAction} handleCodeToText={handleCodeToText} />
      )}
    </Slate>
  );
};

export default SlateEditor;
