import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Container,
  Typography,
} from "@mui/material";
import Logo from "./Logo";
import { styled, useTheme } from "@mui/material/styles";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.heading.main,
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
const StyledListItemText = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "1rem",
  color: theme.palette.para.main,
  transition: "all 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
    fontWeight: "600",
  },
}));

const Navbar = () => {
  const theme = useTheme();
  return (
    <AppBar
      sx={{
        border: "1px solid lightgray",
        display: "flex",
        padding: "10px 0",
        background: theme.palette.background.main,
      }}
    >
      <Container>
        <Toolbar>
          <Box sx={{ flex: "0.2" }}>
            <Logo />
          </Box>
          <Box sx={{ flex: "0.7", marginLeft: "10%" }}>
            <List
              component="nav"
              aria-label="main menu"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <StyledListItem button>
                <StyledListItemText>Home</StyledListItemText>
              </StyledListItem>
              <StyledListItem button>
                <StyledListItemText>Tempalates</StyledListItemText>
              </StyledListItem>
              <StyledListItem button>
                <StyledListItemText>Forms</StyledListItemText>
              </StyledListItem>
              <StyledListItem button>
                <StyledListItemText>Settings</StyledListItemText>
              </StyledListItem>
            </List>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
