import React, { useEffect, useRef, useState } from "react";
import MuiButton from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PortraitIcon from "@mui/icons-material/Portrait";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { useTheme } from "@mui/material";
import { withTranslation } from "react-i18next";
// import "./DynamicDataField.css";

const DynamicDataField = (props) => {
  const { editor, emailTemplateOptions, t } = props;
  const urlInputRef = useRef();
  const theme = useTheme();
  const [showInput, setShowInput] = useState(false);
  const [formData, setFormData] = useState({
    url: "",
    alt: "",
  });

  const [realFieldToInsert, setRealFieldToInsert] = useState("");
  const insertRealFieldToTemplate = () => {
    const { selection } = editor;
    const textNode = { text: realFieldToInsert };

    Transforms.insertNodes(editor, textNode, { at: selection });

    setShowInput(false);
  };

  const [selection, setSelection] = useState();
  const handleButtonClick = (e) => {
    e.preventDefault();
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);

    setShowInput((prev) => !prev);
  };

  return (
    <Box sx={{ display: "inline", position: "relative" }}>
      <MuiButton
        variant="outlined"
        size="small"
        sx={{
          mt: -1,
          opacity: "1!important",
          border: "none !important",
          background: "#009ee266 !important",
          textTransform: "capitalize",
        }}
        onClick={handleButtonClick}
      >
        insert datafield
      </MuiButton>
      {showInput && (
        <Box
          onMouseLeave={() => setShowInput(false)}
          sx={{
            position: "absolute",
            left: "0",
            backgroundColor: "white",
            padding: "6px 10px",
            border: "1px solid lightgray",
            height: "fit-content",
            width: "max-content",
            zIndex: "1",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection:
                emailTemplateOptions?.emailEditorForOption == 1
                  ? "column"
                  : "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Select
                size="small"
                onChange={(e) => {
                  console.log("e.target.value at select", e.target.value);
                  setRealFieldToInsert(e.target.value);
                }}
                sx={{ minWidth: "100px" }}
              >
                {emailTemplateOptions?.isUseCaseOne
                  ? emailTemplateOptions?.allFormFields?.map((f) => (
                      <MenuItem value={`\${data.${f?.fieldName}}`}>
                        {f?.fieldName}
                      </MenuItem>
                    ))
                  : emailTemplateOptions?.csvKeys?.map((k) => (
                      <MenuItem value={`\${data.${k}}`}>{k}</MenuItem>
                    ))}
              </Select>
              <MuiButton
                size="small"
                variant="outlined"
                onClick={() => {
                  insertRealFieldToTemplate();
                }}
                sx={{ m: 1, opacity: "1 !important" }}
              >
                {t("insert")}
              </MuiButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(DynamicDataField);
