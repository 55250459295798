import React, { useState, useEffect } from "react";
import { Box, Typography, Button, MenuItem } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import XfdfPlay from "./XfdfPlay";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

const TemplateEditor = (props) => {
  const {
    selectedDataforTemplateEditor,
    showPreviewModal,
    generalInformation,
    setTemplateCreationStep,
    CSVFileJSON,
    preFilledCanvasObjects,
    setPreFilledCanvasObjects,
    setIsComebackToEdit,
    setCanvasAddedFields,
    canvasAddedFields,
    isTemplateOpened,
    setShowPreviewModal,
    allowedDocumentGenerationEntries,
    formValuesOfSelectedTemplate,
    allFormFields,
    isUseCaseOne,
    formValues,
  } = props;

  const theme = useTheme();
  // Option i.e currently selected like dataField, checkbox image
  const [selectedOption, setSelectedOption] = useState("");
  // Set of styles that will be added to the text
  const [dataFieldsOptions, setDataFieldsOptions] = useState({});
  // State var for the preview button
  const [isPreview, setIsPreview] = useState();
  // Total count of SCV field
  const [totalData, setTotalData] = useState(100);
  // The state variable in preview section denoting the currently shown Data index
  const [dataShown, setDataShown] = useState(1);
  const [pdfFile, setPdfFile] = useState();
  const [csvData, setCSVData] = useState([]);
  const [showEndPreviewButton, setShowEndPreviewButton] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowEndPreviewButton(true), 5000);
  }, []);
  const [selectedField, setSelectedField] = useState(
    selectedDataforTemplateEditor[0]
  );

  const StyledMenuItem = styled(MenuItem)({
    fontSize: "0.9rem",
  });

  useEffect(() => {
    console.log("csv file json", CSVFileJSON);
    setCSVData(CSVFileJSON);
    if (
      CSVFileJSON?.length > 0 &&
      allowedDocumentGenerationEntries?.length > 0 &&
      showPreviewModal
    ) {
      setCSVData(
        CSVFileJSON?.filter((c, i) =>
          allowedDocumentGenerationEntries?.includes(i + 1)
        )
      );
      setTotalData(allowedDocumentGenerationEntries?.length);
      setTimeout(() => setIsPreview(true), 1000);
    } else if (CSVFileJSON?.length > 0) {
      setCSVData(CSVFileJSON);
      setTotalData(CSVFileJSON?.length);
    } else {
      setTotalData(100);
    }
  }, [CSVFileJSON, allowedDocumentGenerationEntries]);

  const [pdfPageSize, setPdfPageSize] = useState({});
  const fetchSizes = async () => {
    if (generalInformation?.templateBackgroundFile) {
      const response = await fetch(generalInformation?.templateBackgroundFile);
      const pdfData = await response.arrayBuffer();

      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      setPdfPageSize({ height: viewport?.height, width: viewport?.width });
    }
  };
  useEffect(() => {
    fetchSizes();
  }, [generalInformation]);

  return (
    <Box
      sx={{
        transform: {
          xs: "scale(0.25) translate(-140%,-140%)",
          sm: "scale(0.5) translate(-50%,-50%)",
        },

        minWidth: { xs: "350px", sm: "max-content" },
        // position: "absolute",
        // top: "0",
        // left: 0,
      }}
    >
      {!formValuesOfSelectedTemplate && (
        <Box
          sx={{
            mt: 0,
            gap: 3,
            display: "flex",
            padding: "20px",
            // backgroundColor: theme.palette.white.main,
            maxWidth: "1001px",
          }}
        >
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "1001px",
            }}
          >
            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {showEndPreviewButton && (
              <Button
                variant="contained"
                onClick={() => setShowPreviewModal(false)}
              >
                {props?.t("End Preview")}
              </Button>
            )}
          </Box> */}
            {!formValuesOfSelectedTemplate && (
              <Box
                sx={{
                  minHeight: "20px",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  mt: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <ArrowBackIosNewIcon
                    sx={{
                      color: theme.palette.para.main,
                      size: "15px",
                      cursor: "pointer",
                      mr: 3,
                    }}
                    onClick={() => {
                      dataShown === 1
                        ? setDataShown(1)
                        : setDataShown((prev) => prev - 1);
                    }}
                  />
                  <Box
                    sx={{
                      padding: "5px 20px",
                      background: theme.palette.primary.main,
                      borderRadius: "22px",
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "#fff" }}>
                      {props?.t("Data")}: {`${dataShown}/${totalData}`}
                    </Typography>
                  </Box>
                  <ArrowForwardIosIcon
                    sx={{
                      color: theme.palette.para.main,
                      size: "15px",
                      cursor: "pointer",
                      ml: 3,
                    }}
                    onClick={() => {
                      dataShown === totalData
                        ? setDataShown(totalData)
                        : setDataShown((prev) => prev + 1);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {pdfPageSize?.width && pdfPageSize?.height && (
        <XfdfPlay
          selectedDataforTemplateEditor={selectedDataforTemplateEditor}
          setTemplateCreationStep={setTemplateCreationStep}
          dataShown={dataShown}
          isPreview={isPreview}
          csvData={csvData}
          generalInformation={generalInformation}
          selectedField={selectedField}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          dataFieldsOptions={dataFieldsOptions}
          preFilledCanvasObjects={preFilledCanvasObjects}
          setPreFilledCanvasObjects={setPreFilledCanvasObjects}
          setIsComebackToEdit={setIsComebackToEdit}
          setCanvasAddedFields={setCanvasAddedFields}
          canvasAddedFields={canvasAddedFields}
          isTemplateOpened={isTemplateOpened}
          allFormFields={allFormFields}
          isUseCaseOne={isUseCaseOne}
          formValues={formValues}
          pdfPageSize={pdfPageSize}
        />
      )}
    </Box>
  );
};

export default withTranslation()(TemplateEditor);
