import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/NoteAdd";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useDispatch } from "react-redux";
import { api } from "../axios/api";
import PDFThumbnail from "../components/PDFThumbnail";
import UseTemplateTab from "../components/UseTemplateTab";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Templates = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [templates, setTemplates] = useState([]);
  const [templateView, setTemplateView] = useState("1");

  const createNewTemplate = () => {
    dispatch({
      type: "SET_TEMPLATE_TO_OPEN",
      payload: null,
    });
    dispatch({
      type: "SET_Directory",
      payload: "templateCreation",
    });
  };

  useEffect(() => {
    // api.get("/templates/draft").then((res) => {
    //   setDraftTemplates(res?.data);
    // });
    // .catch((err) => console.log(err));
    api
      .get("/templates/all")
      .then((res) => {
        console.log("templates", res?.data);
        setTemplates(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <Container
      sx={{
        minHeight: "100vh",
      }}
    >
      <ToastContainer />

      <Box
        sx={{
          padding: { xs: "20px 10px", sm: "20px 20px" },
          paddingTop: "20px",
        }}
      >
        <Typography variant="h4" sx={{ mt: 1, mb: 1, fontWeight: "600" }}>
          {props?.t("Generate PDF Documents in minutes")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 0,
            mb: 4,
            fontWeight: "500",
            color: theme.palette.para.main,
          }}
        >
          {props?.t("With DocSpawn, work smarter, not harder!")}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon sx={{ fontSize: "2rem" }} />}
            sx={{ textTransform: "capitalize" }}
            onClick={() => createNewTemplate()}
          >
            {props?.t("Create new Template")}
          </Button>
        </Box>
        <Grid
          container
          sx={{ mt: 5, padding: "20px 0", gap: { xs: "10px", sm: "20px" } }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              background: theme.palette.white.main,
              borderRadius: "6px",
              padding: "30px 30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.heading.main }}
              >
                {props?.t("Templates")}
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GridViewIcon
                  onClick={() => setTemplateView("1")}
                  sx={{
                    padding: "4px",
                    fontSize: "1.6rem",
                    cursor: "pointer",
                    borderBottomLeftRadius: "4px",
                    background:
                      templateView != "1"
                        ? theme.palette.backgroundSecondary.main
                        : "lightgray",
                    color:
                      templateView != "1"
                        ? theme.palette.para.main
                        : theme.palette.heading.main,
                  }}
                />

                <AppsIcon
                  onClick={() => setTemplateView("2")}
                  sx={{
                    padding: "4px",
                    fontSize: "1.6rem",
                    cursor: "pointer",
                    background:
                      templateView != "2"
                        ? theme.palette.backgroundSecondary.main
                        : "lightgray",
                    color:
                      templateView != "2"
                        ? theme.palette.para.main
                        : theme.palette.heading.main,
                  }}
                />
                <FormatListBulletedIcon
                  onClick={() => setTemplateView("3")}
                  sx={{
                    padding: "4px",
                    cursor: "pointer",
                    fontSize: "1.6rem",
                    borderTopRightRadius: "6px",
                    background:
                      templateView != "3"
                        ? theme.palette.backgroundSecondary.main
                        : "lightgray",
                    color:
                      templateView != "3"
                        ? theme.palette.para.main
                        : theme.palette.heading.main,
                  }}
                />
              </Box> */}
            </Box>
            <Grid
              container
              rowGap={3}
              sx={{
                mt: 4,
                gap: templateView == "2" ? { md: "15px", xs: "10px" } : "20px",
              }}
            >
              {templates
                // ?.filter((d, i) => i < 1)
                ?.sort((a, b) => a.id - b.id)
                ?.map((t, i) => (
                  <Grid
                    key={i}
                    item
                    xs={
                      templateView === "1" ? 5 : templateView === "2" ? 3.5 : 11
                    }
                    sx={{
                      mb: 3,
                    }}
                  >
                    <UseTemplateTab
                      key={t?.id}
                      keyV={t?.id}
                      data={t}
                      templates={templates}
                      setTemplates={setTemplates}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withTranslation()(Templates);
