import React, { useState, useEffect } from "react";

import AES from "crypto-js/aes";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Avatar,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const RecoverPassword = () => {
  const theme = useTheme();
  //   const user = useSelector((state) => state.user);
  //   useEffect(() => {
  //     console.log("user", user);
  //   }, [user]);
  const { userId, uniqueString } = useParams();

  const navigate = useNavigate();
  const [isUser, setIsUser] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  const handleOptionClick = (isUser) => {
    setIsUser(isUser);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.trim() === "") {
      setPasswordError("password is required");
    }
    if (e.target.value.length < 8) {
      setPasswordError("password is too short!");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordError) {
      var newPassword = AES.encrypt(
        password,
        process.env.REACT_APP_CRYPTO_JS_KEY
      ).toString();
      let body = { userId, uniqueString, newPassword };

      api
        .post(`/recoveryupdatepassword`, body)
        .then((res) => {
          if (res.data) {
            toast.success("Password updated successfully!");
            setPassword("");

            setTimeout(() => {
              // navigate("/");
              setIsPasswordUpdated(true);
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error("Error while updating password. Try again!");
          setPassword("");
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Box
        sx={{
          //   backgroundColor: theme.palette.secondary.main,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              // backdropFilter: "blur(5px)",
              // backgroundColor: "rgba(255, 255, 255, 0.5)",
              backgroundColor: "#fff",
              p: 4,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              <Box
                sx={{
                  margin: "20px 0 30px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: "2.2rem",
                    fontWeight: "400",
                    fontFamily: "roboto, sans-serif",
                  }}
                >
                  DocSpawn
                </Typography> */}
                <img
                  src="https://docspawn-bucket-1.s3.eu-central-1.amazonaws.com/Logo.png"
                  style={{ height: "auto", width: "200px" }}
                />
              </Box>
            </Box>
            <Typography component="h1" variant="h5" color="textPrimary">
              Reset Password
            </Typography>
            {isPasswordUpdated ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneOutlineIcon
                    sx={{
                      color: "green",
                      marginTop: "20px",
                      marginBottom: "10px",
                      border: "1px solid lightgray",
                      width: "50px",
                      height: "50px",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography>password updated successfully!</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: "20px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Continue to the login
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ mt: 4, width: "100%" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="password"
                        type="password"
                        label="New Password"
                        name="password"
                        autoComplete="password"
                        value={password}
                        onChange={handlePasswordChange}
                        error={passwordError !== ""}
                        helperText={passwordError}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default RecoverPassword;
