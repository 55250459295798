import React, { useState, useEffect } from "react";
import { Box, Typography, generateUtilityClasses } from "@mui/material";
import Papa from "papaparse"; // CSV parsing library
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";

import { withTranslation } from "react-i18next";
import { api } from "../../../axios/api";

const DataSelection = (props) => {
  let {
    setTemplateCreationStep,
    setSelectedDataForTemplateEditor,
    CSVFileJSON,
    currentTemplateId,
    templateToOpen,
  } = props;
  const theme = useTheme();
  const [csvData, setCSVData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (CSVFileJSON?.length > 0) {
      setColumnNames(Object.keys(CSVFileJSON[0]));
      setSelectedColumns(Object.keys(CSVFileJSON[0]));
      setSelectedDataForTemplateEditor(Object.keys(CSVFileJSON[0]));
      setCSVData(CSVFileJSON);
    }
  }, [CSVFileJSON]);
  useEffect(() => {
    if (templateToOpen?.selected_columns_from_csv) {
      setSelectedDataForTemplateEditor(
        templateToOpen?.selected_columns_from_csv
      );
      setSelectedColumns(templateToOpen?.selected_columns_from_csv);
    }
  }, [templateToOpen]);

  const handleColumnSelect = (name) => {
    let selected = selectedColumns;
    if (selected?.includes(name)) {
      selected = selected?.filter((u) => u !== name);
    } else {
      selected.push(name);
    }
    setSelectedColumns(selected);
    setSelectedDataForTemplateEditor(selected);
  };

  const saveAndContinue = async () => {
    let selected = selectedColumns;
    if (selected?.includes(name)) {
      selected = selected?.filter((u) => u !== name);
    } else {
      selected.push(name);
    }

    let obj = {
      selected_columns_from_csv: selected?.length > 0 ? selected : [],
      saved_till_step: 2,
    };

    await api
      .put("/templates/" + currentTemplateId, obj)
      .then((res) => {
        // console.log("res?.data while saving templates", res?.data);
      })
      .catch((err) => {
        // console.log("err", err);
      });
    setTemplateCreationStep(3);
  };
  return (
    <Box>
      {" "}
      <Box>
        {csvData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                height: "max-content",
                maxHeight: "370px",
                width: "100%",
                overflow: "auto",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  position: "relative",
                  height: "max-content",
                  maxHeight: "370px",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Table
                  sx={{
                    width: "max-content",
                    overflowX: "auto",
                    tableLayout: "fixed", // Added for fixed layout
                    position: "relative",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: theme.palette.accent.main,
                        backgroundColor: theme.palette.accent.main,
                        position: "sticky", // Stick the header
                        top: 0,
                        zIndex: "1",
                      }}
                    >
                      {columnNames.map((name, index) => (
                        <TableCell
                          key={index}
                          sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={selectedColumns?.includes(name)}
                              onClick={() => handleColumnSelect(name)}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.8rem",
                                fontWeight: "600",

                                color: theme.palette.heading.main,
                                padding: "6px", // Add padding to adjust cell height
                              }}
                            >
                              {name}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.map((data, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {columnNames.map((column, columnIndex) => (
                          <TableCell
                            key={columnIndex}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              padding: "6px", // Add padding to adjust cell height
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                                color: theme.palette.text.primary,
                              }}
                            >
                              {data[column]}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button variant="contained" onClick={() => setTemplateCreationStep(1)}>
          {props?.t("Return Back")}
        </Button>
        <Button variant="contained" onClick={() => saveAndContinue()}>
          {props?.t("Save and Continue")}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation()(DataSelection);
