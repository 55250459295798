import { Box } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import Editor from "../components/SlateEmailEditor/Editor";
import "katex/dist/katex.min.css";

const EmailEditingPlay = ({ setHtmlString }) => {
  return (
    <Box sx={{ p: 3 }}>
      <h1>Docspawn Email</h1>
      <Editor setHtmlString={setHtmlString} />
    </Box>
  );
};

export default EmailEditingPlay;
