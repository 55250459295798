import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tab from "./Tab";
import { withTranslation } from "react-i18next";

const Header = (props) => {
  let {
    templateCreationStep,
    templateCreationSteps,
    setTemplateCreationStep,
    isUseCaseOne,
  } = props;
  const theme = useTheme();
  const [clickability, setClickability] = useState(true);
  useEffect(() => {
    if (isUseCaseOne) {
      if (templateCreationStep == 2) {
        setClickability(false);
        setTimeout(() => {
          setClickability(true);
        }, 5000);
      }
    } else {
      if (templateCreationStep == 3) {
        setClickability(false);
        setTimeout(() => {
          setClickability(true);
        }, 5000);
      }
    }
  }, [templateCreationStep]);
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1001px",
        minHeight: "30px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {templateCreationSteps.map((step, i) => (
        <>
          <Tab
            key={i}
            step={step}
            templateCreationStep={templateCreationStep}
            i={i}
            isEditing={props?.isEditing}
            setTemplateCreationStep={setTemplateCreationStep}
            clickability={clickability}
          />
          {i >= 0 && i < 3 && (
            <ArrowRightAltIcon sx={{ color: theme.palette.accent.main }} />
          )}
        </>
      ))}
    </Box>
  );
};

export default withTranslation()(Header);
