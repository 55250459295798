import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { api } from "../../axios/api";
import { withTranslation } from "react-i18next";
import SignatureInputField from "../TemplateCreation/FormEditor.js/SignatureInputField";
import ImageCropping from "../TemplateCreation/FormEditor.js/ImageCropping/ImageCropping";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ShowFormValues = (props) => {
  let {
    formTitle,
    formDescription,
    allFormFields,
    isPreview,
    setIsPreview,
    enabled,
    templateData,
    canvasAddedFields,
    canvasObjects,
    isFinalPreview,
    finalFormValues,
    generateDocumentsFunction,
    setShowEditFinalForm,
    forceEntriesCheck,
    isUseCaseOne,
    isThirdParty,
    setShowPreviewModal,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(
    isFinalPreview ? finalFormValues : {}
  );
  useEffect(() => {
    if (finalFormValues) {
      setFormValues(finalFormValues);
    }
  }, [isFinalPreview, finalFormValues]);
  const [aspectRatios, setAspectRatios] = useState([]);

  function calculateAspectRatio(width, height, scaleX, scaleY) {
    // If scaleX and scaleY are equal, or scaleY is not provided (assumed equal to scaleX)
    if (scaleY === undefined || scaleX === scaleY) {
      return width / height;
    } else {
      return (width * scaleX) / (height * scaleY);
    }
  }
  useEffect(() => {
    const ImageTypeFormFields = allFormFields?.filter(
      (f) => f?.fieldType == "image"
    );

    let aspectRatios = {};
    if (ImageTypeFormFields?.length > 0 && canvasAddedFields?.length > 0) {
      ImageTypeFormFields?.forEach((field) => {
        let indexAtCanvObjs = canvasAddedFields?.findIndex(
          (f) => f?.field == field?.fieldName
        );

        let co = canvasObjects[indexAtCanvObjs];

        let aspectRatio = calculateAspectRatio(
          co?.width,
          co?.height,
          co?.scaleX,
          co?.scaleY
        );

        aspectRatios = { ...aspectRatios, [field?.fieldName]: aspectRatio };
      });

      setAspectRatios(aspectRatios);
    }
  }, [canvasAddedFields, canvasObjects, allFormFields]);

  //   useEffect(() => {
  //     const initialFormValues = allFormFields?.reduce((acc, field) => {
  //       acc[field.fieldName] = field?.fieldType == "checkbox" ? "" : "";
  //       return acc;
  //     }, {});
  //     setFormValues(initialFormValues);
  //   }, [allFormFields]);

  const handleClose = () => {
    setIsPreview(false);
  };

  const handleInputChange = (fieldName, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };
  const handleFileInputChange = async (fieldName, files) => {
    const file = files[0];
    const formData = new FormData();
    formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: response?.data?.url,
      }));

      // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
    }
  };
  const [errors, setErrors] = useState([]);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  useEffect(() => {
    let isEmpty = false;
    allFormFields?.forEach((field) => {
      if (field?.isMandatory) {
        if (
          formValues[field?.fieldName] == "" &&
          field?.fieldType != "checkbox"
        ) {
          isEmpty = true;
        }
      }
    });
    setAllFieldsFilled(!isEmpty);
  }, [formValues]);
  const onSubmit = () => {
    let isEmpty = false;
    let errorArray = [];

    allFormFields?.forEach((field) => {
      if (field?.isMandatory) {
        if (
          formValues[field?.fieldName] == "" &&
          field?.fieldType != "checkbox"
        ) {
          isEmpty = true;
          errorArray.push({ fieldName: field?.fieldName, label: "required" });
        }
      }
    });
    setErrors(errorArray);

    if (isEmpty) {
      toast.error("Error: Some fields are empty.");
    } else {
      toast.success("Form submitted:", formValues);
      dispatch({
        type: "SET_TEMPLATE_TO_OPEN",
        payload: {
          templateData: templateData,
        },
      });
      dispatch({
        type: "SET_Directory",
        payload: "useTemplate",
      });
      dispatch({
        type: "SET_FORM_VALUES_TEMPLATE_TO_OPEN",
        payload: formValues,
      });
    }
  };

  const [verifiedFields, setVerifiedFields] = useState([]);
  const [allFieldsVerified, setAllFieldsVerified] = useState(false);
  useEffect(() => {
    setAllFieldsVerified(forceEntriesCheck ? false : true);
  }, [forceEntriesCheck]);
  useEffect(() => {
    if (forceEntriesCheck) {
      let all = true;
      allFormFields?.forEach((f) => {
        if (verifiedFields?.includes(f?.fieldName)) {
        } else {
          all = false;
        }
      });

      setAllFieldsVerified(all);
    }
  }, [verifiedFields]);
  return (
    <Box
      sx={{
        display: "flex",
        //  maxHeight: "500px", overflow: "auto"
        background: "#fff",
        px: 2,
        width: "max-content",
      }}
    >
      <Box
        sx={{
          // p: 4,
          py: 2,
          //   position: "absolute",
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%, -50%)",
          background: "#fff",
          //   maxWidth: { xs: "95vw", sm: "700px" },
          //   minWidth: { xs: "70vw", sm: "600px" },
          // width: "700px",
          width: "max-content",
          //   maxHeight: "90vh",
          //   overflowY: "auto",
          borderRadius: "4px",
          // display: "flex",
          // justifyContent: "center",
          // flexDirection: "column",
        }}
      >
        {/* <ToastContainer /> */}
        <Box
          sx={{
            mb: 2,
            // display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
            // alignItems: "center",
          }}
        >
          {/* <Typography
            variant="body2"
            sx={{
              fontWeight: "600",
              fontSize: "1.2rem",
              color: theme.palette.heading.main,
              textTransform: "uppercase",
            }}
          >
            {formTitle ? formTitle : props?.t("Title")}
          </Typography> */}
          <Typography variant="body1" sx={{ color: theme.palette.para.main }}>
            {formDescription && formDescription}
          </Typography>
        </Box>

        <Box
          sx={{
            //   display: "flex",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {allFormFields?.map((field, index) => (
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
              {/* {forceEntriesCheck && (
                <Checkbox
                  size="small"
                  sx={{  display: { xs: "block", sm: "block" } }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVerifiedFields([...verifiedFields, field?.fieldName]);
                    } else {
                      setVerifiedFields(
                        verifiedFields?.filter((f) => f != field?.fieldName)
                      );
                    }
                  }}
                />
              )} */}
              {field.fieldType.toLowerCase() == "checkbox" ? (
                <Box
                  sx={{
                    // display: "flex",
                    // alignItems: "center",

                    my: 1,
                  }}
                >
                  {/* <Checkbox
                      size="small"
                      sx={{ width: "max-content" }}
                      type="checkbox"
                      onChange={(e) => {
                        handleInputChange(field.fieldName, e.target.checked);
                      }}
                    /> */}
                  {/* <Switch
                      // size="small"
                      // sx={{ width: "max-content" }}
                      // type="checkbox"
                      onChange={(e) => {
                        handleInputChange(field.fieldName, e.target.checked);
                      }}
                    /> */}

                  <Box>
                    {field?.fieldDescription ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.heading.main,

                          // // textTransform: "capitalize",
                          fontSize: "1rem",
                        }}
                      >
                        {field?.fieldDescription}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.heading.main,

                          // textTransform: "capitalize",
                          fontSize: "1rem",
                        }}
                      >
                        {field?.fieldName}
                      </Typography>
                    )}
                    {console.log(
                      "formValues[field.fieldName] != true",
                      formValues[field.fieldName] != true,
                      " formValues[field.fieldName] != false &&",
                      formValues[field.fieldName] != false
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      {formValues[field?.fieldName] ? (
                        <img
                          style={{ height: "40px", width: "auto" }}
                          src={
                            canvasAddedFields[
                              canvasAddedFields?.findIndex(
                                (f) => f?.field == field.fieldName
                              )
                            ]?.checkedImage
                          }
                        />
                      ) : (
                        <img
                          style={{ height: "40px", width: "auto" }}
                          src={
                            canvasAddedFields[
                              canvasAddedFields?.findIndex(
                                (f) => f?.field == field.fieldName
                              )
                            ]?.uncheckedImage
                          }
                        />
                      )}
                      {/* <Select
                        disabled
                        error={
                          // !formValues[field.fieldName] &&
                          typeof formValues[field.fieldName] != "boolean" &&
                          field?.isMandatory
                        }
                        size="small"
                        sx={{ mt: 1 }}
                        onChange={(e) => {
                          handleInputChange(
                            field.fieldName,
                            e.target.value == "yes" ? true : false
                          );
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        {canvasAddedFields?.length > 0 && (
                          <MenuItem value="yes">
                            {" "}
                            <img
                              style={{ height: "23px", width: "auto" }}
                              src={
                                canvasAddedFields[
                                  canvasAddedFields?.findIndex(
                                    (f) => f?.field == field.fieldName
                                  )
                                ]?.checkedImage
                              }
                            />
                          </MenuItem>
                        )}

                        {canvasAddedFields?.length > 0 && (
                          <MenuItem value="no">
                            <img
                              style={{ height: "23px", width: "auto" }}
                              src={
                                canvasAddedFields[
                                  canvasAddedFields?.findIndex(
                                    (f) => f?.field == field.fieldName
                                  )
                                ]?.uncheckedImage
                              }
                            />
                          </MenuItem>
                        )}
                      </Select> */}
                      {/* {typeof formValues[field.fieldName] == "boolean" && (
                          <Box>
                            {formValues[field.fieldName] ? (
                              <img
                                style={{ height: "30px", width: "auto" }}
                                src={
                                  canvasAddedFields[
                                    canvasAddedFields?.findIndex(
                                      (f) => f?.field == field.fieldName
                                    )
                                  ]?.checkedImage
                                }
                              />
                            ) : (
                              <img
                                style={{ height: "30px", width: "auto" }}
                                src={
                                  canvasAddedFields[
                                    canvasAddedFields?.findIndex(
                                      (f) => f?.field == field.fieldName
                                    )
                                  ]?.uncheckedImage
                                }
                              />
                            )}
                          </Box>
                        )} */}
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                            color: "green",
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    minWidth: "87%",
                    display: "flex",
                    flexDirection: "column",
                    // gap: "15px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      color: theme.palette.heading.main,

                      // textTransform: "capitalize",
                      fontSize: "1rem",
                      // p: 1,
                    }}
                  >
                    {field?.fieldDescription
                      ? field?.fieldDescription
                      : field.fieldName}{" "}
                    {field?.isMandatory ? "" : "(optional)"}
                  </Typography>
                  {errors?.map((e) => e?.fieldName == field?.fieldName)
                    ?.length > 0 && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",

                        // color: "#fff",
                        // background: "red",
                        // py: 0.3,
                        // px: 1,
                        // borderRadius: "4px",
                        // width: "max-content",
                      }}
                    >
                      {
                        errors?.filter(
                          (e) => e?.fieldName == field?.fieldName
                        )[0]?.label
                      }
                    </Typography>
                  )}
                  {/* <Typography
                    variant="p"
                    sx={{ color: theme.palette.para.main }}
                  >
                    {field?.fieldDescription ? field?.fieldDescription : ""}
                  </Typography> */}
                  {field.fieldType.toLowerCase() == "image" ? (
                    <>
                      {/* <Input
                          sx={{ mt: 1, width: {xs:'60vw',sm:'300px'} }}
                          type="file"
                          inputProps={{
                            accept: ".jpg, .png, .jpeg",
                            multiple: false,
                          }}
                          onChange={(e) => {
                            handleFileInputChange(
                              field.fieldName,
                              e.target.files
                            );
                          }}
                        /> */}
                      {aspectRatios[field?.fieldName] && (
                        <ImageCropping
                          setFormValues={setFormValues}
                          fieldName={field?.fieldName}
                          aspectRatios={aspectRatios}
                          ratio={aspectRatios[field?.fieldName]}
                          isFinalPreview={true}
                        />
                      )}
                      {!formValues[field?.fieldName] && field?.isMandatory && (
                        <Typography variant="body2" color="error">
                          No image selected
                        </Typography>
                      )}

                      {formValues[field?.fieldName] && (
                        <Box
                          sx={{
                            my: 2,
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={formValues[field?.fieldName]}
                            alt="image"
                            style={{
                              height: "auto",
                              width: "200px",
                              zIndex: 1,
                            }}
                          />
                          {forceEntriesCheck && (
                            <Checkbox
                              color="success"
                              sx={{
                                display: { xs: "block", sm: "block" },
                              }}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setVerifiedFields([
                                    ...verifiedFields,
                                    field?.fieldName,
                                  ]);
                                } else {
                                  setVerifiedFields(
                                    verifiedFields?.filter(
                                      (f) => f != field?.fieldName
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </>
                  ) : field.fieldType.toLowerCase() == "list" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ListSelecteOption
                        formValues={formValues}
                        field={field}
                        handleInputChange={handleInputChange}
                      />
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : field.fieldType.toLowerCase() == "signature" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SignatureInputField
                        formValues={formValues}
                        field={field}
                        handleInputChange={handleInputChange}
                        enabled={enabled}
                        isFinalPreview={true}
                        finalURL={formValues[field?.fieldName]}
                      />
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : field.fieldType.toLowerCase() == "date" ? (
                    // <DatePicker
                    //   // selected={formatDateForInput(
                    //   //   formValues[field.fieldName],
                    //   //   field.format
                    //   // )}
                    //   style={{ padding: "20px" }}
                    //   selected={formValues[field.fieldName] || ""}
                    //   onChange={(date) =>
                    //     handleInputChange(field.fieldName, date)
                    //   }
                    //   showTimeSelect={false}
                    //   dateFormat="Pp"
                    // />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        disabled
                        autoComplete={false}
                        aria-autocomplete={false}
                        sx={{ mt: 1, width: { xs: "60vw", sm: "300px" } }}
                        type={field.fieldType.toLowerCase()}
                        value={formValues[field.fieldName] || ""}
                        error={
                          !formValues[field.fieldName] && field?.isMandatory
                        }
                        // value={formatDateForInput(
                        //   formValues[field.fieldName],
                        //   field.format
                        // // )}
                        // value="2022-03-09"
                        onChange={(e) => {
                          handleInputChange(field.fieldName, e.target.value);
                        }}
                      />
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : field.fieldType.toLowerCase() == "time" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        disabled
                        autoComplete={false}
                        aria-autocomplete={false}
                        sx={{ mt: 1, width: { xs: "60vw", sm: "300px" } }}
                        type={field.fieldType.toLowerCase()}
                        value={formValues[field.fieldName] || ""}
                        error={
                          !formValues[field.fieldName] && field?.isMandatory
                        }
                        // value={formatDateForInput(
                        //   formValues[field.fieldName],
                        //   field.format
                        // // )}
                        // value="2022-03-09"
                        onChange={(e) => {
                          handleInputChange(field.fieldName, e.target.value);
                          // let valueTime = formatTimeForInput(
                          //   e.target.value,
                          //   field?.format
                          // );
                          // console.log("value time>>>>", valueTime);
                        }}
                      />
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        disabled
                        autoComplete={false}
                        aria-autocomplete={false}
                        multiline={
                          field.fieldType.toLowerCase() == "multiline-text"
                            ? true
                            : false
                        }
                        sx={{ mt: 1, width: { xs: "60vw", sm: "300px" } }}
                        type={field.fieldType.toLowerCase()}
                        value={formValues[field.fieldName] || ""}
                        error={
                          !formValues[field.fieldName] && field?.isMandatory
                        }
                        // value={formatDateForInput(
                        //   formValues[field.fieldName],
                        //   field.format
                        // // )}
                        // value="2022-03-09"
                        onChange={(e) => {
                          handleInputChange(field.fieldName, e.target.value);
                        }}
                      />
                      {forceEntriesCheck && (
                        <Checkbox
                          color="success"
                          sx={{
                            display: { xs: "block", sm: "block" },
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVerifiedFields([
                                ...verifiedFields,
                                field?.fieldName,
                              ]);
                            } else {
                              setVerifiedFields(
                                verifiedFields?.filter(
                                  (f) => f != field?.fieldName
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              )}{" "}
            </Box>
          ))}
        </Box>

        <Box
          sx={{ mt: 4, display: "flex", justifyContent: "left", gap: "10px" }}
        >
          {enabled ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",

                width: "100%",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowPreviewModal(false);
                  setShowEditFinalForm(true);
                }}
              >
                Edit Values
              </Button>
              {isUseCaseOne && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => generateDocumentsFunction()}
                  sx={{ display: { xs: "block", sm: "block" } }}
                  disabled={!allFieldsVerified}
                >
                  Spawn Documents
                </Button>
              )}

              {/* <Button variant="contained" color="primary" onClick={handleClose}>
                {props?.t("Cancel")}
              </Button>{" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={onSubmit}
                sx={{ ml: 2 }}
                disabled={!allFieldsFilled}
              >
                {props?.t("Submit")}
              </Button> */}
            </Box>
          ) : (
            <Button variant="contained" color="primary" onClick={handleClose}>
              {props?.t("Close Preview")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(ShowFormValues);
const ListSelecteOption = ({ formValues, field, handleInputChange }) => {
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    setListItems(field?.listItems);
    if (field?.listId) {
      api
        .get("/lists/items/" + field?.listId)
        .then((res) => {
          if (res?.data?.length > 1) {
            setListItems(res?.data);
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [field]);

  return (
    <Autocomplete
      disabled
      options={listItems}
      value={formValues[field.fieldName] || null}
      onChange={(event, newValue) => {
        handleInputChange(field.fieldName, newValue ? newValue : "");
      }}
      sx={{ width: { xs: "60vw", sm: "300px" }, mt: 1 }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!formValues[field.fieldName] && field?.isMandatory}
          // label={field.fieldName}
          variant="outlined"
        />
      )}
    />
  );
};
// const ListSelecteOption = ({ formValues, field, handleInputChange }) => {
//   const [listItems, setListItems] = useState([]);
//   useEffect(() => {
//     console.log("field?.listId");
//     setListItems(field?.listItems);
//     if (field?.listId) {
//       console.log("list id", field?.listId);
//       api
//         .get("/lists/items/" + field?.listId)
//         .then((res) => {
//           console.log("res", res?.data);
//           if (res?.data?.length > 1) {
//             setListItems(res?.data);
//           }
//         })
//         .catch((err) => console.log("err", err));
//     }
//   }, [field]);
//   return (
//     <Select
//       sx={{ width: {xs:'60vw',sm:'300px'} }}
//       value={formValues[field.fieldName] || ""}
//       onChange={(e) => {
//         handleInputChange(field.fieldName, e.target.value);
//       }}
//     >
//       {listItems?.map((item) => (
//         <MenuItem value={item}>{item}</MenuItem>
//       ))}
//     </Select>
//   );
// };

const formatDateForInput = (dateValue, format) => {
  console.log("date value", dateValue);
  console.log("date format", format);
  if (!dateValue) return "";

  const date = new Date(dateValue);
  let formattedDate = "";

  switch (format) {
    case "MM/DD/YY":
      formattedDate = `${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "DD/MM/YY":
      formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YY/MM/DD":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
      break;
    case "Month D, Yr":
      formattedDate = `${getMonthName(
        date.getMonth()
      )} ${date.getDate()}, ${date.getFullYear()}`;
      break;
    case "M/D/YY":
      formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "D/M/YY":
      formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YY/M/D":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}/${date.getMonth() + 1}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      break;
    case " bM/bD/YY":
      formattedDate = ` ${date.getMonth() + 1}/${date.getDate()}/${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case " bD/bM/YY":
      formattedDate = `${date.getDate()} /${date.getMonth() + 1}/${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YY/ bM/bD":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")} /${date.getMonth() + 1} /${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      break;
    case "MMDDYY":
      formattedDate = `${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "DDMMYY":
      formattedDate = `${date.getDate().toString().padStart(2, "0")}${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YYMMDD":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}`;
      break;
    case "MonDDYY":
      formattedDate = `${getMonthAbbreviation(date.getMonth())}${date
        .getDate()
        .toString()
        .padStart(2, "0")}${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}`;
      break;
    case "DDMonYY":
      formattedDate = `${date
        .getDate()
        .toString()
        .padStart(2, "0")}${getMonthAbbreviation(date.getMonth())}${(
        date.getFullYear() % 100
      )
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YYMonDD":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}${getMonthAbbreviation(date.getMonth())}${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      break;
    case "day/YY":
      formattedDate = `${getDayOfYear(date)}/${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}`;
      break;
    case "YY/day":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}/${getDayOfYear(date)}`;
      break;
    case "D Month, Yr":
      formattedDate = `${date.getDate()} ${getMonthName(
        date.getMonth()
      )}, ${date.getFullYear()}`;
      break;
    case "Yr, Month D":
      formattedDate = `${date.getFullYear()}, ${getMonthName(
        date.getMonth()
      )} ${date.getDate()}`;
      break;
    case "Mon-DD-YYYY":
      formattedDate = `${getMonthAbbreviation(date.getMonth())} ${date
        .getDate()
        .toString()
        .padStart(2, "0")}, ${date.getFullYear()}`;
      break;
    case "DD-Mon-YYYY":
      formattedDate = `${date
        .getDate()
        .toString()
        .padStart(2, "0")} ${getMonthAbbreviation(
        date.getMonth()
      )} ${date.getFullYear()}`;
      break;
    case "YYYYY-Mon-DD":
      formattedDate = `${(date.getFullYear() % 100)
        .toString()
        .padStart(2, "0")}${getMonthAbbreviation(date.getMonth())}${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      break;
    case "Mon DD, YYYY":
      formattedDate = `${getMonthAbbreviation(date.getMonth())} ${date
        .getDate()
        .toString()
        .padStart(2, "0")}, ${date.getFullYear()}`;
      break;
    case "DD Mon, YYYY":
      formattedDate = `${date
        .getDate()
        .toString()
        .padStart(2, "0")} ${getMonthAbbreviation(
        date.getMonth()
      )}, ${date.getFullYear()}`;
      break;
    case "YYYY, Mon DD":
      formattedDate = `${date.getFullYear()}, ${getMonthAbbreviation(
        date.getMonth()
      )} ${date.getDate().toString().padStart(2, "0")}`;
      break;
    default:
      // Handle default case
      break;
  }
  console.log("formatted date", formattedDate);
  console.log("type of formatted date");
  return formattedDate;
};

const getMonthName = (monthIndex) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthIndex];
};

const getMonthAbbreviation = (monthIndex) => {
  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthAbbreviations[monthIndex];
};

const getDayOfYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day.toString().padStart(3, "0");
};

const formatTimeForInput = (timeValue, format) => {
  if (!timeValue) return "";

  const date = new Date(`1970-01-01T${timeValue}`);
  let formattedTime = "";

  switch (format) {
    case "HH:MM:SS":
      formattedTime = date.toISOString().substr(11, 8);
      break;
    case "HH:MM:SS XM":
      formattedTime = date.toLocaleTimeString([], { hour12: true });
      break;
    case "HH:MM":
      formattedTime = date.toISOString().substr(11, 5);
      break;
    case "HH:MM XM":
      formattedTime = date
        .toLocaleTimeString([], { hour12: true })
        .substr(0, 5);
      break;
    default:
      // Handle default case
      break;
  }

  return formattedTime;
};
