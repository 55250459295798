import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { display } from "@mui/system";
import { withTranslation } from "react-i18next";

const QRCodeGenerator = ({ qrString, ...props }) => {
  // console.log("qr string", qrString);
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <QRCode value={qrString} />
      <Typography sx={{ mt: 2 }}>{props?.t("OR")}</Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mt: 2,
        }}
      >
        {" "}
        <WhatsappShareButton url={qrString}>
          <Box
            sx={{
              display: "flex",
              width: "220px",

              gap: "20px",
              p: 1,
              background: theme.palette.primary.main,
              color: "#fff",
            }}
          >
            <ShareRoundedIcon />
            <Typography>{props?.t("Share on Whatsapp")}</Typography>
          </Box>
        </WhatsappShareButton>
        <FacebookShareButton url={qrString}>
          <Box
            sx={{
              display: "flex",
              width: "220px",
              background: theme.palette.primary.main,
              color: "#fff",
              gap: "20px",
              p: 1,
            }}
          >
            <ShareRoundedIcon />
            <Typography>{props?.t("Share on Facebook")}</Typography>
          </Box>
        </FacebookShareButton>
        <TwitterShareButton url={qrString}>
          <Box
            sx={{
              display: "flex",
              width: "220px",
              background: theme.palette.primary.main,
              color: "#fff",
              gap: "20px",
              p: 1,
            }}
          >
            <ShareRoundedIcon />
            <Typography>
              {" "}
              {props?.t("Share on X (formerly Twitter)")}
            </Typography>
          </Box>
        </TwitterShareButton>
      </Box>
    </Box>
  );
};

export default withTranslation()(QRCodeGenerator);
