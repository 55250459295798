import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";

const UseCase = (props) => {
  let { selectedUseCases, handleSelectedUseCases, handleNext } = props;
  const theme = useTheme();

  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ display: "flex", gap: "20px", mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "250px",
            p: 2,
            color: "#fff",
            // background: theme.palette.primary.main,
            background: theme.palette.white.main,
            gap: "10px",
            borderRadius: "4px",
            cursor: "pointer",

            "&:hover": {
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            },
            // boxShadow:
            //   selectedUseCases?.includes("useCase1") &&
            //   "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            border: selectedUseCases?.includes("useCase1") && "2px solid white",
            border: "2px solid pink",
            color: theme.palette.primary.main,
            borderColor: theme.palette.accent.main,
          }}
          // checked={selectedUseCases?.includes("useCase1")}
          onClick={() => {
            handleSelectedUseCases("useCase1");
            handleNext();
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              // borderBottom: "1px solid red",
              pb: 1,
              borderColor: theme.palette.white.main,
            }}
          >
            {props?.t("Form to Doc")}
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            {props?.t("Generate documents through form filling in DocSpawn")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "250px",
            p: 2,
            color: "#fff",
            color: theme.palette.primary.main,
            // background: theme.palette.primary.main,
            background: theme.palette.white.main,
            gap: "10px",
            borderRadius: "4px",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            },

            // boxShadow:
            //   selectedUseCases?.includes("useCase2") &&
            //   "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            border: selectedUseCases?.includes("useCase2") && "2px solid white",
            border: "2px solid pink",
            borderColor: theme.palette.accent.main,
          }}
          // checked={selectedUseCases?.includes("useCase2")}
          onClick={() => {
            handleSelectedUseCases("useCase2");
            handleNext();
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              // borderBottom: "1px solid black",
              pb: 1,
              borderColor: theme.palette.white.main,
            }}
          >
            {props?.t("Data to Doc")}
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            {props?.t(
              "Generate documents from external data sources in csv or xlsx format"
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(UseCase);
