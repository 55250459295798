import React, { useState, useEffect, useRef } from "react";
import DataSelection from "../components/UseTemplate/DataSelection";

import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { useEditor } from "../hooks/useEdtior";
import { useDispatch } from "react-redux";
import DocumentPreview from "../components/UseTemplate/DocumentsPreview/index";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { ToastContainer, collapseToast, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import DeliveryOptionsPreview from "../components/UseTemplate/DeliveryOptionsPreview";
import { api } from "../axios/api";
import FinalForm from "../components/TemplateCreation/FormEditor.js/FinalForm";
import ShowFormValues from "../components/UseTemplate/ShowFormValues";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

//checking is ur is of image or not
function isImageUrl(url) {
  // Regular expression to check if the string is a valid URL
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

  // Check if the string is a valid URL and ends with a valid image extension
  return urlPattern.test(url) && imageExtensions.test(url);
}

const UseTemplate = (props) => {
  const { isThirdParty } = props;
  const { t } = useTranslation();
  const recaptchaRef = useRef();

  const templateToOpen = useSelector((state) => state?.templateToOpen);
  const formValuesOfSelectedTemplate = useSelector(
    (state) => state?.formValuesOfSelectedTemplate
  );

  const user = useSelector((state) => state.user);
  const [templateData, setTemplateData] = useState({});
  const [csvURL, setCSVURL] = useState({});
  const [CSVFileJSON, SetCSVFileJSON] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [showEditFinalForm, setShowEditFinalForm] = useState(false);
  const [
    allowedDocumentGenerationEntries,
    setAllowedDocumentGenerationEntries,
  ] = useState([]);
  const [finalDataForCsv, setFinalDataForCSV] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");

  useEffect(() => {
    if (
      templateToOpen?.templateData &&
      templateToOpen?.fileURL &&
      templateToOpen?.fileJSON
    ) {
      setTemplateData(templateToOpen?.templateData);
      setCSVURL(templateToOpen?.fileURL);
      SetCSVFileJSON(templateToOpen?.fileJSON);
    }
    if (formValuesOfSelectedTemplate) {
      if (templateToOpen?.templateData) {
        setTemplateData(templateToOpen?.templateData);
      }
    }
  }, [templateToOpen]);

  const [allFormFields, setAllFormFields] = useState([]);
  const [isUseCaseOne, setIsUseCaseOne] = useState(false); //was false

  const { fabricRef, setFabricCanvas, setObjects, setImageURL } = useEditor();
  const theme = useTheme();
  const [templateOpenendFabricCanvas, setTOFC] = useState();
  const dispatch = useDispatch();

  const [superProcessing, setSuperProcessing] = useState(true);

  const [isComebackToEdit, setIsComebackToEdit] = useState(false);
  const [canvasAddedFields, setCanvasAddedFields] = useState([]);

  const [canvasObjectsForOutput, setCanvasObjectsForOutput] = useState();
  const [templateCreationStep, setTemplateCreationStep] = useState(1);
  const [generalInformation, setGeneralInformation] = useState({});
  const [selectedDataforTemplateEditor, setSelectedDataForTemplateEditor] =
    useState("");
  const [preFilledCanvasObjects, setPreFilledCanvasObjects] = useState([]);

  useEffect(() => {
    if (templateData) {
      if (templateData?.use_cases?.includes("useCase1")) {
        setIsUseCaseOne(true);
        setAllFormFields(
          templateData?.complete_form_structure?.all_form_fields
        );
      }
      setGeneralInformation({
        templateName: templateData?.template_name,
        templateBackgroundFile: templateData?.background_url,
        selectedUseCases: templateData?.use_cases,
        dataSetCSVFile: templateData?.dataset_url,
      });
      setSelectedDataForTemplateEditor(templateData?.selected_columns_from_csv);
      setCanvasAddedFields(templateData?.canvas_added_fields);
      setCanvasObjectsForOutput(templateData?.canvas_objects_for_output);
      setIsComebackToEdit(true);
      setPreFilledCanvasObjects(templateData?.pre_filled_canvas_objects);
      if (templateData?.canvas_url) {
        fetch(templateData?.canvas_url)
          .then((response) => response.json())
          .then((data) => {
            setFabricCanvas(JSON.stringify(data));
            setImageURL(data?.backgroundImage);
            setObjects(data?.objects);
            setTOFC(data);
            setTemplateCreationStep(
              templateData?.template_status == "draft" ? 3 : 4
            );
          })
          .catch((error) => {
            // console.error("Error fetching JSON data:", error);
          });
      }
    } else {
      setSuperProcessing(false);
    }
  }, [templateData]);
  useEffect(() => {
    if (templateData && CSVFileJSON && templateOpenendFabricCanvas) {
      setSuperProcessing(false);
    }
  }, [templateData, CSVFileJSON, templateOpenendFabricCanvas]);

  const sendRequest = async (pdf, csv, canvas) => {
    let ids = [];
    if (templateData?.template_name) {
      await Promise.all(
        canvas.objects.map((element, index) => {
          //chnaged on 3 jan,2024 because by using canvas objectfor output we are not getting output ids somehow
          // if (canvasObjectsForOutput[index]?.field) {
          //   ids.push(canvasObjectsForOutput[index].field);
          // }
          if (templateData?.canvas_added_fields[index]?.field) {
            ids.push(templateData?.canvas_added_fields[index].field);
          }
        })
      );
    } else {
      await Promise.all(
        canvas.objects.map((element, index) => {
          if (objects[index].id) {
            ids.push(objects[index].id);
          }
        })
      );
    }
    /**************** */
    await api
      .post("/form-values", {
        template_id: templateData?.id,
        form_values: JSON.stringify(formValuesOfSelectedTemplate),
      })
      .then((res) => {
        // console.log("res?.data from submitting form values", res?.data);
      })
      .catch((err) => {
        // console.log("error while uploading form vlaues", err);
      });
    // return;
    /********************** */
    // console.log("ids before sending request>>>>>>>>>>>>>>>>", ids);
    const adminDATA = await api
      .get("/user-info/by-id/" + templateData?.user_id)
      .then((res) => res?.data)
      .catch((err) => {
        // console.log;
      });

    api
      .post(
        "/manipulatePDF",
        {
          pdfURL: pdf,
          csvURL: csv,
          canvas: canvas,
          finalDataForCsv,
          pdfPageSize,
          ids,
          canvasHeightFrontend: templateData?.canvas_height_on_frontend,
          canvasWidthFrontend: templateData?.canvas_width_on_frontend,
          email: adminDATA?.email_address,
          allowedDocumentGenerationEntries,
          templateId: templateData?.id,
          formValues: formValuesOfSelectedTemplate,
          isUseCaseOne: formValuesOfSelectedTemplate ? true : false,
          recipients_options: templateData?.recipients,
          addedFieldsDetails: templateData?.canvas_added_fields,
          recipient_email_address_field:
            templateData?.recipient_email_address_field,
          emailTemplateProps: templateData?.email_template_props,
          multipleEmailsOfManualRecipeints:
            templateData?.multipleEmailsOfManualRecipeints,
          allFormFields: templateData?.complete_form_structure?.all_form_fields,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setGeneratedUrl(response?.data);
        // toast.success(
        //   t(
        //     "documents generated successfully! We have sent you email with generated documents."
        //   )
        // );
        // setLoading(false);
        // setLoadingType("");
        // setTimeout(() => {
        //   dispatch({
        //     type: "SET_Directory",
        //     payload: "home",
        //   });
        // }, 3000);
      })
      .catch((error) => {
        // console.log("error", error);
        if (error?.response?.status === 403) {
          toast.error(t("You don't have enough credits"));
        } else {
          toast.error(t("Unable to save template as draft"));
        }
        setLoading(false);
        setLoadingType("");
      });
  };

  const generateDocuments = () => {
    setLoading(true);
    setLoadingType("Generating Documents");

    if (templateData?.template_name) {
      sendRequest(
        generalInformation?.templateBackgroundFile,
        generalInformation?.dataSetCSVFile,
        templateOpenendFabricCanvas
      );
    }
  };

  /********************* */
  const [pdfPageSize, setPdfPageSize] = useState({});
  const fetchSizes = async () => {
    if (templateData?.background_url) {
      const response = await fetch(templateData?.background_url);
      const pdfData = await response.arrayBuffer();

      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      setPdfPageSize({ height: viewport?.height, width: viewport?.width });
    }
  };
  useEffect(() => {
    fetchSizes();
  }, [templateData]);

  const [showComponent, setShowComponent] = useState(true);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [showMobilePreview, setShowMobilePreview] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsPreviewLoading(true);
    }, 1000);
  }, [showEditFinalForm]);

  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          // return 0;
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, [showEditFinalForm, showMobilePreview]);
  useEffect(() => {
    setProgress(0);
  }, [showEditFinalForm]);

  useEffect(() => {
    console.log("is final preview", isPreviewLoading);
    console.log("show edit final form", showEditFinalForm);
  }, [isPreviewLoading]);
  return (
    <Modal
      open={showComponent}
      // onClose={() => setShowComponent(false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "100vw",
          minWidth: 300,
          // width: isUseCaseOne ? "max-content" : "85vw",
          // width: "max-content",
          width: { xs: "100vw", sm: "max-content" },
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          p: 0,
          // height: "max-content",
          height: { xs: "100vh", sm: "max-content" },
          maxHeight: { xs: "100vh", sm: "95vh" },
          // overflow: "auto",
          overflow: { xs: "hidden", sm: "auto" },
          background: "whitesmoke",
        }}
      >
        {" "}
        <ToastContainer />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            width: "100%",
            px: { xs: 2, sm: 4 },
            // border: "1px solid red",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            Generate Documents
          </Typography>
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,

              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => {
              dispatch({
                type: "SET_TEMPLATE_TO_OPEN",
                payload: {
                  templateData: {},
                },
              });
              dispatch({
                type: "SET_Directory",
                payload: "home",
              });
              dispatch({
                type: "SET_FORM_VALUES_TEMPLATE_TO_OPEN",
                payload: {},
              });
              setShowComponent(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* <Modal
          open={showMobilePreview}
          // onClose={() => setShowComponent(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90vw",
              minWidth: 300,
              maxWidth: "95vw",
              // width: isUseCaseOne ? "max-content" : "85vw",
              width: "max-content",
              // bgcolor: "background.paper",
              background: "transparent",
              boxShadow: 24,
              display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              gap: "20px",
              p: 0,
              height: "max-content",
              maxHeight: "95vh",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                height: "90vh",
                // width: "700px",
                // display: { xs: "none", sm: "flex" },
                flex: 0.5,

                width: "100%",
                minWidth: "280px",
                position: "relative",
                border: "2px solid green",
              }}
            >
              {progress != 100 && (
                <Box
                  sx={{
                    width: "100%",

                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background: "whitesmoke",
                    zIndex: 1,
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Loading preview...</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ maxWidth: "300px" }}
                  />
                </Box>
              )} 
              {isPreviewLoading && !showEditFinalForm && progress == "100" && (
                // false
                <>
                  <DocumentPreview
                    setShowPreviewModal={setShowPreviewModal}
                    setTemplateCreationStep={setTemplateCreationStep}
                    generalInformation={generalInformation}
                    selectedDataforTemplateEditor={
                      selectedDataforTemplateEditor
                    }
                    CSVFileJSON={CSVFileJSON}
                    setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                    preFilledCanvasObjects={
                      isComebackToEdit ? preFilledCanvasObjects : null
                    }
                    canvasAddedFields={
                      isComebackToEdit ? canvasAddedFields : null
                    }
                    setCanvasAddedFields={setCanvasAddedFields}
                    setIsComebackToEdit={setIsComebackToEdit}
                    isTemplateOpened={
                      templateData?.template_name ? true : false
                    }
                    allowedDocumentGenerationEntries={
                      allowedDocumentGenerationEntries
                    }
                    showPreviewModal={showPreviewModal}
                    formValuesOfSelectedTemplate={formValuesOfSelectedTemplate}
                    isUseCaseOne={isUseCaseOne}
                    setAllFormFields={setAllFormFields}
                    allFormFields={allFormFields}
                    formValues={formValuesOfSelectedTemplate}
                  />
                </>
              )}
            </Box>
          </Box>
        </Modal> */}
        <Box
          sx={{
            p: isThirdParty ? 0 : { xs: 1, sm: 2 },
            background: "white",
            // borderLeft: "1px solid lightgray",
            // minHeight: isThirdParty ? "max-content" : "100vh",
            minHeight: "max-content",
            overflow: { xs: "auto" },

            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "50px",

            width: { xs: "95vw", lg: "1200px" },
            background: "whitesmoke",
            // border: "1px solid red",
            mt: { xs: -2 },
          }}
        >
          {/* <Box sx={{ display: "flex", gap: "50px", border: "2px solid red" }}> */}
          <Box
            sx={{
              p: 2,
              flex: { xs: 1, sm: 0.5 },
              // maxHeight: "85vh",
              // overflowY: "auto",
              // border: "3px solid pink",
              display: showMobilePreview ? "none" : "block",
              width: { xs: "100%", sm: "50%" },
              // background: "#fff",
              borderRadius: "4px",
            }}
          >
            <>
              {loading && isThirdParty && generatedUrl ? (
                <Box sx={{ p: 5, background: "#fff" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1.15rem",
                      fontWeight: "700",
                      // cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    DocSpawn
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Form submitted successfully!
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <CheckCircleOutlineIcon
                      color="success"
                      sx={{
                        fontSize: "5rem",
                        color: theme.palette.primary.main,
                        color: "success",
                      }}
                    />
                  </Box>
                  <a href={generatedUrl} target="_blank">
                    <Button
                      variant="contained"
                      sx={{
                        marginLeft: "50%",
                        transform: "translateX(-50%)",
                        mt: 2,
                        textTransform: "inherit",
                        width: "max-content",
                      }}
                    >
                      Download document
                    </Button>
                  </a>
                  <a
                    href={`mailto:?subject=Sending document, generated via DocSpawn&body=Document url: ${generatedUrl}`}
                    target="_blank"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        marginLeft: "50%",
                        transform: "translateX(-50%)",
                        mt: 2,
                        width: "max-content",
                        textTransform: "inherit",
                      }}
                    >
                      Send via e-mail
                    </Button>
                  </a>
                </Box>
              ) : (
                loading &&
                isThirdParty && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <Typography>Generating document...</Typography>
                  </Box>
                )
              )}
              {!isThirdParty && (
                <Modal
                  open={loading}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: 400,
                      minWidth: 300,
                      width: "max-content",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      p: 4,
                    }}
                  >
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {/* {props?.t(loadingType)} */}
                      {props?.t(
                        "Your documents are being generated. They will be dispatched as specified in the template options"
                      )}
                      .
                    </Typography>
                    {/* <CircularProgress /> */}
                    <Button
                      variant="contained"
                      onClick={() => {
                        setLoading(false);
                        dispatch({
                          type: "SET_Directory",
                          payload: "home",
                        });
                      }}
                    >
                      {props?.t("Ok")}
                    </Button>
                  </Box>
                </Modal>
              )}

              {showPreviewModal ? (
                <Modal open={showPreviewModal}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "90vw",
                      width: "max-content",
                      maxHeight: "95vh",
                      overflow: "auto",
                      bgcolor: "background.paper",
                      // border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box
                      sx={{
                        height: "max-content",
                      }}
                    >
                      <DocumentPreview
                        setShowPreviewModal={setShowPreviewModal}
                        setTemplateCreationStep={setTemplateCreationStep}
                        generalInformation={generalInformation}
                        selectedDataforTemplateEditor={
                          selectedDataforTemplateEditor
                        }
                        CSVFileJSON={CSVFileJSON}
                        setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                        preFilledCanvasObjects={
                          isComebackToEdit ? preFilledCanvasObjects : null
                        }
                        canvasAddedFields={
                          isComebackToEdit ? canvasAddedFields : null
                        }
                        setCanvasAddedFields={setCanvasAddedFields}
                        setIsComebackToEdit={setIsComebackToEdit}
                        isTemplateOpened={
                          templateData?.template_name ? true : false
                        }
                        allowedDocumentGenerationEntries={
                          allowedDocumentGenerationEntries
                        }
                        showPreviewModal={showPreviewModal}
                        formValuesOfSelectedTemplate={
                          formValuesOfSelectedTemplate
                        }
                        isUseCaseOne={isUseCaseOne}
                        setAllFormFields={setAllFormFields}
                        allFormFields={allFormFields}
                        formValues={formValuesOfSelectedTemplate}
                      />
                    </Box>
                  </Box>
                </Modal>
              ) : showEditFinalForm ? (
                <Modal open={showEditFinalForm}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "90vw",
                      width: "max-content",
                      maxHeight: "95vh",
                      overflow: "auto",
                      bgcolor: "background.paper",
                      // border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box
                      sx={{
                        height: "max-content",
                      }}
                    >
                      <FinalForm
                        formTitle={
                          templateData?.complete_form_structure?.form_title
                        }
                        formDescription={
                          templateData?.complete_form_structure
                            ?.form_description
                        }
                        allFormFields={
                          templateData?.complete_form_structure?.all_form_fields
                        }
                        // isPreview,
                        // setIsPreview,
                        // enabled,
                        templateData={templateData}
                        canvasAddedFields={templateData?.canvas_added_fields}
                        canvasObjects={templateData?.canvas_objects_for_output}
                        isEdit={true}
                        earlyFormValues={formValuesOfSelectedTemplate}
                        setIsPreview={setShowEditFinalForm}
                        isPreview={showEditFinalForm}
                        enabled={true}
                        isThirdParty={isThirdParty}
                        thirdPartyFromShowForm={isThirdParty ? true : false}
                        recaptchaRef={recaptchaRef}
                        // setShowPreviewModal={setShowPreviewModal}
                        // setTemplateCreationStep={setTemplateCreationStep}
                        // generalInformation={generalInformation}
                        // selectedDataforTemplateEditor={selectedDataforTemplateEditor}
                        // CSVFileJSON={CSVFileJSON}
                        // setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                        // preFilledCanvasObjects={
                        //   isComebackToEdit ? preFilledCanvasObjects : null
                        // }
                        // canvasAddedFields={isComebackToEdit ? canvasAddedFields : null}
                        // setCanvasAddedFields={setCanvasAddedFields}
                        // setIsComebackToEdit={setIsComebackToEdit}
                        // isTemplateOpened={templateData?.template_name ? true : false}
                        // allowedDocumentGenerationEntries={
                        //   allowedDocumentGenerationEntries
                        // }
                        // showPreviewModal={showPreviewModal}
                        // formValuesOfSelectedTemplate={formValuesOfSelectedTemplate}
                        // isUseCaseOne={isUseCaseOne}
                        // setAllFormFields={setAllFormFields}
                        // allFormFields={allFormFields}
                        // formValues={formValuesOfSelectedTemplate}
                      />
                    </Box>
                  </Box>
                </Modal>
              ) : (
                !loading && (
                  <Box sx={{}}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: theme.palette.heading.main,
                        mt: { xs: 0, sm: 1 },
                        mb: 1.5,
                        fontWeight: "600",
                        fontSize: "1.2rem",
                      }}
                    >
                      {/* {formValuesOfSelectedTemplate
                          ? props?.t("Form Data")
                          : props?.t("Data Selection")} */}
                      {!formValuesOfSelectedTemplate &&
                        props?.t("Data Selection")}
                      {formValuesOfSelectedTemplate &&
                        templateData?.complete_form_structure?.form_title}
                    </Typography>
                    {templateData?.force_entries_check_for_form && (
                      <Typography
                        variant="p"
                        sx={{
                          color: theme.palette.para.main,
                          // display: isThirdParty ? "block" : { xs: "block", sm: "none" },
                        }}
                      >
                        Ensure correctness by checking each field
                      </Typography>
                    )}
                    <Box
                      sx={
                        {
                          // display: { xs: "block", sm: "none" }
                        }
                      }
                    >
                      {" "}
                      <Button
                        sx={{ my: 1, display: { xs: "block", sm: "none" } }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setShowMobilePreview(true);
                          setProgress(0);
                        }}
                      >
                        Show Preview
                      </Button>
                    </Box>

                    {formValuesOfSelectedTemplate && templateData?.id ? (
                      <Box
                        sx={{
                          mt: 2,
                          maxHeight: { sm: "60vh" },
                          overflowY: { sm: "auto" },
                          display: { xs: "flex", sm: "block" },
                          justifyContent: "center",
                          background: { xs: "#fff", sm: "transparent" },
                        }}
                      >
                        <ShowFormValues
                          finalFormValues={formValuesOfSelectedTemplate}
                          isFinalPreview={true}
                          formTitle={
                            templateData?.complete_form_structure?.form_title
                          }
                          formDescription={
                            templateData?.complete_form_structure
                              ?.form_description
                          }
                          allFormFields={
                            templateData?.complete_form_structure
                              ?.all_form_fields
                          }
                          canvasAddedFields={templateData?.canvas_added_fields}
                          canvasObjects={
                            templateData?.canvas_objects_for_output
                          }
                          enabled={true}
                          templateData={templateData}
                          generateDocumentsFunction={generateDocuments}
                          setShowEditFinalForm={setShowEditFinalForm}
                          forceEntriesCheck={
                            templateData?.force_entries_check_for_form
                              ? templateData?.force_entries_check_for_form
                              : false
                          }
                          isUseCaseOne={isUseCaseOne}
                          isThirdParty={isThirdParty}
                          setShowPreviewModal={setShowPreviewModal}
                        />
                        {/* {(allowedDocumentGenerationEntries?.length > 0 ||
                formValuesOfSelectedTemplate) && (
                <Button
                  variant="contained"
                  sx={{ width: "max-content" }}
                  onClick={() => generateDocuments()}
                >
                  {props?.t("Generate Documents")}
                </Button>
              )} */}

                        {/* {Object.entries(formValuesOfSelectedTemplate).map(
                ([key, value]) => (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      my: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.heading.main,
                        fontSize: "1rem",
                      }}
                      variant="body2"
                    >
                      {key?.toUpperCase()}
                    </Typography>
                    {isImageUrl(value) ? (
                      <Box
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                          fontSize: "1rem",
                          width: "200px",
                          p: 2,
                          borderRadius: "4px",
                        }}
                      >
                        <img
                          src={value}
                          style={{
                            height: "100px",
                            width: "auto",
                            border: "1px solid lightgray",
                          }}
                        />
                      </Box>
                    ) : allFormFields?.filter((f) => f?.fieldName == key)[0]
                        ?.fieldType == "checkbox" ? (
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                          fontSize: "1rem",
                          background: theme.palette.accent.main,
                          minWidth: "200px",
                          maxWidth: "450px",
                          p: 2,
                          borderRadius: "4px",
                        }}
                        variant="body2"
                      >
                        {value == true ? "checked" : "unchecked"}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                          fontSize: "1rem",
                          background: theme.palette.accent.main,
                          minWidth: "200px",
                          maxWidth: "450px",
                          p: 2,
                          borderRadius: "4px",
                        }}
                        variant="body2"
                      >
                        {allFormFields?.filter((f) => f?.fieldName == key)[0]
                          ?.fieldType == "date"
                          ? formatDateForInput(
                              value,
                              allFormFields?.filter(
                                (f) => f?.fieldName == key
                              )[0]?.format
                            )
                          : allFormFields?.filter((f) => f?.fieldName == key)[0]
                              ?.fieldType == "time"
                          ? formatTimeForInput(
                              value,
                              allFormFields?.filter(
                                (f) => f?.fieldName == key
                              )[0]?.format
                            )
                          : value}
                      </Typography>
                    )}
                  </Box>
                )
              )} */}
                      </Box>
                    ) : (
                      <Box sx={{ width: "500px" }}>
                        {CSVFileJSON?.length > 0 ? (
                          <DataSelection
                            setSelectedDataForTemplateEditor={
                              setSelectedDataForTemplateEditor
                            }
                            CSVFileJSON={CSVFileJSON}
                            allowedDocumentGenerationEntries={
                              allowedDocumentGenerationEntries
                            }
                            setAllowedDocumentGenerationEntries={
                              setAllowedDocumentGenerationEntries
                            }
                            setFinalDataForCSVBackend={setFinalDataForCSV}
                          />
                        ) : (
                          <Box
                            sx={{
                              padding: "50px",
                              display: "flex",
                              justifyContent: "center",
                              background: theme.palette.white.main,
                              mt: 4,
                            }}
                          >
                            <CircularProgress color="primary" />
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* {(allowedDocumentGenerationEntries?.length > 0 ||
            formValuesOfSelectedTemplate) && (
            <Box
              sx={{
                display: "flex",
                p: 3,
                justifyContent: "center",
                mt: 3,
                width: "100%",
              }}
            >
              <Button
                //   variant="contained"
                sx={{ width: "max-content" }}
                onClick={() => setShowPreviewModal(true)}
              >
                {props?.t("Preview documents")}
              </Button>
            </Box>
          )} */}
                    {/* {!isThirdParty && (
                  <>
                    {" "}
                    <Typography
                      variant="h5"
                      sx={{
                        color: theme.palette.heading.main,
                        mt: 4,
                        mb: 1,
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" },
                      }}
                    >
                      {props?.t("Output Recap")}
                    </Typography>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.heading.main,
                          fontSize: "1rem",
                        }}
                      >
                        {props?.t("File Output")}:{" "}
                        {templateData?.file_output == 1
                          ? "Generate 1 file per document"
                          : "Compile all files in 1 document"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.heading.main,
                          fontSize: "1rem",
                          my: 1,
                        }}
                      >
                        {props?.t("File Format")}:{" "}
                        {templateData?.file_preview == 1
                          ? "PDF Document"
                          : templateData?.file_preview == 2
                          ? "Word Document"
                          : "PDF + XML"}
                      </Typography>
                      <DeliveryOptionsPreview
                        templateToOpen={templateToOpen}
                        finalDataForCsv={finalDataForCsv}
                        CSVFileJSON={CSVFileJSON}
                        allowedDocumentGenerationEntries={
                          allowedDocumentGenerationEntries
                        }
                        isUseCaseOne={isUseCaseOne}
                        formValues={formValuesOfSelectedTemplate}
                      />
                    </Box>
                  </>
                )} */}

                    {!isThirdParty && (
                      <Box
                        sx={{
                          display: "flex",
                          p: 3,
                          justifyContent: "center",
                          mt: 3,
                          width: "100%",
                          gap: "20px",
                          display: { xs: "none", sm: "flex" },
                        }}
                      >
                        {(allowedDocumentGenerationEntries?.length > 0 ||
                          formValuesOfSelectedTemplate) && (
                          <Box>
                            {/* <Button
                        variant="outlined"
                        sx={{ width: "max-content" }}
                        onClick={() => setShowPreviewModal(true)}
                        disabled={allowedDocumentGenerationEntries?.length > 25}
                      >
                        {props?.t("Preview documents")}
                      </Button> */}
                          </Box>
                        )}
                        {(allowedDocumentGenerationEntries?.length > 0 ||
                          formValuesOfSelectedTemplate) &&
                          !isUseCaseOne && (
                            <Button
                              variant="contained"
                              sx={{ width: "max-content" }}
                              onClick={() => generateDocuments()}
                              disabled={
                                allowedDocumentGenerationEntries?.length > 25
                              }
                            >
                              {props?.t("Generate Documents")}
                            </Button>
                          )}
                      </Box>
                    )}
                  </Box>
                )
              )}
            </>
          </Box>

          <Box
            sx={{
              height: { xs: "600px", sm: "800px" },
              // width: "700px",
              // display: { xs: "none", sm: "flex" },
              flex: 0.5,
              // display: showMobilePreview ? "block" : "none",
              // border: "1px solid green",
              width: "50%",
              minWidth: { xs: "90vw", sm: "501px" },
              position: { xs: "absolute", sm: "relative" },
              top: !showMobilePreview && "-200%",

              ml: 0,
            }}
          >
            {progress == 100 && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
              >
                <Typography sx={{ mb: -2 }}>Preview: </Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    mb: -2,
                    textTransform: "inherit",
                    display: { xs: "block", sm: "none" },
                  }}
                  onClick={() => setShowMobilePreview(false)}
                >
                  End preview
                </Button>
              </Box>
            )}

            {progress != 100 && (
              <Box
                sx={{
                  width: "100%",

                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "whitesmoke",
                  zIndex: 1,
                }}
              >
                <Typography sx={{ mb: 1 }}>Loading preview...</Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ maxWidth: "300px" }}
                />
              </Box>
            )}
            {isPreviewLoading && !showEditFinalForm && (
              // false
              <DocumentPreview
                setShowPreviewModal={setShowPreviewModal}
                setTemplateCreationStep={setTemplateCreationStep}
                generalInformation={generalInformation}
                selectedDataforTemplateEditor={selectedDataforTemplateEditor}
                CSVFileJSON={CSVFileJSON}
                setPreFilledCanvasObjects={setPreFilledCanvasObjects}
                preFilledCanvasObjects={
                  isComebackToEdit ? preFilledCanvasObjects : null
                }
                canvasAddedFields={isComebackToEdit ? canvasAddedFields : null}
                setCanvasAddedFields={setCanvasAddedFields}
                setIsComebackToEdit={setIsComebackToEdit}
                isTemplateOpened={templateData?.template_name ? true : false}
                allowedDocumentGenerationEntries={
                  allowedDocumentGenerationEntries
                }
                showPreviewModal={showPreviewModal}
                formValuesOfSelectedTemplate={formValuesOfSelectedTemplate}
                isUseCaseOne={isUseCaseOne}
                setAllFormFields={setAllFormFields}
                allFormFields={allFormFields}
                formValues={formValuesOfSelectedTemplate}
              />
            )}
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
    </Modal>
  );
};

export default withTranslation()(UseTemplate);

/***************format date for value */

const formatDateForInput = (dateValue, format) => {
  console.log("date value", dateValue);
  console.log("date format", format);
  if (!dateValue) return "";

  const currentDate = new Date(dateValue);
  let formatted = "";

  switch (format) {
    case "MM/DD/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
      break;

    case "DD/MM/YY":
      formatted = currentDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
      break;

    case "YY/MM/DD":
      formatted = currentDate.toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      break;

    case "Month D, Yr":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "M/D/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
      });
      break;

    case "D/M/YY":
      formatted = currentDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      break;

    case "YY/M/D":
      formatted = currentDate.toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      });
      break;

    case " bM/bD/YY":
      formatted = currentDate
        .toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
        })
        .replace(/\//g, " ");
      break;

    case " bD/bM/YY":
      formatted = currentDate
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "2-digit",
        })
        .replace(/\//g, " ");
      break;

    case "YY/ bM/bD":
      formatted = currentDate
        .toLocaleDateString("en-CA", {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, " ");
      break;

    case "MMDDYY":
      formatted = currentDate
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "DDMMYY":
      formatted = currentDate
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "YYMMDD":
      formatted = currentDate
        .toLocaleDateString("en-CA", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "MonDDYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "2-digit",
      });
      break;

    case "DDMonYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
      break;

    case "YYMonDD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "2-digit",
        month: "short",
        day: "numeric",
      });
      break;

    case "day/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
      });
      break;

    case "YY/day":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        day: "numeric",
      });
      break;

    case "D Month, Yr":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      break;

    case "Yr, Month D":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      break;

    case "Mon-DD-YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "DD-Mon-YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      break;

    case "YYYYY-Mon-DD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;

    case "Mon DD, YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "DD Mon, YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      break;

    case "YYYY, Mon DD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;

    default:
      formatted = currentDate.toLocaleDateString(); // Use the default format if no match
      break;
  }
  console.log("formatted date", formatted);
  console.log("type of formatted date");
  return formatted;
};

// const getMonthName = (monthIndex) => {
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   return monthNames[monthIndex];
// };

// const getMonthAbbreviation = (monthIndex) => {
//   const monthAbbreviations = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   return monthAbbreviations[monthIndex];
// };

// const getDayOfYear = (date) => {
//   const start = new Date(date.getFullYear(), 0, 0);
//   const diff = date - start;
//   const oneDay = 1000 * 60 * 60 * 24;
//   const day = Math.floor(diff / oneDay);
//   return day.toString().padStart(3, "0");
// };

/************************************* */

/***************format time for value */
const formatTimeForInput = (timeValue, format) => {
  if (!timeValue) return "";

  const date = new Date(`1970-01-01T${timeValue}`);
  let formattedTime = "";

  switch (format) {
    // case "HH:MM:SS":
    //   formattedTime = date.toISOString().substr(11, 8);
    //   break;
    // case "HH:MM:SS XM":
    //   formattedTime = date.toLocaleTimeString([], { hour12: true });
    //   break;
    // case "HH:MM":
    //   formattedTime = date.toISOString().substr(11, 5);
    //   break;
    // case "HH:MM XM":
    //   formattedTime = date
    //     .toLocaleTimeString([], { hour12: true })
    //     .substr(0, 5);
    //   break;
    case "HH:MM:SS":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).format(date);
      break;
    case "HH:MM:SS XM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }).format(date);
      break;
    case "HH:MM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(date);
      break;
    case "HH:MM XM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date);
      break;
    default:
      // Handle default case
      break;
  }

  return formattedTime;
};

/************************************* */
