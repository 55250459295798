import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";

import { Navigate, useNavigate } from "react-router-dom";
import { api, setAuthToken } from "../../axios/api";
import { setUser, setToken } from "../../actions/user";
import { useTranslation } from "react-i18next";

const GoogleLogin = ({ toast }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCallbackResponse = (response) => {
    const userObject = jwt_decode(response.credential);
    let obj = {
      name: userObject?.name ? userObject?.name : userObject?.given_name,
      profile_image: userObject?.picture,
      email: userObject?.email,
      by_google: true,
      email_verified: userObject?.email_verified,
    };

    api
      .post("/login/google", obj)
      .then((res) => {
        const { verified, token } = res?.data;

        setAuthToken(token);

        dispatch(setToken(token));
        setTimeout(() => {
          api
            .get("/user-info/me")
            .then((res) => {
              dispatch(setUser(res?.data));
            })
            .catch((err) => {
              // console.log("error while fetching user info", err);
            });
        }, 1000);

        if (token) {
          toast.success(t("Logged in successfully"));

          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      })
      .catch((error) => {
        // console.log("error", error);

        if (error.response.status === 404) {
          // Handle "Your account is not invited!" error
          toast.error(t("Your account is not invited!"));
        } else if (error.response.status === 403) {
          // Handle "Your Account is UnAuthorized" error
          toast.error(t("Your Account is UnAuthorized"));
        } else {
          // Handle other errors
          toast.error(t("An error occurred while loggin in! Try again later"));
        }
      });
  };

  useEffect(() => {
    if (typeof google !== "undefined" && google.accounts) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outlined",
        size: "large",
        // text: "continue with Google",
      });
    }
  }, []);
  return <div id="signInDiv"></div>;
};

export default GoogleLogin;
