import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  Button,
  LinearProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { api, setAuthToken } from "../axios/api";
import Navbar from "../components/Navbar";
import GridViewIcon from "@mui/icons-material/GridView";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import PDFThumbnail from "../components/PDFThumbnail";
import UseTemplateTab from "../components/UseTemplateTab";
import Chart from "react-apexcharts";
import { setUser, setToken } from "../actions/user";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const Home = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [templates, setTemplates] = useState([]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const screenVersion = isSmallScreen ? "mobile" : "desktop";

  const [templateView, setTemplateView] = useState(
    screenVersion == "mobile" ? "3" : "1"
  );

  useEffect(() => {
    let SupTok = localStorage.getItem("docspawn_token")
      ? JSON.parse(localStorage.getItem("docspawn_token"))
      : null;
    if (SupTok) {
      setAuthToken(SupTok);
      api
        .get("/user-info/me")
        .then((res) => {
          dispatch(setUser(res?.data));
        })
        .catch((err) => {
          // console.log("error while fetching user info", err);
        });
    }
    setTimeout(() => {
      api
        .get("/user-info/me")
        .then((res) => {
          dispatch(setUser(res?.data));
        })
        .catch((err) => {
          // console.log("error while fetching user info", err);
        });
    }, 2000);
  }, [token]);

  const [allTimeGeneratedDocuments, setAllTimeGeneratedDocuments] = useState(
    []
  );
  const [thisMonthGeneratedDocuments, setThisMonthGeneratedDocuments] =
    useState([]);
  useEffect(() => {
    api
      .get(`/users/generatedDocuments/${user?.email_address}/all`)
      .then(async (res) => {
        let tDoc = 0;
        res?.data?.forEach(
          (d) => (tDoc = tDoc + d?.generated_documents?.length)
        );
        setAllTimeGeneratedDocuments(tDoc);
      });
    // .catch((err) => console.log(err));
    api
      .get(`/users/generatedDocuments/${user?.email_address}/current-month`)
      .then((res) => {
        let tDoc = 0;
        res?.data?.forEach(
          (d) => (tDoc = tDoc + d?.generated_documents?.length)
        );
        setThisMonthGeneratedDocuments(tDoc);
      })
      .catch((err) => console.log(err));
  }, [user]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "donut",
      width: "50%",
    },
    labels: ["Used Credits", "Remaining Credits"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: [theme.palette.secondary.main, theme.palette.accent.main], // Set custom colors for the chart
  });

  const [chartData, setChartData] = useState([
    user?.account?.credits_used,
    user?.account?.total_credits - user?.account?.credits_used,
  ]); // Sample data, you can replace it with actual data
  useEffect(() => {
    // Fetch and update chart data as needed
    // For example, you can make an API call here
    // and update the chart data and options accordingly

    // For demonstration purposes, updating the chart data after 3 seconds
    const timer = setTimeout(() => {
      setChartData([
        user?.account?.credits_used,
        user?.account?.total_credits - user?.account?.credits_used,
      ]); // Updated data
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [user]);

  useEffect(() => {
    api
      .get("/templates/finalized")
      .then((res) => {
        if (screenVersion == "mobile") {
          setTemplates(
            res?.data?.filter((r) => r?.use_cases?.includes("useCase1"))
          );
        } else {
          setTemplates(res?.data);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [screenVersion]);

  return (
    <Container
      sx={{
        // backgroundColor: theme.palette.background.main,
        minHeight: "100vh",
        // p: 0,
      }}
    >
      <Box
        sx={{
          padding: { xs: "20px 0px", sm: "20px 20px" },
          paddingTop: "20px",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h4" sx={{ mt: 1, mb: 1, fontWeight: "600" }}>
          ACME INC.'s Docspawn Portal
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 0,
            mb: 0,
            fontWeight: "500",
            color: theme.palette.para.main,
          }}
        >
          {props?.t("Remaining credits")}:{" "}
          {user?.account?.total_credits - user?.account?.credits_used}/
          {user?.account?.total_credits}
        </Typography>
        <Box
          sx={{
            mb: 3,
            width: { xs: "290px", sm: "350px" },
          }}
        >
          {/* <LinearProgress variant="determinate" /> */}
          <LinearProgressWithLabel
            value={
              (user?.account?.credits_used * 100) / user?.account?.total_credits
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
            maxWidth: "800px",
          }}
        >
          <Box sx={{ flex: "1" }}>
            {" "}
            <Typography
              variant="h6"
              sx={{
                mt: 0,
                mb: 1,
                fontWeight: "500",
                color: theme.palette.heading.main,
              }}
            >
              {user?.name}'s Space
            </Typography>
            <Box>
              <Typography
                variant="p"
                sx={{
                  mt: 0,
                  mb: 0,
                  fontWeight: "500",
                  color: theme.palette.para.main,
                }}
              >
                {props?.t("Total Documents Created")}:
                {allTimeGeneratedDocuments}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="p"
                sx={{
                  mt: 0,
                  mb: 0,
                  fontWeight: "500",
                  color: theme.palette.para.main,
                }}
              >
                {props?.t("Documents created this month")}:{" "}
                {thisMonthGeneratedDocuments}
              </Typography>
            </Box>
          </Box>
          <Box></Box>
          {/* <Box
            sx={{
              mt: { xs: 3, sm: 0, display: "flex", justifyContent: "center" },
            }}
          >
            {" "}
            <Chart
              options={chartOptions}
              series={chartData}
              type="donut"
              width="380"
              height="240"
            />
          </Box> */}
        </Box>
        {screenVersion == "mobile" && (
          <Box
            sx={{
              background: theme.palette.white.main,
              mt: 7,
              p: 2,
              borderRadius: "4px",
            }}
          >
            <Typography variant="h6">Documents Library</Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2 }}
              onClick={() =>
                dispatch({
                  type: "SET_Directory",
                  payload: "documentsLibrary",
                })
              }
            >
              View documents Library
            </Button>
          </Box>
        )}

        <Grid
          container
          sx={{
            mt: { xs: 1, sm: 5 },
            padding: "20px 0",
            gap: { xs: "10px", sm: "20px" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              background: theme.palette.white.main,
              borderRadius: "6px",
              padding: { xs: "18px", sm: "30px 30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.heading.main }}
              >
                {props?.t("Templates")}
              </Typography>
              {/* {screenVersion == "desktop" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <GridViewIcon
                    onClick={() => setTemplateView("1")}
                    sx={{
                      padding: "4px",
                      fontSize: "1.6rem",
                      cursor: "pointer",
                      borderBottomLeftRadius: "4px",
                      background:
                        templateView != "1"
                          ? theme.palette.backgroundSecondary.main
                          : "lightgray",
                      color:
                        templateView != "1"
                          ? theme.palette.para.main
                          : theme.palette.heading.main,
                    }}
                  />

                  <AppsIcon
                    onClick={() => setTemplateView("2")}
                    sx={{
                      padding: "4px",
                      fontSize: "1.6rem",
                      cursor: "pointer",
                      background:
                        templateView != "2"
                          ? theme.palette.backgroundSecondary.main
                          : "lightgray",
                      color:
                        templateView != "2"
                          ? theme.palette.para.main
                          : theme.palette.heading.main,
                    }}
                  />
                  <FormatListBulletedIcon
                    onClick={() => setTemplateView("3")}
                    sx={{
                      padding: "4px",
                      cursor: "pointer",
                      fontSize: "1.6rem",
                      borderTopRightRadius: "6px",
                      background:
                        templateView != "3"
                          ? theme.palette.backgroundSecondary.main
                          : "lightgray",
                      color:
                        templateView != "3"
                          ? theme.palette.para.main
                          : theme.palette.heading.main,
                    }}
                  />
                </Box>
              )} */}
            </Box>
            <Grid
              container
              rowGap={3}
              sx={{
                mt: 4,
                gap: templateView == "2" ? { md: "15px", xs: "10px" } : "20px",
              }}
            >
              {templates?.map((t, i) => (
                <Grid
                  key={i}
                  item
                  xs={
                    templateView == "1" ? 5 : templateView == "2" ? "3.8" : "12"
                  }
                  sx={{
                    mb: 3,
                  }}
                >
                  <UseTemplateTab
                    key={t?.id}
                    keyV={t?.id}
                    data={t}
                    templates={templates}
                    setTemplates={setTemplates}
                    isFromHome={true}
                    isMobile={screenVersion == "mobile"}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withTranslation()(Home);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" py={2}>
      <Box width="100%" mr={3}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ height: "10px", borderRadius: "10px" }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ whiteSpace: "nowrap" }}
        >{`${
          props?.value == 0 ? 0 : parseFloat(props.value)?.toFixed(2)
        }% Used`}</Typography>
      </Box>
    </Box>
  );
}
