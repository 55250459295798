import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";

import { useTheme } from "@mui/material";

const StyledMenuItem = styled(MenuItem)({
  fontSize: "0.9rem",
});

const DataField = ({
  setDataFieldsOptions,
  showDatafieldsOptions,
  dataFieldsOptions,

  dataFieldBarRefreshor,
}) => {
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState("12");
  const [fontColor, setFontColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [alignment, setAlignment] = useState("left");

  useEffect(() => {
    if (dataFieldsOptions) {
      setFontFamily(
        dataFieldsOptions?.fontFamily ? dataFieldsOptions?.fontFamily : "Arial"
      );
      setFontSize(
        dataFieldsOptions?.fontSize ? dataFieldsOptions?.fontSize : "12"
      );
      setFontColor(
        dataFieldsOptions?.fontColor ? dataFieldsOptions?.fontColor : "#4b4b5a"
      );
      setIsBold(dataFieldsOptions?.isBold ? dataFieldsOptions?.isBold : false);
      setIsItalic(
        dataFieldsOptions?.isItalic ? dataFieldsOptions?.isItalic : false
      );
      setIsUnderlined(
        dataFieldsOptions?.isUnderlined
          ? dataFieldsOptions?.isUnderlined
          : false
      );
      setAlignment(
        dataFieldsOptions?.alignment ? dataFieldsOptions?.alignment : "left"
      );
    }
  }, [dataFieldBarRefreshor]);
  const theme = useTheme();

  useEffect(() => {
    let options = {
      fontFamily,
      fontSize,
      fontColor,
      isBold,
      isItalic,
      isUnderlined,
      alignment,
    };

    setDataFieldsOptions(options);
  }, [
    fontFamily,
    fontSize,
    fontColor,
    isBold,
    isItalic,
    isUnderlined,
    alignment,
  ]);

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  };

  const handleFontColorChange = (event) => {
    setFontColor(event.target.value);
  };

  const handleBoldChange = () => {
    setIsBold(!isBold);
  };

  const handleItalicChange = () => {
    setIsItalic(!isItalic);
  };

  const handleUnderlineChange = () => {
    setIsUnderlined(!isUnderlined);
  };

  const handleAlignmentChange = (event) => {
    setAlignment(event.target.value);
  };

  return (
    <Box
      sx={{
        transition: "height 3s ease",
        position: "relative",
        display: "flex",
        width: "100%",
      }}
    >
      {showDatafieldsOptions && (
        <Box
          sx={{
            top: "-10px",
            left: "14px",
            zIndex: "100",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <FormControl>
            <Select
              value={fontFamily}
              onChange={handleFontFamilyChange}
              displayEmpty
              sx={{ height: "30px", fontSize: "0.9rem" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <StyledMenuItem value="Arial" style={{ fontFamily: "Arial" }}>
                Arial
              </StyledMenuItem>
              <StyledMenuItem value="Verdana" style={{ fontFamily: "Verdana" }}>
                Verdana
              </StyledMenuItem>
              <StyledMenuItem
                value="Times New Roman"
                style={{ fontFamily: "Times New Roman" }}
              >
                Times New Roman
              </StyledMenuItem>
              <StyledMenuItem
                value="Helvetica"
                style={{ fontFamily: "Helvetica" }}
              >
                Helvetica
              </StyledMenuItem>
              <StyledMenuItem
                value="Courier New"
                style={{ fontFamily: "Courier New" }}
              >
                Courier New
              </StyledMenuItem>
              <StyledMenuItem value="Georgia" style={{ fontFamily: "Georgia" }}>
                Georgia
              </StyledMenuItem>
              <StyledMenuItem
                value="Comic Sans MS"
                style={{ fontFamily: "Comic Sans MS" }}
              >
                Comic Sans MS
              </StyledMenuItem>
              <StyledMenuItem value="Impact" style={{ fontFamily: "Impact" }}>
                Impact
              </StyledMenuItem>
              <StyledMenuItem
                value="Lucida Console"
                style={{ fontFamily: "Lucida Console" }}
              >
                Lucida Console
              </StyledMenuItem>
              <StyledMenuItem
                value="Palatino Linotype"
                style={{ fontFamily: "Palatino Linotype" }}
              >
                Palatino Linotype
              </StyledMenuItem>
              <StyledMenuItem value="Tahoma" style={{ fontFamily: "Tahoma" }}>
                Tahoma
              </StyledMenuItem>
              <StyledMenuItem
                value="Trebuchet MS"
                style={{ fontFamily: "Trebuchet MS" }}
              >
                Trebuchet MS
              </StyledMenuItem>
              <StyledMenuItem
                value="Arial Black"
                style={{ fontFamily: "Arial Black" }}
              >
                Arial Black
              </StyledMenuItem>
              <StyledMenuItem
                value="Century Gothic"
                style={{ fontFamily: "Century Gothic" }}
              >
                Century Gothic
              </StyledMenuItem>
              <StyledMenuItem
                value="Garamond"
                style={{ fontFamily: "Garamond" }}
              >
                Garamond
              </StyledMenuItem>
              <StyledMenuItem
                value="Book Antiqua"
                style={{ fontFamily: "Book Antiqua" }}
              >
                Book Antiqua
              </StyledMenuItem>
              <StyledMenuItem
                value="Franklin Gothic Medium"
                style={{ fontFamily: "Franklin Gothic Medium" }}
              >
                Franklin Gothic Medium
              </StyledMenuItem>
              <StyledMenuItem
                value="Segoe UI"
                style={{ fontFamily: "Segoe UI" }}
              >
                Segoe UI
              </StyledMenuItem>
              <StyledMenuItem
                value="Copperplate"
                style={{ fontFamily: "Copperplate" }}
              >
                Copperplate
              </StyledMenuItem>
              <StyledMenuItem value="Verdana" style={{ fontFamily: "Verdana" }}>
                Verdana
              </StyledMenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <Select
              value={fontSize}
              onChange={handleFontSizeChange}
              sx={{ height: "30px", fontSize: "0.9rem" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <StyledMenuItem value="8">8</StyledMenuItem>
              <StyledMenuItem value="9">9</StyledMenuItem>
              <StyledMenuItem value="10">10</StyledMenuItem>
              <StyledMenuItem value="11">11</StyledMenuItem>
              <StyledMenuItem value="12">12</StyledMenuItem>
              <StyledMenuItem value="14">14</StyledMenuItem>
              <StyledMenuItem value="16">16</StyledMenuItem>
              <StyledMenuItem value="18">18</StyledMenuItem>
              <StyledMenuItem value="20">20</StyledMenuItem>
              <StyledMenuItem value="22">22</StyledMenuItem>
              <StyledMenuItem value="24">24</StyledMenuItem>
              <StyledMenuItem value="28">28</StyledMenuItem>
              <StyledMenuItem value="36">36</StyledMenuItem>
              <StyledMenuItem value="48">48</StyledMenuItem>
              <StyledMenuItem value="72">72</StyledMenuItem>
            </Select>
          </FormControl>

          <input
            type="color"
            value={fontColor}
            onChange={handleFontColorChange}
            style={{ width: "100px" }}
          />

          <IconButton>
            <FormatBoldIcon
              onClick={handleBoldChange}
              sx={{
                color: isBold
                  ? theme.palette.primary.main
                  : theme.palette.para.main,
              }}
            />
          </IconButton>

          <IconButton>
            <FormatItalicIcon
              onClick={handleItalicChange}
              sx={{
                color: isItalic
                  ? theme.palette.primary.main
                  : theme.palette.para.main,
              }}
            />
          </IconButton>

          <IconButton>
            <FormatUnderlinedIcon
              onClick={handleUnderlineChange}
              sx={{
                color: isUnderlined
                  ? theme.palette.primary.main
                  : theme.palette.para.main,
              }}
            />
          </IconButton>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <FormatAlignLeftIcon
              sx={{
                color:
                  alignment == "left"
                    ? theme.palette.primary.main
                    : theme.palette.para.main,
                cursor: "pointer",
              }}
              onClick={() => setAlignment("left")}
            />
            <FormatAlignRightIcon
              sx={{
                color:
                  alignment == "right"
                    ? theme.palette.primary.main
                    : theme.palette.para.main,
                cursor: "pointer",
              }}
              onClick={() => setAlignment("right")}
            />
            <FormatAlignCenterIcon
              sx={{
                color:
                  alignment == "center"
                    ? theme.palette.primary.main
                    : theme.palette.para.main,
                cursor: "pointer",
              }}
              onClick={() => setAlignment("center")}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DataField;
