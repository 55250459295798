import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  Modal,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
} from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled, useTheme } from "@mui/material/styles";
import DataFieldToolbar from "./DataFieldToolbar";
import { useDispatch } from "react-redux";

import { withTranslation } from "react-i18next";

import { Tooltip } from "@mui/material";
import { api } from "../../../axios/api";
import CloseIcon from "@mui/icons-material/Close";

const SpecificTabOptions = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isUseCaseOne,
    selectedOption,
    setSelectedOption,
    setShowFixDateInput,
    setShowFixImageInput,
    setShowFixTextInput,
    setShowImageInputForm,
    setIsPreview,
    setShowDatafieldsOptions,
    setShowFixTimeInput,
    setShowTimeStampInputForm,
    showFixImageInput,
    showImageInputForm,
    showFixTextInput,
    showTimeStampInputForm,
    showFixDateInput,
    showFixTimeInput,
    setShowFormFieldOptions,
    isPreview,
    showDatafieldsOptions,
    showDataFieldsKeysList,
    showFormFieldOptions,
    selectedField,
    handleSelectedFieldChange,
    selectedDataforTemplateEditor,
    setDataFieldsOptions,
    setFixOptionValues,
    fixDateValue,
    fixTimeValue,
    setFixDateValue,
    setFixTimeValue,
    dataShown,
    dataFieldsOptions,
    dataFieldBarRefreshor,
    fixImageUrlLoading,
    handleFixImageFileSelection,
    totalData,
    setDataShown,
    formFieldOptions,
    formFieldAlreadyExists,
    handleFormFieldOptions,
    showDropdownListForm,
    setShowDropdownListForm,
    openCreatListModal,
    allListsOptions,
    setFormFieldOptions,
    setOpenCreateListModal,
    isSavingAndDispatching,
    saveTemplateAndDispatchToSettings,
    setSelectedCheckboxImages,
    selectedCheckboxImages,
  } = props;
  const StyledMenuItem = styled(MenuItem)({
    fontSize: "0.9rem",
  });
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [uncheckedCheckboxes, setUncheckedCheckboxes] = useState([]);
  const [enabledDateFormats, setEnabledDateFormats] = useState([]);
  const [enabledTimeFormats, setEnabledTimeFormats] = useState([]);
  useEffect(() => {
    api.get("/checkboxes").then((res) => {
      let checked = [];
      let unchecked = [];
      if (res?.data?.length > 0) {
        let ops = res?.data;
        ops?.forEach((op) => {
          if (op?.option_type == "checked") {
            checked.push(op);
          }
          if (op?.option_type == "unchecked") {
            unchecked.push(op);
          }
        });
        setCheckedCheckboxes(checked);
        setUncheckedCheckboxes(unchecked);
        setSelectedCheckboxImages({
          true: checked[0]?.option_url,
          false: unchecked[0]?.option_url,
        });
      }
    });
  }, []);
  useEffect(() => {
    api
      .get("/formats/date")
      .then((res) => {
        let allFormats = res?.data;
        api
          .get("/formats/enabled/date")
          .then((res) => {
            let enabledIds = res?.data;
            setEnabledDateFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  useEffect(() => {
    api
      .get("/formats/time")
      .then((res) => {
        // console.log("res?.data all formats", res?.data);
        let allFormats = res?.data;
        api
          .get("/formats/enabled/time")
          .then((res) => {
            let enabledIds = res?.data;
            setEnabledTimeFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const [openChangeCheckboxImages, setOpenChangeCheckboxImagesM] =
    useState(false);
  const [changeCheckboxImagesType, setChangeCheckboxImagesType] =
    useState(false);

  const handleChnageCheckboxImagesOpen = () =>
    setOpenChangeCheckboxImagesM(true);
  const handleChangeCheckBoxImagesClose = () =>
    setOpenChangeCheckboxImagesM(false);

  return (
    <Box
      sx={{
        borderRadius: "4px",
        height: "70px",
        p: 2,
        // mx: 2,
        my: 2,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Modal
        open={openChangeCheckboxImages}
        // onClose={handleChangeCheckBoxImagesClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "absolute",
              top: "10px",
              right: "20px",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={() => setOpenChangeCheckboxImagesM(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ mb: 3 }}>
            {props?.t("Select image option for type")}:{" "}
            {changeCheckboxImagesType} {props?.t("Checkbox")}
          </Typography>
          {changeCheckboxImagesType == "checked" ? (
            <Box>
              {checkedCheckboxes?.length > 0 &&
                checkedCheckboxes?.map((box, i) => (
                  <Grid container>
                    <Grid item xs={6} sx={{ display: "flex" }}>
                      <img
                        src={box?.option_url}
                        alt="check"
                        style={{ width: "40px", height: "40px" }}
                      />
                      {i == 0 && <Typography>({props?.t("empty")})</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setSelectedCheckboxImages({
                            ...selectedCheckboxImages,
                            true: box?.option_url,
                          });
                          setOpenChangeCheckboxImagesM(false);
                        }}
                      >
                        {props?.t("Select")}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          ) : (
            <Box>
              {uncheckedCheckboxes?.length > 0 &&
                uncheckedCheckboxes?.map((box, i) => (
                  <Grid container>
                    <Grid item xs={6} sx={{ display: "flex" }}>
                      <img
                        src={box?.option_url}
                        alt="check"
                        style={{ width: "40px", height: "40px" }}
                      />
                      {i == 0 && <Typography>({props?.t("empty")})</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setSelectedCheckboxImages({
                            ...selectedCheckboxImages,
                            false: box?.option_url,
                          });
                          setOpenChangeCheckboxImagesM(false);
                        }}
                      >
                        {props?.t("Select")}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          )}
          {/* <Button
            variant="contained"
            sx={{ mt: 3, width: "100%" }}
            onClick={() => setOpenChangeCheckboxImagesM(false)}
          >
            {props?.t("Close")}
          </Button> */}
        </Box>
      </Modal>
      {showDatafieldsOptions ? (
        <Box sx={{ display: "flex" }}>
          {" "}
          <DataFieldToolbar
            showDatafieldsOptions={showDatafieldsOptions}
            setDataFieldsOptions={setDataFieldsOptions}
            dataFieldsOptions={dataFieldsOptions}
            isUseCaseOne={isUseCaseOne}
            dataFieldBarRefreshor={dataFieldBarRefreshor}
          />
        </Box>
      ) : showFixTextInput ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
            }}
          >
            {props?.t("Enter text")}:
          </Typography>
          <Input
            type="text"
            variant="outlined"
            placeholder="e.g. heading"
            onChange={(e) =>
              setFixOptionValues({
                type: "text",
                value: e.target.value,
              })
            }
          />
        </Box>
      ) : showFixDateInput ? (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
            }}
          >
            {props?.t("Select Date format")}:
          </Typography>
          <Select
            onChange={(e) => setFixDateValue(e.target.value)}
            value={fixDateValue}
            sx={{ height: "2.5rem" }} // Adjust the height here
          >
            {
              // dateFormats
              enabledDateFormats.map((format) => (
                <MenuItem
                  key={format.format}
                  value={format.format}
                  sx={{ fontSize: "0.9rem" }} // Adjust the font size here
                >
                  <Tooltip
                    placement="right"
                    title={
                      <Typography
                        // variant="body1"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {format.description}
                      </Typography>
                    }
                  >
                    <span>{format.format}</span>
                  </Tooltip>
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      ) : showTimeStampInputForm ? (
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            gap: "5px",
          }}
        >
          {" "}
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              mr: 2,
            }}
          >
            {props?.t("Select an option")}:
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textTransform: "capitalize",
              textAlign: "center",
            }}
            onClick={() => {
              setShowFixDateInput(true);
              setShowFixTimeInput(false);
              setShowFixTextInput(false);
              setShowTimeStampInputForm(false);
              setShowFixImageInput(false);
              setSelectedOption("");
              setShowFormFieldOptions(false);
              setShowImageInputForm(false);
              setIsPreview(false);
              setShowDatafieldsOptions(false);
            }}
          >
            {props?.t("Date")}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textTransform: "capitalize",
              textAlign: "center",
            }}
            onClick={() => {
              setShowFixTimeInput(true);
              setShowFixDateInput(false);
              setShowTimeStampInputForm(false);
              setShowFixImageInput(false);
              setShowFixTextInput(false);
              setShowFixDateInput(false);
              setSelectedOption("");
              setShowFormFieldOptions(false);
              setShowImageInputForm(false);
            }}
          >
            {props?.t("Time")}
          </Button>
        </Box>
      ) : showFixTimeInput ? (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
            }}
          >
            {props?.t("Select Time format")}:
          </Typography>
          <Select
            onChange={(e) => setFixTimeValue(e.target.value)}
            value={fixTimeValue}
            sx={{ height: "2.5rem" }} // Adjust the height here
          >
            {
              // timeFormats
              enabledTimeFormats.map((format) => (
                <MenuItem
                  key={format.format}
                  value={format.format}
                  sx={{ fontSize: "0.8rem" }} // Adjust the font size here
                >
                  <Tooltip
                    placement="right"
                    // arrow
                    title={
                      <Typography
                        variant="body1"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {format.description}
                      </Typography>
                    }
                  >
                    <span>{format.format}</span>
                  </Tooltip>
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      ) : showImageInputForm ? (
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            gap: "5px",
          }}
        >
          {" "}
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              mr: 2,
            }}
          >
            {props?.t("Select an option")}:
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textTransform: "capitalize",
              textAlign: "center",
            }}
            onClick={() => {
              setSelectedOption("image");
              setShowFixTextInput(false);
              setShowFixDateInput(false);
              setShowFormFieldOptions(false);
              setShowImageInputForm(false);
              setShowFixImageInput(false);
            }}
          >
            {props?.t("Dataset Image")}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textTransform: "capitalize",
              textAlign: "center",
            }}
            onClick={() => {
              setShowFixImageInput(true);
              setShowFixTextInput(false);
              setShowFixDateInput(false);
              setSelectedOption("");
              setShowFormFieldOptions(false);
              setShowImageInputForm(false);
            }}
          >
            {props?.t("Fixed image")}
          </Button>
        </Box>
      ) : showFixImageInput ? (
        <Box
          sx={{
            // p: 2,
            display: "flex",
            alignItems: "center",
            // flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              mr: 2,
            }}
          >
            {props?.t("Select image")}:
          </Typography>
          <Input
            sx={{ fontSize: "0.85rem" }}
            size="small"
            type="file"
            variant="outlined"
            inputProps={{
              accept: ".jpg, .png, .jpeg",
              multiple: false,
            }}
            onChange={(e) => handleFixImageFileSelection(e)}
          />
          {fixImageUrlLoading && <CircularProgress size="1.2rem" />}
        </Box>
      ) : showDataFieldsKeysList ? (
        <Box
          sx={{
            minHeight: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
            }}
          >
            {props?.t("Linked Data")}:
          </Typography>
          <FormControl>
            <Select
              value={selectedField}
              onChange={handleSelectedFieldChange}
              displayEmpty
              sx={{ height: "30px", fontSize: "0.9rem" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {selectedDataforTemplateEditor?.length > 0 &&
                selectedDataforTemplateEditor?.map((data, index) => (
                  <StyledMenuItem value={data} key={`selected-field-${index}`}>
                    {data}
                  </StyledMenuItem>
                ))}
            </Select>
          </FormControl>

          {selectedOption == "checkbox" && (
            <Box
              sx={{ display: "flex", gap: "10px", alignItems: "center", ml: 3 }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", color: theme.palette.heading.main }}
              >
                {props?.t("Select Image Options for")}:
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                True:
                {selectedCheckboxImages?.true && (
                  <img
                    src={selectedCheckboxImages?.true}
                    alt="check"
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
                <Button
                  size="small"
                  onClick={() => {
                    setOpenChangeCheckboxImagesM(true);
                    setChangeCheckboxImagesType("checked");
                  }}
                >
                  {props?.t("change")}
                </Button>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                False:
                {selectedCheckboxImages?.false && (
                  <img
                    src={selectedCheckboxImages?.false}
                    alt="check"
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
                <Button
                  size="small"
                  onClick={() => {
                    setOpenChangeCheckboxImagesM(true);
                    setChangeCheckboxImagesType("unchecked");
                  }}
                >
                  {props?.t("change")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : showFormFieldOptions ? (
        <Box
          sx={{
            minHeight: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {" "}
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              mr: 2,
            }}
          >
            {props?.t("Form Field")}:
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.heading.main,
                fontWeight: "600",
              }}
            >
              {props?.t("Name")}
            </Typography>
            <Input
              value={formFieldOptions?.fieldName}
              onChange={(e) => {
                // console.log(
                //   "form field options at name onchange",
                //   formFieldOptions
                // );
                handleFormFieldOptions({
                  fieldName: e.target.value
                    ? e.target.value
                    : // ?.toLocaleLowerCase()
                      "",
                  fieldType: formFieldOptions?.fieldType,
                  fieldDescription: formFieldOptions?.fieldDescription,
                  isMandatory: formFieldOptions?.isMandatory,
                  listItems: formFieldOptions?.listItems,
                  listId: formFieldOptions?.listId,
                  format: formFieldOptions?.format,
                  boxHeight: formFieldOptions?.boxHeight,
                  boxWidth: formFieldOptions?.boxWidth,
                });
              }}
            />
            {formFieldAlreadyExists && (
              <Typography sx={{ color: "red" }}>
                {props?.t("already exists")}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              ml: 3,
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.heading.main,
                fontWeight: "600",
                display: "flex",
              }}
            >
              {props?.t("Type")}
            </Typography>
            <FormControl>
              <Select
                value={formFieldOptions?.fieldType}
                onChange={(e) =>
                  handleFormFieldOptions({
                    fieldName: formFieldOptions?.fieldName,
                    fieldType: e.target.value,
                    fieldDescription: formFieldOptions?.fieldDescription,
                    isMandatory: formFieldOptions?.isMandatory,
                    listItems: formFieldOptions?.listItems,
                    listId: formFieldOptions?.listId,
                    format: formFieldOptions?.format,
                    boxHeight: formFieldOptions?.boxHeight,
                    boxWidth: formFieldOptions?.boxWidth,
                  })
                }
                // displayEmpty
                sx={{ height: "30px", fontSize: "0.9rem" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <StyledMenuItem value="" key={`selected-field-1`}>
                  {props?.t("Select Type")}
                </StyledMenuItem>
                <StyledMenuItem value="text" key={`selected-field-2`}>
                  {props?.t("text")}
                </StyledMenuItem>
                <StyledMenuItem
                  value="multiline-text"
                  key={`selected-field-10`}
                >
                  {props?.t("multiline-text")}
                </StyledMenuItem>
                <StyledMenuItem value="number" key={`selected-field-3`}>
                  {props?.t("number")}
                </StyledMenuItem>
                <StyledMenuItem value="date" key={`selected-field-4`}>
                  {props?.t("date")}
                </StyledMenuItem>
                <StyledMenuItem value="time" key={`selected-field-11`}>
                  {props?.t("time")}
                </StyledMenuItem>
                <StyledMenuItem value="email" key={`selected-field-5`}>
                  {props?.t("email")}
                </StyledMenuItem>
                <StyledMenuItem value="image" key={`selected-field-6`}>
                  {props?.t("image")}
                </StyledMenuItem>
                <StyledMenuItem value="list" key={`selected-field-7`}>
                  {props?.t("list")}
                </StyledMenuItem>
                <StyledMenuItem value="signature" key={`selected-field-8`}>
                  {props?.t("signature")}
                </StyledMenuItem>
                <StyledMenuItem value="checkbox" key={`selected-field-9`}>
                  {props?.t("checkbox")}
                </StyledMenuItem>
              </Select>
            </FormControl>
            {formFieldOptions?.fieldType == "date" && (
              <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.heading.main,
                    fontWeight: "600",
                    display: "flex",
                    mr: 1,
                  }}
                >
                  {props?.t("Selected format")}:
                </Typography>
                <Select
                  onChange={(e) =>
                    handleFormFieldOptions({
                      ...formFieldOptions,
                      format: e.target.value,
                    })
                  }
                  value={formFieldOptions?.format}
                  sx={{ height: "2.5rem" }} // Adjust the height here
                >
                  {enabledDateFormats.map((format) => (
                    <MenuItem
                      key={format.format}
                      value={format.format}
                      sx={{ fontSize: "0.9rem" }} // Adjust the font size here
                    >
                      <Tooltip
                        placement="right"
                        title={
                          <Typography
                            // variant="body1"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {format.description}
                          </Typography>
                        }
                      >
                        <span>{format.format}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {formFieldOptions?.fieldType == "time" && (
              <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.heading.main,
                    fontWeight: "600",
                    display: "flex",
                    mr: 1,
                  }}
                >
                  {props?.t("Selected format")}:
                </Typography>
                <Select
                  onChange={(e) =>
                    handleFormFieldOptions({
                      ...formFieldOptions,
                      format: e.target.value,
                    })
                  }
                  value={formFieldOptions?.format}
                  sx={{ height: "2.5rem" }} // Adjust the height here
                >
                  {enabledTimeFormats.map((format) => (
                    <MenuItem
                      key={format.format}
                      value={format.format}
                      sx={{ fontSize: "0.9rem" }} // Adjust the font size here
                    >
                      <Tooltip
                        placement="right"
                        title={
                          <Typography
                            // variant="body1"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {format.description}
                          </Typography>
                        }
                      >
                        <span>{format.format}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            {/* {formFieldOptions?.fieldType == "multiline-text" && (
              <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.heading.main,
                    fontWeight: "600",
                    display: "flex",
                    mr: 1,
                  }}
                >
                  {props?.t("Select dimensions")}:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    ml: 2,
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="body2">Height(px):</Typography>
                    <Input
                      type="number"
                      size="small"
                      sx={{ maxWidth: "50px" }}
                      onChange={(e) =>
                        handleFormFieldOptions({
                          ...formFieldOptions,
                          boxHeight: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="body2">Width(px):</Typography>
                    <Input
                      type="number"
                      size="small"
                      sx={{ maxWidth: "50px" }}
                      onChange={(e) =>
                        handleFormFieldOptions({
                          ...formFieldOptions,
                          boxWidth: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )} */}

            {formFieldOptions?.fieldType == "list" && (
              <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.heading.main,
                    fontWeight: "600",
                    display: "flex",
                    mr: 1,
                  }}
                >
                  {props?.t("Selected lists")}
                </Typography>
                {formFieldOptions?.listItems &&
                formFieldOptions?.listItems?.length > 0 ? (
                  <Select
                    // displayEmpty
                    sx={{ height: "30px", fontSize: "0.9rem" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {formFieldOptions?.listItems?.map((item, i) => (
                      <StyledMenuItem value={item} key={`selected-field-${i}`}>
                        {item}
                      </StyledMenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography sx={{ mr: 2, color: "red" }}>
                    {props?.t("no list selected")}
                  </Typography>
                )}

                {!showDropdownListForm ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setShowDropdownListForm(true)}
                    sx={{ ml: 1 }}
                  >
                    {props?.t("Select List")}
                  </Button>
                ) : (
                  <Modal
                    open={showDropdownListForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        py: 2,
                        maxHeight: "90vh",
                        overflowY: "auto",
                      }}
                    >
                      {openCreatListModal ? (
                        <Box sx={{ p: 1 }}>
                          {isSavingAndDispatching ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: 4,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <Box>
                              {" "}
                              <Typography
                                variant="body2"
                                sx={{
                                  color: theme.palette.heading.main,
                                  fontWeight: "600",
                                }}
                              >
                                {props?.t(
                                  "You will be directed to settings. Do you want to save the new Changes"
                                )}
                                ?
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    saveTemplateAndDispatchToSettings();
                                    // dispatch({
                                    //   type: "SET_Directory",
                                    //   payload: "settings",
                                    // });
                                    // callSaveAndContinueFunction();
                                  }}
                                >
                                  {props?.t("Yes")}
                                </Button>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={() => {
                                    dispatch({
                                      type: "SET_Directory",
                                      payload: "settings",
                                    });
                                  }}
                                >
                                  {props?.t("No")}
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          {" "}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: 2,
                            }}
                          >
                            {" "}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                mb: 4,
                              }}
                            >
                              <Box sx={{ mt: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {" "}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "1.2rem", mb: 2 }}
                                  >
                                    {props?.t("Available Lists")}
                                  </Typography>{" "}
                                  <Button
                                    size="small"
                                    sx={{ height: "max-content" }}
                                    variant="contained"
                                    onClick={() => {
                                      setOpenCreateListModal(true);
                                    }}
                                  >
                                    {props?.t("Create new List")}
                                  </Button>
                                </Box>

                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          {props?.t("List")}
                                        </TableCell>
                                        <TableCell>
                                          {props?.t("Items")}
                                        </TableCell>
                                        <TableCell>
                                          {props?.t("Actions")}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {allListsOptions?.map((list, n) => (
                                        <TableRow>
                                          <TableCell>
                                            {list?.list_name}
                                          </TableCell>
                                          <TableCell>
                                            <Select size="small">
                                              {list?.list_items?.map(
                                                (item, m) => (
                                                  <MenuItem value={item}>
                                                    {item}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              onClick={() => {
                                                setFormFieldOptions({
                                                  ...formFieldOptions,
                                                  listItems: list?.list_items,
                                                  listId: list?.id,
                                                });
                                                setShowDropdownListForm(false);
                                              }}
                                            >
                                              {props?.t("Select")}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Box>
                          </Box>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ ml: 1, width: "100%" }}
                            onClick={() => setShowDropdownListForm(false)}
                          >
                            {props?.t("close")}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Modal>
                )}
              </Box>
            )}
            {formFieldOptions?.fieldType == "checkbox" && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  ml: 3,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", color: theme.palette.heading.main }}
                >
                  {props?.t("Select Images")} :
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {props?.t("Checked")}:
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {selectedCheckboxImages?.true &&
                    selectedCheckboxImages?.true ==
                      "https://docspawn-bucket-1.s3.eu-central-1.amazonaws.com/docspawn-bucket-1/3023150a-74f0-4b47-92ec-5ec0df52b65d_transparent.png.png" ? (
                      <Typography sx={{ color: theme.palette.para.main }}>
                        ({props?.t("empty")})
                      </Typography>
                    ) : (
                      <img
                        src={selectedCheckboxImages?.true}
                        alt="check"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    <Button
                      size="small"
                      onClick={() => {
                        setOpenChangeCheckboxImagesM(true);
                        setChangeCheckboxImagesType("checked");
                      }}
                    >
                      {props?.t("change")}
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {props?.t("Unchecked")}:
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {selectedCheckboxImages?.false &&
                    selectedCheckboxImages?.false ==
                      "https://docspawn-bucket-1.s3.eu-central-1.amazonaws.com/docspawn-bucket-1/3023150a-74f0-4b47-92ec-5ec0df52b65d_transparent.png.png" ? (
                      <Typography sx={{ color: theme.palette.para.main }}>
                        ({props?.t("empty")})
                      </Typography>
                    ) : (
                      <img
                        src={selectedCheckboxImages?.false}
                        alt="check"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    <Button
                      size="small"
                      onClick={() => {
                        setOpenChangeCheckboxImagesM(true);
                        setChangeCheckboxImagesType("unchecked");
                      }}
                    >
                      {props?.t("change")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        isPreview && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.heading.main,
                fontWeight: "600",
                mr: 3,
              }}
            >
              {props?.t("Preview Data")}:
            </Typography>
            <ArrowBackIosNewIcon
              sx={{
                color: theme.palette.para.main,
                size: "11px",
                cursor: "pointer",
              }}
              onClick={() => {
                dataShown === 1
                  ? setDataShown(1)
                  : setDataShown((prev) => prev - 1);
              }}
            />
            <Box
              sx={{
                padding: "3px 8px",
                border: "1px solid lightgray",
              }}
            >
              <Typography variant="body2">
                {props?.t("Data")}: {`${dataShown}/${totalData}`}
              </Typography>
            </Box>
            <ArrowForwardIosIcon
              sx={{
                color: theme.palette.para.main,
                size: "11px",
                cursor: "pointer",
              }}
              onClick={() => {
                dataShown === totalData
                  ? setDataShown(totalData)
                  : setDataShown((prev) => prev + 1);
              }}
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default withTranslation()(SpecificTabOptions);

const dateFormats = [
  {
    value: "MM/DD/YY",
    description: "Month-Day-Year with leading zeros (02/17/2009)",
  },
  {
    value: "DD/MM/YY",
    description: "Day-Month-Year with leading zeros (17/02/2009)",
  },
  {
    value: "YY/MM/DD",
    description: "Year-Month-Day with leading zeros (2009/02/17)",
  },
  {
    value: "Month D, Yr",
    description:
      "Month name-Day-Year with no leading zeros (February 17, 2009)",
  },
  {
    value: "M/D/YY",
    description: "Month-Day-Year with no leading zeros (2/17/2009)",
  },
  {
    value: "D/M/YY",
    description: "Day-Month-Year with no leading zeros (17/2/2009)",
  },
  {
    value: "YY/M/D",
    description: "Year-Month-Day with no leading zeros (2009/2/17)",
  },
  {
    value: " bM/bD/YY",
    description:
      "Month-Day-Year with spaces instead of leading zeros ( 2/17/2009)",
  },
  {
    value: " bD/bM/YY",
    description:
      "Day-Month-Year with spaces instead of leading zeros (17/ 2/2009)",
  },
  {
    value: "YY/ bM/bD",
    description:
      "Year-Month-Day with spaces instead of leading zeros (2009/ 2/17)",
  },
  {
    value: "MMDDYY",
    description: "Month-Day-Year with no separators (02172009)",
  },
  {
    value: "DDMMYY",
    description: "Day-Month-Year with no separators (17022009)",
  },
  {
    value: "YYMMDD",
    description: "Year-Month-Day with no separators (20090217)",
  },
  {
    value: "MonDDYY",
    description: "Month abbreviation-Day-Year with leading zeros (Feb172009)",
  },
  {
    value: "DDMonYY",
    description: "Day-Month abbreviation-Year with leading zeros (17Feb2009)",
  },
  {
    value: "YYMonDD",
    description: "Year-Month abbreviation-Day with leading zeros (2009Feb17)",
  },
  {
    value: "day/YY",
    description: "Day of year-Year (48/2009)",
  },
  {
    value: "YY/day",
    description: "Year-Day of Year (2009/48)",
  },
  {
    value: "D Month, Yr",
    description: "Day-Month name-Year (17 February, 2009)",
  },
  {
    value: "Yr, Month D",
    description: "Year-Month name-Day (2009, February 17)",
  },
  {
    value: "Mon-DD-YYYY",
    description:
      "Month abbreviation, Day with leading zeros, Year (Feb 17, 2009)",
  },
  {
    value: "DD-Mon-YYYY",
    description:
      "Day with leading zeros, Month abbreviation, Year (17 Feb, 2009)",
  },
  {
    value: "YYYYY-Mon-DD",
    description:
      "Year, Month abbreviation, Day with leading zeros (2009, Feb 17)",
  },
  {
    value: "Mon DD, YYYY",
    description:
      "Month abbreviation, Day with leading zeros, Year (Feb 17, 2014)",
  },
  {
    value: "DD Mon, YYYY",
    description:
      "Day with leading zeros, Month abbreviation, Year (17 Feb, 2014)",
  },
  {
    value: "YYYY, Mon DD",
    description:
      "Year, Month abbreviation, Day with leading zeros (2014, Feb 17)",
  },
];

const timeFormats = [
  {
    value: "HH:MM:SS",
    description:
      'Time is based on a 24 hour system. This is frequently referred to as "military time". The 24 hour system is the default format.Example: 14:18:23',
  },
  {
    value: "HH:MM:SS XM",
    description:
      "Time is based on a 12 hour system. AM or PM is given. Example: 02:18:23 PM",
  },
  {
    value: "HH:MM",
    description:
      "Time is based on a 24 hour system. Seconds are not given.Example: 14:18",
  },
  {
    value: "HH:MM XM",
    description:
      "Time is based on a 12 hour system. Seconds are not given. AM or PM is given.Example: 02:18 PM",
  },
];
