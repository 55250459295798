import React, { useState, useEffect } from "react";
import { Box, Checkbox, Input, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const DataSelection = (props) => {
  const { t } = useTranslation();
  const {
    setSelectedDataForTemplateEditor,
    CSVFileJSON,
    allowedDocumentGenerationEntries,
    setAllowedDocumentGenerationEntries,
    setFinalDataForCSVBackend,
  } = props;
  const theme = useTheme();
  const [csvData, setCSVData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const templateToOpen = useSelector((state) => state.templateToOpen);
  const [selectedRows, setSelectedRows] = useState(
    allowedDocumentGenerationEntries?.length > 0
      ? allowedDocumentGenerationEntries
      : []
  );
  const [userInput, setUserInput] = useState("");
  const [finalDataForCSV, setFinalDataForCSV] = useState([]);
  const [showInputSeletion, setShowInputSelection] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showMarkDataOptions, setShowMarkDataOptions] = useState(true);

  useEffect(() => {
    if (CSVFileJSON?.length > 0) {
      setColumnNames(Object.keys(CSVFileJSON[0]));

      setSelectedColumns(Object.keys(CSVFileJSON[0]));
      setSelectedDataForTemplateEditor(Object.keys(CSVFileJSON[0]));
      setCSVData(CSVFileJSON);
    }
  }, [CSVFileJSON]);

  const onChangeSelectedRows = (input) => {
    if (input) {
      setUserInput(input);
      const selectedRowsArray = parseUserInput(input);
      setSelectedRows(selectedRowsArray);
    } else {
      setUserInput(input);

      setSelectedRows([]);
    }
  };
  useEffect(() => {
    setAllowedDocumentGenerationEntries(selectedRows);
    setFinalDataForCSV(
      CSVFileJSON?.filter((c, i) => selectedRows.includes(i + 1))
    );
  }, [selectedRows]);
  useEffect(() => {
    setFinalDataForCSVBackend(finalDataForCSV);
  }, [finalDataForCSV]);

  // Helper function to parse user input like "1-10, 20, 30-40" into an array of row numbers.
  const parseUserInput = (input) => {
    const ranges = input.split(",");
    let selectedRowsArray = [];

    ranges.forEach((range) => {
      const parts = range.trim().split("-");
      if (parts.length === 1) {
        selectedRowsArray.push(parseInt(parts[0]));
      } else if (parts.length === 2) {
        const start = parseInt(parts[0]);
        const end = parseInt(parts[1]);
        for (let i = start; i <= end; i++) {
          selectedRowsArray.push(i);
        }
      }
    });

    return selectedRowsArray;
  };

  const isRowSelected = (rowIndex) => {
    return selectedRows.includes(rowIndex + 1); // Rows are 1-indexed
  };

  return (
    <Box>
      <Box
        sx={{
          my: 2,
          display: "flex",
          // gap: "140px",
          gap: "14px",
          flexDirection: "column",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "10px", flex: "0.5", mb: 1.4 }}>
            <input
              type="radio"
              checked={showMarkDataOptions}
              onChange={(e) => {
                if (e.target?.checked) {
                  setShowMarkDataOptions(true);
                  setSelectedRows([]);
                  setFinalDataForCSV([]);
                  setShowInputSelection(false);
                  setUserInput("");
                } else {
                  setShowMarkDataOptions(false);
                  setSelectedRows([]);
                  setFinalDataForCSV([]);
                  setShowInputSelection(false);
                  setUserInput("");
                }
              }}
            />
            <Typography variant="p" sx={{ fontSize: "1rem" }}>
              {props?.t("Mark data for document creation")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flex: "0.5" }}>
            <input
              type="radio"
              disabled
              onChange={(e) => {
                e.target?.checked ? setFileOutput(1) : setFileOutput(0);
              }}
            />
            <Typography
              variant="p"
              sx={{ color: "lightgray", fontSize: "1rem" }}
            >
              {props?.t("Generate for fresh data only")}
            </Typography>
          </Box>
        </Box>
        <Box>
          {" "}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flex: "0.5",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <input
                style={{}}
                type="radio"
                checked={showInputSeletion}
                onChange={(e) => {
                  setIsAllChecked(false);
                  setShowMarkDataOptions(false);
                  setSelectedRows([]);
                  setFinalDataForCSV([]);
                  setUserInput("");
                  e.target?.checked
                    ? setShowInputSelection(true)
                    : setShowInputSelection(false);
                }}
              />
              <Typography variant="p" sx={{ fontSize: "1rem", pl: 1.5 }}>
                {props?.t("Manual Data entry")}
              </Typography>
            </Box>

            {showInputSeletion && (
              <Input
                size="small"
                variant="contained"
                placeholder="e.g 1,2, 10-12"
                id="selectRowInput"
                // type="input"
                className=".MuiInput-root"
                value={userInput}
                onChange={(e) => {
                  onChangeSelectedRows(e.target.value);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>{" "}
      <Box sx={{ mt: 2 }}>
        {selectedRows?.length > 25 ? (
          <Typography sx={{ color: "red" }}>
            {props?.t(
              "Sorry for the inconvenience, but it is not possible to generate more than 25 documents at a time in the beta."
            )}
          </Typography>
        ) : (
          <Typography
            sx={{ color: theme.palette.para.main, fontWeight: "500" }}
          >
            {props?.t(
              "In the beta, it is only possible to generate up to 25 documents at a time."
            )}
          </Typography>
        )}
      </Box>
      <Box>
        {csvData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Box sx={{}}>
              <TableContainer
                component={Paper}
                sx={{
                  position: "relative",
                  height: "max-content",
                  maxHeight: "370px",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Table
                  style={{
                    width: "max-content",
                    // tableLayout: "fixed",
                    overflowX: "auto",
                    tableLayout: "fixed", // Added for fixed layout
                    position: "relative",
                  }}
                  sx={{ position: "relative" }}
                >
                  <TableHead sx={{ position: "relative" }}>
                    <TableRow
                      sx={{
                        backgroundColor: theme.palette.accent.main,
                        position: "sticky", // Stick the header
                        top: 0,
                        zIndex: "1",
                      }}
                    >
                      {showMarkDataOptions && (
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            py: 0,
                          }}
                        >
                          <Checkbox
                            checked={isAllChecked}
                            size="small"
                            style={{ py: "0px", zIndex: "0" }}
                            onChange={(e) => {
                              if (e.target?.checked) {
                                setSelectedRows(
                                  CSVFileJSON?.map((c, i) => i + 1)
                                );
                                setFinalDataForCSV(CSVFileJSON);
                                setShowInputSelection(false);
                                setIsAllChecked(true);
                                setUserInput("");
                              } else {
                                setSelectedRows([]);
                                setFinalDataForCSV([]);
                                setShowInputSelection(false);
                                setIsAllChecked(false);
                                setUserInput("");
                              }
                            }}
                          />
                        </TableCell>
                      )}

                      {columnNames.map((name, index) => (
                        <TableCell
                          key={index}
                          sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.8rem",
                                fontWeight: "600",

                                color: theme.palette.heading.main,
                                padding: "6px", // Add padding to adjust cell height
                              }}
                            >
                              {name}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {csvData.map((data, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          backgroundColor: isRowSelected(rowIndex)
                            ? "lightblue"
                            : "inherit",
                        }}
                      >
                        {showMarkDataOptions && (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              height: "10px",
                              py: "0px",
                            }}
                          >
                            <Checkbox
                              checked={isRowSelected(rowIndex)}
                              style={{ py: "0px", zIndex: "0" }}
                              size="small"
                              onChange={(e) => {
                                if (!e.target?.checked) {
                                  console.log("run1");
                                  setSelectedRows(
                                    selectedRows?.filter(
                                      (ind) => ind !== rowIndex + 1
                                    )
                                  );
                                } else {
                                  let filt = [...selectedRows, rowIndex + 1];
                                  setSelectedRows(filt);
                                }
                              }}
                              color="primary" // Set the checkbox color to primary
                            />
                          </TableCell>
                        )}

                        {columnNames.map((column, columnIndex) => (
                          <TableCell
                            key={columnIndex}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              padding: "12px", // Add padding to adjust cell height
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                                color: theme.palette.text.primary,
                              }}
                            >
                              {data[column]}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withTranslation()(DataSelection);
