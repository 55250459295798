import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { withTranslation } from "react-i18next";
const FileUploader = (props) => {
  let { setFile, type, isUseTemplateTab, refreshIt } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    refreshIt && setSelectedFile(null);
  }, [refreshIt]);
  const [error, setError] = useState(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateFile(file);
  };

  const handleBrowse = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    validateFile(file);
  };

  const validateFile = (file) => {
    if (type === "background" && !file.name.endsWith(".pdf")) {
      setError("Invalid file format. Please select a PDF file.");
    } else if (
      type === "data" &&
      ![".csv", ".xls", ".xlsx"].some((ext) => file.name.endsWith(ext))
    ) {
      setError("Invalid file format. Please select a CSV, XLS, or XLSX file.");
    } else {
      setError("");
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    setFile(selectedFile);
  }, [selectedFile]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: isUseTemplateTab ? "100%" : "200px",
        width: isUseTemplateTab ? "100%" : "300px",
        border: "2px dashed gray",
        borderRadius: "4px",
        padding: "16px",
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop} // Add the event listener here
    >
      {error && (
        <Typography variant="body1" color="error" sx={{ marginBottom: "16px" }}>
          {props?.t(error)}
        </Typography>
      )}
      {selectedFile ? (
        <>
          <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
            {props?.t("Selected File")}:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "16px" }}>
            {selectedFile.name}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" sx={{ marginBottom: "16px" }}>
            {type === "background"
              ? props?.t("Drag and drop a PDF file here")
              : props?.t("Drag and drop a CSV or XLSX file here")}
          </Typography>
          <label>
            <input
              // id="file-upload"
              type="file"
              accept={
                type === "background"
                  ? ".pdf"
                  : ".csv,.xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              style={{ display: "none" }}
              onChange={handleBrowse}
            />
            <Button variant="contained" component="span">
              {props?.t("Browse File")}
            </Button>
          </label>
        </>
      )}
    </Box>
  );
};

export default withTranslation()(FileUploader);
