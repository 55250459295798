import React from "react";
import GoogleLogin from "../components/Auth/GoogleLogin.js";
import LinkedinLogin from "../components/Auth/LinkedinLogin.js";
import { Box, Button, Input, TextField, Typography } from "@mui/material";
import Logo from "../components/Logo.js";
import { useTheme } from "@mui/material";
import Img1 from "../utils/images/login_img_1.png";
import Img2 from "../utils/images/login_img_2.png";
import Img3 from "../utils/images/login_img_3.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import AES from "crypto-js/aes";
import { api, setAuthToken } from "../axios/api";
import { setUser, setToken } from "../actions/user";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import imgLogo from "../utils/images/Logo.png";

const Login = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [adminPassword, setAdminPassword] = useState("");
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, []);
  const submitLogin = () => {
    api
      .post("/login/manual", {
        email_address: "admin@beta.docspawn.app",
        password: adminPassword,
      })
      .then((res) => {
        const { verified, token } = res?.data;
        setAuthToken(token);

        dispatch(setToken(token));
        /************************** */
        api
          .get("/user-info/me")
          .then((res) => {
            dispatch(setUser(res?.data));
          })
          .catch((err) => {
            // console.log("error while fetching user info", err);
          });

        /************************** */

        toast.success(t("Logged in successfully"));
        // clearForms();
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
    // .catch(() => toast.error(t("unable to login")));
  };

  const [signupName, setSignupName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  // const [couponText, setCouponText] = useState("");
  const [signupNameError, setSignupNameError] = useState("");
  const [signupEmailError, setSignupEmailError] = useState("");
  const [signupPasswordError, setSignupPasswordError] = useState("");
  // const [couponTextError, setCouponTextError] = useState("");
  const [initiatedSignupValidation, setIsInitiatedSignupValidation] =
    useState(false);

  const signupFromLandingPage = useSelector((state) => state.emailForSignup);
  useEffect(() => {
    if (signupFromLandingPage?.length > 0) {
      setSignupEmail(signupFromLandingPage);
    }
  }, [signupFromLandingPage]);

  const validateSignupForm = (check) => {
    if (!initiatedSignupValidation && !check) {
      return;
    }

    setSignupEmailError("");
    setSignupNameError("");
    setSignupPasswordError("");
    if (signupName?.length < 1) {
      setSignupNameError("required");
    }
    if (signupEmail?.length < 1) {
      setSignupEmailError("required");
    }
    if (signupPassword?.length < 8) {
      setSignupPasswordError("min 8 chars. required");
    }
    // if (couponText?.length != 8) {
    //   setCouponTextError("coupon must have 8-charachters");
    // }
  };

  useEffect(() => {
    validateSignupForm();
  }, [signupEmail, signupName, signupPassword]);

  /************************* */
  const [signinEmail, setSigninEmail] = useState("");
  const [signinPassword, setSigninPassword] = useState("");
  // const [couponText, setCouponText] = useState("");
  const [signinEmailError, setSigninEmailError] = useState("");
  const [signinPasswordError, setSigninPasswordError] = useState("");
  // const [couponTextError, setCouponTextError] = useState("");
  const [initiatedSigninValidation, setIsInitiatedSigninValidation] =
    useState(false);

  const signinFromLandingPage = useSelector((state) => state.emailForSignin);
  useEffect(() => {
    if (signinFromLandingPage?.length > 0) {
      setSigninEmail(signinFromLandingPage);
    }
  }, [signinFromLandingPage]);

  const validateSigninForm = (check) => {
    console.log("initiatedSigninValidation", initiatedSigninValidation);
    if (!initiatedSigninValidation && !check) {
      return;
    }

    setSigninEmailError("");
    setSigninPasswordError("");

    if (signinEmail?.length < 1) {
      setSigninEmailError("required");
    }
    if (signinPassword?.length < 8) {
      setSigninPasswordError("min 8 chars. required");
    }
    // if (couponText?.length != 8) {
    //   setCouponTextError("coupon must have 8-charachters");
    // }
  };

  useEffect(() => {
    validateSigninForm();
  }, [signinEmail, signinPassword]);

  /***************** */
  /************************* */
  const [recoveryEmail, setRecoveryEmail] = useState("");

  const [recoveryEmailError, setRecoveryEmailError] = useState("");

  const [initiatedRecoveryValidation, setIsInitiatedRecoveryValidation] =
    useState(false);

  const validateRecoveryForm = (check) => {
    if (!initiatedRecoveryValidation && !check) {
      return;
    }

    setRecoveryEmailError("");

    if (recoveryEmail?.length < 1) {
      setSigninEmailError("required");
    }
  };

  useEffect(() => {
    validateRecoveryForm();
  }, [recoveryEmail]);

  /***************** */
  const [showVerificationButton, setShowVerificationButton] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (location.pathname == "/login") {
      setIsInitiatedSigninValidation(true);
      // login logic
      validateSigninForm(true);
      if (
        !signinEmailError &&
        !signinPasswordError
        // !couponTextError
      ) {
        /************** checking coupon exist */

        // api
        //   .get("/signup-coupon/check/" + couponText)
        //   .then((res) => {
        //     console.log("res?.datas", res?.data);
        //     toast.success("correct coupon");
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     toast.success("coupon is incorrect");
        //   });
        // return;

        var encPass = AES.encrypt(
          signinPassword,
          process.env.REACT_APP_CRYPTO_JS_KEY
        ).toString();
        let obj = {
          email_address: signinEmail,
          password: encPass,
        };

        api
          .post("/login/manual", obj)
          .then((res) => {
            const { verified, token } = res?.data;
            if (!verified) {
              toast.error(props?.t("Please verify your account"));
              setAuthToken(token);
              setTimeout(() => {
                setShowVerificationButton(true);
              }, 1000);
            } else {
              toast?.success(props?.t("Logged in successfully"));
              setAuthToken(token);

              dispatch(setToken(token));
              setTimeout(() => {
                api
                  .get("/user-info/me")
                  .then((res) => {
                    dispatch(setUser(res?.data));
                  })
                  .catch((err) => {
                    // console.log("error while fetching user info", err);
                  });
              }, 1000);

              if (token) {
                // toast.success(t("logged in successfully!"));

                setTimeout(() => {
                  navigate("/");
                }, 2000);
              }
            }
            // navigate("/login");
          })
          .catch((error) => {
            if (error.response.status === 404) {
              // Handle "Your account is not invited!" error
              toast.error(props?.t("Your account is not registered"));
            } else if (error.response.status === 400) {
              // Handle "Your Account is UnAuthorized" error
              toast.error(props?.t("Your password is incorrect"));
            } else {
              // Handle other errors
              toast.error(
                t("An error occurred while logging in. Try again later")
              );
            }
          });
      }
    }
    if (location.pathname == "/forgot-password") {
      setIsInitiatedRecoveryValidation(true);
      // login logic
      validateRecoveryForm(true);
      if (!recoveryEmailError) {
        let obj = {
          email_address: recoveryEmail,
        };

        api
          .post("/sendpasswordrecoverylink", obj)
          .then((res) => {
            setRecoveryEmail("");
            // toast.success("Recovery email has been sent to your account");
            toast.success("email with instructions has been sent");
          })
          .catch((err) => {
            // console.log("error", err);
            toast.error("unable to send recovery link!");
          });
        return;
        api
          .post("/login/manual", obj)
          .then((res) => {
            console.log("response from login", res?.data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              toast.error(props?.t("Your account is not registered"));
            } else {
              toast.error(
                t("An error occurred while logging in. Try again later")
              );
            }
          });
      }
    }
    if (location.pathname == "/signup") {
      setIsInitiatedSignupValidation(true);
      validateSignupForm(true);
      if (
        !signupEmailError &&
        !signupNameError &&
        !signupPasswordError
        // !couponTextError
      ) {
        /************** checking coupon exist */

        // api
        //   .get("/signup-coupon/check/" + couponText)
        //   .then((res) => {
        //     console.log("res?.datas", res?.data);
        //     toast.success("correct coupon");
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     toast.success("coupon is incorrect");
        //   });
        // return;

        var encPass = AES.encrypt(
          signupPassword,
          process.env.REACT_APP_CRYPTO_JS_KEY
        ).toString();
        let obj = {
          name: signupName,
          email: signupEmail,
          password: encPass,
        };

        api
          .post("/signup/manual", obj)
          .then((res) => {
            toast?.success(props?.t("Verification email sent successfully"));
            // navigate("/login");
          })
          .catch((error) => {
            if (error.response.status === 404) {
              // Handle "Your account is not invited!" error
              toast.error(t("Your account is not invited"));
            } else if (error.response.status === 403) {
              // Handle "Your Account is UnAuthorized" error
              toast.error(t("Your Account is Unauthorized"));
            } else if (error.response.status === 409) {
              // Handle "Your Account is UnAuthorized" error
              toast.error(t("Your Account already exists"));
            } else {
              // Handle other errors
              toast.error(
                t("An error occurred while loggin in. Try again later")
              );
            }
          });
      }
    }
  };

  const sendAccountVerificationEmail = () => {
    api
      .post("/sendAccountVerificationEmail")
      .then((res) => {
        toast.success("email sent successfully!");
        setShowVerificationButton(false);
        // setIsVerificationEmailSent(true);
        setTimeout(() => {
          // setShowNotVerifiedStatus(false);
          // setIsVerificationEmailSent(false);
        }, 4000);
      })
      .catch((err) => {
        // console.log(err);
        toast.error("unable to send email");
        setShowVerificationButton(false);
      });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        // overflowY: "scroll",
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          background: theme.palette.background.main,
          padding: "20px",
          borderRadius: "4px",
          // minHeight: "100px",
          minWidth: "300px",

          flex: { xs: "1", sm: "0.3" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 0,
          minHeight: "max-content",
          // height: "100%",
          overflowY: "auto",
          // minHeight: "max-content",
          // border: "1px solid red",
        }}
      >
        <Box
          sx={{
            margin: "-20px 0 40px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={imgLogo} style={{ width: "190px", height: "auto" }} />
          {/* <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.7rem",
              fontWeight: "400",
              fontFamily: "roboto, sans-serif",
              textAlign: "center",
            }}
          >
            DocSpawn
          </Typography> */}
        </Box>
        <Box
          sx={{
            margin: "10px 0 20px 0",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            // border: "1px solid red",
          }}
        >
          {/* <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, fontWeight: "600" }}
          >
            {props?.t("Login your account")}
          </Typography> */}
          {location?.pathname == "/login" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "280px",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.heading.main,
                  // fontWeight: "600",
                  mb: 3,
                }}
              >
                {props?.t("Login your account")}
              </Typography>{" "}
              <GoogleLogin toast={toast} />
              <Typography sx={{ my: 2, color: theme.palette.para.main }}>
                {props?.t("OR")}
              </Typography>
              {/* <LinkedinLogin /> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  fontSize: "0.7rem",
                  width: "100%",
                }}
              >
                {showVerificationButton && (
                  <Button
                    size="small"
                    onClick={() => sendAccountVerificationEmail()}
                    sx={{ textTransform: "inherit" }}
                    variant="outlined"
                  >
                    Resend verification email
                  </Button>
                )}
                <TextField
                  size="small"
                  placeholder={props?.t("Enter your email")}
                  fullWidth
                  InputProps={{
                    sx: { fontSize: "12px" }, // Adjust the font size as needed
                  }}
                  value={signinEmail}
                  onChange={(e) => setSigninEmail(e.target.value)}
                  error={signinEmailError}
                  helperText={signinEmailError && signinEmailError}
                />
                <TextField
                  size="small"
                  placeholder={props?.t("Enter your password")}
                  fullWidth
                  InputProps={{
                    sx: { fontSize: "12px", width: "100%" }, // Adjust the font size as needed
                  }}
                  value={signinPassword}
                  onChange={(e) => setSigninPassword(e.target.value)}
                  error={signinPasswordError}
                  helperText={signinPasswordError && signinPasswordError}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    cursor: "pointer",
                    textAlign: "right",
                    mb: 1,
                  }}
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot password?
                </Typography>

                <Button
                  variant="contained"
                  sx={{ fontSize: "12px", textTransform: "inherit" }}
                  onClick={handleSubmit}
                >
                  {props?.t("Login")}
                </Button>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/signup")}
                >
                  {props?.t("Don't have an account? Signup")}
                </Typography>
              </Box>
            </Box>
          ) : location?.pathname == "/forgot-password" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "280px",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.heading.main,
                  // fontWeight: "600",
                  mb: 3,
                }}
              >
                Recover your account
              </Typography>{" "}
              {/* <LinkedinLogin /> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  fontSize: "0.7rem",
                  width: "100%",
                }}
              >
                <TextField
                  size="small"
                  placeholder={props?.t("Enter your email")}
                  fullWidth
                  InputProps={{
                    sx: { fontSize: "12px" }, // Adjust the font size as needed
                  }}
                  value={recoveryEmail}
                  onChange={(e) => setRecoveryEmail(e.target.value)}
                  error={recoveryEmailError}
                  helperText={recoveryEmailError && recoveryEmailError}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    cursor: "pointer",
                    textAlign: "right",
                    mb: 1,
                  }}
                  onClick={() => navigate("/login")}
                >
                  Remember password? login
                </Typography>

                <Button
                  variant="contained"
                  sx={{ fontSize: "12px", textTransform: "inherit" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          ) : location?.pathname == "/signup" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid green",
                maxWidth: "280px",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.heading.main,
                  // fontWeight: "600",
                  mb: 3,
                }}
              >
                {props?.t("Create your account")}
              </Typography>{" "}
              <GoogleLogin toast={toast} />
              {/* <GoogleLogin toast={toast} /> */}
              <Typography sx={{ my: 2, color: theme.palette.para.main }}>
                {props?.t("OR")}
              </Typography>
              {/* <LinkedinLogin /> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  fontSize: "0.7rem",
                  width: "100%",
                }}
              >
                <TextField
                  size="small"
                  placeholder={props?.t("Full Name")}
                  fullWidth
                  InputProps={{
                    sx: { fontSize: "14px", width: "100%" }, // Adjust the font size as needed
                  }}
                  value={signupName}
                  onChange={(e) => setSignupName(e.target.value)}
                  error={signupNameError}
                  helperText={signupNameError && signupNameError}
                />
                <TextField
                  size="small"
                  placeholder={props?.t("Enter your email")}
                  fullWidth
                  InputProps={{
                    sx: { fontSize: "14px" }, // Adjust the font size as needed
                  }}
                  value={signupEmail}
                  onChange={(e) => setSignupEmail(e.target.value)}
                  error={signupEmailError}
                  helperText={signupEmailError && signupEmailError}
                />
                <TextField
                  size="small"
                  placeholder={props?.t("Enter password(min 8 charachters)")}
                  fullWidth
                  type="password"
                  InputProps={{
                    sx: { fontSize: "14px", width: "100%" }, // Adjust the font size as needed
                  }}
                  value={signupPassword}
                  onChange={(e) => setSignupPassword(e.target.value)}
                  error={signupPasswordError}
                  helperText={signupPasswordError && signupPasswordError}
                />
                {/*<TextField
               //   size="small"
               //   placeholder="Enter coupon shared by Admin"
               //   fullWidth
               //   InputProps={{
               //     sx: { fontSize: "14px", width: "100%" }, // Adjust the font size as needed
               //   }}
              //    value={couponText}
              //    onChange={(e) => setCouponText(e.target.value)}
              //    error={couponTextError}
               //   helperText={couponTextError && couponTextError}
                // />*/}
                <Button
                  variant="contained"
                  sx={{ fontSize: "12px", textTransform: "inherit" }}
                  onClick={handleSubmit}
                >
                  {props?.t("Sign up")}
                </Button>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  {props?.t("Already have an account? Login")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.heading.main, fontWeight: "600" }}
              >
                {props?.t("Login to admin dashboard")}
              </Typography>{" "}
              <Input
                placeholder="Admin Password"
                value={adminPassword}
                type="password"
                onChange={(e) => setAdminPassword(e.target.value)}
              ></Input>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                onClick={() => submitLogin()}
              >
                {props?.t("Login")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          flex: "1",
          backgroundImage:
            "linear-gradient(to bottom, #ffffff, #f3f0f8, #eae1ef, #e3d1e4, #dec1d7)",
          height: "100%",
          position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        }}
      >
        <Box
          sx={{
            backdropFilter: "blur(4px)", // Adjust the blur value as per your requirement
            WebkitBackdropFilter: "blur(4px)", // For Safari support
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2, // Set the z-index to place it behind the images
          }}
        ></Box>
        <img
          src={Img1}
          style={{
            width: "55%",
            height: "auto",
            position: "absolute",
            bottom: "0%",
            right: "5%",
            zIndex: "1",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        />
        <img
          src={Img2}
          style={{
            width: "40%",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "10%",
          }}
        />
        <img
          src={Img3}
          style={{
            width: "70%",
            height: "auto",
            position: "absolute",
            top: "5%",
            left: "5%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        />
      </Box>
    </Box>
  );
};

export default withTranslation()(Login);
