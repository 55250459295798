import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../axios/api";
import DeleteIcon from "@mui/icons-material/Delete";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { withTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const Lists = (props) => {
  const [allLists, setAllLists] = useState("");
  useEffect(() => {
    api
      .get("/lists")
      .then((res) => {
        // console.log("response", res?.data);
        if (res?.data?.length > 0) {
          setAllLists(res?.data);
        }
      })
      .catch((err) => {});
  }, []);
  const [showListForm, setShowListForm] = useState("");
  const [listName, setListName] = useState("");
  const [inputListItems, setInputListItems] = useState("");
  const [addedItems, setAddedItems] = useState([]);
  const [listToEdit, setListToEdit] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const handleCloseListForm = () => {
    setShowListForm(false);
    setShowEditForm(false);
    setListToEdit({});
    setListName("");
    setAddedItems([]);
  };
  const theme = useTheme();

  const handleItemListInput = (e) => {
    setInputListItems(e.target.value);
  };

  // const handleAddItems = () => {
  //   if (inputListItems.trim() !== "") {
  //     // Split the input string into an array of items
  //     const newItems = inputListItems.split(",").map((item) => item.trim());

  //     // Update the added items state
  //     setAddedItems((prevItems) => [...prevItems, ...newItems]);

  //     // Clear the input field
  //     setInputListItems("");
  //   }
  // };
  // const handleAddItems = () => {
  //   if (inputListItems.trim() !== "") {
  //     // Split the input string into an array of items using line breaks as separators
  //     const newItems = inputListItems.split(/\r?\n/).map((item) => item.trim());

  //     console.log("new items to add", newItems);

  //     // Update the added items state with the new items
  //     setAddedItems((prevItems) => [...prevItems, ...newItems]);

  //     // Clear the input field
  //     setInputListItems("");
  //   }
  // };
  //88888888888888888888888888888
  // const handleAddItems = () => {
  //   if (inputListItems.trim() !== "") {
  //     // Split the input string into an array of items using flexible separators
  //     const newItems = inputListItems
  //       .split(/\s*,\s*|\s+/)
  //       .map((item) => item.trim());

  //     console.log("new items to add", newItems);

  //     // Update the added items state with the new items
  //     setAddedItems((prevItems) => [...prevItems, ...newItems]);

  //     // Clear the input field
  //     setInputListItems("");
  //   }
  // };
  const handleAddItems = () => {
    if (inputListItems.trim() !== "") {
      // Split the input string into an array of items using commas and line breaks
      const newItems = inputListItems
        .split(/,\s*|\n/)
        .map((item) => item.trim());

      // Update the added items state with the new items
      setAddedItems((prevItems) => [...prevItems, ...newItems]);

      // Clear the input field
      setInputListItems("");
    }
  };

  // const handleAddItems = () => {
  //   if (inputListItems.trim() !== "") {
  //     // Split the input string into an array of items using spaces or commas as separators
  //     const newItems = inputListItems
  //       .split(/[\s,]+/)
  //       .map((item) => item.trim());

  //     console.log("new items to add", newItems);

  //     // Update the added items state with the new items
  //     setAddedItems((prevItems) => [...prevItems, ...newItems]);

  //     // Clear the input field
  //     setInputListItems("");
  //   }
  // };

  const handleCreateList = async () => {
    // Perform actions to create the list with listName and addedItems

    if (!listName || !addedItems || addedItems?.length < 1) {
      toast.error("fill up all fields!");
      return;
    }
    try {
      const result = await api.post("/lists/add", {
        list_name: listName,
        list_items: addedItems,
      });
      if (result?.data?.length > 0) {
        setAllLists(result?.data);
      }

      toast.success("list added successfully");
    } catch (err) {
      // console.log("error", err);
      toast.error("unable to create list!");
    }

    // Reset the state
    setListName("");
    setAddedItems([]);
    setShowListForm(false);
  };
  const handleUpdateList = async () => {
    // Perform actions to create the list with listName and addedItems

    if (!listName || !addedItems || addedItems?.length < 1) {
      toast.error("fill up all fields!");
    }
    try {
      const result = await api.put("/lists/" + listToEdit?.id, {
        list_name: listName,
        list_items: addedItems,
      });
      if (result?.data?.id) {
        let upLis = allLists?.filter((l) => l?.id != listToEdit?.id);
        upLis?.push(result?.data);
        setAllLists(upLis);
      }

      toast.success("list updated successfully");
    } catch (err) {
      // console.log("error", err);
      toast.error("unable to update list!");
    }

    // Reset the state
    setListName("");
    setAddedItems([]);
    setShowEditForm(false);
    setListToEdit({});
  };

  const deleteList = (list) => {
    api
      .delete("/lists/" + list?.id)
      .then((res) => {
        // console.log("res", res);
        toast.success("list deleted successfully");
        setAllLists(allLists?.filter((a) => a?.id != list?.id));
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error("unable to delete");
      });
  };

  // Define a type for the drag-and-drop
  const ItemTypes = {
    ROW: "row",
  };

  // Function to wrap each row with drag-and-drop logic
  const DraggableRow = ({
    index,
    item,
    moveRow,
    lastSelectableRowIndex,
    setLastSelectableRowIndex,
    ...rest
  }) => {
    const [, ref] = useDrag({
      type: ItemTypes.ROW,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (draggedItem) => {
        setLastSelectableRowIndex(draggedItem?.index);

        if (lastSelectableRowIndex != draggedItem?.index) {
          setTimeout(() => {
            setLastSelectableRowIndex(-1);
          }, 1000);
        }

        if (draggedItem.index !== index) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    return (
      <TableRow
        ref={(node) => ref(drop(node))}
        {...rest}
        sx={{
          cursor: "move",
          // borderBottom: lastSelectableRowIndex == index && "2px solid blue",
          // borderColor: theme.palette.primary.main,
          // boxShadow:
          //   lastSelectableRowIndex == index &&
          //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        }}
      >
        {/* Render your row content here */}
        <TableCell
          sx={{
            color:
              lastSelectableRowIndex == index && theme.palette.primary.main,
          }}
        >
          {/* {index + 1} */}
          <DragIndicatorIcon />
        </TableCell>
        <TableCell
          style={{
            width: "max-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <Typography>{item}</Typography>
        </TableCell>
        <TableCell>
          {" "}
          <IconButton
            onClick={() => setAddedItems(addedItems?.filter((a) => a != item))}
          >
            <DeleteIcon sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const moveRow = (fromIndex, toIndex) => {
    const updatedFields = [...addedItems];
    const [removed] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, removed);
    setAddedItems(updatedFields);
  };

  const [lastSelectableRowIndex, setLastSelectableRowIndex] = useState();

  return (
    <Box>
      <ToastContainer />
      <Modal
        open={showListForm || showEditForm}
        // onClose={handleCloseListForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: "90vw",
            width: "max-content",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              position: "sticky",
              top: "-10px",
              left: "100%",
              zIndex: 1,
              // transform: "translateX(-50%)",
              // border: "1px solid blue",
              // borderColor: theme.palette.primary.main,
            }}
            onClick={handleCloseListForm}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {showEditForm
              ? props?.t("Edit List info")
              : props?.t("Add List info")}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              {props?.t("List name")}
            </Typography>
            <TextField
              size="small"
              sx={{ mt: 1 }}
              value={listName}
              onChange={(e) => setListName(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mt: 2, fontWeight: "600" }}>
              {props?.t("List items")}
            </Typography>
            <Typography
              sx={{
                my: 1,
                color: theme.palette.primary.main,
                fontWeight: "600",
              }}
              variant="body2"
            >
              Multiple entries are allowed
            </Typography>
            <Typography
              sx={{
                my: 1,
                color: theme.palette.primary.main,
                fontWeight: "600",
              }}
              variant="body2"
            >
              (comma and line-jump can be used as separator)
            </Typography>
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                multiline={true}
                // size="small"
                sx={{ mt: 1, width: "100%" }}
                value={inputListItems}
                onChange={handleItemListInput}
              />
              <Button
                sx={{ width: "max-content", my: 2 }}
                onClick={handleAddItems}
                variant="contained"
              >
                {props?.t("Add")}
              </Button>
            </Box>
          </Box>
          <Box>
            {/* <Typography
              variant="body2"
              sx={{ my: 1, mt: 3, fontWeight: "600" }}
            >
              Added Items
            </Typography> */}
            <DndProvider backend={HTML5Backend}>
              <TableContainer sx={{ width: "max-content" }}>
                <Table sx={{ width: "max-content" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>index</TableCell>
                      <TableCell>{props?.t("List elements")}</TableCell>
                      <TableCell>{props?.t("Action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedItems.length > 0 ? (
                      addedItems.map((item, index) => (
                        <DraggableRow
                          key={index}
                          index={index}
                          item={item}
                          moveRow={moveRow}
                          lastSelectableRowIndex={lastSelectableRowIndex}
                          setLastSelectableRowIndex={setLastSelectableRowIndex}
                        ></DraggableRow>
                      ))
                    ) : (
                      <Typography>{props?.t("no item added")}.</Typography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DndProvider>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="outlined"
                sx={{ width: "100%", mt: 4 }}
                onClick={() => {
                  setShowEditForm(false);
                  setShowListForm(false);
                }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 4 }}
                onClick={showEditForm ? handleUpdateList : handleCreateList}
              >
                {showEditForm
                  ? props?.t("Update list")
                  : props?.t("Create list")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mb: 4,
        }}
      >
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ width: "max-content" }}
          onClick={() => {
            setListToEdit({});
            setShowListForm(true);
            setListName("");
            setAddedItems([]);
          }}
        >
          {props?.t("Create new list")}
        </Button>
        <Box sx={{ mt: 2 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ background: theme.palette.accent.main }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1.1rem",
                    }}
                  >
                    {props?.t("List")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1.1rem",
                    }}
                  >
                    {props?.t("Items")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1.1rem",
                    }}
                  >
                    {props?.t("Actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allLists?.length > 0 &&
                  allLists
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((list, n) => (
                      <TableRow>
                        <TableCell>{list?.list_name}</TableCell>
                        <TableCell>
                          <Select
                            size="small"
                            sx={{ minWidth: "200px" }}
                            // defaultValue={list?.list_items[0]}
                          >
                            {list?.list_items?.map((item, m) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setShowEditForm(true);
                              setListToEdit(list);
                              setListName(list?.list_name);
                              setAddedItems(list?.list_items);
                            }}
                          >
                            {props?.t("Edit")}
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => deleteList(list)}
                            sx={{ ml: 2 }}
                          >
                            {props?.t("Delete")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(Lists);
