// import { Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import React from "react";

// const SignatureInputField = ({ formValues, field, handleInputChnage }) => {
//   return (
//     <Box sx={{ p: 3, border: "2px solid yellow", mt: 3 }}>
//       <Typography>Draw your Sign</Typography>
//     </Box>
//   );
// };

// export default SignatureInputField;

import {
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { api } from "../../../axios/api";

const SignatureInputField = ({
  field,
  handleInputChange,
  enabled,
  isFinalPreview,
  finalURL,
}) => {
  const signatureRef = useRef();
  const [savedSign, setSavedSign] = useState(false);
  const [penColor, setPenColor] = useState();
  const [penSize, setPenSize] = useState("small");
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileToBackend = async (file) => {
    const formData = new FormData();
    formData.append("files", file, "image.png");

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  async function modifyImage(dataURL) {
    const img = new Image();
    img.src = dataURL;
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const gray = (r + g + b) / 3;
        data[i] = gray;
        data[i + 1] = gray;
        data[i + 2] = gray;
      }
      ctx.putImageData(imageData, 0, 0);
      const signatureImage = canvas.toDataURL();

      // Convert data URL to Blob
      const blob = await fetch(signatureImage).then((res) => res.blob());

      try {
        // Upload the Blob to S3 and get the URL
        const imageUrl = await uploadFileToBackend(blob);

        // Now you can use the imageUrl as needed in your application
        setSavedSign(imageUrl);
        setIsLoading(false);
        handleInputChange(field.fieldName, imageUrl);
      } catch (error) {
        // console.error("Error uploading image to S3:", error);
        setIsLoading(false);
        // Handle the error as needed
      }
      // Do something with the modified data URL
    };
  }

  const handleSave = async () => {
    setIsLoading(true);
    modifyImage(signatureRef.current.toDataURL("image/png"));
    // const signatureImage = signatureRef.current.toDataURL("image/png");

    // // Convert data URL to Blob
    // const blob = await fetch(signatureImage).then((res) => res.blob());

    // try {
    //   // Upload the Blob to S3 and get the URL
    //   const imageUrl = await uploadFileToBackend(blob);
    //   console.log("Image URL from S3:", imageUrl);
    //   // Now you can use the imageUrl as needed in your application
    //   setSavedSign(imageUrl);
    //   setIsLoading(false);
    //   handleInputChange(field.fieldName, imageUrl);
    // } catch (error) {
    //   console.error("Error uploading image to S3:", error);
    //   setIsLoading(false);
    //   // Handle the error as needed
    // }
  };

  // useEffect(() => {
  //   console.log("saved sign", savedSign);
  // }, [savedSign]);
  const handleClear = () => {
    signatureRef.current.clear();
    setSavedSign("");
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box>
        {/* <Box sx={{ display: "flex", gap: "20px", alignItems: "center", mb: 2 }}>
          <Typography variant="body2">Pen Color:</Typography>
          <input
            sx={{ width: "200" }}
            defaultValue="#000"
            type="color"
            onChange={(e) => setPenColor(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px", mb: 2 }}>
          <Typography variant="body2">Pen Width:</Typography>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Button
              size="small"
              variant={penSize == "small" ? "contained" : "outlined"}
              sx={{ fontSize: "0.8rem" }}
              onClick={() => setPenSize("small")}
            >
              small
            </Button>
            <Button
              size="small"
              variant={penSize == "medium" ? "contained" : "outlined"}
              sx={{ fontSize: "0.8rem" }}
              onClick={() => setPenSize("medium")}
            >
              medium
            </Button>
            <Button
              size="small"
              variant={penSize == "large" ? "contained" : "outlined"}
              sx={{ fontSize: "0.8rem" }}
              onClick={() => setPenSize("large")}
            >
              large
            </Button>
          </Box>
        </Box> */}
      </Box>
      {!isFinalPreview && (
        <>
          {" "}
          <Typography variant="body2" sx={{ mb: 2 }}>
            Draw Here:
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "max-content",
              height: "max-content",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          >
            {" "}
            <SignatureCanvas
              ref={signatureRef}
              penColor={penColor ? penColor : "black"}
              canvasProps={{
                width: 300,
                height: 150,
                className: "signature-canvas",
              }}
              // minWidth={penSize == "large" ? 2.5 : penSize == "medium" ? 1.5 : 0.5} //default=0.5
              // maxWidth={penSize == "large" ? 4.5 : penSize == "medium" ? 3.5 : 2.5} //default=2.5     width of pen stroke  id by default min+max/2
              minWidth={0.5}
              maxWidth={2.5}
            />
          </Box>
          {/* {enabled && ( */}
          <Box sx={{ my: 2, display: "flex", gap: "10px" }}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleClear}
              sx={{ fontSize: "0.7rem", textTransform: "inherit" }}
            >
              Clear signature
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleSave}
              sx={{ fontSize: "0.7rem", textTransform: "inherit" }}
            >
              Save signature
            </Button>
          </Box>
          {/* )} */}
          {isLoading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {!savedSign && field?.isMandatory && !finalURL && (
            <Typography variant="body2" color="error">
              No Signature
            </Typography>
          )}
        </>
      )}
      {finalURL ? (
        <Box
          sx={{ border: "1px solid lightgray", maxWidth: "max-content", p: 1 }}
        >
          <Typography variant="body2">Signature: </Typography>
          <img
            src={finalURL}
            alt="sign"
            style={{ height: "auto", width: "auto" }}
          />
        </Box>
      ) : (
        savedSign && (
          <Box
            sx={{
              // border: "1px solid lightgray",
              maxWidth: "max-content",
              p: 1,
            }}
          >
            <Typography variant="body2">Signature saved.</Typography>

            {/* <Typography variant="body2">Signature: </Typography>
            <img
              src={savedSign}
              alt="sign"
              style={{ height: "auto", width: "auto" }}
            /> */}
          </Box>
        )
      )}
    </Box>
  );
};

export default SignatureInputField;
