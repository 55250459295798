import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  Slider,
  Typography,
} from "@mui/material";
import { api } from "../../../../axios/api";
import { useTheme } from "@emotion/react";
import ImageFileUploader from "./imageFileUploader";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropping({
  setFormValues,
  fieldName,
  aspectRatios,
  ratio,
  isFinalPreview,
  setImageStateWhileEditing,
}) {
  const theme = useTheme();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  // const [aspect, setAspect] = useState(16 / 9);
  // const [aspect, setAspect] = useState(aspectRatios[fieldName]);
  const [aspect, setAspect] = useState(ratio);

  const [isUploading, setIsUploading] = useState(false);
  // const [aspect, setAspect] = useState("undefined");

  // function onSelectFile(e) {
  //   console.log("e.target value>>>>", e);
  //   if (e.target.files && e.target.files.length > 0) {
  //     setCrop(undefined);
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () =>
  //       setImgSrc(reader.result?.toString() || "")
  //     );
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // }
  function onSelectFile(file) {
    console.log("file>>>>", file);
    if (file) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(file);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    setIsUploading(true);
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    const anchorRef = hiddenAnchorRef.current;
    const imageFileType = "png";

    if (!image || !previewCanvas || !completedCrop || !anchorRef) {
      throw new Error("Crop canvas does not exist");
      setIsUploading(false);
    }

    const scaleX = 2; //image.naturalWidth / image.width;
    const scaleY = 2; // image.naturalHeight / image.height;
    console.log("scaleX", scaleX);
    console.log("scaley", scaleY);

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
      setIsUploading(false);
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    console.log("image drawn success");
    const blob = await offscreen.convertToBlob({
      type: "image/" + imageFileType,
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    console.log("blob receivec success", blob);
    // Upload the image to the backend
    try {
      const formData = new FormData();
      formData.append("files", blob);
      console.log("form data", formData);

      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "image/" + imageFileType,
        },
      });

      // Assuming the response contains the S3 URL
      console.log("url response recived", response?.data?.url);
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: response?.data?.url,
      }));
      setImageStateWhileEditing(response?.data?.url);
      setIsUploading(false);

      // Handle any additional logic after successful upload
    } catch (error) {
      // console.error("Error uploading image:", error);
      setIsUploading(false);
      // Handle errors during the upload process
    }

    // Clean up: Revoke the object URL
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
  }
  // async function onDownloadCropClick() {
  //   const image = imgRef.current;
  //   const previewCanvas = previewCanvasRef.current;
  //   if (!image || !previewCanvas || !completedCrop) {
  //     throw new Error("Crop canvas does not exist");
  //   }
  //   console.log("image natural width", image.naturalWidth);
  //   console.log("image natural height", image.naturalHeight);
  //   console.log("img height", image.height);
  //   console.log("image width", image.width);
  //   console.log("image>>", image);
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;

  //   const offscreen = new OffscreenCanvas(
  //     completedCrop.width * scaleX,
  //     completedCrop.height * scaleY
  //   );
  //   const ctx = offscreen.getContext("2d");
  //   if (!ctx) {
  //     throw new Error("No 2d context");
  //   }

  //   ctx.drawImage(
  //     previewCanvas,
  //     0,
  //     0,
  //     previewCanvas.width,
  //     previewCanvas.height,
  //     0,
  //     0,
  //     offscreen.width,
  //     offscreen.height
  //   );

  //   const blob = await offscreen.convertToBlob({
  //     type: "image/png",
  //   });
  //   console.log("blob url ref", blobUrlRef);
  //   if (blobUrlRef.current) {
  //     URL.revokeObjectURL(blobUrlRef.current);
  //   }
  //   blobUrlRef.current = URL.createObjectURL(blob);
  //   hiddenAnchorRef.current.href = blobUrlRef.current;
  //   hiddenAnchorRef.current.click();
  // }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // useEffect(() => {
  //   aspect && setAspect(undefined);
  // }, [aspect]);
  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     console.log("running if");
  //     setAspect(undefined);
  //   } else {
  //     console.log("running else");
  //     setAspect(16 / 9);

  //     if (imgRef.current) {
  //       const { width, height } = imgRef.current;
  //       const newCrop = centerAspectCrop(width, height, 16 / 9);
  //       setCrop(newCrop);
  //       setCompletedCrop(convertToPixelCrop(newCrop, width, height));
  //     }
  //   }
  // }

  return (
    <div className="App">
      <div className="Crop-Controls">
        {/* {!isFinalPreview && (
          <Input
            sx={{ my: 2 }}
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
        )} */}
        {!isFinalPreview && <ImageFileUploader onImageSelect={onSelectFile} />}
        {/* <ImageFileUploader onImageSelect={onSelectFile} /> */}

        {/* <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div> */}
        {/* <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div> */}
        {/* <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? "off" : "on"}
          </button>
        </div> */}
      </div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          maxWidth="300px"
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{
              transform: `scale(${scale}) rotate(${rotate}deg)`,
              width: "300px",
              height: "auto",
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {/* <Typography sx={{ my: 2 }}>Image: </Typography> */}
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
                height: "1px",
                opacity: 0,
              }}
            />
          </div>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Zoom
            </Typography>
            <Box sx={{ width: 300, display: "flex", gap: "15px" }}>
              <Typography variant="h6">-</Typography>
              <Slider
                // size="small"
                defaultValue={1}
                // aria-label="Small"
                step={0.1} // Set the step size to 0.1
                marks
                max={5}
                min={0.5}
                value={scale}
                valueLabelDisplay="auto"
                onChange={(e) => setScale(e.target.value)}
              />
              {/* <Slider
              defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
            /> */}
              <Typography variant="h6">+</Typography>
            </Box>
          </Box>
          <div>
            <Button
              size="small"
              variant="contained"
              onClick={onDownloadCropClick}
              sx={{ textTransform: "inherit" }}
            >
              Save
            </Button>
            {/* <div style={{ fontSize: 12, color: "#666" }}>
              You need to open the CodeSandbox preview in a new window (top
              right icon) as security restrictions prevent the download
            </div> */}
            <a
              href="#hidden"
              ref={hiddenAnchorRef}
              download
              style={{
                position: "absolute",
                top: "-200vh",
                visibility: "hidden",
              }}
            >
              Hidden download
            </a>
          </div>
        </>
      )}
      {isUploading && (
        <CircularProgress sx={{ mt: 0.3, color: theme.palette.primary.main }} />
      )}
    </div>
  );
}
