import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import { Box } from "@mui/material";
import { useEditor } from "../../../hooks/useEdtior";
import { useDispatch } from "react-redux";

const PDFRenderer = ({
  generalInformation,
  dataShown,
  selectedField,
  dataFieldsOptions,
  selectedOption,
  isPreview,
  csvData,
  preFilledCanvasObjects,
  setPreFilledCanvasObjects,
  setCanvasAddedFields,
  canvasAddedFields,
  isTemplateOpened,
  setSelectedOption,
  allFormFields,
  isUseCaseOne,
  formValues,
  pdfPageSize,
}) => {
  const canvasRef = useRef(null);
  const { fabricRef, setFabricCanvas, setObjects, setImageURL } = useEditor();
  const [isActiveObjectSelected, setIsActiveObjectSelected] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  useEffect(() => {
    if (fabricRef?.current?.getActiveObject()) {
      setIsActiveObjectSelected(true);
    }
  }, [fabricRef]);
  const [addedFields, setAddedFields] = useState(
    !canvasAddedFields
      ? []
      : !isTemplateOpened
      ? canvasAddedFields
      : canvasAddedFields.map((preFilledObject) => {
          let fabricObject = null;
          if (
            preFilledObject.type === "text" ||
            preFilledObject.type === "date" ||
            preFilledObject.type === "time"
          ) {
            fabricObject = {
              ...preFilledObject,
              element: new fabric.Text(
                preFilledObject.element.text,
                preFilledObject.element
              ),
            };
            // console.log("fabric bject at type text", fabricObject);
          } else if (preFilledObject.type === "multiline-text") {
            // Create a Fabric.js Text object with the provided text
            fabricObject = {
              ...preFilledObject,
              element: new fabric.Textbox(
                preFilledObject.element.text,
                preFilledObject.element
              ),
              // element: new fabric.Rect(
              //   preFilledObject.element,
              //   preFilledObject.element
              // ),
            };
          } else if (
            preFilledObject.type === "checkbox" ||
            preFilledObject.type === "image"
          ) {
            // Create a Fabric.js Image object for checkboxes and images
            // console.log("prefilled object>>>>", preFilledObject);
            fabricObject = {
              ...preFilledObject,
              element: new fabric.Image(
                preFilledObject?.element?.src
                  ? preFilledObject?.element?.src
                  : "",
                preFilledObject?.element
              ),
            };
            // console.log("fabric bject at type image or check", fabricObject);
          }
          return fabricObject;
        })
  );
  const [elemntsFromAddedFields, setElementsFromAddedFields] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    fabricRef?.current?.on("mouse:down", canvasMouseDownHandler);

    return () => {
      fabricRef?.current?.off("mouse:down", canvasMouseDownHandler);
    };
  }, [
    fabricRef.current,
    dataFieldsOptions,
    selectedField,
    dataShown,
    selectedOption,
    isPreview,
  ]);

  useEffect(() => {
    fabricRef.current = new fabric.Canvas(canvasRef.current);
    // Disable selection on canvas
    fabricRef.current.selection = false;

    // Load PDF from URL
    loadPDFFromURL(generalInformation?.templateBackgroundFile);

    if (preFilledCanvasObjects && preFilledCanvasObjects.length > 0) {
      let superobjs = preFilledCanvasObjects;
      if (isTemplateOpened) {
        superobjs = addedFields?.map((f) => f?.element);
      }
      //i set timeout of 3 sec so it has time to complete previous loading and iteration and to to avoid DrawImage error
      setTimeout(() => {
        superobjs.forEach((fabricObject) => {
          if (fabricObject) {
            fabricObject.selectable = false;
            fabricObject.evented = false;
            // Check if element is not undefined or null
            fabricRef.current.add(fabricObject);
          }
        });
        fabricRef.current.renderAll();
      }, 3000);
    }

    // Clean up
    return () => {
      fabricRef.current.dispose();
    };
  }, [preFilledCanvasObjects]);

  useEffect(() => {
    if (pdfLoaded) {
      let tempAddedFields = addedFields;

      tempAddedFields.map(
        (
          { element, field, type, checkedImage, uncheckedImage, format },
          index
        ) => {
          if (
            // field == "date" ||
            field == "fix_date_input" ||
            field == "fix_text_input" ||
            field == "fix_time_input"
          ) {
            return;
          }
          if (type === "text" || type == "multiline-text") {
            if (element.id === "date_system_current_date_generated") return;
            // if (element?.text == "fix_date_input") return;
            if (isUseCaseOne) {
              if (formValues[field]) element.text = formValues[field];
            } else {
              if (csvData?.length > 0 && field) {
                if (csvData[dataShown - 1][field])
                  element.text = csvData[dataShown - 1][field]?.toString();
                else element.text = "";
              }
            }
          }
          if (type === "date") {
            if (element.id === "date_system_current_date_generated") return;
            // if (element?.text == "fix_date_input") return;
            if (isUseCaseOne) {
              if (formValues[field])
                element.text = formatDateForInput(formValues[field], format);
            } else {
            }
          } else if (type === "time") {
            if (element.id === "date_system_current_date_generated") return;
            // if (element?.text == "fix_date_input") return;
            if (isUseCaseOne) {
              if (formValues[field])
                element.text = formatTimeForInput(formValues[field], format);
            } else {
            }
          } else if (type === "checkbox") {
            // let isCheck =
            //   csvData[dataShown - 1][field] === "TRUE" ? true : false;
            // let isCheck = csvData[dataShown - 1][field] === "TRUE" ? true : false;

            let isCheck = false;
            let value;
            if (isUseCaseOne) {
              value = formValues[field];
            } else value = csvData[dataShown - 1][field];

            if (isUseCaseOne) {
              value ? (isCheck = true) : (isCheck = false);
            } else {
              if (
                !value ||
                value?.toLowerCase() == "false" ||
                value?.toLowerCase() == "no"
              ) {
                isCheck = false;
              } else if (
                value?.toLowerCase() == "true" ||
                value?.toLowerCase() == "yes" ||
                value == true
              ) {
                isCheck = true;
              }
            }

            element.setSrc(`${isCheck ? checkedImage : uncheckedImage}`, () => {
              fabricRef.current.renderAll();
            });
          } else if (type === "image") {
            let imgSrc;
            if (!isUseCaseOne && csvData?.length > 0) {
              if (csvData[dataShown - 1][field])
                imgSrc = csvData[dataShown - 1][field];
              else imgSrc = element?.src;
            }

            if (isUseCaseOne && formValues[field]) {
              imgSrc = formValues[field];
            }
            if (field == "fix_image_input") {
              imgSrc = element.src;
            }
            console.log("ing src>>>>>>>>>>>", imgSrc);

            let desiredHeight = element.height * element.scaleY;
            let desiredWidth = element.width * element.scaleX;

            if (!imgSrc) {
              imgSrc = `https://images.placeholders.dev/?width=${
                desiredWidth ? parseInt(desiredWidth) : 400
              }&height=${
                desiredHeight ? parseInt(desiredHeight) : 400
              }&text=Docspawn%20Image&fontSize=50`;
            }
            element.setSrc(imgSrc, () => {
              try {
                if (desiredHeight && desiredWidth) {
                  element.scaleToWidth(desiredWidth);
                  element.scaleToHeight(desiredHeight);
                  fabricRef.current.renderAll();
                }
              } catch (err) {
                // console.log("error", err);
              }
            });
          }
        }
      );

      setAddedFields(tempAddedFields);
      fabricRef.current.renderAll();
    }
  }, [isPreview, dataShown, pdfLoaded]);

  const canvasMouseDownHandler = (e) => {
    //to allow add only one object after selecting new option
    setSelectedOption("");
    if (e.target) return;
    if (selectedField && selectedOption === "dataField") {
      let styles = {
        fontFamily: dataFieldsOptions.fontFamily,
        textAlign: dataFieldsOptions.alignment,
        fill: dataFieldsOptions.fontColor,
        fontSize: parseInt(dataFieldsOptions.fontSize),
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined,
      };
      let text = isPreview
        ? csvData[dataShown - 1][selectedField]
        : selectedField;

      let textEle = new fabric.Text(text, {
        left: e.absolutePointer.x,
        top: e.absolutePointer.y,
        ...styles,
        id: selectedField,
        hasControls: false,
        // hasBorders: false
      });
      textEle.id = selectedField;

      let tempAddedFields = addedFields;
      addedFields.push({
        element: textEle,
        field: selectedField,
        type: "text",
      });
      setAddedFields(tempAddedFields);

      fabricRef.current.add(textEle);

      fabricRef.current.renderAll();
    } else if (selectedField && selectedOption === "checkbox") {
      // I have to add some logic here if use wanna drag checkbox, we can cjheck value in csv field is true r false , if not then we can show error and do not show user

      let isCheck = isPreview
        ? csvData[dataShown - 1][selectedField] === "TRUE"
          ? true
          : false
        : false;
      console.log(isCheck);

      fabric.Image.fromURL(
        `/${isCheck ? "checkbox" : "unchecked"}.png`,
        function (myImg) {
          console.log(myImg);
          //i create an extra var for to change some image properties
          myImg.set({
            left: e.absolutePointer.x,
            top: e.absolutePointer.y,
            scaleX: 50 / myImg.width,
            scaleY: 50 / myImg.height,
            id: selectedField,
          });
          myImg.id = selectedField;
          let tempAddedFields = addedFields;
          addedFields.push({
            element: myImg,
            field: selectedField,
            type: "checkbox",
          });
          setAddedFields(tempAddedFields);
          fabricRef.current.add(myImg);
          fabricRef.current.renderAll();
        }
      );
    } else if (selectedField && selectedOption === "image") {
      let imgSrc = isPreview
        ? csvData[dataShown - 1][selectedField]
        : "https://placehold.co/400x400/png";
      fabric.Image.fromURL(imgSrc, function (myImg) {
        myImg.set({
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          scaleX: 400 / myImg.width,
          scaleY: 400 / myImg.height,
          id: selectedField,
        });
        myImg.id = selectedField;

        let tempAddedFields = addedFields;
        addedFields.push({
          element: myImg,
          field: selectedField,
          type: "image",
        });
        setAddedFields(tempAddedFields);
        fabricRef.current.add(myImg);
        fabricRef.current.renderAll();
      });
    } else if (selectedField && selectedOption === "date") {
      const enteredDate = prompt("Enter a date (DD-MM-YYYY):");

      if (enteredDate) {
        let textEle = new fabric.Text(enteredDate, {
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          hasControls: false,
        });
        textEle.id = "date_system_current_date_generated";
        let tempAddedFields = addedFields;
        tempAddedFields.push({ element: textEle, field: "date", type: "text" });
        setAddedFields(tempAddedFields);
        fabricRef.current.add(textEle);
        fabricRef.current.renderAll();
      }
    }
  };
  useEffect(() => {
    const activeObject = fabricRef?.current?.getActiveObject();

    // Check if there's an active object
    if (activeObject) {
      const activeAddedField = addedFields.find((field) => {
        return field.element === activeObject;
      });

      // Assuming dataFieldsOptions contains the styles you want to apply
      const updatedStyles = {
        // Merge existing styles with styles from dataFieldsOptions
        ...activeAddedField.element.styles,
        fontFamily: dataFieldsOptions.fontFamily,
        textAlign: dataFieldsOptions.alignment,
        fill: dataFieldsOptions.fontColor,
        fontSize: parseInt(dataFieldsOptions.fontSize),
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined,
      };

      // Update the styles of the Fabric.js element
      activeAddedField.element.set(updatedStyles);

      // Render the canvas to apply the changes
      fabricRef.current.renderAll();
    }
  }, [dataFieldsOptions]);

  const loadPDFFromURL = async (pdfURL) => {
    try {
      const response = await fetch(pdfURL);
      const pdfData = await response.arrayBuffer();

      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";

      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 2 });

      // Get the parent container's width
      const parentWidth = document?.getElementById("canvascover")?.offsetWidth;

      const scale = parentWidth / viewport.width;

      const canvasWidth = viewport.width * scale;
      const canvasHeight = viewport.height * scale;

      // Set the canvas dimensions
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;

      fabricRef.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: page.getViewport({ scale: 2 }),
      };

      await page.render(renderContext).promise;
      var bg = canvas.toDataURL("image/png");
      setImageURL(bg);

      fabric.Image.fromURL(bg, function (img) {
        if (img) {
          fabricRef.current.setBackgroundImage(
            img,
            () => {
              fabricRef.current.renderAll();
            },
            {
              // Set the background image to cover the canvas without cropping
              scaleX: canvasWidth / img.width,
              scaleY: canvasHeight / img.height,
            }
          );
        }
      });
      setPdfLoaded(true);
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  return (
    <Box sx={{ background: "#fff", width: "1001px" }}>
      {/*****  we have set canvas cover to fix height and width(equal to reponsive 100% browser)- we cant make it responsive, because to make it synchronize on different screen sizes and to make it work it backend, we have to have fix canvas size */}
      <Box
        id="canvascover"
        sx={{
          mt: { xs: 1, sm: 3 },
          minHeight: "200px",
          // width: "300px",
          // height: "1290px",

          // height: pdfPageSize?.height,
          // width: pdfPageSize?.width,
          height: "max-content",
          width: "100%",
        }}
      >
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{
            width: "100%",
            //  height: "100%"

            // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        />
      </Box>
    </Box>
  );
};

export default PDFRenderer;

/***************format date for value */

const formatDateForInput = (dateValue, format) => {
  if (!dateValue) return "";

  const currentDate = new Date(dateValue);
  let formatted = "";

  switch (format) {
    case "MM/DD/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
      break;

    case "DD/MM/YY":
      formatted = currentDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
      break;

    case "YY/MM/DD":
      formatted = currentDate.toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      break;

    case "Month D, Yr":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "M/D/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
      });
      break;

    case "D/M/YY":
      formatted = currentDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      break;

    case "YY/M/D":
      formatted = currentDate.toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      });
      break;

    case " bM/bD/YY":
      formatted = currentDate
        .toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
        })
        .replace(/\//g, " ");
      break;

    case " bD/bM/YY":
      formatted = currentDate
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "2-digit",
        })
        .replace(/\//g, " ");
      break;

    case "YY/ bM/bD":
      formatted = currentDate
        .toLocaleDateString("en-CA", {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, " ");
      break;

    case "MMDDYY":
      formatted = currentDate
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "DDMMYY":
      formatted = currentDate
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "YYMMDD":
      formatted = currentDate
        .toLocaleDateString("en-CA", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "");
      break;

    case "MonDDYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "2-digit",
      });
      break;

    case "DDMonYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
      break;

    case "YYMonDD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "2-digit",
        month: "short",
        day: "numeric",
      });
      break;

    case "day/YY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
      });
      break;

    case "YY/day":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        day: "numeric",
      });
      break;

    case "D Month, Yr":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      break;

    case "Yr, Month D":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      break;

    case "Mon-DD-YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "DD-Mon-YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      break;

    case "YYYYY-Mon-DD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;

    case "Mon DD, YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      break;

    case "DD Mon, YYYY":
      formatted = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      break;

    case "YYYY, Mon DD":
      formatted = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;

    default:
      formatted = currentDate.toLocaleDateString(); // Use the default format if no match
      break;
  }

  return formatted;
};

// const getMonthName = (monthIndex) => {
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   return monthNames[monthIndex];
// };

// const getMonthAbbreviation = (monthIndex) => {
//   const monthAbbreviations = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   return monthAbbreviations[monthIndex];
// };

// const getDayOfYear = (date) => {
//   const start = new Date(date.getFullYear(), 0, 0);
//   const diff = date - start;
//   const oneDay = 1000 * 60 * 60 * 24;
//   const day = Math.floor(diff / oneDay);
//   return day.toString().padStart(3, "0");
// };

/************************************* */

/***************format time for value */
const formatTimeForInput = (timeValue, format) => {
  if (!timeValue) return "";

  const date = new Date(`1970-01-01T${timeValue}`);
  let formattedTime = "";

  switch (format) {
    // case "HH:MM:SS":
    //   formattedTime = date.toISOString().substr(11, 8);
    //   break;
    // case "HH:MM:SS XM":
    //   formattedTime = date.toLocaleTimeString([], { hour12: true });
    //   break;
    // case "HH:MM":
    //   formattedTime = date.toISOString().substr(11, 5);
    //   break;
    // case "HH:MM XM":
    //   formattedTime = date
    //     .toLocaleTimeString([], { hour12: true })
    //     .substr(0, 5);
    //   break;
    case "HH:MM:SS":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).format(date);
      break;
    case "HH:MM:SS XM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }).format(date);
      break;
    case "HH:MM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(date);
      break;
    case "HH:MM XM":
      formattedTime = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date);
      break;
    default:
      // Handle default case
      break;
  }

  return formattedTime;
};

/************************************* */
