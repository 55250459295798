import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";

const SearchComponent = (props) => {
  const theme = useTheme();
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "2px",
        overflow: "hidden",
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.para.main}`,
        "&:hover": {
          boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
        },
        padding: "0 15px",
        display: { xs: "none", sm: "flex" },
      }}
    >
      <SearchIcon
        sx={{
          color: theme.palette.para.main,
          p: "7",
          flexGrow: 0,
        }}
      />
      <InputBase
        placeholder={props?.t("Search item...")}
        value={query}
        onChange={handleInputChange}
        sx={{
          flexGrow: 1,
          "& input": {
            padding: "7px 10px",
            color: theme.palette.heading.main,
          },
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </Box>
  );
};

export default withTranslation()(SearchComponent);
