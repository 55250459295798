export const setUser = (user) => (dispatch) => {
  dispatch({
    type: "SET_USER",
    payload: user,
  });
  localStorage.setItem("docspawn_user", JSON.stringify(user));
};
export const setToken = (token) => (dispatch) => {
  dispatch({
    type: "SET_TOKEN",
    payload: token,
  });
  localStorage.setItem("docspawn_token", JSON.stringify(token));
};
