import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/system";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import Editor from "../../SlateEmailEditor/Editor";
import "katex/dist/katex.min.css";
import AttachmentHandler from "./AttachmentHandler";

const EmailEditor = (props) => {
  const { t } = useTranslation();
  let {
    isUseCaseOne,
    emailTemplateProps,
    setEmailTemplateProps,
    allFormFields,
    csvKeys,
    emailEditorForOption,
    setOpenEmailEditor,
  } = props;
  useEffect(() => {}, [csvKeys, allFormFields]);
  const theme = useTheme();

  const [htmlString, setHtmlString] = useState("");
  const [subjectOfEmail, setSubjectofEmail] = useState("");
  const [stateOfEmailBodyEditor, setStateOfEmailBodyEditor] = useState("");
  const [emailAttachments, setEmailAttachments] = useState([]);

  useEffect(() => {
    if (emailEditorForOption == 1) {
      if (emailTemplateProps?.adminEmailOptions?.subjectOfEmail) {
        setSubjectofEmail(
          emailTemplateProps?.adminEmailOptions?.subjectOfEmail
        );
      } else {
        setSubjectofEmail("Generated documents from DocSpawn");
      }
      if (emailTemplateProps?.adminEmailOptions?.stateOfEmailBodyEditor) {
        setStateOfEmailBodyEditor(
          emailTemplateProps?.adminEmailOptions?.stateOfEmailBodyEditor
        );
      }
      if (emailTemplateProps?.adminEmailOptions?.emailAttachments) {
        setEmailAttachments(
          emailTemplateProps?.adminEmailOptions?.emailAttachments
        );
      }
    } else if (emailEditorForOption == 2) {
      if (emailTemplateProps?.datasetEmailOptions?.subjectOfEmail) {
        setSubjectofEmail(
          emailTemplateProps?.datasetEmailOptions?.subjectOfEmail
        );
      } else {
        setSubjectofEmail("Generated documents from DocSpawn");
      }
      if (emailTemplateProps?.datasetEmailOptions?.stateOfEmailBodyEditor) {
        setStateOfEmailBodyEditor(
          emailTemplateProps?.datasetEmailOptions?.stateOfEmailBodyEditor
        );
      }
      if (emailTemplateProps?.datasetEmailOptions?.emailAttachments) {
        setEmailAttachments(
          emailTemplateProps?.datasetEmailOptions?.emailAttachments
        );
      }
    } else if (emailEditorForOption == 3) {
      if (emailTemplateProps?.manualEmailsOptions?.subjectOfEmail) {
        setSubjectofEmail(
          emailTemplateProps?.manualEmailsOptions?.subjectOfEmail
        );
      } else {
        setSubjectofEmail("Generated documents from DocSpawn");
      }
      if (emailTemplateProps?.manualEmailsOptions?.stateOfEmailBodyEditor) {
        setStateOfEmailBodyEditor(
          emailTemplateProps?.manualEmailsOptions?.stateOfEmailBodyEditor
        );
      }
      if (emailTemplateProps?.manualEmailsOptions?.emailAttachments) {
        setEmailAttachments(
          emailTemplateProps?.manualEmailsOptions?.emailAttachments
        );
      }
    }
  }, [emailTemplateProps]);

  const saveAndCloseEditor = () => {
    let obj;
    if (emailEditorForOption == 1) {
      obj = {
        ...emailTemplateProps,
        adminEmailOptions: {
          subjectOfEmail,
          htmlString,
          stateOfEmailBodyEditor,
          emailAttachments,
        },
      };
    } else if (emailEditorForOption == 2) {
      obj = {
        ...emailTemplateProps,
        datasetEmailOptions: {
          subjectOfEmail,
          htmlString,
          stateOfEmailBodyEditor,
          emailAttachments,
        },
      };
    } else if (emailEditorForOption == 3) {
      obj = {
        ...emailTemplateProps,
        manualEmailsOptions: {
          subjectOfEmail,
          htmlString,
          stateOfEmailBodyEditor,
          emailAttachments,
        },
      };
    }
    setEmailTemplateProps(obj);
  };
  // useEffect(() => {
  //   console.log("emial template props", emailTemplateProps);
  // }, [emailTemplateProps]);

  return (
    <Box sx={{ py: 0, justifyContent: "space-between" }}>
      <Grid
        container
        sx={{
          width: "100%",
          my: 2,
          background: "whitesmoke",
          minHeight: "50px",
        }}
      >
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              py: 4,
              px: 1,
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h6">
                  {props?.t("Edit the template")}{" "}
                  {emailEditorForOption == 1
                    ? props?.t("for admin")
                    : emailEditorForOption == 2 &&
                      props?.t("for dataset users")}
                </Typography>
                {emailEditorForOption == 1 ? (
                  <Typography
                    variant="label"
                    sx={{ color: theme.palette.para.main }}
                  >
                    {props?.t("Send via email to DocSpawn User")}
                  </Typography>
                ) : (
                  emailEditorForOption == 2 && (
                    <Typography
                      variant="label"
                      sx={{ color: theme.palette.para.main }}
                    >
                      {props?.t("Send via email to dataset email address")}
                    </Typography>
                  )
                )}
              </Box>
              <Box sx={{ p: 2 }}>
                <Button
                  disabled={subjectOfEmail?.length < 1}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setOpenEmailEditor(false);
                    saveAndCloseEditor();
                  }}
                >
                  Save & close
                </Button>
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.heading.main,
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >
                  {props?.t("Subject")}
                </Typography>
              </Box>
              <TextField
                size="small"
                value={subjectOfEmail}
                sx={{
                  fontSize: "0.9rem",
                  width: "100%",
                  my: 1,
                  background: "#fff",
                }}
                onChange={(e) => setSubjectofEmail(e.target.value)}
              />
              {subjectOfEmail?.length < 1 && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Box>
            <Box className="email_editor_wrapper" sx={{ border: "none" }}>
              <Typography
                sx={{
                  color: theme.palette.heading.main,
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                Email Body
              </Typography>
              <Editor
                setHtmlString={setHtmlString}
                setStateOfEmailBodyEditor={setStateOfEmailBodyEditor}
                stateOfEmailBodyEditor={stateOfEmailBodyEditor}
                emailTemplateOptions={{
                  csvKeys,
                  allFormFields,
                  emailEditorForOption,
                  emailTemplateProps,
                  isUseCaseOne,
                }}
              />
            </Box>
            <AttachmentHandler
              emailAttachments={emailAttachments}
              setEmailAttachments={setEmailAttachments}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(EmailEditor);
