import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FormikConsumer } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  email_address: Yup.string().required("Required"),
  total_credits: Yup.string().required("Required"),
});

const AddAccountForm = ({ editOptions, setShowAddForm }) => {
  const theme = useTheme();

  const [initialValues, setInitialValues] = useState(
    editOptions
      ? {
          email_address: editOptions?.email_address,
          total_credits: editOptions?.total_credits,
        }
      : {
          email_address: "",
          total_credits: "",
        }
  );

  const onSubmit = (values, { resetForm }) => {
    let objToAdd = {
      email_address: values?.email_address?.trim(),
      total_credits: values?.total_credits,
    };

    if (editOptions?.id) {
      api
        .put("/updateUserByAdmin/" + editOptions?.id, objToAdd)
        .then((res) => {
          toast.success("user updated successfully!");
          setShowAddForm(false);
          resetForm();
        })
        .catch((err) => {
          toast.error("error in adding contact");
        });
    } else {
      api
        .post("/admin/invite", objToAdd)
        .then((res) => {
          toast.success("User invited successfully");
          resetForm();
          setTimeout(() => {
            setShowAddForm(false);
          }, 2000);
        })
        .catch((err) => {
          toast.error("unable to invite");
        });
    }
  };
  const cancelForm = () => {
    setShowAddForm(false);
  };
  return (
    <Box sx={{ padding: "10px 0px" }}>
      <ToastContainer />

      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "10px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          Fill up the form with user data.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={editOptions?.id ? 12 : 12}>
                  <Field
                    name="email_address"
                    as={TextField}
                    label="Email"
                    fullWidth
                    error={
                      touched.email_address && Boolean(errors.email_address)
                    }
                    helperText={touched.email_address && errors.email_address}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="total_credits"
                    as={TextField}
                    label="Total Credits"
                    type="number"
                    fullWidth
                    error={
                      touched.total_credits && Boolean(errors.total_credits)
                    }
                    helperText={touched.total_credits && errors.total_credits}
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.white.main,
                    }}
                    type="submit"
                    size="small"
                  >
                    {editOptions?.id ? "Update" : "Submit"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cancelForm()}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.white.main,
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddAccountForm;
