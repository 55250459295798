import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  Button,
  MenuItem,
  Select,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { ToastContainer, collapseToast, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../axios/api";
import { useEditor } from "../../../hooks/useEdtior";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Modal from "@mui/material/Modal";
import EmailEditor from "./EmailEditor";
import { withTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  p: 4,
};

const index = (props) => {
  const { t } = useTranslation();
  let {
    selectedDataforTemplateEditor,
    generalInformation,
    setTemplateCreationStep,
    CSVFileJSON,
    setIsComebackToEdit,
    canvasAddedFields,
    isTemplateOpenedIsDraft,
    isTemplateOpened,
    templateOpenendFabricCanvas,
    canvasObjectsForOutput,
    preFilledCanvasObjects,
    templateToOpen,
    completeFormStructure,
    isUseCaseOne,
    emailTemplateProps,
    setEmailTemplateProps,
    currentTemplateId,
    forceEntriesCheckForForm,
    areThirdPartiesAllowed,
  } = props;
  const user = useSelector((state) => state.user);

  const { fabricRef, objects, fabricCanvas } = useEditor();

  const theme = useTheme();
  const dispatch = useDispatch();
  const [fileOutput, setFileOutput] = useState(1);
  const [filePreview, setFilePreview] = useState(1);
  const [recepients, setRecepients] = useState(
    templateToOpen?.recipients ? templateToOpen?.recipients : 0
  );

  const [forceEntriesCheck, setForceEntriesCheck] = useState(
    forceEntriesCheckForForm
  );
  const [allowThirdPrtiesCheck, setAllowThirdPartiesCheck] = useState(
    areThirdPartiesAllowed
  );
  useEffect(() => {
    setForceEntriesCheck(forceEntriesCheckForForm);
  }, [forceEntriesCheckForForm]);
  useEffect(() => {
    setAllowThirdPartiesCheck(areThirdPartiesAllowed);
  }, [areThirdPartiesAllowed]);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [recipientEmailAddressField, setRecipientEmailAddressField] =
    useState("");

  const [selectedRecipientsOptions, setSelectedRecipientsOptions] = useState(
    []
  );

  const [emailEditorForOption, setEmailEditorForOption] = useState(0); //1, 2 or 3

  useEffect(() => {
    if (!emailTemplateProps?.datasetEmailOptions) {
      setEmailTemplateProps({
        ...emailTemplateProps,
        datasetEmailOptions: {
          stateOfEmailBodyEditor: [
            {
              type: "paragraph",
              children: [{ text: "Hi" }],
            },
            {
              type: "paragraph",
              children: [
                { text: "Download the generated Documents from here" },
              ],
            },
            {
              type: "button",
              id: "url-for-downloading-docspawn-email-docs",
              children: [{ text: "Click to Download" }],
            },
          ],
          subjectOfEmail: "Generated documents from DocSpawn.",
          emailAttachments: [],
          htmlString:
            '<div>Hi,</div><div>Download the generated documents from here</div><div><br></div><div style="background:#b8379b;padding:10px;color:#ffffff;font-size:1.2rem;width:max-content;margin:10px 0"><a href="url-for-downloading-files-from email" style="text-decoration:none;color:white">Click to Download</a></div>',
        },
      });
    }
    if (!emailTemplateProps?.adminEmailOptions) {
      setEmailTemplateProps({
        ...emailTemplateProps,
        adminEmailOptions: {
          stateOfEmailBodyEditor: [
            {
              type: "paragraph",
              children: [{ text: "Hi" }],
            },
            {
              type: "paragraph",
              children: [
                { text: "Download the generated Documents from here" },
              ],
            },
            {
              type: "button",
              id: "url-for-downloading-docspawn-email-docs",
              children: [{ text: "Click to Download" }],
            },
          ],
          subjectOfEmail: "Generated documents from DocSpawn.",
          emailAttachments: [],
          htmlString:
            '<div>Hi,</div><div>Download the generated documents from here</div><div><br></div><div style="background:#b8379b;padding:10px;color:#ffffff;font-size:1.2rem;width:max-content;margin:10px 0"><a href="url-for-downloading-files-from email" style="text-decoration:none;color:white">Click to Download</a></div>',
        },
      });
    }
    if (!emailTemplateProps?.manualEmailsOptions) {
      setEmailTemplateProps({
        ...emailTemplateProps,
        manualEmailsOptions: {
          stateOfEmailBodyEditor: [
            {
              type: "paragraph",
              children: [{ text: "Hi" }],
            },
            {
              type: "paragraph",
              children: [
                { text: "Download the generated Documents from here" },
              ],
            },
            {
              type: "button",
              id: "url-for-downloading-docspawn-email-docs",
              children: [{ text: "Click to Download" }],
            },
          ],
          subjectOfEmail: "Generated documents from DocSpawn.",
          emailAttachments: [],
          htmlString:
            '<div>Hi,</div><div>Download the generated documents from here</div><div><br></div><div style="background:#b8379b;padding:10px;color:#ffffff;font-size:1.2rem;width:max-content;margin:10px 0"><a href="url-for-downloading-files-from email" style="text-decoration:none;color:white">Click to Download</a></div>',
        },
      });
    }
  }, [selectedRecipientsOptions]);
  const [openEmailEditor, setOpenEmailEditor] = useState(false);

  /** for multiple manual email options */
  const [multipleEmailInput, setMultipleEmailInput] = useState("");
  const [multipleEmailArray, setMultipleEmailArray] = useState([]);
  const [multipleEmailErrorMessages, setMultipleEmailErrorMessages] = useState(
    []
  );

  const handleMultipleEmailInputChange = (event) => {
    setMultipleEmailInput(event.target.value);
  };

  const handleAddMultipleEmails = () => {
    const emails = multipleEmailInput.split(",").map((email) => email.trim());

    // Perform basic email validation
    const invalidEmails = emails.filter(
      (email) => !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(email)
    );

    if (invalidEmails.length > 0) {
      setMultipleEmailErrorMessages(
        invalidEmails.map((email) => `${email} is not a valid email.`)
      );
    } else {
      setMultipleEmailErrorMessages([]);
      setMultipleEmailArray((prevEmails) => [...prevEmails, ...emails]);
      setMultipleEmailInput("");
    }
  };

  const cancelTemplate = () => {
    toast.error(t("you have cancelled the template editing without saving it"));
    setTimeout(() => {
      dispatch({
        type: "SET_Directory",
        payload: "home",
      });
    }, 2000);
  };

  useEffect(() => {
    if (templateToOpen?.recipients) {
      setRecepients(templateToOpen?.recipients);
      setSelectedRecipientsOptions(
        templateToOpen?.recipients &&
          templateToOpen?.recipients?.map((t) => parseInt(t))
      );
      setMultipleEmailArray(templateToOpen?.multipleEmailsOfManualRecipeints);

      if (isUseCaseOne) {
        let filt = completeFormStructure?.all_form_fields?.filter((f) => {
          if (
            f?.fieldName?.toLowerCase() ==
            templateToOpen?.recipient_email_address_field?.toLowerCase()
          )
            return true;
          else return false;
        })[0];

        setRecipientEmailAddressField(
          filt?.fieldName && filt?.fieldType == "email" ? filt?.fieldName : ""
        );
      } else {
        let filt = selectedDataforTemplateEditor?.filter(
          (f) =>
            f?.toLowerCase() ==
            templateToOpen?.recipient_email_address_field?.toLowerCase()
        )[0];
        setRecipientEmailAddressField(filt ? filt : "");
      }
    }
  }, [templateToOpen]);

  // const uploadFileToBackend = async (file, isJSON) => {
  //   const formData = new FormData();
  //   isJSON
  //     ? formData.append(
  //         "files",
  //         new Blob([file], { type: "application/json" }),
  //         "data.json"
  //       )
  //     : formData.append("files", file);

  //   try {
  //     const response = await api.post("/files/upload/s3", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     return response.data.url; // Assuming the response contains the S3 URL
  //   } catch (error) {
  //     // console.error(error);
  //     return null;
  //   }
  // };
  // const saveTemplateAsDraft = async () => {
  //   setLoading(true);
  //   setLoadingType("Saving Template Draft...");

  //   const canvasS3Url = await uploadFileToBackend(fabricCanvas, true);

  //   if (
  //     canvasS3Url &&
  //     user?.email_address &&
  //     (generalInformation?.dataSetCSVFile || isUseCaseOne) &&
  //     generalInformation?.templateBackgroundFile
  //   ) {
  //     let obj = {
  //       template_name: generalInformation?.templateName,
  //       background_url: generalInformation?.templateBackgroundFile,
  //       dataset_url: generalInformation?.dataSetCSVFile,
  //       canvas_url: canvasS3Url,
  //       use_cases: generalInformation?.selectedUseCases,
  //       complete_form_structure: isUseCaseOne
  //         ? JSON.stringify(completeFormStructure)
  //         : null,
  //       recipient_email_address_field: selectedRecipientsOptions?.includes(2)
  //         ? recipientEmailAddressField
  //         : user?.email_address,
  //       multipleEmailsOfManualRecipeints: multipleEmailArray,
  //       file_output: fileOutput,
  //       file_preview: filePreview,
  //       email_template_props: JSON.stringify(emailTemplateProps),
  //       recipients: recepients,
  //       user_email: user?.email_address,
  //       documents_created: CSVFileJSON?.length > 0 ? CSVFileJSON?.length : 100,
  //       template_status: "draft",
  //       selected_columns_from_csv: JSON.stringify(
  //         selectedDataforTemplateEditor
  //       ),
  //       canvas_added_fields: canvasAddedFields,
  //       canvas_objects_for_output: objects,
  //       pre_filled_canvas_objects: preFilledCanvasObjects,
  //     };
  //     if (isTemplateOpened && isTemplateOpenedIsDraft) {
  //       api
  //         .put("/templates/" + templateToOpen?.id, obj)
  //         .then((res) => {
  //           toast.success(t("template saved successfully!"));
  //           setLoading(false);
  //           setLoadingType("");
  //           dispatch({
  //             type: "SET_Directory",
  //             payload: "home",
  //           });
  //         })
  //         .catch((err) => {
  //           // console.log("err", err);
  //           toast.error(t("unable to save template as draft!"));
  //           setLoading(false);
  //           setLoadingType("");
  //         });
  //     } else {
  //       api
  //         .post("/templates/add", obj)
  //         .then((res) => {
  //           toast.success(t("template saved successfully!"));
  //           setLoading(false);
  //           setLoadingType("");
  //           dispatch({
  //             type: "SET_Directory",
  //             payload: "home",
  //           });
  //         })
  //         .catch((err) => {
  //           // console.log("err", err);
  //           toast.error(t("unable to save template as draft!"));
  //           setLoading(false);
  //           setLoadingType("");
  //         });
  //     }
  //   } else {
  //     // console.error("unable to upload files");
  //     toast.error(t("unable to save template as draft!"));
  //     setLoading(false);
  //     setLoadingType("");
  //   }
  // };

  const saveTemplateAndGenerateDocuments = async () => {
    // setLoading(true);
    // setLoadingType("Saving Template...");
    // console.log("canvas added fields", canvasAddedFields);
    // return;

    let obj = {
      file_output: fileOutput,
      file_preview: filePreview,
      recipients: selectedRecipientsOptions,
      recipient_email_address_field: selectedRecipientsOptions?.includes(2)
        ? recipientEmailAddressField
        : user?.email_address,
      multipleEmailsOfManualRecipeints: multipleEmailArray,
      email_template_props: JSON.stringify(emailTemplateProps),
      documents_created: CSVFileJSON?.length > 0 ? CSVFileJSON?.length : 100,
      force_entries_check_for_form: forceEntriesCheck,
      are_third_parties_allowed: allowThirdPrtiesCheck,
      template_status: "finalized",
      saved_till_step: 4,
      // selected_columns_from_csv: JSON.stringify(
      //   selectedDataforTemplateEditor
      // ),
    };

    api
      .put("/templates/" + currentTemplateId, obj)
      .then((res) => {
        toast.success(t("template saved successfully!"));
        setLoading(false);
        setLoadingType("");
        setTimeout(() => {
          dispatch({
            type: "SET_Directory",
            payload: "home",
          });
        }, 3000);
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(t("unable to save template!"));
        setLoading(false);
        setLoadingType("");
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <ToastContainer />
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {loadingType}
          </Typography>
        </Box>
      </Modal>
      <Box sx={{ p: 2, background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
            width: "100%",
            mt: 4,
            mb: 3,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              fontColor: theme.palette.heading.main,
            }}
          >
            {props?.t("Delivery Options")}
          </Typography>
          {true ? (
            <Button
              variant="contained"
              onClick={() => {
                setTemplateCreationStep(3);
                setIsComebackToEdit(true);
              }}
            >
              {props?.t("Back to Editor")}
            </Button>
          ) : (
            <>
              {isTemplateOpenedIsDraft && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setTemplateCreationStep(3);
                    setIsComebackToEdit(true);
                  }}
                >
                  {props?.t("Back to Editor")}
                </Button>
              )}
            </>
          )}
        </Box>
        <Grid container sx={{ mb: 2 }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "30px" }}
          >
            <Typography
              variant="h6"
              sx={{
                my: 1,
                color: theme.palette.heading.main,
                fontSize: "1.1rem",
              }}
            >
              {props?.t("File Output")}:
            </Typography>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box sx={{ display: "flex", gap: "0px", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  disabled={isUseCaseOne ? true : false}
                  defaultChecked
                  onChange={(e) => {
                    e.target?.checked ? setFileOutput(1) : setFileOutput(0);
                  }}
                />
                <Typography variant="label">
                  {props?.t("Generate 1 file per document")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "0px", alignItems: "center" }}>
                <Checkbox size="small" disabled />
                <Typography variant="label" sx={{ color: "gray" }}>
                  {props?.t("Merge all files in 1 document")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", gap: "30px" }}>
            <Typography
              variant="h6"
              sx={{
                my: 1,
                color: theme.palette.heading.main,
                fontSize: "1.1rem",
              }}
            >
              {props?.t("File Format")}:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              {" "}
              <Box sx={{ display: "flex", gap: "0px", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  defaultChecked
                  onChange={(e) => {
                    e.target?.checked ? setFilePreview(1) : setFilePreview(0);
                  }}
                />
                <Typography variant="label">
                  {props?.t("PDF Document")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0px",
                  color: "gray",
                  alignItems: "center",
                }}
              >
                <Checkbox size="small" disabled />
                <Typography variant="label">
                  {props?.t("Word Document")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0px",
                  color: "gray",
                  alignItems: "center",
                }}
              >
                <Checkbox size="small" disabled />
                <Typography variant="label">PDF + XML</Typography>
              </Box>
            </Box>
          </Grid>
          {isUseCaseOne && (
            <Grid item xs={12} sx={{ display: "flex", gap: "30px" }}>
              <Typography
                variant="h6"
                sx={{
                  my: 1,
                  color: theme.palette.heading.main,
                  fontSize: "1.1rem",
                }}
              >
                {props?.t("Form Options")}:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "0px",
                  flexDirection: "column",
                  mt: 0.5,
                }}
              >
                {" "}
                <Box sx={{ display: "flex", gap: "0px", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    defaultChecked={forceEntriesCheck}
                    onChange={(e) => {
                      e.target?.checked
                        ? setForceEntriesCheck(true)
                        : setForceEntriesCheck(false);
                    }}
                  />
                  <Typography variant="label">
                    {props?.t("Force entries verification upon filling a form")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "0px", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    defaultChecked={allowThirdPrtiesCheck}
                    onChange={(e) => {
                      e.target?.checked
                        ? setAllowThirdPartiesCheck(true)
                        : setAllowThirdPartiesCheck(false);
                    }}
                  />
                  <Box sx={{}}>
                    <Typography
                      variant="label"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {props?.t(
                        "Allow third parties to generate documents through form filling"
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.para.main }}
                    >
                      {props?.t(
                        "Every time a new form is submitted, the corresponding credit amount will be deducted from your account"
                      )}
                      .
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ my: 1, fontSize: "1.1rem", textAlign: "left" }}
            >
              {props?.t("Recipients")}:
            </Typography>
            <Typography
              sx={{
                // background: theme.palette.accent.main,
                color: "#000", //theme.palette.white.main,
                color: theme.palette.primary.main,
                width: "max-content",
                p: 0.5,
                borderRadius: "4px",
                my: 1,
                mb: 2,
                fontSize: "1rem",
              }}
              variant="h6"
            >
              {props?.t(
                "The documents can be downloaded anytime from the 'Document Library"
              )}
              '
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  height: "40px",
                  // border: "1px solid red",
                }}
              >
                <Checkbox
                  size="small"
                  checked={selectedRecipientsOptions?.includes(1)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedRecipientsOptions([
                        ...selectedRecipientsOptions,
                        1,
                      ]);
                    } else {
                      setSelectedRecipientsOptions(
                        selectedRecipientsOptions?.filter((s) => s != 1)
                      );
                    }
                    // e.target?.checked ? setRecepients(1) : setRecepients(0);
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem", fontWeight: "400" }}
                >
                  {props?.t("Send via mail to current DocSpawn user")}
                </Typography>
                {selectedRecipientsOptions?.includes(1) && (
                  <Button
                    size="small"
                    sx={{ background: theme.palette.backgroundSecondary.main }}
                    // variant="outlined"
                    onClick={() => {
                      setEmailEditorForOption(1);
                      setOpenEmailEditor(true);
                    }}
                  >
                    {props?.t("Edit Email Template")}
                  </Button>
                )}
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    // height: "31px",
                    // border: "1px solid red",
                  }}
                >
                  <Checkbox
                    size="small"
                    // checked={recepients == 2 ? true : false}
                    checked={selectedRecipientsOptions?.includes(2)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRecipientsOptions([
                          ...selectedRecipientsOptions,
                          2,
                        ]);
                      } else {
                        setSelectedRecipientsOptions(
                          selectedRecipientsOptions?.filter((s) => s != 2)
                        );
                      }
                      // e.target?.checked ? setRecepients(1) : setRecepients(0);
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", fontWeight: "400" }}
                  >
                    {props?.t("Send to linked data")}
                  </Typography>
                  {selectedRecipientsOptions?.includes(2) && (
                    <Box sx={{ display: "flex", maxHeight: "40px" }}>
                      {" "}
                      {recipientEmailAddressField && (
                        <Button
                          size="small"
                          sx={{
                            background: theme.palette.backgroundSecondary.main,
                          }}
                          // variant="outlined"
                          onClick={() => {
                            setEmailEditorForOption(2);
                            setOpenEmailEditor(true);
                          }}
                        >
                          Edit Email Template
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
                <Box sx={{ pl: 4 }}>
                  {selectedRecipientsOptions?.includes(2) && (
                    <Box sx={{ display: "flex", maxHeight: "40px" }}>
                      {" "}
                      {isUseCaseOne ? (
                        <Box>
                          {completeFormStructure?.all_form_fields?.filter(
                            (f) => f?.fieldType == "email"
                          )?.length < 1 ? (
                            <Typography sx={{ color: "red" }}>
                              {props?.t(
                                "There is no form field of email type."
                              )}
                            </Typography>
                          ) : (
                            <Select
                              size="small"
                              sx={{ mx: 2, fontSize: "0.9rem", height: "40px" }}
                              value={recipientEmailAddressField}
                              onChange={(e) =>
                                setRecipientEmailAddressField(e.target.value)
                              }
                            >
                              {completeFormStructure?.all_form_fields
                                ?.filter((f) => f?.fieldType == "email")
                                ?.map((ff) => (
                                  <MenuItem
                                    key={ff?.fieldName}
                                    value={ff?.fieldName}
                                  >
                                    {ff?.fieldName}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          <Select
                            size="small"
                            value={recipientEmailAddressField}
                            onChange={(e) =>
                              setRecipientEmailAddressField(e.target.value)
                            }
                            sx={{ mx: 2, fontSize: "0.9rem" }}
                          >
                            {selectedDataforTemplateEditor &&
                              selectedDataforTemplateEditor?.map((ff) => (
                                <MenuItem key={ff} value={ff}>
                                  {ff}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedRecipientsOptions?.includes(3)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRecipientsOptions([
                          ...selectedRecipientsOptions,
                          3,
                        ]);
                      } else {
                        setSelectedRecipientsOptions(
                          selectedRecipientsOptions?.filter((s) => s != 3)
                        );
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1rem", fontWeight: "400" }}
                  >
                    {props?.t("Send to multiple")}
                  </Typography>
                  {selectedRecipientsOptions?.includes(3) &&
                    multipleEmailArray?.length > 0 && (
                      <Button
                        size="small"
                        sx={{
                          background: theme.palette.backgroundSecondary.main,
                        }}
                        onClick={() => {
                          setEmailEditorForOption(3);
                          setOpenEmailEditor(true);
                        }}
                      >
                        Edit Email Template
                      </Button>
                    )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", pl: 6 }}>
                {selectedRecipientsOptions?.includes(3) && (
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <TextField
                          label="Enter emails separated by commas"
                          variant="outlined"
                          sx={{ minWidth: "300px" }}
                          value={multipleEmailInput}
                          onChange={handleMultipleEmailInputChange}
                          helperText={multipleEmailErrorMessages.join(" ")}
                          error={multipleEmailErrorMessages.length > 0}
                          size="small" // Adjust size
                          margin="dense" // Reduce height
                        />
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleAddMultipleEmails}
                          sx={{
                            height: "max-content",
                            p: 0.8,
                            mt: 0.3,
                          }}
                        >
                          Add Emails
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          maxWidth: "400px",
                          gap: "6px",
                          flexWrap: "wrap",
                        }}
                      >
                        {multipleEmailArray?.map((email, index) => (
                          <Box
                            key={index}
                            sx={{
                              p: 0.4,
                              background: theme.palette.accent.main,
                              width: "max-content",
                              borderRadius: "5px",
                              px: 1.2,
                            }}
                          >
                            <Typography variant="p" sx={{ color: "#fff" }}>
                              {email}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setMultipleEmailArray(
                                  multipleEmailArray?.filter(
                                    (em) => em != email
                                  )
                                )
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {emailEditorForOption != 0 && (
          <Modal
            open={openEmailEditor}
            onClose={() => setOpenEmailEditor(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60vw",
                minWidth: "350px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                maxHeight: "95vh",
                overflowY: "auto",
                // p: 4,
                px: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <EmailEditor
                    emailTemplateProps={emailTemplateProps}
                    setEmailTemplateProps={setEmailTemplateProps}
                    isUseCaseOne={isUseCaseOne}
                    recepients={selectedRecipientsOptions}
                    templateToOpen={templateToOpen}
                    allFormFields={completeFormStructure?.all_form_fields}
                    csvKeys={selectedDataforTemplateEditor}
                    emailEditorForOption={emailEditorForOption}
                    setOpenEmailEditor={setOpenEmailEditor}
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )}

        <Box sx={{ mt: 4, pl: 6 }}>
          <Box>
            <Button
              size="small"
              variant="outlined"
              onClick={() => cancelTemplate()}
              sx={{ my: 2 }}
            >
              {props?.t("Cancel Template Editing")}
            </Button>
            {filePreview > 0 &&
              fileOutput > 0 &&
              ((!selectedRecipientsOptions?.includes(2) &&
                !recipientEmailAddressField) ||
                (selectedRecipientsOptions?.includes(2) &&
                  recipientEmailAddressField)) &&
              (!selectedRecipientsOptions?.includes(3) ||
                multipleEmailArray?.length > 0) && (
                <>
                  {" "}
                  {/* {(isTemplateOpenedIsDraft || !isTemplateOpened) && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => saveTemplateAsDraft()}
                      sx={{ m: 2 }}
                    >
                      {isTemplateOpened && isTemplateOpenedIsDraft
                        ? props?.t("Update Draft")
                        : props?.t("Save Template as Draft")}
                    </Button>
                  )} */}
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => saveTemplateAndGenerateDocuments()}
                    sx={{ m: 2 }}
                  >
                    {!isTemplateOpened
                      ? props?.t("Save Template")
                      : props?.t("Save Template")}
                  </Button>
                </>
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(index);
