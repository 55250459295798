import axios from "axios";

let baseURL =
  process.env.REACT_APP_NODE_ENV == "production"
    ? `${process.env.REACT_APP_BACKEND_API_URL}/v1`
    : "http://localhost:5000/api/v1";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL,
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["auth-token"] = token;
  } else {
    delete api.defaults.headers.common["auth-token"];
  }
};

// Set initial token if available
setAuthToken(JSON.parse(localStorage.getItem("docspawn_token")));

export { api, setAuthToken };
