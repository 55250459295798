import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Modal,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/NoteAdd";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useDispatch } from "react-redux";
import { api } from "../axios/api";
import PDFThumbnail from "../components/PDFThumbnail";
import UseTemplateTab from "../components/UseTemplateTab";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import FinalForm from "../components/TemplateCreation/FormEditor.js/FinalForm";

const DataLibrary = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [templates, setTemplates] = useState([]);
  const [templateView, setTemplateView] = useState("2");
  const [showAllFormsData, setShowAllFormsData] = useState(false);
  const [templateForAllFormData, setTemplateForAllFormData] = useState();
  const handleOpen = () => setShowAllFormsData(true);
  const handleClose = () => setShowAllFormsData(false);
  const [formCompleteDataset, setFormCompleteDataset] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [selectedUseCase, setSelectedUseCase] = useState("");

  // useEffect(() => {
  //   if (templateForAllFormData?.id) {
  //     api.get("/form-values/" + templateForAllFormData?.id).then((res) => {
  //       if (res?.data) {
  //         console.log("res of form values", res?.data);
  //         setFormCompleteDataset(res?.data);
  //       }
  //     });
  //   }
  // }, [templateForAllFormData]);
  useEffect(() => {
    if (selectedTemplateId) {
      api
        .get("/form-values/" + selectedTemplateId)
        .then((res) => {
          if (res?.data) {
            setFormCompleteDataset(res?.data);
          }
        })
        .catch((err) => {
          // console.log()
        });
    }
  }, [selectedTemplateId]);

  const createNewTemplate = () => {
    dispatch({
      type: "SET_TEMPLATE_TO_OPEN",
      payload: null,
    });
    dispatch({
      type: "SET_Directory",
      payload: "templateCreation",
    });
  };

  useEffect(() => {
    // api.get("/templates/draft").then((res) => {
    //   setDraftTemplates(res?.data);
    // });
    // .catch((err) => console.log(err));
    api
      .get("/templates/all")
      .then((res) => {
        setTemplates(
          res?.data?.filter((d) => d?.use_cases?.includes("useCase1"))
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  const [allKeysOfDataset, setAllKeysOfDataset] = useState([]);
  useEffect(() => {
    if (formCompleteDataset?.length > 0) {
      let allKeys = Object.keys(formCompleteDataset[0]?.form_values);

      setAllKeysOfDataset(allKeys);
    }
  }, [formCompleteDataset]);

  // const exportToCsv = () => {
  //   console.log("running export csv");
  //   if (formCompleteDataset.length > 0) {
  //     try {
  //       const keys = [
  //         "Filled On",
  //         ...Object.keys(formCompleteDataset[0].form_values),
  //       ];
  //       const csvContent =
  //         "data:text/csv;charset=utf-8," +
  //         keys.join(",") +
  //         "\n" +
  //         formCompleteDataset
  //           .map((data) => {
  //             return [
  //               new Date(data.used_on)?.toDateString(), // Add "Used On" field as the first entry
  //               ...keys.slice(1).map((key) => data.form_values[key] || "N/A"), // Map over other keys
  //             ].join(",");
  //           })
  //           .join("\n");
  //       const encodedUri = encodeURI(csvContent);
  //       console.log("encoded uri", encodedUri);
  //       const link = document.createElement("a");
  //       link.setAttribute("href", encodedUri);
  //       link.setAttribute("download", "data.csv");
  //       document.body.appendChild(link);
  //       link.click();
  //     } catch (error) {
  //       console.error("Error exporting to CSV:", error);
  //     }
  //   } else {
  //     console.warn("No data to export.");
  //   }
  // };

  const exportToXls = () => {
    if (formCompleteDataset.length > 0) {
      try {
        const keys = [
          "Filled On",
          ...Object.keys(formCompleteDataset[0].form_values),
        ];
        const data = [
          keys,
          ...formCompleteDataset.map((data) => {
            return [
              new Date(data.used_on)?.toLocaleDateString(), // Add "Used On" field as the first entry
              ...keys.slice(1).map((key) => data.form_values[key] || "N/A"), // Map over other keys
            ];
          }),
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "data.xlsx";
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.error("Error exporting to XLS:", error);
      }
    } else {
      // console.warn("No data to export.");
    }
  };

  // Convert string to array buffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  useEffect(() => {
    setSelectedTemplateId("");
    setShowAllFormsData(false);
  }, [selectedUseCase]);
  useEffect(() => {
    setShowAllFormsData(false);
  }, [selectedTemplateId]);

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    // console.log("templateForAllFormData", templateForAllFormData);
  }, [templateForAllFormData]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <ToastContainer />
      {showPreview && templateForAllFormData?.id && (
        <FinalForm
          setIsPreview={setShowPreview}
          isPreview={showPreview}
          formTitle={
            templateForAllFormData?.complete_form_structure?.form_title
          }
          formDescription={
            templateForAllFormData?.complete_form_structure?.form_description
          }
          allFormFields={
            templateForAllFormData?.complete_form_structure?.all_form_fields
          }
          canvasAddedFields={
            templateForAllFormData?.complete_form_structure?.canvas_added_fields
          }
          canvasObjects={
            templateForAllFormData?.complete_form_structure?.canvasAddedFields
          }
        />
      )}

      <Box>
        <Box
          sx={{
            padding: { xs: "20px 10px", sm: "20px 20px" },
            paddingTop: "20px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mt: 1,
              mb: 1,
              fontWeight: "600",
              fontSize: { xs: "1.1rem", sm: "2rem" },
            }}
          >
            {props?.t("Data library")}
          </Typography>

          <Box
            sx={{
              my: 3,
              mt: 5,
              // border: "2px solid red",
              display: "flex",
              gap: "20px",
              alignItems: "flex-end",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  color: theme.palette.heading.main,
                }}
              >
                {props?.t("Select use case")}
              </Typography>
              <Select
                size="small"
                value={selectedUseCase}
                onChange={(e) => setSelectedUseCase(e.target.value)}
                sx={{ width: "200px", fontSize: "1rem", mt: 1 }}
              >
                <MenuItem value="form-to-doc">
                  {props?.t("Form to Doc")}
                </MenuItem>
                <MenuItem disabled value="data-to-doc">
                  {props?.t("Data to Doc")}
                </MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  color: theme.palette.heading.main,
                }}
              >
                {props?.t("Select template")}
              </Typography>
              <Select
                size="small"
                value={selectedTemplateId}
                onChange={(e) => {
                  setSelectedTemplateId(e.target.value);
                  setTemplateForAllFormData(
                    templates?.filter((t) => t?.id == e.target.value)[0]
                  );
                }}
                sx={{ width: "200px", fontSize: "1rem", mt: 1 }}
              >
                {templates?.map((data) => (
                  <MenuItem value={data?.id}>
                    {data?.template_name}
                    {/* <UseTemplateTab
                      key={data?.id}
                      keyV={data?.id}
                      data={data}
                      templates={templates}
                      setTemplates={setTemplates}
                      fromFormValues={true}
                      setShowAllFormsData={setShowAllFormsData}
                      setTemplateForAllFormData={setTemplateForAllFormData}
                    /> */}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ ml: 4 }}>
              <Button
                disabled={!selectedTemplateId || !selectedUseCase}
                onClick={() => setShowPreview(true)}
                sx={{ textTransform: "inherit", fontSize: "1rem" }}
                variant="outlined"
              >
                {props?.t("Preview")}
              </Button>
            </Box>
            <Box>
              <Button
                disabled={!selectedTemplateId || !selectedUseCase}
                onClick={() => setShowAllFormsData(!showAllFormsData)}
                variant="contained"
                sx={{ textTransform: "inherit", fontSize: "1rem" }}
              >
                {showAllFormsData
                  ? props?.t("Hide data")
                  : props?.t("Show data")}
              </Button>
            </Box>
          </Box>
          {/* <Modal
        open={showAllFormsData}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
          {showAllFormsData && (
            <Box
              sx={{
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                minWidth: { xs: 350, sm: 500 },
                // width: "60vw",
                width: "100%",
                maxHeight: "90vh",
                overflowY: "auto",
                bgcolor: "background.paper",
                // boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {props?.t("All data related to form")}
                </Typography>
                {formCompleteDataset?.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{ textTransform: "inherit" }}
                    onClick={exportToXls}
                  >
                    {props?.t("Export data")}
                  </Button>
                )}
              </Box>

              {formCompleteDataset?.length < 1 ? (
                <Typography>
                  {props?.t("There is no data in the database")}
                </Typography>
              ) : (
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ mb: 4, color: theme.palette.para.main }}
                  >
                    {props?.t(
                      "Here you have data of everytime form is filled up"
                    )}
                  </Typography>
                  <TableContainer sx={{ mt: "2" }}>
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "whitesmoke",
                          }}
                        >
                          <TableCell sx={{ color: theme.palette.primary.main }}>
                            Filled on
                          </TableCell>
                          {allKeysOfDataset?.map((key) => (
                            <TableCell
                              sx={{ color: theme.palette.primary.main }}
                            >
                              {key}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formCompleteDataset?.map((data) => (
                          <TableRow>
                            <TableCell>
                              {new Date(data?.used_on)?.toLocaleDateString()}
                            </TableCell>
                            {allKeysOfDataset?.map((key) => (
                              <TableCell>
                                {data?.form_values[key]
                                  ? data?.form_values[key]
                                  : "N/A"}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          )}
          {/* </Modal> */}

          {/* <Box sx={{}}>
            <Grid container sx={{ mt: 0, gap: { xs: "10px", sm: "20px" } }}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  background: theme.palette.white.main,
                  borderRadius: "6px",
                  // py: 2,
                  px: 3,
                  //   padding: "30px 30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.heading.main }}
                  >
                    
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GridViewIcon
                      onClick={() => setTemplateView("1")}
                      sx={{
                        padding: "4px",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                        borderBottomLeftRadius: "4px",
                        background:
                          templateView != "1"
                            ? theme.palette.backgroundSecondary.main
                            : "lightgray",
                        color:
                          templateView != "1"
                            ? theme.palette.para.main
                            : theme.palette.heading.main,
                      }}
                    />

                    <AppsIcon
                      onClick={() => setTemplateView("2")}
                      sx={{
                        padding: "4px",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                        background:
                          templateView != "2"
                            ? theme.palette.backgroundSecondary.main
                            : "lightgray",
                        color:
                          templateView != "2"
                            ? theme.palette.para.main
                            : theme.palette.heading.main,
                      }}
                    />
                    <FormatListBulletedIcon
                      onClick={() => setTemplateView("3")}
                      sx={{
                        padding: "4px",
                        cursor: "pointer",
                        fontSize: "1.6rem",
                        borderTopRightRadius: "6px",
                        background:
                          templateView != "3"
                            ? theme.palette.backgroundSecondary.main
                            : "lightgray",
                        color:
                          templateView != "3"
                            ? theme.palette.para.main
                            : theme.palette.heading.main,
                      }}
                    />
                  </Box>
                </Box>
                {templates?.length < 1 && (
                  <Typography>No templates in archive.</Typography>
                )}
                <Grid
                  container
                  rowGap={3}
                  sx={{
                    mt: 4,
                    gap:
                      templateView == "2" ? { md: "15px", xs: "10px" } : "20px",
                  }}
                >
                  {templates
                    // ?.filter((d, i) => i < 1)
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((t, i) => (
                      <Grid
                        key={i}
                        item
                        xs={
                          templateView == "1"
                            ? "5"
                            : templateView == "2"
                            ? "3.5"
                            : "11"
                        }
                        sx={{
                          mb: 3,
                        }}
                      >
                        <UseTemplateTab
                          key={t?.id}
                          keyV={t?.id}
                          data={t}
                          templates={templates}
                          setTemplates={setTemplates}
                          fromFormValues={true}
                          setShowAllFormsData={setShowAllFormsData}
                          setTemplateForAllFormData={setTemplateForAllFormData}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(DataLibrary);
