import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  Modal,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import DataFieldToolbar from "./DataFieldToolbar";

import PDFTemplateEditor from "./PDFTemplateEditor";
import styled from "@emotion/styled";
import { api } from "../../../axios/api";
import { withTranslation } from "react-i18next";

import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEditor } from "../../../hooks/useEdtior";
import TemplateEditorTabs from "./TemplateEditorTabs";
import SpecificTabOptions from "./SpecificTabOptions";

const TemplateEditor = (props) => {
  let {
    selectedDataforTemplateEditor,
    generalInformation,
    setTemplateCreationStep,
    CSVFileJSON,
    preFilledCanvasObjects,
    setPreFilledCanvasObjects,
    setIsComebackToEdit,
    setCanvasAddedFields,
    canvasAddedFields,
    isTemplateOpened,
    isUseCaseOne,
    setAllFormFields,
    allFormFields,
    currentTemplateId,
  } = props;

  const dispatch = useDispatch();

  const [addedFieldsForParent, setAddedFieldsForParent] = useState();
  const [showDropdownListForm, setShowDropdownListForm] = useState(false);
  const [showDatafieldsOptions, setShowDatafieldsOptions] = useState(false);
  const [showDataFieldsKeysList, setShowDataFieldsKeysList] = useState(false);
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState("");
  const [dataFieldsOptions, setDataFieldsOptions] = useState({});
  const [isPreview, setIsPreview] = useState();
  const [totalData, setTotalData] = useState(100);
  const [dataShown, setDataShown] = useState(1);
  const [csvData, setCSVData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showFormFieldOptions, setShowFormFieldOptions] = useState(false);
  const [allListsOptions, setAllListsOptions] = useState([]);
  useEffect(() => {
    api
      .get("/lists")
      .then((res) => {
        if (res?.data?.length > 0) {
          setAllListsOptions(res?.data);
        }
      })
      .catch((err) => {});
  }, []);
  const [formFieldOptions, setFormFieldOptions] = useState({
    fieldName: "",
    fieldType: "text",
    fieldDescription: "",
    isMandatory: true,
  });

  const [formFieldAlreadyExists, setFormFieldAlreadyExists] = useState(false);

  useEffect(() => {
    allFormFields?.forEach((f) => {
      if (
        // f?.fieldName?.toLocaleLowerCase() ==
        // formFieldOptions?.fieldName?.toLocaleLowerCase()
        f?.fieldName === formFieldOptions?.fieldName
      )
        setFormFieldAlreadyExists(true);
    });
  }, [formFieldOptions]);

  const handleFormFieldOptions = (obj) => {
    setFormFieldAlreadyExists(false);
    setFormFieldOptions(obj);
  };
  const [selectedField, setSelectedField] = useState(
    selectedDataforTemplateEditor[0]
  );
  const [showFixImageInput, setShowFixImageInput] = useState(false);
  const [showFixDateInput, setShowFixDateInput] = useState(false);
  const [fixDateValue, setFixDateValue] = useState("");
  const [showFixTextInput, setShowFixTextInput] = useState(false);
  const [fixOptionValues, setFixOptionValues] = useState({
    type: "", //could be image or date
    value: "", // could be date string or image url
  });
  const [showImageInputForm, setShowImageInputForm] = useState(false);
  const [fixImageUrlLoading, setFixImageUrlLoading] = useState(false);

  const [showTimeStampInputForm, setShowTimeStampInputForm] = useState(false);
  const [showFixTimeInput, setShowFixTimeInput] = useState("");
  const [fixTimeValue, setFixTimeValue] = useState("");
  const [selectedCheckboxImages, setSelectedCheckboxImages] = useState({
    true: "",
    false: "",
  });
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [uncheckedCheckboxes, setUncheckedCheckboxes] = useState([]);

  const [dataFieldBarRefreshor, setDataFieldbarRefreshor] = useState(
    Math.random()
  );
  const [isEditorLoading, setisEditorLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisEditorLoading(false);
    }, 3000);
  }, []);

  const handleFixImageFileSelection = async (e) => {
    setFixImageUrlLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFixOptionValues({ type: "image", value: response?.data?.url });
      setFixImageUrlLoading(false);

      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setFixImageUrlLoading(false);
      toast.error("unable to load image at the moment!");
      return null;
    }
  };

  useEffect(() => {
    if (CSVFileJSON?.length > 0) {
      setCSVData(CSVFileJSON);
      setTotalData(CSVFileJSON?.length);
    } else {
      setTotalData(100);
    }
  }, [CSVFileJSON]);

  const handleSelectedFieldChange = (e) => {
    setSelectedField(e.target.value);
  };
  useEffect(() => {
    setShowDataFieldsKeysList(false);
    if (selectedOption == "formField") {
      setShowFormFieldOptions(true);
    } else if (selectedOption != "date" && selectedOption) {
      setShowDataFieldsKeysList(true);
    } else {
      setShowDataFieldsKeysList(false);
    }
  }, [selectedOption]);

  //added on 29 nov
  useEffect(() => {
    if (showDatafieldsOptions) {
      setIsPreview(false);
    }
  }, [showDatafieldsOptions]);

  const [openCreatListModal, setOpenCreateListModal] = useState(false);
  const handleOpenCreateListModal = () => setOpenCreateListModal(true);
  const handleCloseCreateListModal = () => setOpenCreateListModal(false);

  const { fabricRef, objects, fabricCanvas } = useEditor();
  const [isSavingAndDispatching, setIsSavingAndDispatching] = useState(false);
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      return null;
    }
  };

  const saveTemplateAndDispatchToSettings = async () => {
    let canvas_width =
      document.getElementsByClassName("canvas-container")[0]?.style?.width;
    let canvas_width_inetger = parseFloat(
      canvas_width.slice(0, canvas_width?.length - 2)
    );

    let canvas_height =
      document.getElementsByClassName("canvas-container")[0]?.style?.height;
    let canvas_heigth_inetger = parseFloat(
      canvas_height.slice(0, canvas_height?.length - 2)
    );

    setIsSavingAndDispatching(true);

    const canvasS3Url = await uploadFileToBackend(fabricCanvas, true);
    // const objs = objects;
    const objs = fabricRef?.current?.getObjects();

    const updatedObjs = objs.map((element, i) => {
      if (addedFieldsForParent[i]) {
        element.id = addedFieldsForParent[i].field;
      }
      return element;
    });

    let obj = {
      canvas_url: canvasS3Url,
      canvas_added_fields: JSON.stringify(addedFieldsForParent),
      canvas_objects_for_output: JSON.stringify(updatedObjs),
      pre_filled_canvas_objects: JSON.stringify(updatedObjs),
      canvas_width_on_frontend: canvas_width_inetger,
      canvas_height_on_frontend: canvas_heigth_inetger,
      saved_till_step: 2,
      complete_form_structure: JSON.stringify({
        all_form_fields: allFormFields,
      }),
    };

    api
      .put("/templates/" + currentTemplateId, obj)
      .then((res) => {
        setIsSavingAndDispatching(false);
        dispatch({
          type: "SET_Directory",
          payload: "settings",
        });
      })
      .catch((err) => {
        setIsSavingAndDispatching(false);
      });
  };

  /************************** */
  const [pdfPageSize, setPdfPageSize] = useState({});
  const fetchSizes = async () => {
    if (generalInformation?.templateBackgroundFile) {
      const response = await fetch(generalInformation?.templateBackgroundFile);
      const pdfData = await response.arrayBuffer();

      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      setPdfPageSize({ height: viewport?.height, width: viewport?.width });
    }
  };
  useEffect(() => {
    fetchSizes();
  }, [generalInformation]);

  return (
    <Box sx={{ position: "relative" }}>
      {isSaving && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 8,
            background: "#fff",
            mt: 3,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {/* <Typography>Saving</Typography> */}
            <CircularProgress color="primary" />
          </Box>
        </Box>
      )}
      <Box
        sx={{ height: isSaving ? "1px" : "max-content", overflow: "hidden" }}
      >
        {" "}
        <Box
          sx={{
            mt: 6,
            pb: 5,
            gap: 3,
            padding: "7px",
            pt: 2,
            backgroundColor: theme.palette.white.main,
            maxWidth: "1001px",
          }}
        >
          <Box>
            <TemplateEditorTabs
              isUseCaseOne={isUseCaseOne}
              selectedOption={selectedOption}
              setShowFixDateInput={setShowFixDateInput}
              setShowFixImageInput={setShowFixImageInput}
              setShowFixTextInput={setShowFixTextInput}
              setShowImageInputForm={setShowImageInputForm}
              setIsPreview={setIsPreview}
              setShowDatafieldsOptions={setShowDatafieldsOptions}
              setShowFixTimeInput={setShowFixTimeInput}
              setShowTimeStampInputForm={setShowTimeStampInputForm}
              setSelectedOption={setSelectedOption}
              showFixImageInput={showFixImageInput}
              showImageInputForm={showImageInputForm}
              showFixTextInput={showFixTextInput}
              showTimeStampInputForm={showTimeStampInputForm}
              showFixDateInput={showFixDateInput}
              showFixTimeInput={showFixTimeInput}
              setShowFormFieldOptions={setShowFormFieldOptions}
              showDatafieldsOptions={showDatafieldsOptions}
              isPreview={isPreview}
            />

            <SpecificTabOptions
              isUseCaseOne={isUseCaseOne}
              selectedOption={selectedOption}
              setShowFixDateInput={setShowFixDateInput}
              setShowFixImageInput={setShowFixImageInput}
              setShowFixTextInput={setShowFixTextInput}
              setShowImageInputForm={setShowImageInputForm}
              setIsPreview={setIsPreview}
              setShowDatafieldsOptions={setShowDatafieldsOptions}
              setShowFixTimeInput={setShowFixTimeInput}
              setShowTimeStampInputForm={setShowTimeStampInputForm}
              setSelectedOption={setSelectedOption}
              showFixImageInput={showFixImageInput}
              showImageInputForm={showImageInputForm}
              showFixTextInput={showFixTextInput}
              showTimeStampInputForm={showTimeStampInputForm}
              showFixDateInput={showFixDateInput}
              showFixTimeInput={showFixTimeInput}
              setShowFormFieldOptions={setShowFormFieldOptions}
              showDatafieldsOptions={showDatafieldsOptions}
              isPreview={isPreview}
              showDataFieldsKeysList={showDataFieldsKeysList}
              showFormFieldOptions={showFormFieldOptions}
              selectedField={selectedField}
              handleSelectedFieldChange={handleSelectedFieldChange}
              selectedDataforTemplateEditor={selectedDataforTemplateEditor}
              setDataFieldsOptions={setDataFieldsOptions}
              setFixOptionValues={setFixOptionValues}
              fixDateValue={fixDateValue}
              fixTimeValue={fixTimeValue}
              setFixDateValue={setFixDateValue}
              setFixTimeValue={setFixTimeValue}
              dataShown={dataShown}
              dataFieldsOptions={dataFieldsOptions}
              dataFieldBarRefreshor={dataFieldBarRefreshor}
              fixImageUrlLoading={fixImageUrlLoading}
              handleFixImageFileSelection={handleFixImageFileSelection}
              totalData={totalData}
              setDataShown={setDataShown}
              formFieldOptions={formFieldOptions}
              formFieldAlreadyExists={formFieldAlreadyExists}
              handleFormFieldOptions={handleFormFieldOptions}
              showDropdownListForm={showDropdownListForm}
              setShowDropdownListForm={setShowDropdownListForm}
              openCreatListModal={openCreatListModal}
              allListsOptions={allListsOptions}
              setFormFieldOptions={setFormFieldOptions}
              setOpenCreateListModal={setOpenCreateListModal}
              isSavingAndDispatching={isSavingAndDispatching}
              saveTemplateAndDispatchToSettings={
                saveTemplateAndDispatchToSettings
              }
              selectedCheckboxImages={selectedCheckboxImages}
              setSelectedCheckboxImages={setSelectedCheckboxImages}
            />
          </Box>
          <Box
            sx={{
              flex: "0.4",
            }}
          ></Box>
        </Box>
        {pdfPageSize?.width && pdfPageSize?.height && (
          <PDFTemplateEditor
            selectedDataforTemplateEditor={selectedDataforTemplateEditor}
            setTemplateCreationStep={setTemplateCreationStep}
            dataShown={dataShown}
            isPreview={isPreview}
            csvData={csvData}
            setDataFieldbarRefreshor={setDataFieldbarRefreshor}
            generalInformation={generalInformation}
            selectedField={selectedField}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            dataFieldsOptions={dataFieldsOptions}
            preFilledCanvasObjects={preFilledCanvasObjects}
            setPreFilledCanvasObjects={setPreFilledCanvasObjects}
            setIsComebackToEdit={setIsComebackToEdit}
            setCanvasAddedFields={setCanvasAddedFields}
            canvasAddedFields={canvasAddedFields}
            isTemplateOpened={isTemplateOpened}
            setShowDatafieldsOptions={setShowDatafieldsOptions}
            isUseCaseOne={isUseCaseOne}
            formFieldOptions={formFieldOptions}
            setShowFormFieldOptions={setShowFormFieldOptions}
            setFormFieldOptions={setFormFieldOptions}
            showFormFieldOptions={showFormFieldOptions}
            setAllFormFields={setAllFormFields}
            allFormFields={allFormFields}
            fixOptionValues={fixOptionValues}
            setFixOptionValues={setFixOptionValues}
            showFixDateInput={showFixDateInput}
            setShowFixDateInput={setShowFixDateInput}
            showFixImageInput={showFixImageInput}
            setShowFixImageInput={setShowFixImageInput}
            showFixTextInput={showFixTextInput}
            setShowFixTextInput={setShowFixTextInput}
            setShowImageInputForm={setShowImageInputForm}
            setIsPreview={setIsPreview}
            showImageInputForm={showImageInputForm}
            fixDateValue={fixDateValue}
            showFixTimeInput={showFixTimeInput}
            fixTimeValue={fixTimeValue}
            setShowFixTimeInput={setShowFixTimeInput}
            setDataFieldsOptions={setDataFieldsOptions}
            isEditorLoading={isEditorLoading}
            formFieldAlreadyExists={formFieldAlreadyExists}
            currentTemplateId={currentTemplateId}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            setAddedFieldsForParent={setAddedFieldsForParent}
            selectedCheckboxImages={selectedCheckboxImages}
            pdfPageSize={pdfPageSize}
          />
        )}
      </Box>
    </Box>
  );
};

export default withTranslation()(TemplateEditor);
