import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#009ee2",
      // main: "#b8379b", // purple
    },
    secondary: {
      main: "#0D47A1", //  Blue
    },
    accent: {
      main: "#009ee266",
      // main: "#dea7d1", //light purple
    },
    background: {
      main: "#fff", //white
    },
    heading: {
      // main: "#4b4b5a",
      main: "#000d",
    },
    backgroundSecondary: {
      main: "whiteSmoke",
      // main: "#e39ff644",
    },
    para: {
      main: "#6c757d",
    },
    white: {
      main: "#fff",
    },
    delete: {
      main: "red",
    },
  },
  typography: {
    // fontFamily: 'Roboto, sans-serif',
    fontFamily: "IBM Plex Sans, sans-serif",
    //fontFamily: "Inter, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 600, // Small devices (tablets)
      md: 960, // Medium devices (laptops)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices
    },
  },
});

export default theme;

// Classic Neutrals:

// Primary Color: #333333 (Dark Gray)
// Secondary Color: #FFFFFF (White)
// Accent Color: #C0C0C0 (Silver)
// Background Color: #F5F5F5 (Light Gray)
// Subtle Blues:

// Primary Color: #0D47A1 (Navy Blue)
// Secondary Color: #E3F2FD (Pale Blue)
// Accent Color: #1565C0 (Royal Blue)
// Background Color: #FFFFFF (White)
// Professional Greens:

// Primary Color: #00695C (Dark Teal)
// Secondary Color: #E8F5E9 (Pale Green)
// Accent Color: #43A047 (Green)
// Background Color: #F1F8E9 (Light Green)
// Elegant Grayscale:

// Primary Color: #212121 (Black)
// Secondary Color: #E0E0E0 (Light Gray)
// Accent Color: #757575 (Medium Gray)
// Background Color: #FFFFFF (White)
// Sophisticated Reds:

// Primary Color: #B71C1C (Dark Red)
// Secondary Color: #FFEBEE (Pale Pink)
// Accent Color: #D32F2F (Red)
// Background Color: #F5F5F5 (Light Gray)
