import {
  Box,
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";

const AttachmentHandler = ({ emailAttachments, setEmailAttachments }) => {
  const theme = useTheme();
  const [showAddAttachmentForm, setShowAddAttachmentForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleFileUpload = async (e) => {
    setIsLoading(true);
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let url = response.data.url; // Assuming the response contains the S3 URL
      setIsLoading(false);
      toast.success("Attachment added successfully!");
      setEmailAttachments([
        ...emailAttachments,
        { name: file?.name, url: url },
      ]);
      setShowAddAttachmentForm(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const removeAttachemnt = (attachment) => {
    setEmailAttachments(emailAttachments?.filter((att) => att != attachment));
    toast.success("removed successfully");
  };
  return (
    <Box item sx={{ p: 2, background: "#fff" }}>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", fontSize: "1rem" }}
          >
            Attachments
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
            add attachments to the email
          </Typography>
        </Box>

        <Button
          disabled={isLoading}
          onClick={() => setShowAddAttachmentForm(!showAddAttachmentForm)}
          sx={{ transition: "all 0.3s ease" }}
        >
          {showAddAttachmentForm ? "Show Attachments" : " + Add"}
        </Button>
      </Box>
      {showAddAttachmentForm ? (
        <Box>
          <Typography>Upload Attachment</Typography>
          <input
            disabled={isLoading}
            type="file"
            onChange={(e) => handleFileUpload(e)}
          />
        </Box>
      ) : (
        <Box>
          {emailAttachments?.length < 1 ? (
            <Typography>No attachments.</Typography>
          ) : (
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Link</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emailAttachments?.map((att) => (
                      <TableRow>
                        <TableCell>{att?.name}</TableCell>
                        <TableCell>
                          <a href={att?.url} target="_blank">
                            Link
                          </a>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => removeAttachemnt(att)}>
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AttachmentHandler;
