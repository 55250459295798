import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Input,
  TextField,
  CircularProgress,
} from "@mui/material";
import FileUploader from "./FileUploader";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseCase from "./steps/UseCase";

// Import your custom step components here
// import SelectUseCaseStep from "./SelectUseCaseStep";
// import EnterTemplateNameStep from "./EnterTemplateNameStep";
// import UploadDocumentsStep from "./UploadDocumentsStep";

function GeneralInformation(props) {
  let {
    setTemplateCreationStep,
    setGeneralInformation,
    isUseCaseOne,
    setHasSetUseCaseOne,
    setCurrentTemplateId,
    isEditing,
    generalInformation,
    currentTemplateId,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [templateName, setTemplateName] = useState("");
  const [templateBackgroundFile, setTemplateBackgroundFile] = useState("");
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [templateNameError, setTemplateNameError] = useState("");
  const [templateBackgroundFileError, setTemplateBackgroundFileError] =
    useState("");
  const [selectedUseCasesError, setSelectedUseCasesError] = useState("");
  const [showImportDataFileInput, setShowImportDataFileInput] = useState(false);
  const [dataSetCSVFile, setDataSetCSVFile] = useState();
  const [dataSetCSVFileError, setDataSetCSVFileError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showUseCase1Info, setShowUseCase1Info] = useState(false);

  const handleTemplateName = (e) => {
    e.preventDefault();

    setTemplateName(e.target.value);
    if (e.target.value == "") setTemplateNameError("required");
    else setTemplateNameError("");
  };
  const handleSelectedUseCases = (useCase) => {
    let useCases;
    // = selectedUseCases;
    // if (selectedUseCases?.includes(useCase)) {
    //   useCases = useCases?.filter((u) => u !== useCase);
    // } else {
    useCases = [useCase];
    // }
    setSelectedUseCases(useCases);
    setShowImportDataFileInput(false);
    setShowUseCase1Info(false);
    if (useCases?.includes("useCase2")) {
      setShowImportDataFileInput(true);
      setHasSetUseCaseOne(false);
    } else if (useCases?.includes("useCase1")) {
      setHasSetUseCaseOne(true);
      setShowUseCase1Info(true);
    } else {
      setShowImportDataFileInput(false);
    }
  };

  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      toast.error("unable to cntinue at the moment!");
      return null;
    }
  };

  const handleSubmitGeneralInformation = async (e) => {
    e.preventDefault();
    if (!templateName) setTemplateNameError("required");
    else setTemplateNameError("");
    if (!templateBackgroundFile) setTemplateBackgroundFileError("required");
    else setTemplateBackgroundFileError("");
    if (selectedUseCases?.length < 1)
      setSelectedUseCasesError("select atleast one");
    else setSelectedUseCasesError("");
    if (dataSetCSVFile?.length < 1 && !isUseCaseOne)
      setDataSetCSVFileError("required");
    else setDataSetCSVFileError("");

    if (
      templateName &&
      templateBackgroundFile &&
      selectedUseCases?.length > 0
    ) {
      setIsLoading(true);
      let originalPdf = await uploadFileToBackend(templateBackgroundFile);
      const modifiedPdfUrl = originalPdf;
      // await api
      //   .post("files/modify/fix-size/pdf", {
      //     pdfUrl: originalPdf,
      //   })
      //   .then((res) => res?.data?.fileUrl);

      // console.log("modified url", modifiedPdfUrl);
      // return;
      let csvS3Url;
      if (!isUseCaseOne) {
        csvS3Url = await uploadFileToBackend(dataSetCSVFile);
        if (modifiedPdfUrl && csvS3Url) {
          setGeneralInformation({
            templateName,
            templateBackgroundFile: modifiedPdfUrl,
            selectedUseCases,
            dataSetCSVFile: csvS3Url,
          });
          /**** saving template as draft */
          let obj = {
            template_name: templateName,
            background_url: modifiedPdfUrl,
            dataset_url: csvS3Url,
            template_status: "draft",
            use_cases: selectedUseCases,
            saved_till_step: 1,
            user_email: user?.email_address,
          };

          api
            .post("/templates/add", obj)
            .then((res) => {
              // console.log("template saved successfully!", res?.data);
              setCurrentTemplateId(res?.data?.id);
            })
            .catch((err) => {
              // console.log("err", err);
            });

          /************************* */
          setIsLoading(false);
          setTemplateCreationStep(2);
        }
      } else {
        if (modifiedPdfUrl) {
          setGeneralInformation({
            templateName,
            templateBackgroundFile: modifiedPdfUrl,
            selectedUseCases,
            user_email: user?.email_address,
          });

          /**** saving template as draft */
          let obj = {
            template_name: templateName,
            background_url: modifiedPdfUrl,
            template_status: "draft",
            use_cases: selectedUseCases,
            saved_till_step: 1,
            unique_identification_string: uuidv4(),
          };
          api
            .post("/templates/add", obj)
            .then((res) => {
              // console.log("template saved successfully!", res?.data);
              setCurrentTemplateId(res?.data?.id);
            })
            .catch((err) => {
              // console.log("err", err);
            });

          /************************* */
          setIsLoading(false);
          setTemplateCreationStep(2);
        }
      }
    }
  };
  /*********************************************************** */

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [steps, setSteps] = useState([]);
  useEffect(() => {
    setSteps([
      {
        label: props?.t("Select use case:"),
        component: (
          <UseCase
            selectedUseCases={selectedUseCases}
            handleSelectedUseCases={handleSelectedUseCases}
            handleNext={handleNext}
          />
        ),
      },
      {
        label: props?.t("Enter template name:"),
        component: (
          <Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                type="text"
                InputProps={{
                  style: {
                    height: "40px",
                    width: "300px",
                  },
                }}
                placeholder={props?.t("e.g. Invoice form")}
                // defaultValue={templateName}
                onChange={handleTemplateName}
              />
            </Box>
          </Box>
        ),
      },
      {
        label: props?.t("Upload Template background"),
        component: (
          <Box>
            {" "}
            <Box>
              {/* <Typography
            variant="h6"
            sx={{ color: theme.palette.heading.main, mb: 1.5 }}
          >
            {props?.t("Template background")}
          </Typography> */}

              <FileUploader
                setFile={setTemplateBackgroundFile}
                type="background"
              />
              {templateBackgroundFileError && (
                <Typography
                  variant="body2"
                  sx={{ color: "red", mt: 1, fontWeight: "400" }}
                >
                  {templateBackgroundFileError}
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
    ]);
  }, [selectedUseCases]);
  // useEffect(() => {
  //   console.log("selected use cases", selectedUseCases);
  //   if (selectedUseCases?.includes("useCase2") && steps?.length == 3) {
  //     setSteps([
  //       ...steps,
  //       {
  //         label: "Upload CSV File",
  //         component: <Box>Heheheh</Box>,
  //       },
  //     ]);
  //   } else {
  //     if (steps?.length == 4 && selectedUseCases?.includes("useCase1"))
  //       setSteps(steps?.splice(0, 3));
  //   }
  // }, [selectedUseCases]);
  useEffect(() => {
    if (selectedUseCases?.includes("useCase2") && steps?.length === 3) {
      setSteps((prevSteps) => [
        ...prevSteps,
        {
          label: props?.t("Upload Dataset File in CSV or XLSX format"),
          component: (
            <Box>
              {/* <Typography
                variant="h6"
                sx={{
                  mt: 3,
                  mb: 3,
                  color: theme.palette.heading.main,
                  fontSize: "1rem",
                }}
              >
                {props?.t("Upload a new dataset in csv or xlsx format")}
              </Typography> */}
              <FileUploader setFile={setDataSetCSVFile} type="data" />

              {dataSetCSVFileError && (
                <Typography
                  variant="body2"
                  sx={{ color: "red", mt: 1, fontWeight: "400" }}
                >
                  {dataSetCSVFileError}
                </Typography>
              )}
            </Box>
          ),
        },
      ]);
    } else if (steps?.length === 4 && selectedUseCases?.includes("useCase1")) {
      setSteps((prevSteps) => prevSteps.slice(0, 3));
    }
  }, [selectedUseCases]);

  const [newName, setNewName] = useState("");
  useEffect(() => {
    console.log("general information", generalInformation);
    setNewName(generalInformation?.templateName);
  }, [generalInformation]);
  const saveName = async () => {
    if (
      !newName ||
      newName?.toLocaleLowerCase() ==
        generalInformation?.templateName?.toLocaleLowerCase()
    )
      return;
    let obj = {
      template_name: newName,
    };
    await api
      .put("/templates/" + currentTemplateId, obj)
      .then((res) => {
        console.log("saved successfully!", res?.data);
        setGeneralInformation({
          ...generalInformation,
          templateName: obj?.template_name,
        });
        setNewName("");
        toast.success("name updated!");
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error("failed");
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: "4px",
        marginTop: "30px",
      }}
    >
      <ToastContainer />
      {isLoading ? (
        <Box
          sx={{
            padding: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : isEditing ? (
        <Box
          sx={{
            padding: "20px 30px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography variant="h6">Edit general information</Typography>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "500", color: theme.palette.heading.main }}
            >
              Selected use case:{" "}
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              {generalInformation?.selectedUseCases?.includes("useCase1")
                ? "Form to Doc"
                : "Data to Doc"}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
                color: theme.palette.heading.main,
                mb: 1,
              }}
            >
              Template name:
            </Typography>
            <TextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Box>

          <Box sx={{ gap: "10px", display: "flex" }}>
            <Button
              variant="outlined"
              sx={{ textTransform: "inherit" }}
              onClick={() => {
                saveName();
              }}
            >
              Update{" "}
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "inherit" }}
              onClick={() => setTemplateCreationStep(2)}
            >
              Continue{" "}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "20px 30px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="body2">
                        {props?.t("Last step")}
                      </Typography>
                    ) : index == 0 && activeStep > 0 ? (
                      <Typography variant="body2">
                        {selectedUseCases?.includes("useCase1")
                          ? props?.t("Form to Doc")
                          : props?.t("Data to Doc")}
                      </Typography>
                    ) : index == 1 && activeStep > 1 ? (
                      <Typography variant="body2">{templateName}</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {step.component}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index != 0 && (
                        <Box>
                          {" "}
                          <Button
                            disabled={
                              index == 0
                                ? selectedUseCases?.length < 1
                                : index == 1 && templateName?.length < 1
                            }
                            variant="contained"
                            onClick={(e) => {
                              if (index === steps.length - 1) {
                                handleSubmitGeneralInformation(e);
                              } else {
                                handleNext();
                              }
                            }}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1
                              ? props?.t("Save and Continue")
                              : props?.t("Continue")}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={() => {
                              index == steps.length - 1
                                ? dispatch({
                                    type: "SET_Directory",
                                    payload: "templates",
                                  })
                                : handleBack();
                            }}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1
                              ? props?.t("Exit")
                              : props?.t("Back")}
                          </Button>
                        </Box>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {/* {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )} */}
        </Box>
      )}
    </Box>
  );
}

export default withTranslation()(GeneralInformation);
