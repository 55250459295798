import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import he from "he";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const convertEmailBodyToRender = (
  htmlString,
  buttonURL,
  replacementDataObject
) => {
  let finalSTR = htmlString.replace(
    /url-for-downloading-files-from email/g,
    buttonURL
  );

  if (replacementDataObject) {
    let replacedSTR = replaceWithObjectValues(finalSTR, replacementDataObject);
    finalSTR = replacedSTR;
  }
  let decodedSTR = he.decode(finalSTR);
  return decodedSTR;
};
//trying another sol for prenom
function replaceWithObjectValues(inputString, replacementObject) {
  // Regular expression to match placeholders like ${...}
  const placeholderRegex = /\${(.*?)}/g;

  const extractedValues = [];
  const replacedIndexes = [];

  // Use replace method with a callback function
  const finalReplacedString = inputString.replace(
    placeholderRegex,
    (match, value, index) => {
      // Extract the value between ${ and }
      const trimmedValue = value.trim();

      // Store the extracted value and its index in the arrays
      extractedValues.push(trimmedValue);
      replacedIndexes.push(index);

      // Replace the extracted value with its corresponding value from the object
      const replacementValue = getReplacementValue(
        trimmedValue,
        replacementObject
      );

      // Return the replacement value
      return replacementValue !== undefined ? replacementValue : match;
    }
  );
  // return { finalReplacedString, extractedValues, replacedIndexes };
  return finalReplacedString ? finalReplacedString : inputString;
}

function getReplacementValue(key, replacementObject) {
  try {
    // Split the key into parts and use them to navigate the object
    const keyParts = key.split(".");
    const result = getObjectValue(keyParts, replacementObject);
    return result;
  } catch (error) {
    // console.error(`Error evaluating key '${key}': ${error}`);
    return undefined;
  }
}

function getObjectValue(keys, obj) {
  return keys.reduce((acc, curr) => (acc ? acc[curr] : undefined), obj);
}

const DeliveryOptionsPreview = (props) => {
  const {
    finalDataForCsv,
    CSVFileJSON,
    allowedDocumentGenerationEntries,
    isUseCaseOne,
    formValues,
  } = props;
  const templateToOpen = useSelector((state) => state?.templateToOpen);
  const theme = useTheme();
  const [data, setData] = useState({});
  useEffect(() => {
    setData(templateToOpen?.templateData);
  }, [templateToOpen]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsDatasetPreview(false);
  };
  const [previewOptions, setPreviewOptions] = useState({});
  const [isDatasetPreview, setIsDatasetPreview] = useState(false);
  const [dataShown, setDataShown] = useState(0);
  const [totalData, setTotalData] = useState(100);
  useEffect(() => {
    if (
      CSVFileJSON?.length > 0 &&
      allowedDocumentGenerationEntries?.length > 0
    ) {
      setDataShown(1);
      setTotalData(allowedDocumentGenerationEntries?.length);
    } else if (CSVFileJSON?.length > 0) {
      setDataShown(1);
      setTotalData(CSVFileJSON?.length);
    } else {
      setTotalData(0);
    }
  }, [CSVFileJSON, allowedDocumentGenerationEntries]);
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            background: theme.palette.backgroundSecondary.main,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Email preview
          </Typography>
          <Box>
            <Typography>Subject: {previewOptions?.subjectOfEmail}</Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {isDatasetPreview && !isUseCaseOne && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  {finalDataForCsv?.length < 1 ? (
                    <Typography sx={{ color: "red" }}>
                      select data from dataset to view preview
                    </Typography>
                  ) : (
                    <>
                      {" "}
                      <ArrowBackIosNewIcon
                        sx={{
                          color: theme.palette.para.main,
                          size: "15px",
                          cursor: "pointer",
                          mr: 3,
                        }}
                        onClick={() => {
                          dataShown === 1
                            ? setDataShown(1)
                            : setDataShown((prev) => prev - 1);
                        }}
                      />
                      <Box
                        sx={{
                          padding: "5px 20px",
                          background: theme.palette.white.main,
                          borderRadius: "22px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: "1.1rem",
                          }}
                        >
                          {props?.t("Data")}: {`${dataShown}/${totalData}`}
                        </Typography>
                      </Box>
                      <ArrowForwardIosIcon
                        sx={{
                          color: theme.palette.para.main,
                          size: "15px",
                          cursor: "pointer",
                          ml: 3,
                        }}
                        onClick={() => {
                          dataShown === totalData
                            ? setDataShown(totalData)
                            : setDataShown((prev) => prev + 1);
                        }}
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box>
              {previewOptions?.htmlString && (
                <Box
                  sx={{
                    margin: "0 auto",
                    backgroundColor: "#ffffff",
                    padding: "30px",
                    mt: 2,
                    width: "60vw",
                    minWidth: "350px",
                    minHeight: "400px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: he.decode(
                        he.decode(
                          he.decode(
                            he.decode(
                              he.decode(
                                he.decode(
                                  he.decode(
                                    convertEmailBodyToRender(
                                      previewOptions?.htmlString,
                                      "",
                                      isUseCaseOne
                                        ? { data: formValues }
                                        : { data: finalDataForCsv[dataShown] }
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      ),
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ my: 2 }}>
              {" "}
              <Typography
                variant="body2"
                sx={{
                  color: theme?.palette?.heading?.main,
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                Attachments
              </Typography>
              {previewOptions?.emailAttachments?.length < 1 ? (
                <Typography></Typography>
              ) : (
                previewOptions?.emailAttachments?.map((em, i) => (
                  <Box sx={{ display: "flex" }}>
                    <Typography>
                      {em?.name} <a href={em?.url}>View file</a>
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.heading.main, fontSize: "1rem" }}
        >
          {props?.t(" Selected delivery options")} :{" "}
        </Typography>
        <Box sx={{ py: 1, px: 2 }}>
          {" "}
          {data?.recipients?.includes("1") && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography>Admin email</Typography>
              <Button
                size="small"
                onClick={(e) => {
                  handleOpen();
                  setPreviewOptions(
                    data?.email_template_props?.adminEmailOptions
                  );
                }}
              >
                Preview
              </Button>
            </Box>
          )}
          {data?.recipients?.includes("2") && (
            <Box sx={{ my: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography>Dataset emails</Typography>
                <Button
                  size="small"
                  onClick={(e) => {
                    handleOpen();
                    setPreviewOptions(
                      data?.email_template_props?.datasetEmailOptions
                    );
                    setIsDatasetPreview(true);
                  }}
                >
                  Preview
                </Button>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.para.main }}
              >
                {props?.t("Dataset Field")}:{" "}
                {data?.recipient_email_address_field}
              </Typography>
            </Box>
          )}
          {data?.recipients?.includes("3") && (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography>Manual emails</Typography>
                <Button
                  size="small"
                  onClick={(e) => {
                    handleOpen();
                    setPreviewOptions(
                      data?.email_template_props?.manualEmailsOptions
                    );
                  }}
                >
                  Preview
                </Button>
              </Box>

              <Box sx={{}}>
                <Typography></Typography>
                {data?.multipleEmailsOfManualRecipeints?.map((d) => (
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.para.main }}
                  >
                    {d}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(DeliveryOptionsPreview);
