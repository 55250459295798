import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Container,
  Button,
  TextField,
  Input,
  Paper,
  FormControl,
  Select,
  styled,
  MenuItem,
  Checkbox,
  Switch,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FinalForm from "./FinalForm";
import CreateIcon from "@mui/icons-material/Create";
import { withTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { api } from "../../../axios/api";
import { useDispatch } from "react-redux";

const StyledMenuItem = styled(MenuItem)({
  fontSize: "0.9rem",
});

const FormEditor = (props) => {
  let {
    allFormFields,
    setAllFormFields,
    setTemplateCreationStep,
    setCompleteFormStructure,
    completeFormStructure,
    setIsComebackToEdit,
    generalInformation,
    preFilledCanvasObjects,
    setPreFilledCanvasObjects,
    canvasAddedFields,
    setCanvasAddedFields,
    currentTemplateId,
  } = props;
  const [isPreview, setIsPreview] = useState(false);
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null);
  const [showFieldDetailForm, setShowFieldDetailForm] = useState(false);
  const [selectedFieldForEditDetail, setSelectedFieldForEditDetail] = useState(
    {}
  );
  const [enabledDateFormats, setEnabledDateFormats] = useState([]);
  const [enabledTimeFormats, setEnabledTimeFormats] = useState([]);
  useEffect(() => {
    api
      .get("/formats/date")
      .then((res) => {
        let allFormats = res?.data;
        api
          .get("/formats/enabled/date")
          .then((res) => {
            let enabledIds = res?.data;
            setEnabledDateFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  useEffect(() => {
    api
      .get("/formats/time")
      .then((res) => {
        let allFormats = res?.data;
        api
          .get("/formats/enabled/time")
          .then((res) => {
            let enabledIds = res?.data;
            setEnabledTimeFormats(
              allFormats?.filter((f) => enabledIds?.includes(f?.id))
            );
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const [allListsOptions, setAllListsOptions] = useState([]);
  useEffect(() => {
    api
      .get("/lists")
      .then((res) => {
        // console.log("response", res?.data);
        if (res?.data?.length > 0) {
          setAllListsOptions(res?.data);
        }
      })
      .catch((err) => {});
  }, []);
  const handleClose = () => {
    setShowFieldDetailForm(false);
    setShowListItemForm(false);
    setOpenCreateListModal(false);
    setIsSavingAndDispatching(false);
  };
  const [showListItemForm, setShowListItemForm] = useState(false);

  useEffect(() => {
    if (completeFormStructure?.form_title) {
      setTitle(completeFormStructure?.form_title);
    } else {
      setTitle(generalInformation?.templateName);
    }
    if (completeFormStructure?.form_description) {
      setDescription(completeFormStructure?.form_description);
    } else {
      setDescription("");
    }
  }, [completeFormStructure]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setTitleError(e.target.value.trim() === "");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError(e.target.value.trim() === "");
  };

  useEffect(() => {
    setCompleteFormStructure({
      ...completeFormStructure,
      all_form_fields: allFormFields,
    });
  }, [allFormFields]);

  const handleSubmit = async (toStep) => {
    if (title == undefined) setTitle("");
    if (description == undefined) setDescription("");

    if (!title) {
      toast.error("Title is empty. Cannot submit.");
    } else {
      let completeFormStruct = {
        form_title: title,
        form_description: description,
        all_form_fields: allFormFields,
      };
      setCompleteFormStructure(completeFormStruct);
      if (toStep != 2) {
        let obj = {
          complete_form_structure: JSON.stringify(completeFormStruct),
          canvas_added_fields: JSON.stringify(canvasAddedFields),
          canvas_objects_for_output: JSON.stringify(preFilledCanvasObjects),
          pre_filled_canvas_objects: JSON.stringify(preFilledCanvasObjects),
          saved_till_step: 3,
        };
        await api
          .put("/templates/" + currentTemplateId, obj)
          .then((res) => {
            // console.log("res?.data while saving templates", res?.data);
          })
          .catch((err) => {
            // console.log("err", err);
          });
      }
      setTemplateCreationStep(toStep == 2 ? toStep : 4);
    }
  };

  const deleteField = (field) => {
    toast.success("deleting ");
    // console.log("canvas added fields", canvasAddedFields);

    let indexForCanvasObjects = canvasAddedFields?.findIndex(
      (c) => c?.field == field?.fieldName
    );
    // console.log("prefilled canvas objects", preFilledCanvasObjects);
    let objToRemove = preFilledCanvasObjects[indexForCanvasObjects];
    // console.log("obj to remove", objToRemove);
    // console.log(objToRemove);
    const updatedObjs = preFilledCanvasObjects?.filter(
      (ob) => ob != objToRemove
    );
    // console.log("canvas updated objs", updatedObjs);
    setPreFilledCanvasObjects(updatedObjs);
    // return;
    setCanvasAddedFields(
      canvasAddedFields?.filter((c) => c?.field != field?.fieldName)
    );
    setAllFormFields(
      allFormFields?.filter((f) => f?.fieldName != field?.fieldName)
    );
  };

  // Define a type for the drag-and-drop
  const ItemTypes = {
    ROW: "row",
  };

  // Function to wrap each row with drag-and-drop logic
  const DraggableRow = ({
    index,
    field,
    moveRow,
    lastSelectableRowIndex,
    setLastSelectableRowIndex,
    ...rest
  }) => {
    const [, ref] = useDrag({
      type: ItemTypes.ROW,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (draggedItem) => {
        // console.log("on hovering", draggedItem);
        // console.log("last selectable row index", lastSelectableRowIndex);
        // console.log(lastSelectableRowIndex != draggedItem?.index);
        setLastSelectableRowIndex(draggedItem?.index);

        if (lastSelectableRowIndex != draggedItem?.index) {
          setTimeout(() => {
            setLastSelectableRowIndex(-1);
          }, 1000);
        }

        if (draggedItem.index !== index) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    return (
      <TableRow
        ref={(node) => ref(drop(node))}
        {...rest}
        sx={{
          cursor: "move",
          // borderBottom: isDragging ? "2px solid blue" : "none",

          // borderBottom: lastSelectableRowIndex == index && "2px solid pink",
          // borderColor: theme.palette.primary.main,
          // boxShadow:
          //   lastSelectableRowIndex == index &&
          //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          transition: "all 0.3s ease-in",
        }}
      >
        {/* Render your row content here */}
        <TableCell
          sx={{
            color:
              lastSelectableRowIndex == index && theme.palette.primary.main,
            transition: "all 0.3s ease-in",
          }}
        >
          {/* {index + 1} */}
          <DragIndicatorIcon />
        </TableCell>
        <TableCell
          style={{
            width: "max-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {field.fieldName}
        </TableCell>
        <TableCell
          style={{
            width: "max-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {field.fieldType}
        </TableCell>
        <TableCell>{field.isMandatory ? "Yes" : "No"}</TableCell>
        <TableCell style={{ width: "100%" }}>
          {field.fieldDescription || "N/A"}
        </TableCell>

        <TableCell>
          {/* Actions buttons */}
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="small"
              onClick={() => {
                setShowFieldDetailForm(true);
                setSelectedFieldForEditDetail(field);
              }}
            >
              <CreateIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1.1rem" }}
              />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                deleteField(field);
              }}
            >
              <DeleteIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1.1rem" }}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const moveRow = (fromIndex, toIndex) => {
    const updatedFields = [...allFormFields];
    const [removed] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, removed);
    setAllFormFields(updatedFields);
  };
  const dispatch = useDispatch();
  const [isSavingAndDispatching, setIsSavingAndDispatching] = useState(false);
  const [openCreatListModal, setOpenCreateListModal] = useState(false);
  const saveFormAndDispatchToSettings = async () => {
    setIsSavingAndDispatching(true);
    let completeFormStruct = {
      form_title: title,
      form_description: description,
      all_form_fields: allFormFields,
    };
    let obj = {
      complete_form_structure: JSON.stringify(completeFormStruct),
      canvas_added_fields: JSON.stringify(canvasAddedFields),
      canvas_objects_for_output: JSON.stringify(preFilledCanvasObjects),
      pre_filled_canvas_objects: JSON.stringify(preFilledCanvasObjects),
      saved_till_step: 3,
    };
    await api
      .put("/templates/" + currentTemplateId, obj)
      .then((res) => {
        setIsSavingAndDispatching(false);
        dispatch({
          type: "SET_Directory",
          payload: "settings",
        });
      })
      .catch((err) => {
        // console.log("err", err);
        setIsSavingAndDispatching(false);
      });
  };

  const [lastSelectableRowIndex, setLastSelectableRowIndex] = useState(-1);

  return (
    <DndProvider backend={HTML5Backend}>
      <Container sx={{ p: 4 }}>
        <ToastContainer />
        <Modal
          open={showFieldDetailForm}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 1,
            }}
          >
            {openCreatListModal ? (
              <Box sx={{ p: 1 }}>
                {isSavingAndDispatching ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      p: 4,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.heading.main,
                        fontWeight: "600",
                      }}
                    >
                      You will be directed to settings. Do you want to save the
                      new Changes?
                    </Typography>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          saveFormAndDispatchToSettings();
                          // dispatch({
                          //   type: "SET_Directory",
                          //   payload: "settings",
                          // });
                          // callSaveAndContinueFunction();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch({
                            type: "SET_Directory",
                            payload: "settings",
                          });
                        }}
                      >
                        No
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {" "}
                <Box
                  sx={{
                    height: "100%",
                    flex: "0.7",
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    {" "}
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      {props?.t("Edit Details of Field: ") +
                        selectedFieldForEditDetail?.fieldName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "500",
                      }}
                    >
                      {props?.t("Field Type")}
                    </Typography>

                    {selectedFieldForEditDetail?.fieldType == "image" ? (
                      <>
                        <Typography
                          variant="p"
                          sx={{
                            color: theme.palette.heading.main,
                            fontWeight: "500",
                          }}
                        >
                          image
                        </Typography>
                        {selectedFieldForEditDetail?.fieldType == "image" && (
                          <Typography variant="body2" sx={{ mt: 0 }}>
                            Image fields cannot be converted to other types.
                          </Typography>
                        )}
                      </>
                    ) : selectedFieldForEditDetail?.fieldType == "signature" ? (
                      <>
                        <Typography
                          variant="p"
                          sx={{
                            color: theme.palette.heading.main,
                            fontWeight: "500",
                          }}
                        >
                          signature
                        </Typography>
                        {selectedFieldForEditDetail?.fieldType ==
                          "signature" && (
                          <Typography variant="body2" sx={{ mt: 0 }}>
                            Signature fields cannot be converted to other types.
                          </Typography>
                        )}
                      </>
                    ) : selectedFieldForEditDetail?.fieldType ==
                      "multiline-text" ? (
                      <>
                        <Typography
                          variant="p"
                          sx={{
                            color: theme.palette.heading.main,
                            fontWeight: "500",
                          }}
                        >
                          Multiline text
                        </Typography>
                        {selectedFieldForEditDetail?.fieldType ==
                          "multiline-text" && (
                          <Typography variant="body2" sx={{ mt: 0 }}>
                            Multiline text fields cannot be converted to other
                            types.
                          </Typography>
                        )}
                      </>
                    ) : selectedFieldForEditDetail?.fieldType == "checkbox" ? (
                      <>
                        <Typography
                          variant="p"
                          sx={{
                            color: theme.palette.heading.main,
                            fontWeight: "500",
                          }}
                        >
                          checkbox
                        </Typography>
                        {selectedFieldForEditDetail?.fieldType ==
                          "checkbox" && (
                          <Typography variant="body2" sx={{ mt: 0 }}>
                            checkboxes fields cannot be converted to other
                            types.
                          </Typography>
                        )}
                      </>
                    ) : (
                      <FormControl>
                        <Select
                          disabled={
                            selectedFieldForEditDetail?.fieldType == "image"
                          }
                          value={selectedFieldForEditDetail?.fieldType}
                          onChange={(e) => {
                            setSelectedFieldForEditDetail({
                              ...selectedFieldForEditDetail,
                              fieldType: e.target.value,
                            });
                          }}
                          // displayEmpty
                          sx={{ height: "30px", fontSize: "0.9rem" }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          <StyledMenuItem value="" key={`selected-field-1`}>
                            {props?.t("Select Type")}
                          </StyledMenuItem>
                          <StyledMenuItem value="text" key={`selected-field-2`}>
                            {props?.t("text")}
                          </StyledMenuItem>
                          <StyledMenuItem
                            value="number"
                            key={`selected-field-3`}
                          >
                            {props?.t("number")}
                          </StyledMenuItem>
                          <StyledMenuItem value="date" key={`selected-field-4`}>
                            {props?.t("date")}
                          </StyledMenuItem>
                          <StyledMenuItem value="time" key={`selected-field-6`}>
                            {props?.t("time")}
                          </StyledMenuItem>
                          <StyledMenuItem
                            value="email"
                            key={`selected-field-5`}
                          >
                            {props?.t("email")}
                          </StyledMenuItem>
                          <StyledMenuItem value="list" key={`selected-field-7`}>
                            {props?.t("list")}
                          </StyledMenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                  {selectedFieldForEditDetail?.fieldType == "date" && (
                    <>
                      {" "}
                      <Typography
                        variant="p"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "500",
                        }}
                      >
                        {props?.t("Field format")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Select
                          // displayEmpty
                          sx={{ height: "30px", fontSize: "0.9rem" }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          defaultValue={selectedFieldForEditDetail?.format}
                          onChange={(e) => {
                            setSelectedFieldForEditDetail({
                              ...selectedFieldForEditDetail,
                              format: e.target.value,
                            });
                          }}
                        >
                          {enabledDateFormats?.map((format) => (
                            <StyledMenuItem
                              value={format.format}
                              key={`selected-field-1`}
                            >
                              {format.format}
                            </StyledMenuItem>
                          ))}
                        </Select>
                      </Box>
                    </>
                  )}
                  {selectedFieldForEditDetail?.fieldType == "time" && (
                    <>
                      {" "}
                      <Typography
                        variant="p"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "500",
                        }}
                      >
                        {props?.t("Field format")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Select
                          // displayEmpty
                          sx={{ height: "30px", fontSize: "0.9rem" }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          defaultValue={selectedFieldForEditDetail?.format}
                          onChange={(e) => {
                            setSelectedFieldForEditDetail({
                              ...selectedFieldForEditDetail,
                              format: e.target.value,
                            });
                          }}
                        >
                          {enabledTimeFormats?.map((format) => (
                            <StyledMenuItem
                              value={format.format}
                              key={`selected-field-1`}
                            >
                              {format.format}
                            </StyledMenuItem>
                          ))}
                        </Select>
                      </Box>
                    </>
                  )}
                  {selectedFieldForEditDetail?.fieldType == "checkbox" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    ></Box>
                  )}
                  {selectedFieldForEditDetail?.fieldType == "list" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: "500",
                          }}
                        >
                          {props?.t("List Elements")}
                        </Typography>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => setShowListItemForm(true)}
                        >
                          Select List
                        </Button>
                      </Box>
                      {showListItemForm ? (
                        <Box sx={{ background: "whitesmoke", p: 2, m: 1 }}>
                          <Box sx={{ mt: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "1.2rem", mb: 2 }}
                              >
                                {props?.t("Available Lists")}
                              </Typography>
                              <Button
                                size="small"
                                variant="contained"
                                sx={{ height: "max-content" }}
                                onClick={() => {
                                  setOpenCreateListModal(true);
                                }}
                              >
                                {props?.t("Create new list")}
                              </Button>
                            </Box>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{props?.t("List")}</TableCell>
                                    <TableCell>{props?.t("Items")}</TableCell>
                                    <TableCell>{props?.t("Actions")}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {allListsOptions?.map((list, n) => (
                                    <TableRow>
                                      <TableCell>{list?.list_name}</TableCell>
                                      <TableCell>
                                        <Select size="small">
                                          {list?.list_items?.map((item, m) => (
                                            <MenuItem value={item}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          onClick={() => {
                                            setSelectedFieldForEditDetail({
                                              ...selectedFieldForEditDetail,
                                              listItems: list?.list_items,
                                            });
                                            setShowListItemForm(false);
                                          }}
                                        >
                                          {props?.t("Select")}
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>

                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ ml: 1, width: "100%", my: 3 }}
                            onClick={() => setShowListItemForm(false)}
                          >
                            {props?.t("close")}
                          </Button>
                        </Box>
                      ) : (
                        <SelectListItemsComp
                          selectedFieldForEditDetail={
                            selectedFieldForEditDetail
                          }
                        />
                      )}
                    </Box>
                  )}

                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="p"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "500",
                      }}
                    >
                      {props?.t("Field Description")}
                    </Typography>
                    <Input
                      key={selectedFieldForEditDetail?.description}
                      type="text"
                      placeholder="write description of the field..."
                      variant="outlined"
                      value={
                        selectedFieldForEditDetail?.fieldDescription
                          ? selectedFieldForEditDetail?.fieldDescription
                          : ""
                      }
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setSelectedFieldForEditDetail({
                          ...selectedFieldForEditDetail,
                          fieldDescription: e.target.value,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        my: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: "0px",
                        mt: 3,
                      }}
                    >
                      {/* <Checkbox
                                      checked={selectedFieldForEditDetail?.isMandatory}
                                      onChange={(e) => {
                                        setSelectedFieldForEditDetail({
                                          ...selectedFieldForEditDetail,
                                          isMandatory: e.target.checked,
                                        });
                                      }}
                                    /> */}
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "400",
                          color: theme.palette.heading.main,
                          fontSize: "1rem",
                        }}
                      >
                        {props?.t("Required field")}
                      </Typography>
                      <Switch
                        // size="small"
                        checked={selectedFieldForEditDetail?.isMandatory}
                        onChange={(e) => {
                          setSelectedFieldForEditDetail({
                            ...selectedFieldForEditDetail,
                            isMandatory: e.target.checked,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 5 }}
                    >
                      <Button
                        size="small"
                        onClick={() => {
                          console.log("clicked");
                          let updatedFields = allFormFields.map((field) =>
                            field?.fieldName ==
                            selectedFieldForEditDetail?.fieldName
                              ? selectedFieldForEditDetail
                              : field
                          );
                          setAllFormFields(updatedFields);
                          console.log(
                            "cnavs added fields>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
                            canvasAddedFields
                          );
                          console.log(
                            "selectedFieldForEditDetail",
                            selectedFieldForEditDetail
                          );
                          let updatedcanvasaddedfields = canvasAddedFields?.map(
                            (c) => {
                              console.log("fields", c);
                              if (
                                c?.field ==
                                selectedFieldForEditDetail?.fieldName
                              ) {
                                if (
                                  selectedFieldForEditDetail?.fieldType ==
                                    "image" ||
                                  selectedFieldForEditDetail?.fieldType ==
                                    "signature"
                                ) {
                                  return { ...c, type: "image" };
                                } else if (
                                  selectedFieldForEditDetail?.fieldType ==
                                    "date" ||
                                  selectedFieldForEditDetail?.fieldType ==
                                    "time"
                                ) {
                                  return {
                                    ...c,
                                    type: selectedFieldForEditDetail?.fieldType,
                                    format: selectedFieldForEditDetail?.format,
                                  };
                                } else if (
                                  selectedFieldForEditDetail?.fieldType ==
                                  "checkbox"
                                ) {
                                  return { ...c, type: "checkbox" };
                                } else if (
                                  selectedFieldForEditDetail?.fieldType ==
                                  "multiline-text"
                                ) {
                                  return { ...c, type: "multiline-text" };
                                } else {
                                  return { ...c, type: "text" };
                                }
                              } else {
                                return c;
                              }
                            }
                          );
                          console.log(
                            "updated canvas added fields",
                            updatedcanvasaddedfields
                          );
                          setCanvasAddedFields(updatedcanvasaddedfields);
                          setShowFieldDetailForm(false);
                          setShowListItemForm(false);
                        }}
                        variant="contained"
                        sx={{}}
                        disabled={
                          selectedFieldForEditDetail?.fieldType == "list" &&
                          (selectedFieldForEditDetail?.listItems < 1 ||
                            !selectedFieldForEditDetail?.listItems)
                        }
                      >
                        {props?.t("Save")}
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setShowFieldDetailForm(false);
                          setShowListItemForm(false);
                        }}
                        variant="outlined"
                        sx={{ ml: 2 }}
                      >
                        {props?.t("Cancel")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
        <Box
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              // setTemplateCreationStep(2);
              handleSubmit(2);
              setIsComebackToEdit(true);
            }}
          >
            {props?.t("Back to Editor")}
          </Button>
        </Box>
        {isPreview && (
          <FinalForm
            setIsPreview={setIsPreview}
            isPreview={isPreview}
            formTitle={title}
            formDescription={description}
            allFormFields={allFormFields}
            canvasAddedFields={canvasAddedFields}
            canvasObjects={preFilledCanvasObjects}
          />
        )}
        <Box sx={{ p: 3, background: "#fff", borderRadius: "4px" }}>
          <Box sx={{ display: "flex", mb: 3 }}>
            <Box
              sx={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "600", color: theme.palette.heading.main }}
              >
                {props?.t("Form Editor")}
              </Typography>
            </Box>
            <Box
              sx={{
                minHeight: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                  color: isPreview
                    ? theme.palette.primary.main
                    : theme.palette.para.main,
                }}
                onClick={() => setIsPreview(!isPreview)}
              >
                <RemoveRedEyeIcon />
                <Typography variant="body2">
                  {props?.t("see preview")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", color: theme.palette.heading.main }}
            >
              {props?.t("Form Title")}
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              placeholder={props?.t("Form Title")}
              value={title}
              onChange={handleTitleChange}
              error={titleError}
              sx={{ maxWidth: "500px" }}
            />
          </Box>
          {titleError && (
            <Typography variant="caption" color="red">
              {props?.t("Title cannot be empty")}
            </Typography>
          )}
          <Box sx={{ my: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", color: theme.palette.heading.main }}
            >
              {props?.t("Form Description")}
            </Typography>
            <TextField
              size="small"
              placeholder="optional"
              value={description}
              onChange={handleDescriptionChange}
              // error={descriptionError}
              sx={{ mt: 1, width: "100%", maxWidth: "500px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              alignItems: "center",
              // py: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",

                width: "100%",
                // py: 5,
              }}
            >
              <Box
                sx={{
                  my: 2,

                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    color: theme.palette.heading.main,
                    mb: 2,
                  }}
                >
                  {props?.t("Form Fields")}
                </Typography>

                <Box sx={{ width: "100%", mt: 1 }}>
                  <TableContainer component={Paper} sx={{}}>
                    <Table sx={{}}>
                      <TableHead>
                        <TableRow
                          sx={{
                            backgroundColor: theme.palette.accent.main,
                          }}
                        >
                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                            }}
                          ></TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: "1rem",
                            }}
                          >
                            {props?.t("Name")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: "1rem",
                            }}
                          >
                            {props?.t("Type")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: "1rem",
                            }}
                          >
                            {props?.t("Mandatory")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: "1rem",
                            }}
                            style={{ width: "100%" }}
                          >
                            {props?.t("Description")}
                          </TableCell>

                          <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: "1rem",
                            }}
                          >
                            {props?.t("Actions")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allFormFields?.map((f, index) => (
                          <DraggableRow
                            key={index}
                            index={index}
                            field={f}
                            moveRow={moveRow}
                            setLastSelectableRowIndex={
                              setLastSelectableRowIndex
                            }
                            lastSelectableRowIndex={lastSelectableRowIndex}
                          ></DraggableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              onClick={handleSubmit}
            >
              {props?.t("save & continue")}
            </Button>
          </Box>
        </Box>
      </Container>
    </DndProvider>
  );
};

export default withTranslation()(FormEditor);

const SelectListItemsComp = ({ selectedFieldForEditDetail }) => {
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    setListItems(selectedFieldForEditDetail?.listItems);
    if (selectedFieldForEditDetail?.listId) {
      api
        .get("/lists/items/" + selectedFieldForEditDetail?.listId)
        .then((res) => {
          console.log("res", res?.data);
          if (res?.data?.length > 1) {
            setListItems(res?.data);
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [selectedFieldForEditDetail]);
  return (
    <Select
      // displayEmpty
      sx={{ height: "30px", fontSize: "0.9rem" }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      {listItems?.map((item) => (
        <StyledMenuItem value="" key={`selected-field-1`}>
          {item}
        </StyledMenuItem>
      ))}
    </Select>
  );
};
