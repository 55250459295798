import React, { useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { Range } from "slate";
import Button from "../common/Button";
import Icon from "../common/Icon";
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  addMarkData,
  isBlockActive,
  activeMark,
} from "../utils/SlateUtilityFunctions.js";
import useFormat from "../utils/customHooks/useFormat.js";
import defaultToolbarGroups from "./toolbarGroups.js";
import "./styles.css";
import ColorPicker from "../Elements/Color Picker/ColorPicker";
import LinkButton from "../Elements/Link/LinkButton";
import Embed from "../Elements/Embed/Embed";
import TableSelector from "../Elements/Table/TableSelector";
import EquationButton from "../Elements/Equation/EquationButton";
import Id from "../Elements/ID/Id";
import TableContextMenu from "../Elements/TableContextMenu/TableContextMenu";
import CodeToTextButton from "../Elements/CodeToText/CodeToTextButton";
import HtmlContextMenu from "../Elements/CodeToText/HtmlContextMenu";
import DownloadIcon from "@mui/icons-material/Download";
import DynamicDataField from "../Elements/DynamicDataField/DynamicDataField";
import TimeStamp from "../Elements/TimeStamp/TimeStamp.js";
import { Box, IconButton } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { useTheme } from "@emotion/react";
import TextFieldsIcon from "@mui/icons-material/TextFields";
const Toolbar = (props) => {
  const theme = useTheme();
  const {
    handleCodeToText,
    handleDownloadFileAddClick,
    emailTemplateOptions,
    handleAddText,
  } = props;
  const editor = useSlate();
  const isTable = useFormat(editor, "table");
  const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);

  useEffect(() => {
    // Filter out the groups which are not allowed to be inserted when a table is in focus.
    let filteredGroups = [...defaultToolbarGroups];
    if (isTable) {
      filteredGroups = toolbarGroups.map((grp) =>
        grp.filter(
          (element) =>
            //groups that are not supported inside the table
            !["codeToText"].includes(element.type)
        )
      );
      filteredGroups = filteredGroups.filter((elem) => elem.length);
    }
    setToolbarGroups(filteredGroups);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTable]);

  const BlockButton = ({ format }) => {
    return (
      <Button
        active={isBlockActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };
  const MarkButton = ({ format }) => {
    return (
      <Button
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };
  const Dropdown = ({ format, options }) => {
    return (
      <select
        value={activeMark(editor, format)}
        onChange={(e) => changeMarkData(e, format)}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    );
  };
  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  // Inside the Toolbar component
  const DownloadButton = () => {
    return (
      <MuiButton
        size="small"
        variant="outlined"
        sx={{
          // borderRadius: "4px",
          mt: -1,
          opacity: "1!important",
          border: "none !important",
          background: "#009ee266 !important",
          textTransform: "capitalize",
        }}
        onMouseDown={(e) => handleDownloadFileAddClick(e)}
      >
        {/* <Icon icon="empty-line-icon" /> */}
        {/* <DownloadIcon /> */}+ Download Button
        {/* Add Download MuiButton */}
      </MuiButton>
    );
  };

  // Inside the Toolbar component
  const AddTextButton = () => {
    return (
      <IconButton onMouseDown={(e) => handleAddText(e)}>
        <TextFieldsIcon
          sx={{
            color: "black !important",
            opacity: "1!important",
            fontSize: "1.3rem",
          }}
        />
      </IconButton>
    );
  };

  // Add EmptyLineButton to the toolbarGroups
  // ...

  return (
    <Box className="toolbar">
      <AddTextButton />
      {toolbarGroups.map((group, index) => (
        <span key={index} className="toolbar-grp">
          {group.map((element) => {
            switch (element.type) {
              case "block":
                return <BlockButton key={element.id} {...element} />;
              case "mark":
                return <MarkButton key={element.id} {...element} />;
              // case "download-email-file":
              //   return <DownloadButton key={element.id} {...element} />;
              case "dropdown":
                return <Dropdown key={element.id} {...element} />;
              case "link":
                return (
                  <LinkButton
                    key={element.id}
                    active={isBlockActive(editor, "link")}
                    editor={editor}
                  />
                );
              case "embed":
                return (
                  <Embed
                    key={element.id}
                    format={element.format}
                    editor={editor}
                  />
                );
              // case "add-dynamic-data":
              //   return emailTemplateOptions?.emailEditorForOption == 2 ? (
              //     <DynamicDataField
              //       key={element.id}
              //       format={element.format}
              //       editor={editor}
              //       emailTemplateOptions={emailTemplateOptions}
              //     />
              //   ) : (
              //     <></>
              //   );
              case "color-picker":
                return (
                  <ColorPicker
                    key={element.id}
                    activeMark={activeMark}
                    format={element.format}
                    editor={editor}
                  />
                );
              case "table":
                return <TableSelector key={element.id} editor={editor} />;
              case "id":
                return <Id editor={editor} />;
              case "equation":
                return <EquationButton editor={editor} />;
              case "codeToText":
                return (
                  <CodeToTextButton handleButtonClick={handleCodeToText} />
                );
              default:
                return null;
            }
          })}
        </span>
      ))}
      <Box sx={{ mr: 2 }}>
        <TimeStamp
          editor={editor}
          emailTemplateOptions={emailTemplateOptions}
        />
      </Box>
      <Box sx={{ mr: 2 }}>
        <DownloadButton

        // key={element.id} {...element}
        />
      </Box>
      <Box>
        {emailTemplateOptions?.emailEditorForOption == 2 ? (
          <DynamicDataField
            // key={element.id}
            // format={element.format}
            editor={editor}
            emailTemplateOptions={emailTemplateOptions}
          />
        ) : (
          <></>
        )}
      </Box>
      <TableContextMenu editor={editor} />
      <HtmlContextMenu editor={editor} handleCodeToText={handleCodeToText} />
    </Box>
  );
};

export default Toolbar;
