import React, { useState } from "react";
import {
  Paper,
  Typography,
  InputBase,
  IconButton,
  useTheme,
} from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";

const ImageFileUploader = ({ onImageSelect }) => {
  const theme = useTheme();
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      onImageSelect(file);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageSelect(file);
    }
  };

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        border: (theme) => `1px dashed ${theme.palette.grey[400]}`,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
        elevation: dragging ? 4 : 0,
        mt: 1.2,
        mb: 1,
        color: theme.palette.heading.main,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      component="label"
    >
      <CloudUploadOutlined sx={{ marginRight: 2 }} />
      <Typography variant="body1" sx={{ flexGrow: 1 }}>
        Click or drag an image file here
      </Typography>
      <InputBase
        type="file"
        inputProps={{ accept: "image/*" }}
        onChange={handleInputChange}
        sx={{ display: "none" }}
      />
    </Paper>
  );
};

export default ImageFileUploader;
