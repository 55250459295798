import React, { useState } from "react";

import { LinkedIn } from "react-linkedin-login-oauth2";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../../actions/user";
import { Navigate, useNavigate } from "react-router-dom";
// You can use provided image shipped by this package or using your own
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { api } from "../../axios/api";

function LinkedinLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <LinkedIn
      clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
      redirectUri={`${window.location.origin}/linkedin`}
      onSuccess={async (code) => {
        const response = await api.post("/auth/linkedin/token", {
          code,
        });
        const accessToken = response.data.accessToken;
        console.log("access token", response.data);

        // Now fetch the user data using the accessToken
        const userResponse = await axios.get(
          `/auth/linkedin/user?access_token=${accessToken}`
        );
        // setUserData(userResponse.data.userData);
        console.log("userobject", userResponse.data.userData);
        dispatch(setUser(userResponse.data.userData));
        navigate("/");
      }}
      onError={(error) => {
        console.log("error in loggin in through linked in", error);
      }}
    >
      {({ linkedInLogin }) => (
        <img
          onClick={linkedInLogin}
          src={linkedin}
          alt="Sign in with Linked In"
          style={{ maxWidth: "180px", cursor: "pointer" }}
        />
      )}
    </LinkedIn>
  );
}

export default LinkedinLogin;
