import { Box, useTheme, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import TitleIcon from "@mui/icons-material/Title";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import InsertPhotoIcon from "@mui/icons-material/ImageOutlined";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import CalendarMonthIcon from "@mui/icons-material/ScheduleOutlined";
import PersonIcon from "@mui/icons-material/Person2Outlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const TemplateEditorTabs = (props) => {
  const theme = useTheme();
  const {
    isUseCaseOne,
    selectedOption,
    setSelectedOption,
    setShowFixDateInput,
    setShowFixImageInput,
    setShowFixTextInput,
    setShowImageInputForm,
    setIsPreview,
    setShowDatafieldsOptions,
    setShowFixTimeInput,
    setShowTimeStampInputForm,
    showFixImageInput,
    showImageInputForm,
    showFixTextInput,
    showTimeStampInputForm,
    showFixDateInput,
    showFixTimeInput,
    setShowFormFieldOptions,
    isPreview,
    showDatafieldsOptions,
  } = props;
  return (
    <Box
      sx={{
        height: "90px",
        display: "flex",
        gap: "20px",
        padding: "5px 15px",
      }}
    >
      {isUseCaseOne ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            padding: "7px",
            width: "82px",
            alignItems: "center",
            gap: "3px",
            height: "70px",
            transition: "all 0.3s ease",
            outlineColor: theme.palette.primary.main,
            outlineColor:
              selectedOption == "formField"
                ? theme.palette.primary.main
                : theme.palette.para.main,
            background: theme.palette.backgroundSecondary.main,
            borderBottom:
              selectedOption == "formField" ? "1px solid red" : "none",
            borderColor: theme.palette.primary.main,
            color:
              selectedOption == "formField"
                ? theme.palette.primary.main
                : theme.palette.heading.main,
          }}
          onClick={() => {
            if (isUseCaseOne) {
              selectedOption == "formField"
                ? setSelectedOption("")
                : setSelectedOption("formField");
              setShowFixDateInput(false);
              setShowFixImageInput(false);
              setShowFixTextInput(false);
              setShowImageInputForm(false);
              setIsPreview(false);
              setShowDatafieldsOptions(false);
              setShowFixTimeInput(false);
              setShowTimeStampInputForm(false);
            }
          }}
        >
          <PersonIcon
            sx={{
              fontSize: selectedOption == "formField" ? "1.7rem" : "1.4rem",
              transition: "all 0.3s ease",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
              lineHeight: "15px",
              fontWeight: "400",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            {props?.t("form fields")}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            padding: "7px",
            width: "82px",
            alignItems: "center",
            gap: "3px",
            height: "70px",
            transition: "all 0.3s ease",
            outlineColor: theme.palette.primary.main,
            outlineColor:
              selectedOption == "dataField"
                ? theme.palette.primary.main
                : theme.palette.para.main,
            background: theme.palette.backgroundSecondary.main,
            borderBottom:
              selectedOption == "dataField" ? "1px solid red" : "none",
            borderColor: theme.palette.primary.main,
            color:
              selectedOption == "dataField"
                ? theme.palette.primary.main
                : theme.palette.heading.main,
          }}
          onClick={() => {
            if (!isUseCaseOne) {
              selectedOption == "dataField"
                ? setSelectedOption("")
                : setSelectedOption("dataField");
              setShowImageInputForm(false);
              setShowFixTextInput(false);
              setShowFixImageInput(false);
              setShowFixDateInput(false);
              setIsPreview(false);
              setShowDatafieldsOptions(false);
              setShowFixTimeInput(false);
              setShowTimeStampInputForm(false);
            }
          }}
        >
          <TitleIcon
            sx={{
              fontSize: selectedOption == "dataField" ? "1.7rem" : "1.4rem",
              transition: "all 0.3s ease",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
              lineHeight: "15px",
              fontWeight: "400",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            {props?.t("data field")}
          </Typography>
        </Box>
      )}
      {!isUseCaseOne && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            padding: "7px",
            width: "82px",
            alignItems: "center",
            gap: "3px",
            height: "70px",
            transition: "all 0.3s ease",
            outlineColor: theme.palette.primary.main,
            outlineColor:
              selectedOption == "checkbox"
                ? theme.palette.primary.main
                : theme.palette.para.main,
            background: theme.palette.backgroundSecondary.main,
            borderBottom:
              selectedOption == "checkbox" ? "1px solid red" : "none",
            borderColor: theme.palette.primary.main,
            color:
              selectedOption == "checkbox"
                ? theme.palette.primary.main
                : theme.palette.heading.main,
          }}
          onClick={() => {
            if (!isUseCaseOne) {
              selectedOption == "checkbox"
                ? setSelectedOption("")
                : setSelectedOption("checkbox");
              setShowImageInputForm(false);
              setShowFixTextInput(false);
              setShowFixDateInput(false);
              setIsPreview(false);
              setShowDatafieldsOptions(false);
              setShowFixTimeInput(false);
              setShowTimeStampInputForm(false);
            }
          }}
        >
          <CheckBoxIcon
            sx={{
              fontSize: selectedOption == "checkbox" ? "1.7rem" : "1.4rem",
              transition: "all 0.3s ease",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
              lineHeight: "15px",
              fontWeight: "400",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            {props?.t("checkbox")}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          padding: "7px",
          width: "82px",
          alignItems: "center",
          gap: "3px",
          height: "70px",
          transition: "all 0.3s ease",
          outlineColor: theme.palette.primary.main,
          outlineColor:
            showFixImageInput ||
            (!isUseCaseOne && selectedOption == "image") ||
            showImageInputForm ||
            showFixImageInput
              ? theme.palette.primary.main
              : theme.palette.para.main,
          background: theme.palette.backgroundSecondary.main,
          borderBottom:
            showFixImageInput ||
            (!isUseCaseOne && selectedOption == "image") ||
            showImageInputForm ||
            showFixImageInput
              ? "1px solid red"
              : "none",
          borderColor: theme.palette.primary.main,
          color:
            showFixImageInput ||
            (!isUseCaseOne && selectedOption == "image") ||
            showImageInputForm ||
            showFixImageInput
              ? theme.palette.primary.main
              : theme.palette.heading.main,
        }}
        onClick={() => {
          if (!isUseCaseOne) {
            setSelectedOption("");
            setShowFixTextInput(false);
            setShowFixDateInput(false);
            setShowFormFieldOptions(false);
            setShowImageInputForm(!showImageInputForm);
            setIsPreview(false);
            setShowDatafieldsOptions(false);
            setShowFixTimeInput(false);
            setShowTimeStampInputForm(false);
          } else {
            setShowFixImageInput(!showImageInputForm);
            setShowFixTextInput(false);
            setShowFixDateInput(false);
            setSelectedOption("");
            setShowFormFieldOptions(false);
            setIsPreview(false);
            setShowDatafieldsOptions(false);
            setShowFixTimeInput(false);
            setShowTimeStampInputForm(false);
          }
        }}
      >
        <InsertPhotoIcon
          sx={{
            fontSize:
              showFixImageInput ||
              (!isUseCaseOne && selectedOption == "image") ||
              showImageInputForm ||
              showFixImageInput
                ? "1.7rem"
                : "1.4rem",
            transition: "all 0.3s ease",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.8rem",
            lineHeight: "15px",
            fontWeight: "400",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {props?.t("image")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          padding: "7px",
          width: "82px",
          alignItems: "center",
          gap: "3px",
          height: "70px",
          transition: "all 0.3s ease",
          outlineColor: theme.palette.primary.main,
          outlineColor: showFixTextInput
            ? theme.palette.primary.main
            : theme.palette.para.main,
          background: theme.palette.backgroundSecondary.main,
          borderBottom: showFixTextInput ? "1px solid red" : "none",
          borderColor: theme.palette.primary.main,
          color: showFixTextInput
            ? theme.palette.primary.main
            : theme.palette.heading.main,
        }}
        onClick={() => {
          setShowFixTextInput(!showFixTextInput);
          setShowFixImageInput(false);
          setShowFixDateInput(false);
          setSelectedOption("");
          setShowFormFieldOptions(false);
          setShowImageInputForm(false);
          setIsPreview(false);
          setShowDatafieldsOptions(false);
          setShowFixTimeInput(false);
          setShowTimeStampInputForm(false);
        }}
      >
        <TextFormatIcon
          sx={{
            fontSize: showFixTextInput ? "1.7rem" : "1.4rem",
            transition: "all 0.3s ease",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.8rem",
            lineHeight: "15px",
            fontWeight: "400",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {props?.t("text")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          padding: "7px",
          width: "82px",
          alignItems: "center",
          gap: "3px",
          height: "70px",
          transition: "all 0.3s ease",
          outlineColor: theme.palette.primary.main,
          outlineColor:
            showTimeStampInputForm || showFixDateInput || showFixTimeInput
              ? theme.palette.primary.main
              : theme.palette.para.main,
          background: theme.palette.backgroundSecondary.main,
          borderBottom:
            showTimeStampInputForm || showFixDateInput || showFixTimeInput
              ? "1px solid red"
              : "none",
          borderColor: theme.palette.primary.main,

          color:
            showTimeStampInputForm || showFixDateInput || showFixTimeInput
              ? theme.palette.primary.main
              : theme.palette.heading.main,
        }}
        onClick={() => {
          setShowTimeStampInputForm(!showTimeStampInputForm);
          setShowFixDateInput(false);
          setShowFixTimeInput(false);
          setShowFixTextInput(false);
          setShowFixImageInput(false);
          setSelectedOption("");
          setShowFormFieldOptions(false);
          setShowImageInputForm(false);
          setIsPreview(false);
          setShowDatafieldsOptions(false);
        }}
      >
        <CalendarMonthIcon
          sx={{
            fontSize:
              showTimeStampInputForm || showFixDateInput || showFixTimeInput
                ? "1.7rem"
                : "1.4rem",
            transition: "all 0.3s ease",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.8rem",
            lineHeight: "15px",
            fontWeight: "400",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {props?.t("timestamp")}
        </Typography>
      </Box>
      {!isUseCaseOne && (
        <Box
          sx={{
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              padding: "7px",
              width: "82px",
              alignItems: "center",
              gap: "3px",
              height: "70px",
              transition: "all 0.3s ease",
              outlineColor: theme.palette.primary.main,
              outlineColor: isPreview
                ? theme.palette.primary.main
                : theme.palette.para.main,
              background: theme.palette.backgroundSecondary.main,
              borderBottom: isPreview ? "1px solid red" : "none",
              borderColor: theme.palette.primary.main,
              color: isPreview
                ? theme.palette.primary.main
                : theme.palette.heading.main,
            }}
            onClick={() => {
              if (!isUseCaseOne) {
                setIsPreview(!isPreview);
                setSelectedOption("");
                setShowFixDateInput(false);
                setShowFixImageInput(false);
                setShowImageInputForm(false);
                setShowDatafieldsOptions(false);
                setShowFixTimeInput(false);
                setShowTimeStampInputForm(false);
              }
            }}
          >
            <RemoveRedEyeIcon
              sx={{
                fontSize: isPreview ? "1.7rem" : "1.4rem",
                transition: "all 0.3s ease",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.8rem",
                lineHeight: "15px",
                fontWeight: "400",
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              {props?.t("see preview")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(TemplateEditorTabs);
