import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddAccountForm from "../components/Admin/AddAccountForm";

import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";

const AdminCoupon = (props) => {
  const theme = useTheme();
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    api
      .get("/signup-coupon")
      .then((res) => {
        setCoupon(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  //   function generateCoupon() {
  //     return Math.random().toString(36).substr(2, 8).toUpperCase();
  //   }

  const handleRegenerate = () => {
    // setCoupon(generateCoupon());
    // toast.success("Coupon regenerated");
    api
      .post("/signup-coupon")
      .then((res) => {
        setCoupon(res?.data?.coupon);
        toast.success("Coupon regenerated");
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(coupon);
    toast.success("Coupon copied to clipboard");
  };

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Box sx={{}}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          Coupon
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.para.main,
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          This coupon is necessary for any user to signup
        </Typography>
      </Box>
      <div>
        <Box display="flex" alignItems="center" sx={{ mt: 3 }}>
          <IconButton onClick={handleCopy} sx={{ mr: 1 }}>
            <ContentPasteOutlinedIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
          <Typography>{coupon}</Typography>
          {/* <Button
            onClick={handleCopy}
            variant="text"
            size="small"
            sx={{ ml: 1, textTransform: "inherit" }}
            startIcon={<ContentPasteOutlinedIcon />}
          >
            Copy
          </Button> */}
        </Box>
      </div>
      <Button
        onClick={handleRegenerate}
        variant="outlined"
        size="small"
        sx={{ textTransform: "inherit", mt: 2 }}
      >
        Regenerate
      </Button>
    </Box>
  );
};

export default withTranslation()(AdminCoupon);
