import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useEditor } from "../../../hooks/useEdtior";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";
import { api } from "../../../axios/api";
import { maxHeight } from "@mui/system";

function isURL(str) {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
}

const PDFTemplateEditor = (props) => {
  const {
    generalInformation,
    dataShown,
    selectedField,
    dataFieldsOptions,
    selectedOption,
    isPreview,
    showImageInputForm,
    csvData,
    setTemplateCreationStep,
    setIsComebackToEdit,
    preFilledCanvasObjects,
    setPreFilledCanvasObjects,
    setCanvasAddedFields,
    canvasAddedFields,
    isTemplateOpened,
    setSelectedOption,
    setShowDatafieldsOptions,
    isUseCaseOne,
    formFieldOptions,
    showFormFieldOptions,
    setShowFormFieldOptions,
    setFormFieldOptions,
    allFormFields,
    setAllFormFields,
    showFixDateInput,
    showFixImageInput,
    setShowFixImageInput,
    setShowFixDateInput,
    fixOptionValues,
    setFixOptionValues,
    showFixTextInput,
    setShowFixTextInput,
    setIsPreview,
    setShowImageInputForm,
    setDataFieldbarRefreshor,
    fixDateValue,
    showFixTimeInput,
    fixTimeValue,
    setShowFixTimeInput,
    setDataFieldsOptions,
    isEditorLoading,
    formFieldAlreadyExists,
    currentTemplateId,
    isSaving,
    setIsSaving,
    setAddedFieldsForParent,
    selectedCheckboxImages,
    pdfPageSize,
  } = props;
  const canvasRef = useRef(null);
  const { fabricRef, setFabricCanvas, setObjects, setImageURL } = useEditor();
  const [isActiveObjectSelected, setIsActiveObjectSelected] = useState(false);
  const [deleteActiveFieldError, setDeleteActiveFieldError] = useState("");
  const [showDeleteObjectButton, setShowDeleteObjectButton] = useState(false);
  const [oneTimeImageUploadDone, setOneTimeImageUploadDone] = useState(false);
  const [checkboxScalingOptions, setCheckboxScalingOptions] = useState({});
  // let checkboxScalingOptions = {};

  const [, forceUpdate] = useState();
  // console.log("fabricRef?.current", fabricRef?.current);
  useEffect(() => {
    // console.log("form field options at xfdf play", formFieldOptions);
  }, [formFieldOptions]);
  useEffect(() => {
    if (fabricRef?.current?.getActiveObject()) {
      setIsActiveObjectSelected(true);
    }
  }, [fabricRef]);
  const [addedFields, setAddedFields] = useState(
    !canvasAddedFields
      ? []
      : !isTemplateOpened
      ? canvasAddedFields
      : canvasAddedFields.map((preFilledObject) => {
          let fabricObject = null;
          // console.log("hourrah>>>>>", preFilledObject);
          if (preFilledObject?.type == "checkbox") {
            // console.log(
            //   "is check box",
            //   preFilledObject?.element?.scaleX,
            //   preFilledObject?.element?.scaleY
            // );
            // checkboxScalingOptions = {
            //   x: preFilledObject?.element?.scaleX,
            //   y: preFilledObject?.element?.scaleY,
            //   height: preFilledCanvasObjects?.element?.height,
            //   width: preFilledCanvasObjects?.element?.width,
            // };
            // setCheckboxScalingOptions({
            //   x: preFilledObject?.element?.scaleX,
            //   y: preFilledObject?.element?.scaleY,
            // });
          }
          if (
            preFilledObject.type === "text" ||
            preFilledObject.type === "time" ||
            preFilledObject.type === "date"
          ) {
            // Create a Fabric.js Text object with the provided text
            fabricObject = {
              ...preFilledObject,
              element: new fabric.Text(
                preFilledObject.element.text,
                preFilledObject.element
              ),
            };
            // console.log("fabric bject at type text", fabricObject);
          } else if (preFilledObject.type === "multiline-text") {
            // Create a Fabric.js Text object with the provided text
            fabricObject = {
              ...preFilledObject,
              element: new fabric.Textbox(preFilledObject.element.text, {
                ...preFilledObject.element,
                hasControls: false,
                editable: false,
              }),
            };
            // fabricObject = {
            //   ...preFilledObject,
            //   element: new fabric.Rect(
            //     preFilledObject.element,
            //     preFilledObject.element
            //   ),
            // };
            // console.log("fabric bject at type text", fabricObject);
          } else if (
            preFilledObject.type === "checkbox" ||
            preFilledObject.type === "image"
          ) {
            // Create a Fabric.js Image object for checkboxes and images

            fabricObject = {
              ...preFilledObject,
              element: new fabric.Image(
                preFilledObject?.element,
                // preFilledObject?.element
                { ...preFilledObject?.element, id: preFilledObject?.field }
              ),
            };
            // console.log("fabric bject at type image or check", fabricObject);
          }
          return fabricObject;
        })
  );
  useEffect(() => {
    setAddedFieldsForParent(addedFields);
    console.log("added fields", addedFields);
    addedFields?.forEach((f) => {
      if (f?.type == "checkbox") {
        setCheckboxScalingOptions({
          x: f?.element?.scaleX,
          y: f?.element?.scaleY,
          height: f?.element?.height,
          width: f?.element?.width,
        });
      }
    });
  }, [addedFields]);

  useEffect(() => {
    console.log("checkbox scaling options", checkboxScalingOptions);
  }, [checkboxScalingOptions]);

  const [elemntsFromAddedFields, setElementsFromAddedFields] = useState();
  // useEffect(() => {
  //   console.log("canvas added fields", canvasAddedFields);
  // }, [canvasAddedFields]);
  const dispatch = useDispatch();

  useEffect(() => {
    fabricRef?.current?.on("mouse:down", canvasMouseDownHandler);

    return () => {
      fabricRef?.current?.off("mouse:down", canvasMouseDownHandler);
    };
  }, [
    fabricRef.current,
    dataFieldsOptions,
    formFieldOptions,
    fixOptionValues,
    showFixDateInput,
    showFixImageInput,
    showFixTextInput,
    selectedField,
    dataShown,
    selectedOption,
    isPreview,
    fixDateValue,
    fixTimeValue,
    selectedCheckboxImages,
    checkboxScalingOptions,
  ]);

  /*********************************** mouse hover handler testing */
  // useEffect(() => {
  //   fabricRef?.current?.on("mouse:over", canvasMouseHoverHandler);

  // }, []);
  // fabricRef?.current?.on("mouse:over", function (e) {
  //   // e.target.set("fill", "red");
  //   console.log("e", e);
  //   fabricRef?.current?.renderAll();
  // });
  // const canvasMouseHoverHandler = (e, activeFF) => {
  //   console.log("mouse handler evernt", e);
  //   console.log("hehe");
  // };

  /*************************** */

  // const [hoveredRect, setHoveredRect] = useState();
  // const [isDrawing, setIsDrawing] = useState(false);

  // useEffect(() => {
  //   const canvas = fabricRef.current;

  //   if (canvas) {
  //     canvas.on("mouse:move", (event) => {
  //       if (isDrawing) {
  //         const { e } = event;
  //         const { offsetX, offsetY } = e;

  //         // Remove the previously hovered rectangle
  //         if (hoveredRect) {
  //           console.log("hovered rect");
  //           canvas.remove(hoveredRect);
  //         }

  //         // Create a new rectangle
  //         const rect = new fabric.Rect({
  //           left: offsetX,
  //           top: offsetY,
  //           width: 100,
  //           height: 50,
  //           fill: "rgba(255, 0, 0, 0.3)",
  //           selectable: false,
  //         });

  //         // Create text
  //         // const text = new fabric.Text("Your Text", {
  //         //   left: offsetX + 5,
  //         //   top: offsetY + 5,
  //         //   fontSize: 28,
  //         //   fill: "black",
  //         //   selectable: false,
  //         // });

  //         // Add both rectangle and text to canvas
  //         // canvas.add(rect, text);
  //         // setHoveredRect(text);
  //         setHoveredRect(rect);
  //         // canvas.add(text);
  //         canvas.add(rect);
  //         console.log("rect to add", rect);

  //         // Set the hoveredRect for removal on the next move

  //         canvas?.renderAll();
  //       }
  //     });

  //     canvas.on("mouse:down", () => {
  //       if (isDrawing) {
  //         setIsDrawing(false);
  //         setHoveredRect(null);
  //         // Add the rectangle and text to your list of objects here
  //       }
  //     });
  //   }

  //   return () => {
  //     if (canvas) {
  //       canvas.off("mouse:move");
  //       canvas.off("mouse:down");
  //     }
  //   };
  // }, [isDrawing, hoveredRect]);

  // const handleCanvasClick = () => {
  //   setIsDrawing(true);
  // };

  const [isDrawing, setIsDrawing] = useState(false);

  function displayTextWidth(text, font) {
    let canvas =
      displayTextWidth.canvas ||
      (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  const setTextContentAccToFontSize = (text, fontSize, rectWidth) => {
    console.log(" setTextContentAccToFontSizetext", text);
    console.log("setTextContentAccToFontSize font size", fontSize);
    console.log("rect width", rectWidth);

    let sentenceSize = displayTextWidth(text, "normal 18pt helvetica");
    if (sentenceSize < rectWidth) {
      return text;
    }
    console.log("rect width", rectWidth);
    console.log("senetence size", sentenceSize);
    const wordsArray = text.split(" ");
    for (let w of wordsArray) {
      console.log("w", w);
      console.log("w size", displayTextWidth(w, "normal 18pt helvetica"));
    }

    // return text;
    return wordsArray?.join(" \n ");
  };

  useEffect(() => {
    const canvas = fabricRef.current;

    if (canvas) {
      if (
        isUseCaseOne &&
        formFieldOptions &&
        formFieldOptions?.fieldType == "multiline-text"
      ) {
        const activedObj = canvas?.getActiveObject();

        var line,
          isDown,
          startPosition = {},
          endPosition = {},
          textEle,
          drawingMode = true;

        canvas.on("mouse:down", function (event) {
          const { e } = event;
          const { offsetX, offsetY } = e;
          if (
            isUseCaseOne &&
            formFieldOptions &&
            formFieldOptions?.fieldType == "multiline-text"
          ) {
            if (fabricRef?.current?.getActiveObject()) {
              drawingMode = false;
            }

            if (!drawingMode || fabricRef?.current?.getActiveObject()) return;
            isDown = true;
            console.log(event.e.clientX, event.e.clientY);
            startPosition = canvas.getPointer(event.e);

            let styles = {
              fontFamily: dataFieldsOptions.fontFamily
                ? dataFieldsOptions.fontFamily
                : "Arial",
              textAlign: dataFieldsOptions.alignment
                ? dataFieldsOptions.alignment
                : "left",
              fill: dataFieldsOptions.fontColor
                ? dataFieldsOptions.fontColor
                : "#000",
              fontSize: dataFieldsOptions.fontSize
                ? parseInt(dataFieldsOptions.fontSize)
                : "18",
              fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
              fontWeight: dataFieldsOptions.isBold ? "bold" : "",
              underline: dataFieldsOptions.isUnderlined
                ? dataFieldsOptions.isUnderlined
                : false,
            };

            const textContent = formFieldOptions?.fieldName;
            //  "sample";
            // ("lorem ipsum dipsum chicksum ifsum sispsum iouyh jsj kjs kjdsc sdj");

            textEle = new fabric.Textbox(textContent, {
              // left: e.absolutePointer.x,
              // top: e.absolutePointer.y,
              left: offsetX,
              top: offsetY,
              ...styles,
              id: formFieldOptions?.fieldName,
              hasControls: false,
              editable: false,
              width: parseInt(formFieldOptions?.boxWidth),
              height: parseInt(formFieldOptions?.boxheight),
              minHeight: parseInt(formFieldOptions?.boxheight),
              maxHeight: parseInt(formFieldOptions?.boxheight),
              maxWidth: parseInt(formFieldOptions?.boxWidth),
              lineHeight: 1,
              textAlign: dataFieldsOptions?.alignment
                ? dataFieldsOptions?.alignment
                : "left",
              // wrapMode: "word",
              // whiteSpace: "normal",
              // hasBorders: false
            });

            textEle.id = formFieldOptions?.fieldName;

            let tempAddedFields = addedFields;
            addedFields.push({
              // element: rect,
              element: textEle,
              field: formFieldOptions?.fieldName,
              type: "multiline-text",
            });

            setAddedFields(tempAddedFields);
            setAllFormFields([...allFormFields, formFieldOptions]);

            // fabricRef.current.add(rect, text);
            fabricRef.current.add(textEle);

            fabricRef.current.renderAll();
          }
        });

        canvas.on("mouse:move", function (event) {
          if (
            isUseCaseOne &&
            formFieldOptions &&
            formFieldOptions?.fieldType == "multiline-text"
          ) {
            if (
              !isDown ||
              !drawingMode ||
              fabricRef?.current?.getActiveObject()
            )
              return;

            endPosition = canvas.getPointer(event.e);
            // console.log("rect at mouse move", rect);
            // rect.setWidth(Math.abs(endPosition.x - startPosition.x));

            textEle.set("width", Math.abs(endPosition.x - startPosition.x));
            textEle.set("maxWidth", Math.abs(endPosition.x - startPosition.x));

            // rect.setHeight(Math.abs(endPosition.y - startPosition.y));

            textEle.set("height", Math.abs(endPosition.y - startPosition.y));
            textEle.set("maxHeight", Math.abs(endPosition.y - startPosition.y));

            canvas.renderAll();
          }
        });

        canvas.on("mouse:up", function () {
          isDown = false;
          setFormFieldOptions({});
          // canvas.add(rect);
        });

        canvas.on("object:selected", function () {
          drawingMode = false;
        });

        // canvas.on("object:selected", function () {
        //   drawingMode = false;
        // });
        // canvas.on("selection:cleared", function () {
        //   drawingMode = true;
        // });
      } else {
        let hoveredElement = null;

        canvas.on("mouse:move", (event) => {
          if (
            !formFieldAlreadyExists &&
            (formFieldOptions?.fieldType != "list" ||
              formFieldOptions?.listItems?.length > 0)
          ) {
            const { e } = event;
            const { offsetX, offsetY } = e;
            // console.log("hovered elemnt in this iteration", hoveredElement);
            // console.log("checkbox scaling options", checkboxScalingOptions);

            if (showFixTextInput && fixOptionValues?.value) {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let textEle = new fabric.Text(fixOptionValues?.value, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,

                ...styles,
                hasControls: false,
              });
              textEle.id = fixOptionValues?.value;
              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (showFixDateInput) {
              if (!fixDateValue) return;
              // if (!fixOptionValues?.value) return;
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let textEle = new fabric.Text(
                fixDateValue ? fixDateValue : "mm/dd/yyyy",
                {
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  ...styles,
                  hasControls: false,
                }
              );
              textEle.id = "date_system_current_date_generated";
              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (showFixTimeInput) {
              console.log("fix time value at xfdf play", fixTimeValue);
              if (!fixTimeValue) return;
              // if (!fixOptionValues?.value) return;
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let textEle = new fabric.Text(
                fixTimeValue ? fixTimeValue : "HH:MM:SS",
                {
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  ...styles,
                  hasControls: false,
                }
              );
              textEle.id = "date_system_current_date_generated";
              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (
              // isUseCaseOne &&
              showFixImageInput &&
              fixOptionValues?.value
            ) {
              let imgSrc =
                // isPreview
                //   ?
                fixOptionValues?.value;
              // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  // scaleX: 400 / myImg.width,
                  // scaleY: 400 / myImg.height,
                  id: "fix_image_input",
                  src: imgSrc,
                  // fixOptionValues?.value,
                });
                if (hoveredElement) {
                  canvas.remove(hoveredElement);
                }
                myImg?.scaleToWidth(400);
                myImg?.scaleToHeight(400);
                myImg.id = "fix_image_input";

                canvas.add(myImg);
                hoveredElement = myImg;

                canvas?.renderAll();
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "date"
            ) {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName || !formFieldOptions?.format)
                return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "time"
            ) {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName || !formFieldOptions?.format)
                return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "multiline-text"
            ) {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }

              if (!formFieldOptions?.boxHeight || !formFieldOptions?.boxWidth) {
                return;
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName) return;
              let text = formFieldOptions?.fieldName;
              /***** converting single line text to multiline */
              console.log("text", text);

              /*********** */

              let textEle = new fabric.Textbox(
                `${text} `,
                //   `line 1\n line2 \n line3 \n line4 `,
                {
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  ...styles,
                  id: formFieldOptions?.fieldName,
                  hasControls: false,
                  width: parseInt(formFieldOptions?.boxWidth),
                  height: parseInt(formFieldOptions?.boxheight),
                  minHeight: parseInt(formFieldOptions?.boxheight),
                  maxHeight: parseInt(formFieldOptions?.boxheight),
                  maxWidth: parseInt(formFieldOptions?.boxWidth),
                  textAlign: dataFieldsOptions?.alignment
                    ? dataFieldsOptions?.alignment
                    : "left",
                  // wrapMode: "word",
                  // whiteSpace: "normal",

                  hasBorders: true,
                }
              );
              textEle.id = formFieldOptions?.fieldName;

              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType != "image" &&
              formFieldOptions?.fieldType != "signature" &&
              formFieldOptions?.fieldType != "checkbox"
              // &&
              // showFormFieldOptions
            ) {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName) return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;
              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "image"
              // &&
              // showFormFieldOptions
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                if (hoveredElement) {
                  canvas.remove(hoveredElement);
                }
                myImg.set({
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  scaleX: 400 / myImg.width,
                  scaleY: 400 / myImg.height,
                  id: formFieldOptions?.fieldName,
                });
                myImg.id = formFieldOptions?.fieldName;
                canvas.add(myImg);
                hoveredElement = myImg;

                canvas?.renderAll();
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "signature"
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                "https://images.placeholders.dev/?width=300&height=150&text=Signature&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                if (hoveredElement) {
                  canvas.remove(hoveredElement);
                }
                myImg.set({
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  scaleX: 300 / myImg.width,
                  scaleY: 150 / myImg.height,
                  id: formFieldOptions?.fieldName,
                  lockScalingX: true,
                  lockScalingY: true,
                });
                myImg.id = formFieldOptions?.fieldName;
                canvas.add(myImg);
                hoveredElement = myImg;

                canvas?.renderAll();
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "checkbox"
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                selectedCheckboxImages?.true;
              fabric.Image.fromURL(imgSrc, function (myImg) {
                if (hoveredElement) {
                  canvas.remove(hoveredElement);
                }
                myImg.set({
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  scaleX: checkboxScalingOptions?.x
                    ? checkboxScalingOptions?.x
                    : 50 / myImg.width,
                  scaleY: checkboxScalingOptions?.y
                    ? checkboxScalingOptions?.y
                    : 50 / myImg.height,
                  // width: checkboxScalingOptions?.width
                  //   ? checkboxScalingOptions?.width
                  //   : myImg.width,
                  // height: checkboxScalingOptions?.height
                  //   ? checkboxScalingOptions?.height
                  //   : myImg.height,
                  id: formFieldOptions?.fieldName,
                  lockScalingX: true,
                  lockScalingY: true,
                });
                myImg.id = formFieldOptions?.fieldName;
                canvas.add(myImg);
                hoveredElement = myImg;

                canvas?.renderAll();
              });
            } else if (selectedField && selectedOption === "dataField") {
              if (hoveredElement) {
                canvas.remove(hoveredElement);
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let text = isPreview
                ? csvData[dataShown - 1][selectedField]
                : selectedField;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: selectedField,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = selectedField;
              canvas.add(textEle);
              hoveredElement = textEle;

              canvas?.renderAll();
            } else if (selectedField && selectedOption === "checkbox") {
              // I have to add some logic here if use wanna drag checkbox, we can cjheck value in csv field is true r false , if not then we can show error and do not show user

              let isCheck = isPreview
                ? csvData[dataShown - 1][selectedField] === "TRUE"
                  ? true
                  : false
                : false;

              fabric.Image.fromURL(
                // `/${isCheck ? "checkbox" : "unchecked"}.png`
                isCheck
                  ? selectedCheckboxImages?.true
                  : selectedCheckboxImages?.false,
                function (myImg) {
                  console.log(myImg);
                  if (hoveredElement) {
                    canvas.remove(hoveredElement);
                  }
                  //i create an extra var for to change some image properties
                  myImg.set({
                    // left: e.absolutePointer.x,
                    // top: e.absolutePointer.y,
                    left: offsetX,
                    top: offsetY,
                    scaleX: 50 / myImg.width,
                    scaleY: 50 / myImg.height,
                    id: selectedField,
                  });
                  myImg.id = selectedField;
                  canvas.add(myImg);
                  hoveredElement = myImg;

                  canvas?.renderAll();
                }
              );
            } else if (selectedField && selectedOption === "image") {
              let imgSrc =
                // isPreview
                //   ?
                csvData[dataShown - 1][selectedField];
              // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              if (!imgSrc || !isURL(imgSrc))
                imgSrc =
                  "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                if (hoveredElement) {
                  canvas.remove(hoveredElement);
                }
                myImg.set({
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  scaleX: 400 / myImg.width,
                  scaleY: 400 / myImg.height,
                  id: selectedField,
                });
                myImg.id = selectedField;
                canvas.add(myImg);
                hoveredElement = myImg;

                canvas?.renderAll();

                // let tempAddedFields = addedFields;
                // addedFields.push({
                //   element: myImg,
                //   field: selectedField,
                //   type: "image",
                // });
                // setAddedFields(tempAddedFields);
                // fabricRef.current.add(myImg);
                // fabricRef.current.renderAll();
                // setOneTimeImageUploadDone(true);
                // setTimeout(() => {
                //   setOneTimeImageUploadDone(false);
                // }, 1000);
              });
            }
            //  else {
            //   console.log("running most extra one");
            //   // Create text
            //   const text = new fabric.Text("Your Text", {
            //     left: offsetX + 5,
            //     top: offsetY + 5,
            //     fontSize: 28,
            //     fill: "black",
            //     selectable: false,
            //   });

            //   // Add both rectangle and text to canvas
            //   canvas.add(text);
            //   hoveredElement = text;

            //   canvas?.renderAll();
            // }
          }
        });
        canvas.on("mouse:out", (e) => {
          if (hoveredElement) {
            canvas.remove(hoveredElement);
          }
        });
        canvas.on("mouse:down", (e) => {
          const { offsetX, offsetY } = e.e;
          if (
            !formFieldAlreadyExists &&
            (formFieldOptions?.fieldType != "list" ||
              formFieldOptions?.listItems?.length > 0)
          ) {
            canvas.remove(hoveredElement);
            if (showFixTextInput) {
              console.log("if 1");
              if (!fixOptionValues?.value) return;

              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              let textEle = new fabric.Text(fixOptionValues?.value, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                hasControls: false,
              });
              textEle.id = fixOptionValues?.value;
              let tempAddedFields = addedFields;
              tempAddedFields.push({
                element: textEle,
                field: "fix_text_input",
                type: "text",
              });
              setAddedFields(tempAddedFields);
              fabricRef.current.add(textEle);
              fabricRef.current.renderAll();
              setFixOptionValues({ type: "", value: "" });
              setShowFixTextInput(false);
            } else if (showFixDateInput) {
              if (!fixDateValue) return;
              // if (!fixOptionValues?.value) return;

              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let textEle = new fabric.Text(
                fixDateValue ? fixDateValue : "mm/dd/yyyy",
                {
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  ...styles,
                  hasControls: false,
                }
              );
              textEle.id = "date_system_current_date_generated";
              let tempAddedFields = addedFields;
              tempAddedFields.push({
                element: textEle,
                field: "fix_date_input",
                type: "text",
              });
              setAddedFields(tempAddedFields);
              fabricRef.current.add(textEle);
              fabricRef.current.renderAll();
              setFixOptionValues({ type: "", value: "" });
              setShowFixDateInput(false);
            } else if (showFixTimeInput) {
              if (!fixTimeValue) return;
              // if (!fixOptionValues?.value) return;

              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let textEle = new fabric.Text(
                fixTimeValue ? fixTimeValue : "HH:MM:SS",
                {
                  // left: e.absolutePointer.x,
                  // top: e.absolutePointer.y,
                  left: offsetX,
                  top: offsetY,
                  ...styles,
                  hasControls: false,
                }
              );
              textEle.id = "date_system_current_date_generated";
              let tempAddedFields = addedFields;
              tempAddedFields.push({
                element: textEle,
                field: "fix_time_input",
                type: "text",
              });
              setAddedFields(tempAddedFields);
              fabricRef.current.add(textEle);
              fabricRef.current.renderAll();
              setFixOptionValues({ type: "", value: "" });
              setShowFixTimeInput(false);
            } else if (
              // isUseCaseOne &&
              showFixImageInput
            ) {
              if (!fixOptionValues?.value) return;

              let imgSrc =
                // isPreview
                //   ?
                fixOptionValues?.value;
              setFixOptionValues({ type: "", value: "" });
              // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  left: e.absolutePointer.x,
                  top: e.absolutePointer.y,
                  // scaleX: 400 / myImg.width,
                  // scaleY: 400 / myImg.height,
                  id: "fix_image_input",
                  src: imgSrc,
                  // fixOptionValues?.value,
                });
                myImg?.scaleToWidth(400);
                myImg?.scaleToHeight(400);
                myImg.id = "fix_image_input";

                let tempAddedFields = addedFields;
                addedFields.push({
                  element: myImg,
                  field: "fix_image_input",
                  // fixOptionValues?.value + "fix image" + uuidv4(),
                  type: "image",
                });

                setAddedFields(tempAddedFields);

                fabricRef.current.add(myImg);
                fabricRef.current.renderAll();
                // setFixOptionValues({ type: "", value: "" });
                setShowFixImageInput(false);
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "date"
            ) {
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName || !formFieldOptions?.format)
                return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              let tempAddedFields = addedFields;
              addedFields.push({
                element: textEle,
                field: formFieldOptions?.fieldName,
                type: "date",
                format: formFieldOptions?.format,
              });
              setAddedFields(tempAddedFields);
              setAllFormFields([...allFormFields, formFieldOptions]);

              fabricRef.current.add(textEle);
              // setFormFieldOptions({});

              fabricRef.current.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "time"
            ) {
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName || !formFieldOptions?.format)
                return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              let tempAddedFields = addedFields;
              addedFields.push({
                element: textEle,
                field: formFieldOptions?.fieldName,
                type: "time",
                format: formFieldOptions?.format,
              });
              setAddedFields(tempAddedFields);
              setAllFormFields([...allFormFields, formFieldOptions]);

              fabricRef.current.add(textEle);
              // setFormFieldOptions({});

              fabricRef.current.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "multiline-text"
            ) {
              if (!formFieldOptions?.boxHeight || !formFieldOptions?.boxWidth) {
                return;
              }
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName) return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Textbox(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                editable: false,
                width: parseInt(formFieldOptions?.boxWidth),
                height: parseInt(formFieldOptions?.boxheight),
                minHeight: parseInt(formFieldOptions?.boxheight),
                maxHeight: parseInt(formFieldOptions?.boxheight),
                maxWidth: parseInt(formFieldOptions?.boxWidth),
                textAlign: dataFieldsOptions?.alignment
                  ? dataFieldsOptions?.alignment
                  : "left",
                // wrapMode: "word",
                // whiteSpace: "normal",
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              let tempAddedFields = addedFields;
              addedFields.push({
                element: textEle,
                field: formFieldOptions?.fieldName,
                type: "multiline-text",
              });
              setAddedFields(tempAddedFields);
              setAllFormFields([...allFormFields, formFieldOptions]);

              fabricRef.current.add(textEle);
              // setFormFieldOptions({});

              fabricRef.current.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType != "image" &&
              formFieldOptions?.fieldType != "signature" &&
              formFieldOptions?.fieldType != "checkbox"
              // &&
              // showFormFieldOptions
            ) {
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };

              if (!formFieldOptions?.fieldName) return;
              let text = formFieldOptions?.fieldName;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: formFieldOptions?.fieldName,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = formFieldOptions?.fieldName;

              let tempAddedFields = addedFields;
              addedFields.push({
                element: textEle,
                field: formFieldOptions?.fieldName,
                type: "text",
              });
              setAddedFields(tempAddedFields);
              setAllFormFields([...allFormFields, formFieldOptions]);

              fabricRef.current.add(textEle);
              // setFormFieldOptions({});

              fabricRef.current.renderAll();
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "signature"
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                "https://images.placeholders.dev/?width=300&height=150&text=signature&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  left: e.absolutePointer.x,
                  top: e.absolutePointer.y,
                  scaleX: 300 / myImg.width,
                  scaleY: 150 / myImg.height,
                  id: formFieldOptions?.fieldName,
                  lockScalingX: true,
                  lockScalingY: true,
                });

                myImg.id = formFieldOptions?.fieldName;

                let tempAddedFields = addedFields;
                addedFields.push({
                  element: myImg,
                  field: formFieldOptions?.fieldName,
                  type: "image",
                });
                setAddedFields(tempAddedFields);
                setAllFormFields([...allFormFields, formFieldOptions]);
                fabricRef.current.add(myImg);
                fabricRef.current.renderAll();
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "image"
              // &&
              // showFormFieldOptions
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  left: e.absolutePointer.x,
                  top: e.absolutePointer.y,
                  scaleX: 400 / myImg.width,
                  scaleY: 400 / myImg.height,
                  id: formFieldOptions?.fieldName,
                });
                myImg.id = formFieldOptions?.fieldName;

                let tempAddedFields = addedFields;
                addedFields.push({
                  element: myImg,
                  field: formFieldOptions?.fieldName,
                  type: "image",
                });
                setAddedFields(tempAddedFields);
                setAllFormFields([...allFormFields, formFieldOptions]);
                fabricRef.current.add(myImg);
                fabricRef.current.renderAll();
              });
            } else if (
              isUseCaseOne &&
              formFieldOptions &&
              formFieldOptions?.fieldType == "checkbox"
              // &&
              // showFormFieldOptions
            ) {
              if (!formFieldOptions?.fieldName) return;
              let imgSrc =
                // isPreview
                //   ? csvData[dataShown - 1][selectedField]
                // :
                // "https://placehold.co/400x400/png";
                selectedCheckboxImages?.true;
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  left: e.absolutePointer.x,
                  top: e.absolutePointer.y,
                  scaleX: checkboxScalingOptions?.x
                    ? checkboxScalingOptions?.x
                    : 50 / myImg.width,
                  scaleY: checkboxScalingOptions?.y
                    ? checkboxScalingOptions?.y
                    : 50 / myImg.height,
                  // width: checkboxScalingOptions?.width
                  //   ? checkboxScalingOptions?.width
                  //   : myImg.width,
                  // height: checkboxScalingOptions?.height
                  //   ? checkboxScalingOptions?.height
                  //   : myImg.height,
                  id: formFieldOptions?.fieldName,
                });
                myImg.id = formFieldOptions?.fieldName;

                let tempAddedFields = addedFields;
                addedFields.push({
                  element: myImg,
                  field: formFieldOptions?.fieldName,
                  type: "checkbox",
                  checkedImage: selectedCheckboxImages?.true,
                  uncheckedImage: selectedCheckboxImages?.false,
                });
                setAddedFields(tempAddedFields);
                setAllFormFields([...allFormFields, formFieldOptions]);
                fabricRef.current.add(myImg);
                fabricRef.current.renderAll();
              });
            } else if (selectedField && selectedOption === "dataField") {
              let styles = {
                fontFamily: dataFieldsOptions.fontFamily
                  ? dataFieldsOptions.fontFamily
                  : "Arial",
                textAlign: dataFieldsOptions.alignment
                  ? dataFieldsOptions.alignment
                  : "left",
                fill: dataFieldsOptions.fontColor
                  ? dataFieldsOptions.fontColor
                  : "#000",
                fontSize: dataFieldsOptions.fontSize
                  ? parseInt(dataFieldsOptions.fontSize)
                  : "18",
                fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
                fontWeight: dataFieldsOptions.isBold ? "bold" : "",
                underline: dataFieldsOptions.isUnderlined
                  ? dataFieldsOptions.isUnderlined
                  : false,
              };
              let text = isPreview
                ? csvData[dataShown - 1][selectedField]
                : selectedField;

              let textEle = new fabric.Text(text, {
                // left: e.absolutePointer.x,
                // top: e.absolutePointer.y,
                left: offsetX,
                top: offsetY,
                ...styles,
                id: selectedField,
                hasControls: false,
                // hasBorders: false
              });
              textEle.id = selectedField;
              console.log("text eleemnt at datafield insertion", textEle);

              let tempAddedFields = addedFields;
              addedFields.push({
                element: textEle,
                field: selectedField,
                type: "text",
              });
              setAddedFields(tempAddedFields);

              fabricRef.current.add(textEle);

              fabricRef.current.renderAll();
            } else if (selectedField && selectedOption === "checkbox") {
              // I have to add some logic here if use wanna drag checkbox, we can cjheck value in csv field is true r false , if not then we can show error and do not show user

              let isCheck = isPreview
                ? csvData[dataShown - 1][selectedField] === "TRUE"
                  ? true
                  : false
                : false;

              fabric.Image.fromURL(
                // `/${isCheck ? "checkbox" : "unchecked"}.png`
                isCheck
                  ? selectedCheckboxImages?.true
                  : selectedCheckboxImages?.false,
                function (myImg) {
                  console.log(myImg);
                  //i create an extra var for to change some image properties
                  myImg.set({
                    left: e.absolutePointer.x,
                    top: e.absolutePointer.y,
                    scaleX: 50 / myImg.width,
                    scaleY: 50 / myImg.height,
                    id: selectedField,
                  });
                  myImg.id = selectedField;
                  let tempAddedFields = addedFields;
                  addedFields.push({
                    element: myImg,
                    field: selectedField,
                    type: "checkbox",
                    checkedImage: selectedCheckboxImages?.true,
                    uncheckedImage: selectedCheckboxImages?.false,
                  });
                  setAddedFields(tempAddedFields);
                  fabricRef.current.add(myImg);
                  fabricRef.current.renderAll();
                }
              );
            } else if (selectedField && selectedOption === "image") {
              let imgSrc =
                // isPreview
                //   ?
                csvData[dataShown - 1][selectedField];
              // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              if (!imgSrc || !isURL(imgSrc))
                imgSrc =
                  "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
              fabric.Image.fromURL(imgSrc, function (myImg) {
                myImg.set({
                  left: e.absolutePointer.x,
                  top: e.absolutePointer.y,
                  scaleX: 400 / myImg.width,
                  scaleY: 400 / myImg.height,
                  id: selectedField,
                });
                myImg.id = selectedField;

                let tempAddedFields = addedFields;
                addedFields.push({
                  element: myImg,
                  field: selectedField,
                  type: "image",
                });
                setAddedFields(tempAddedFields);
                fabricRef.current.add(myImg);
                fabricRef.current.renderAll();
                setOneTimeImageUploadDone(true);
                setTimeout(() => {
                  setOneTimeImageUploadDone(false);
                }, 1000);
              });
            }
            // Add the rectangle and text to your list of objects here
            setFormFieldOptions({
              fieldName: "",
              fieldType: "text",
              fieldDescription: "",
              isMandatory: true,
            });
          }
        });
      }
      // let hoveredRect = null;
    }

    return () => {
      if (canvas) {
        canvas.off("mouse:move");
        canvas.off("mouse:down");
      }
    };
  }, [
    showFixTextInput,
    fixOptionValues,
    showFixImageInput,
    showFixDateInput,
    showFixTimeInput,
    fixDateValue,
    fixTimeValue,
    selectedField,
    selectedOption,
    formFieldOptions,
    formFieldAlreadyExists,
    selectedCheckboxImages,
    checkboxScalingOptions,
  ]);

  const handleCanvasClick = () => {
    setIsDrawing(true);
  };

  /************************************************************ */
  /******in above useffect you have to put every param, onchange of which you want can mouse handler to be reload */

  useEffect(() => {
    // Initialize fabric canvas
    fabricRef.current = new fabric.Canvas(canvasRef.current);

    // Load PDF from URL
    loadPDFFromURL(generalInformation?.templateBackgroundFile);

    if (preFilledCanvasObjects && preFilledCanvasObjects.length > 0) {
      let superobjs;
      if (isTemplateOpened) {
        superobjs = addedFields
          // ?.filter((a) => a?.type != "image" && a?.type != "checkbox")
          ?.map((f) => f?.element);
      } else {
        superobjs = preFilledCanvasObjects;
      }
      //   console.log("super objs", superobjs);
      //i set timeout of 3 sec so it has time to complete previous loading and iteration and to to avoid DrawImage error
      setTimeout(() => {
        superobjs.forEach((fabricObject) => {
          //   // console.log("fabricObject at iteration", fabricObject);
          if (fabricObject) {
            // Check if element is not undefined or null
            fabricRef?.current.remove(fabricObject);
            fabricRef.current.add(fabricObject);
          }
        });
        fabricRef.current.renderAll();
        setTimeout(() => {
          //   console.log("checkpost 3", fabricRef?.current);
        }, 1000);
      }, 3000);

      // setIsComebackToEdit(false);
      // console.log("fabricRef.current after", fabricRef?.current);
    }

    // Clean up
    return () => {
      fabricRef.current.dispose();
    };
  }, [preFilledCanvasObjects]);

  useEffect(() => {
    if (!isPreview) {
      let tempAddedFields = addedFields;

      tempAddedFields.map(
        ({ element, field, type, checkedImage, uncheckedImage }, index) => {
          if (
            // field == "date" ||
            field == "fix_date_input" ||
            field == "fix_text_input" ||
            field == "fix_time_input"
          ) {
            return;
          }
          if (type === "text" || type === "multiline-text") {
            // console.log("element before alteration", element);
            element.text = field?.toString();
          } else if (type == "date" && isUseCaseOne) {
            element.text = field?.toString();
          } else if (type == "time" && isUseCaseOne) {
            element.text = field?.toString();
          } else if (type === "checkbox") {
            element.setSrc(checkedImage, () => {
              fabricRef.current.renderAll();
            });
          } else if (type === "image") {
            console.log("running hahahahah");
            let widthTp = element.width;
            // / element.scaleX;
            let heightTp = element.height;
            // / element.scaleY;

            // console.log("height tp", parseInt(heightTp));
            let imgSrc = `https://images.placeholders.dev/?width=${
              widthTp ? parseInt(widthTp) : 400
            }&height=${
              heightTp ? parseInt(heightTp) : 400
            }&text=Docspawn%20Image&fontSize=50`;
            // `https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50`;
            // `https://placehold.co/${
            //   widthTp ? parseInt(widthTp) : 400
            // }x${heightTp ? parseInt(heightTp) : 400}/png`;
            // https:

            if (element?.src && isURL(element?.src)) {
              imgSrc = element?.src;
            }

            if (field == "fix_image_input") {
              imgSrc = element.src;
            }
            if (imgSrc) {
              element.setSrc(imgSrc, () => {
                fabricRef.current.renderAll();
              });
            }
          }
        }
      );

      setAddedFields(tempAddedFields);
      fabricRef.current.renderAll();
      return;
    }
    // if (!isUseCaseOne) {
    let tempAddedFields = addedFields;

    tempAddedFields.map(
      ({ element, field, type, checkedImage, uncheckedImage }, index) => {
        // console.log("element", element);
        // console.log("field", field);
        // console.log("type", type);
        if (
          // field == "date" ||
          field == "fix_date_input" ||
          field == "fix_text_input" ||
          field == "fix_time_input"
        ) {
          return;
        }
        if (type === "text") {
          // console.log(
          //   "element id >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
          //   element?.id
          // );
          // console.log("element", element);
          // console.log("element?.text", element?.text);
          if (element.id === "date_system_current_date_generated") return;
          // if (element?.text == "fix_date_input") return;
          let stringer = csvData[dataShown - 1][field];

          if (stringer) {
            element.text = csvData[dataShown - 1][field]?.toString();
          } else element.text = "";
        } else if (type === "checkbox") {
          // let isCheck = csvData[dataShown - 1][field] === "TRUE" ? true : false;
          let isCheck = false;
          let value = csvData[dataShown - 1][field];

          if (
            !value ||
            value?.toLowerCase() == "false" ||
            value?.toLowerCase() == "no"
          ) {
            isCheck = false;
          } else if (
            value?.toLowerCase() == "true" ||
            value?.toLowerCase() == "yes" ||
            value == true
          ) {
            isCheck = true;
          }

          element.setSrc(`${isCheck ? checkedImage : uncheckedImage}`, () => {
            fabricRef.current.renderAll();
          });
        } else if (type === "image") {
          let desiredHeight = element?.height * element?.scaleY;
          let desiredWidth = element?.width * element?.scaleX;
          // let desiredScaleX = element?.scaleX;
          // let desiredScaleY = element?.scaleY;
          // let desiredLeft = element?.left;
          // let desiredTop = element?.top;
          // let desiredId = element?.id;
          // let desiredField;

          let imgSrc = csvData[dataShown - 1][field];

          if (element?.src) {
            imgSrc = element?.src;
          }

          if (field === "fix_image_input") {
            imgSrc = element?.src;
          }

          if (!imgSrc || !isURL(imgSrc)) {
            imgSrc = `https://images.placeholders.dev/?width=${
              desiredWidth ? parseInt(desiredWidth) : 400
            }&height=${
              desiredHeight ? parseInt(desiredHeight) : 400
            }&text=Docspawn%20Image&fontSize=50`;
          }

          /********** *********************************************/

          // Set the image source and wait for it to load
          element.setSrc(imgSrc, () => {
            // let ratio = 0.6107784431137725;
            // Adjust width and height

            element?.scaleToWidth(desiredWidth);
            element?.scaleToHeight(desiredHeight);
            element.set({});
            element?.scaleToWidth(desiredWidth);
            element?.scaleToHeight(desiredHeight);
            // Render the canvas
            fabricRef.current.renderAll();
          });
        }
      }
    );

    setAddedFields(tempAddedFields);
    fabricRef.current.renderAll();
    // }
  }, [isPreview, dataShown, oneTimeImageUploadDone]);

  const canvasMouseDownHandler = (e, activeFF) => {
    if (
      isUseCaseOne &&
      formFieldOptions &&
      formFieldOptions?.fieldType == "multiline-text"
    ) {
      // console.log("form field options", formFieldOptions);
      // setShowDatafieldsOptions(true);
      return;
    }
    if (formFieldAlreadyExists) {
      return;
    }
    if (
      formFieldOptions?.fieldType == "list" &&
      formFieldOptions?.listItems?.length < 1
    ) {
      return;
    }
    // console.log("activeFF at mouse handler", activeFF);
    //to allow add only one object after selecting new option

    setShowDeleteObjectButton(false);
    const activeObject = fabricRef?.current?.getActiveObject();

    if (activeObject) {
      setShowDeleteObjectButton(true);
      console.log("active object", activeObject);
      console.log("addedFields", addedFields);
      /****************  for checkbox smaert scaling */
      let activeField = addedFields?.filter(
        (f) => f?.field == activeObject?.id
      )[0];
      console.log("active field", activeField);
      if (activeField && activeField?.type == "checkbox") {
        setCheckboxScalingOptions({
          ...checkboxScalingOptions,
          x: activeField?.element?.scaleX,
          y: activeField?.element?.scaleY,
        });
      }

      /***************************************** */
      // fontFamily: dataFieldsOptions.fontFamily,
      // textAlign: dataFieldsOptions.alignment,
      // fill: dataFieldsOptions.fontColor,
      // fontSize: parseInt(dataFieldsOptions.fontSize),
      // fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
      // fontWeight: dataFieldsOptions.isBold ? "bold" : "",
      // underline: dataFieldsOptions.isUnderlined,

      if (activeObject?.text) {
        setDataFieldsOptions({
          fontFamily: activeObject?.fontFamily
            ? activeObject?.fontFamily
            : "Arial",
          fontSize: activeObject?.fontSize ? activeObject?.fontSize : "12",
          fontColor: activeObject?.fill ? activeObject?.fill : "#4b4b5a",
          isBold: activeObject?.fontWeight == "bold" ? true : false,
          isItalic: activeObject?.fontStyle == "italic" ? true : false,
          isUnderlined: activeObject?.underline ? true : false,
          alignment: activeObject?.alignment ? activeObject?.alignment : "left",
        });
        setDataFieldbarRefreshor(Math.random());
        setShowDatafieldsOptions(true);
      } else {
        setShowDatafieldsOptions(false);
      }
    } else {
      setShowDatafieldsOptions(false);
    }
    setSelectedOption("");
    // setFormFieldOptions({});
    setShowFormFieldOptions(false);
    setShowFixDateInput(false);
    setShowFixTextInput(false);
    setShowFixImageInput(false);
    return;

    if (e.target) return;
    if (showFixTextInput) {
      if (!fixOptionValues?.value) return;
      console.log("fix option values at fixText", fixOptionValues);
      let styles = {
        fontFamily: dataFieldsOptions.fontFamily
          ? dataFieldsOptions.fontFamily
          : "Arial",
        textAlign: dataFieldsOptions.alignment
          ? dataFieldsOptions.alignment
          : "left",
        fill: dataFieldsOptions.fontColor
          ? dataFieldsOptions.fontColor
          : "#000",
        fontSize: dataFieldsOptions.fontSize
          ? parseInt(dataFieldsOptions.fontSize)
          : "18",
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined
          ? dataFieldsOptions.isUnderlined
          : false,
      };
      console.log("styles", styles);
      let textEle = new fabric.Text(fixOptionValues?.value, {
        left: e.absolutePointer.x,
        top: e.absolutePointer.y,
        ...styles,
        hasControls: false,
      });
      textEle.id = fixOptionValues?.value;
      console.log("text ele added", textEle);
      let tempAddedFields = addedFields;
      tempAddedFields.push({
        element: textEle,
        field: "fix_text_input",
        type: "text",
      });
      setAddedFields(tempAddedFields);
      fabricRef.current.add(textEle);
      fabricRef.current.renderAll();
      setFixOptionValues({ type: "", value: "" });
      setShowFixTextInput(false);
    } else if (showFixDateInput) {
      console.log("fix date value at xfdf play", fixDateValue);
      if (!fixDateValue) return;
      // if (!fixOptionValues?.value) return;

      let styles = {
        fontFamily: dataFieldsOptions.fontFamily
          ? dataFieldsOptions.fontFamily
          : "Arial",
        textAlign: dataFieldsOptions.alignment
          ? dataFieldsOptions.alignment
          : "left",
        fill: dataFieldsOptions.fontColor
          ? dataFieldsOptions.fontColor
          : "#000",
        fontSize: dataFieldsOptions.fontSize
          ? parseInt(dataFieldsOptions.fontSize)
          : "18",
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined
          ? dataFieldsOptions.isUnderlined
          : false,
      };
      let textEle = new fabric.Text(
        fixDateValue ? fixDateValue : "mm/dd/yyyy",
        {
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          ...styles,
          hasControls: false,
        }
      );
      textEle.id = "date_system_current_date_generated";
      let tempAddedFields = addedFields;
      tempAddedFields.push({
        element: textEle,
        field: "fix_date_input",
        type: "text",
      });
      setAddedFields(tempAddedFields);
      fabricRef.current.add(textEle);
      fabricRef.current.renderAll();
      setFixOptionValues({ type: "", value: "" });
      setShowFixDateInput(false);
    } else if (showFixTimeInput) {
      console.log("fix time value at xfdf play", fixTimeValue);
      if (!fixTimeValue) return;
      // if (!fixOptionValues?.value) return;

      let styles = {
        fontFamily: dataFieldsOptions.fontFamily
          ? dataFieldsOptions.fontFamily
          : "Arial",
        textAlign: dataFieldsOptions.alignment
          ? dataFieldsOptions.alignment
          : "left",
        fill: dataFieldsOptions.fontColor
          ? dataFieldsOptions.fontColor
          : "#000",
        fontSize: dataFieldsOptions.fontSize
          ? parseInt(dataFieldsOptions.fontSize)
          : "18",
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined
          ? dataFieldsOptions.isUnderlined
          : false,
      };
      let textEle = new fabric.Text(fixTimeValue ? fixTimeValue : "HH:MM:SS", {
        left: e.absolutePointer.x,
        top: e.absolutePointer.y,
        ...styles,
        hasControls: false,
      });
      textEle.id = "date_system_current_date_generated";
      let tempAddedFields = addedFields;
      tempAddedFields.push({
        element: textEle,
        field: "fix_time_input",
        type: "text",
      });
      setAddedFields(tempAddedFields);
      fabricRef.current.add(textEle);
      fabricRef.current.renderAll();
      setFixOptionValues({ type: "", value: "" });
      setShowFixTimeInput(false);
    } else if (
      // isUseCaseOne &&
      showFixImageInput
    ) {
      console.log("running show fix date input");
      console.log("fix option values", fixOptionValues);

      if (!fixOptionValues?.value) return;

      console.log("selected field image");
      let imgSrc =
        // isPreview
        //   ?
        fixOptionValues?.value;
      setFixOptionValues({ type: "", value: "" });
      // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
      fabric.Image.fromURL(imgSrc, function (myImg) {
        myImg.set({
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          // scaleX: 400 / myImg.width,
          // scaleY: 400 / myImg.height,
          id: "fix_image_input",
          src: imgSrc,
          // fixOptionValues?.value,
        });
        myImg?.scaleToWidth(400);
        myImg?.scaleToHeight(400);
        myImg.id = "fix_image_input";

        let tempAddedFields = addedFields;
        addedFields.push({
          element: myImg,
          field: "fix_image_input",
          // fixOptionValues?.value + "fix image" + uuidv4(),
          type: "image",
        });
        setAddedFields(tempAddedFields);
        fabricRef.current.add(myImg);
        fabricRef.current.renderAll();
        // setFixOptionValues({ type: "", value: "" });
        setShowFixImageInput(false);
      });
    } else if (
      isUseCaseOne &&
      formFieldOptions &&
      formFieldOptions?.fieldType != "image"
      // &&
      // showFormFieldOptions
    ) {
      let styles = {
        fontFamily: dataFieldsOptions.fontFamily
          ? dataFieldsOptions.fontFamily
          : "Arial",
        textAlign: dataFieldsOptions.alignment
          ? dataFieldsOptions.alignment
          : "left",
        fill: dataFieldsOptions.fontColor
          ? dataFieldsOptions.fontColor
          : "#000",
        fontSize: dataFieldsOptions.fontSize
          ? parseInt(dataFieldsOptions.fontSize)
          : "18",
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined
          ? dataFieldsOptions.isUnderlined
          : false,
      };

      if (!formFieldOptions?.fieldName) return;
      let text = formFieldOptions?.fieldName;

      let textEle = new fabric.Text(text, {
        left: e.absolutePointer.x,
        top: e.absolutePointer.y,
        ...styles,
        id: formFieldOptions?.fieldName,
        hasControls: false,
        // hasBorders: false
      });
      textEle.id = formFieldOptions?.fieldName;

      let tempAddedFields = addedFields;
      addedFields.push({
        element: textEle,
        field: formFieldOptions?.fieldName,
        type: "text",
      });
      setAddedFields(tempAddedFields);
      setAllFormFields([...allFormFields, formFieldOptions]);

      fabricRef.current.add(textEle);
      // setFormFieldOptions({});

      fabricRef.current.renderAll();
    } else if (
      isUseCaseOne &&
      formFieldOptions &&
      formFieldOptions?.fieldType == "image"
      // &&
      // showFormFieldOptions
    ) {
      if (!formFieldOptions?.fieldName) return;
      let imgSrc =
        // isPreview
        //   ? csvData[dataShown - 1][selectedField]
        // :
        // "https://placehold.co/400x400/png";
        "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
      fabric.Image.fromURL(imgSrc, function (myImg) {
        myImg.set({
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          scaleX: 400 / myImg.width,
          scaleY: 400 / myImg.height,
          id: formFieldOptions?.fieldName,
        });
        myImg.id = formFieldOptions?.fieldName;

        let tempAddedFields = addedFields;
        addedFields.push({
          element: myImg,
          field: formFieldOptions?.fieldName,
          type: "image",
        });
        setAddedFields(tempAddedFields);
        setAllFormFields([...allFormFields, formFieldOptions]);
        fabricRef.current.add(myImg);
        fabricRef.current.renderAll();
      });
    } else if (selectedField && selectedOption === "dataField") {
      let styles = {
        fontFamily: dataFieldsOptions.fontFamily
          ? dataFieldsOptions.fontFamily
          : "Arial",
        textAlign: dataFieldsOptions.alignment
          ? dataFieldsOptions.alignment
          : "left",
        fill: dataFieldsOptions.fontColor
          ? dataFieldsOptions.fontColor
          : "#000",
        fontSize: dataFieldsOptions.fontSize
          ? parseInt(dataFieldsOptions.fontSize)
          : "18",
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined
          ? dataFieldsOptions.isUnderlined
          : false,
      };
      let text = isPreview
        ? csvData[dataShown - 1][selectedField]
        : selectedField;

      let textEle = new fabric.Text(text, {
        left: e.absolutePointer.x,
        top: e.absolutePointer.y,
        ...styles,
        id: selectedField,
        hasControls: false,
        // hasBorders: false
      });
      textEle.id = selectedField;

      let tempAddedFields = addedFields;
      addedFields.push({
        element: textEle,
        field: selectedField,
        type: "text",
      });
      setAddedFields(tempAddedFields);

      fabricRef.current.add(textEle);

      fabricRef.current.renderAll();
    } else if (selectedField && selectedOption === "checkbox") {
      // I have to add some logic here if use wanna drag checkbox, we can cjheck value in csv field is true r false , if not then we can show error and do not show user

      let isCheck = isPreview
        ? csvData[dataShown - 1][selectedField] === "TRUE"
          ? true
          : false
        : false;

      fabric.Image.fromURL(
        `/${isCheck ? "checkbox" : "unchecked"}.png`,
        function (myImg) {
          console.log(myImg);
          //i create an extra var for to change some image properties
          myImg.set({
            left: e.absolutePointer.x,
            top: e.absolutePointer.y,
            scaleX: 50 / myImg.width,
            scaleY: 50 / myImg.height,
            id: selectedField,
          });
          myImg.id = selectedField;
          let tempAddedFields = addedFields;
          addedFields.push({
            element: myImg,
            field: selectedField,
            type: "checkbox",
          });
          setAddedFields(tempAddedFields);
          fabricRef.current.add(myImg);
          fabricRef.current.renderAll();
        }
      );
    } else if (selectedField && selectedOption === "image") {
      let imgSrc =
        // isPreview
        //   ?
        csvData[dataShown - 1][selectedField];
      // : "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
      if (!imgSrc)
        imgSrc =
          "https://images.placeholders.dev/?width=400&height=400&text=Docspawn%20Image&fontSize=50";
      fabric.Image.fromURL(imgSrc, function (myImg) {
        myImg.set({
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          scaleX: 400 / myImg.width,
          scaleY: 400 / myImg.height,
          id: selectedField,
        });
        myImg.id = selectedField;

        let tempAddedFields = addedFields;
        addedFields.push({
          element: myImg,
          field: selectedField,
          type: "image",
        });
        setAddedFields(tempAddedFields);
        fabricRef.current.add(myImg);
        fabricRef.current.renderAll();
        setOneTimeImageUploadDone(true);
        setTimeout(() => {
          setOneTimeImageUploadDone(false);
        }, 1000);
      });
    } else if (selectedField && selectedOption === "date") {
      console.log("selected field date");
      const enteredDate = prompt("Enter a date (DD-MM-YYYY):");

      if (enteredDate) {
        let textEle = new fabric.Text(enteredDate, {
          left: e.absolutePointer.x,
          top: e.absolutePointer.y,
          hasControls: false,
        });
        textEle.id = "date_system_current_date_generated";
        let tempAddedFields = addedFields;
        tempAddedFields.push({ element: textEle, field: "date", type: "text" });
        setAddedFields(tempAddedFields);
        fabricRef.current.add(textEle);
        fabricRef.current.renderAll();
      }
    }
  };
  useEffect(() => {
    const activeObject = fabricRef?.current?.getActiveObject();

    // Check if there's an active object
    if (activeObject) {
      const activeAddedField = addedFields.find((field) => {
        return field.element === activeObject;
      });

      // Assuming dataFieldsOptions contains the styles you want to apply
      const updatedStyles = {
        // Merge existing styles with styles from dataFieldsOptions
        ...activeAddedField.element.styles,
        fontFamily: dataFieldsOptions.fontFamily,
        textAlign: dataFieldsOptions.alignment,
        fill: dataFieldsOptions.fontColor,
        fontSize: parseInt(dataFieldsOptions.fontSize),
        fontStyle: dataFieldsOptions.isItalic ? "italic" : "",
        fontWeight: dataFieldsOptions.isBold ? "bold" : "",
        underline: dataFieldsOptions.isUnderlined,
      };

      // Update the styles of the Fabric.js element
      activeAddedField.element.set(updatedStyles);

      // Render the canvas to apply the changes
      fabricRef.current.renderAll();
    }
  }, [dataFieldsOptions]);

  const loadPDFFromURL = async (pdfURL) => {
    try {
      const response = await fetch(pdfURL);
      const pdfData = await response.arrayBuffer();

      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";

      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 2 });

      // Get the parent container's width
      const parentWidth = document?.getElementById("canvascover")?.offsetWidth;

      // Calculate the scale to fit the canvas within the parent width
      const scale = parentWidth / viewport.width;

      // Calculate the canvas width and height based on the scale
      // I have added 40 more to canvas height and width, because there was some issues in when bject rendered on backend, they place some points above then we have have set them in editor, so its a solution to that. rason to that that maybe scaling or wwhat
      // const canvasWidth = (viewport.width + 40) * scale;
      // const canvasHeight = (viewport.height + 40) * scale;
      const canvasWidth = viewport.width * scale;
      const canvasHeight = viewport.height * scale;

      // Set the canvas dimensions
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;

      fabricRef.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: page.getViewport({ scale: 2 }),
      };

      await page.render(renderContext).promise;
      var bg = canvas.toDataURL("image/png");
      setImageURL(bg);

      fabric.Image.fromURL(bg, function (img) {
        if (img) {
          fabricRef.current.setBackgroundImage(
            img,
            () => {
              fabricRef.current.renderAll();
            },
            {
              // Set the background image to cover the canvas without cropping
              scaleX: canvasWidth / img.width,
              scaleY: canvasHeight / img.height,
            }
          );
        }
      });
    } catch (error) {
      // console.error("Error loading PDF:", error);
    }
  };

  const setCustomizedObjects = () => {
    const objs = fabricRef.current.getObjects();
    // console.log("objs", objs);
    // console.log("Added fields", addedFields);

    const updatedObjs = objs.map((element, i) => {
      if (addedFields[i]) {
        element.id = addedFields[i].field;
      }
      return element;
    });

    setObjects(updatedObjs);
    setPreFilledCanvasObjects(updatedObjs);

    setFabricCanvas(JSON.stringify(fabricRef.current));
    setCanvasAddedFields(addedFields);
  };

  const handleDeleteObject = () => {
    const fabricInstance = fabricRef?.current;

    if (!fabricInstance) {
      return;
    }

    const activeObject = fabricInstance.getActiveObject();

    if (!activeObject) {
      setDeleteActiveFieldError("No object is selected!");
      setTimeout(() => {
        setDeleteActiveFieldError("");
      }, 5000);
      return;
    }
    setDeleteActiveFieldError("");

    //x
    const objectIndexToRemove = addedFields.findIndex(
      (field) => field.element === activeObject
    );

    if (objectIndexToRemove === -1) {
      return;
    }

    // Remove the active object from the canvas
    fabricInstance.remove(activeObject);
    fabricInstance.requestRenderAll(); // Ensure canvas renders the removal
    fabricInstance.renderAll();

    // Deselect the removed object
    fabricInstance.discardActiveObject().renderAll();
    if (isUseCaseOne) {
      let FiletrFormFolds = allFormFields?.filter((field) => {
        if (
          field?.fieldName?.toLowerCase() ==
          addedFields[objectIndexToRemove]?.field?.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
      setAllFormFields(FiletrFormFolds);
      // setAllFormFields((prevAllFormFields) =>
      //   prevAllFormFields.filter(
      //     (field) =>
      //       field.fieldName?.toLowerCase() !==
      //         addedFields[objectIndexToRemove]?.field?.toLowerCase() ||
      //       field.fieldType?.toLowerCase() !==
      //         addedFields[objectIndexToRemove]?.type?.toLowerCase()
      //   )
      // );
    }

    // Update the addedFields state by excluding the removed object
    setAddedFields((prevAddedFields) =>
      prevAddedFields.filter((_, index) => index !== objectIndexToRemove)
    );

    setShowDeleteObjectButton(false);
  };

  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/s3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      return null;
    }
  };

  const saveAndContinue = async () => {
    setIsSaving(true);

    let fabCanvas = JSON.stringify(fabricRef.current);
    const canvasS3Url = await uploadFileToBackend(fabCanvas, true);
    const objs = fabricRef.current.getObjects();

    const updatedObjs = objs.map((element, i) => {
      if (addedFields[i]) {
        element.id = addedFields[i].field;
      }
      return element;
    });
    if (!isUseCaseOne) {
      /*********save template here */
      let canvas_width =
        document.getElementsByClassName("canvas-container")[0]?.style?.width;
      let canvas_width_inetger = parseFloat(
        canvas_width.slice(0, canvas_width?.length - 2)
      );

      let canvas_height =
        document.getElementsByClassName("canvas-container")[0]?.style?.height;
      let canvas_heigth_inetger = parseFloat(
        canvas_height.slice(0, canvas_height?.length - 2)
      );

      let obj = {
        canvas_url: canvasS3Url,
        canvas_added_fields: JSON.stringify(addedFields),
        canvas_objects_for_output: JSON.stringify(updatedObjs),
        canvas_width_on_frontend: canvas_width_inetger,
        canvas_height_on_frontend: canvas_heigth_inetger,
        pre_filled_canvas_objects: JSON.stringify(updatedObjs),
        saved_till_step: 3,
      };

      api
        .put("/templates/" + currentTemplateId, obj)
        .then((res) => {
          setIsSaving(false);
          setCustomizedObjects();
          setTemplateCreationStep(4);
        })
        .catch((err) => {
          setIsSaving(false);
        });
      /******************************* */
    }
    if (isUseCaseOne) {
      /*********save template here */
      let canvas_width =
        document.getElementsByClassName("canvas-container")[0]?.style?.width;
      let canvas_width_inetger = parseFloat(
        canvas_width.slice(0, canvas_width?.length - 2)
      );

      let canvas_height =
        document.getElementsByClassName("canvas-container")[0]?.style?.height;
      let canvas_heigth_inetger = parseFloat(
        canvas_height.slice(0, canvas_height?.length - 2)
      );

      let obj = {
        canvas_url: canvasS3Url,
        canvas_added_fields: JSON.stringify(addedFields),
        canvas_objects_for_output: JSON.stringify(updatedObjs),
        pre_filled_canvas_objects: JSON.stringify(updatedObjs),
        canvas_width_on_frontend: canvas_width_inetger,
        canvas_height_on_frontend: canvas_heigth_inetger,
        saved_till_step: 2,
        complete_form_structure: JSON.stringify({
          all_form_fields: allFormFields,
        }),
      };

      api
        .put("/templates/" + currentTemplateId, obj)
        .then((res) => {
          setCustomizedObjects();
          setTemplateCreationStep(3);
          setIsSaving(false);
        })
        .catch((err) => {
          setIsSaving(false);
        });
      /******************************* */
    }
    // setCustomizedObjects();
  };

  return (
    <Box className="pdf_template_editor">
      <Box sx={{ background: "#fff", maxWidth: "1001px" }}>
        {/* <Button onClick={() => setIsDrawing(!isDrawing)}>hover canvas</Button> */}
        <Box sx={{ border: "1px solid #fff", position: "relative" }}>
          {showDeleteObjectButton && (
            <Button
              variant="contained"
              size="small"
              startIcon={<CloseIcon />}
              sx={{
                ml: 1,
                position: "absolute",
                top: "10px",
                left: "0px",
                zIndex: "100",
              }}
              onClick={handleDeleteObject}
            >
              {props?.t("Delete Object")}
            </Button>
          )}

          {deleteActiveFieldError && (
            <Typography
              variant="p"
              sx={{ color: "red", fontSize: "0.9rem", ml: 3 }}
            >
              {deleteActiveFieldError}
            </Typography>
          )}
        </Box>

        {/*****  we have set canvas cover to fix height and width(equal to reponsive 100% browser)- we cant make it responsive, because to make it synchronize on different screen sizes and to make it work it backend, we have to have fix canvas size */}
        <Box
          id="canvascover"
          sx={{
            // border: "1px solid lightgray",
            mt: 0,
            minHeight: "200px",
            // width: "300px",
            // height: "1300px", //making it convert from 1291  0n 22 nov

            //these are latest
            // height: "max-content",
            // width: "1003px",
            // height: pdfPageSize?.height,
            // width: pdfPageSize?.width,
            width: "100%",
            height: "auto",
          }}
        >
          {/* <>
            {console.log(
              "canvas cover height",
              document.getElementsByClassName("canvas-container")[0]?.style
                ?.height,
              "canvas cover width",
              document.getElementsByClassName("canvas-container")[0]?.style
                ?.width
            )}
          </> */}
          <canvas
            id="canvas"
            ref={canvasRef}
            style={{
              width: "100%",
              //  height: "100%", //uncommented it  0n 22 nov
              border: "2px solid lightgray",
            }}
          />
        </Box>
        <Button
          disabled={isEditorLoading}
          variant="contained"
          sx={{
            mt: 2,
            mb: 2,
            marginLeft: "50%",
            transform: "translateX(-50%)",
          }}
          onClick={() => {
            // setCustomizedObjects();
            saveAndContinue();
            // setTemplateCreationStep(isUseCaseOne ? 3 : 4);
          }}
        >
          {props?.t("Save and Continue")}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation()(PDFTemplateEditor);
