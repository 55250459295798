import * as React from "react";

import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Avatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setUser, setToken } from "../actions/user";
import SearchComponent from "../components/SearchInput";
import { api } from "../axios/api";
import logo from "../utils/images/Logo.png";

import Tooltip from "@mui/material/Tooltip";
import "react-toastify/dist/ReactToastify.css";
import {
  AlignLeft,
  Home,
  Package,
  Bell,
  Settings,
  BarChart2,
  Users,
  LogOut,
  Truck,
  ShoppingCart,
  ArrowDownCircle,
  ArrowUpCircle,
  Upload,
  Download,
  User,
} from "react-feather";
// import HomeIcon from "@mui/icons-material/Home";
import HomeIcon from "@mui/icons-material/HomeTwoTone";
// import NoteAltIcon from "@mui/icons-material/NoteAlt";
import NoteAltIcon from "@mui/icons-material/DescriptionTwoTone";
// import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggestTwoTone";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartIcon from "@mui/icons-material/DonutSmallTwoTone";
// import StorageIcon from "@mui/icons-material/Storage";
import StorageIcon from "@mui/icons-material/SnippetFolderTwoTone";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LanguageDropdown from "../components/LanguageDropdown";
import { withTranslation } from "react-i18next";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import LaunchIcon from "@mui/icons-material/Launch";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontSize: "15px !important",
  color: theme.palette.heading.main,
  "&:hover": {
    backgroundColor: theme.palette.accent.main,
  },
  "&:active": {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
  },
}));

const drawerWidth = 260;

function DashboardLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    api
      .get("/user-info/me")
      .then((res) => {
        dispatch(setUser(res?.data));
      })
      .catch((err) => {
        // console.log("error while fetching user info", err);
      });
  }, []);

  const [open, setOpen] = useState(false);
  const directory = useSelector((state) => state.directory);
  useEffect(() => {
    dispatch({
      type: "SET_Directory",
      payload: user?.profile_type == "admin" ? "manageAccounts" : "home",
    });
  }, []);
  const changeDirection = (dir) => {
    dispatch({
      type: "SET_Directory",
      payload: dir,
    });
  };
  const adminMenuItems = [
    {
      label: "Accounts",
      value: "manageAccounts",
      icon: <Users />,
    },
    {
      label: "Coupon",
      value: "signup-coupon",
      icon: <ConfirmationNumberOutlinedIcon />,
    },
  ];
  const menuItems = [
    {
      label: "Home",
      value: "home",
      icon: <HomeIcon />,
    },
    {
      label: "Templates",
      value: "templates",
      icon: <NoteAltIcon />,
    },
    {
      label: "Documents library",
      value: "documentsLibrary",
      icon: <StorageIcon />,
    },
    {
      label: "Data library",
      value: "dataLibrary",
      icon: <InsertChartIcon />,
    },

    {
      label: "Settings",
      value: "settings",
      icon: <SettingsSuggestIcon />,
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleItemClick = (item) => {
    changeDirection(item?.value);
  };
  const logout = () => {
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/login");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileDropdown = Boolean(anchorEl);
  const handleProfileDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileDropdownClose = () => {
    setAnchorEl(null);
  };
  const menuItemsToShow =
    user?.profile_type == "admin" ? adminMenuItems : menuItems;
  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        paddingBottom: "100px",
        height: "100%",
      }}
    >
      <Toolbar sx={{ py: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            sx={{
              margin: "20px 0 30px 0",
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            {/* <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: "2rem",
                fontWeight: "400",
                fontFamily: "roboto, sans-serif",
                mt: 2,
              }}
            >
              DocSpawn
            </Typography> */}
            <img src={logo} style={{ width: "180px", height: "auto" }} />
          </Box>
        </Box>
      </Toolbar>

      <List
        sx={{
          padding: "0 18px",
          marginTop: "50px",
          // border: "1px solid red",
          flex: "1",
        }}
      >
        {menuItemsToShow?.map((item, index) => (
          <StyledListItem
            key={item?.value}
            disablePadding
            sx={{ display: "block", marginBottom: "15px" }}
            selected={directory === item?.value}
            onClick={() => handleItemClick(item)}
          >
            <ListItemButton
              sx={{
                minHeight: 30,
                justifyContent: "initial",
                px: 1.5,
                py: 1.3,
                backgroundColor:
                  directory === item?.value
                    ? theme.palette.primary.main
                    : "transparent",
                color: directory === item?.value && theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.accent.main,
                },

                borderRadius: "3px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.7,
                  justifyContent: "center",
                  color:
                    directory === item?.value
                      ? theme.palette.white.main
                      : theme.palette.heading.main,
                }}
              >
                {item?.icon}
              </ListItemIcon>

              <Typography
                style={{
                  opacity: 1,
                  fontWeight: directory === item?.value ? "400" : 400,
                  fontSize: directory === item?.value ? "14px" : "14px",
                  color:
                    directory === item?.value
                      ? theme.palette.white.main
                      : theme.palette.heading.main,
                }}
              >
                {" "}
                {props?.t(item?.label)}
              </Typography>
            </ListItemButton>
          </StyledListItem>
        ))}
        <hr
          style={{
            marginTop: "100%",
            marginBottom: "20px",
            color: "lightgray",
            background: "lightgray",
          }}
        />
        <StyledListItem
          // key={item?.value}
          disablePadding
          sx={{ display: "block", marginBottom: "15px" }}

          // onClick={() => handleItemClick(item)}
        >
          <a href="https://documentation.docspawn.eu/nIV72G" target="_blank">
            <ListItemButton
              sx={{
                minHeight: 30,
                justifyContent: "initial",
                px: 1.5,
                py: 1.3,
                backgroundColor: "transparent",
                color: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.accent.main,
                },

                borderRadius: "3px",
              }}
            >
              <Typography
                style={{
                  opacity: 1,
                  fontWeight: 400,
                  fontSize: "14px",
                  color: theme.palette.heading.main,
                }}
              >
                {" "}
                {props?.t("Documentation")}
              </Typography>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.7,
                  ml: 1,
                  justifyContent: "center",
                  color: theme.palette.heading.main,
                }}
              >
                <LaunchIcon sx={{ fontSize: "1.1rem" }} />
              </ListItemIcon>
            </ListItemButton>
          </a>
        </StyledListItem>
      </List>

      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          width: `${drawerWidth}px`,
        }}
      ></Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          ml: { sm: `${drawerWidth}px`, xs: "0px" },
          backgroundColor: theme.palette.background.main,
          boxShadow: " rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.background.main,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "none", sm: "none" } }}
          >
            <AlignLeft
              size={24}
              color={theme.palette.primary.main}
              strokeWidth={0.7}
            />
          </IconButton>

          <Box
            sx={{
              flex: "1",
              margin: "0 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              {/* <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: "1.15rem",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch({
                    type: "SET_Directory",
                    payload: "home",
                  })
                }
              >
                DocSpawn
              </Typography> */}
              <Box
                sx={{
                  margin: "10px 0 10px 0",
                  display: "flex",
                  justifyContent: "center",
                  pt: 0,
                }}
                onClick={() =>
                  dispatch({
                    type: "SET_Directory",
                    payload: "home",
                  })
                }
              >
                <img src={logo} style={{ width: "120px", height: "auto" }} />
              </Box>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.para.main,
                  fontSize: "1.15rem",
                  fontWeight: "600",
                  ml: 3,
                }}
              >
                {directory == "templates" && props?.t("Templates")}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "auto",
                width: "max-content",

                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <LanguageDropdown />
              {/* <SearchComponent /> */}

              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.heading.main,
                  ml: { xs: 2, sm: 5 },
                  fontSize: "600",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                {user?.name}
              </Typography>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleProfileDropdownClick}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {user?.profile_image ? (
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: theme.palette.primary.main,
                      }}
                      src={user?.profile_image}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: theme.palette.primary.main,
                      }}
                    >
                      {user?.name && user?.name[0]?.toUpperCase()}
                    </Avatar>
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openProfileDropdown}
              onClose={handleProfileDropdownClose}
              onClick={handleProfileDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  changeDirection("profile");
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <User size="20px" />
                </ListItemIcon>

                <Typography variat="p" color={theme.palette.para.main}>
                  {" "}
                  {props?.t("My Profile")}
                </Typography>
              </MenuItem>

              <Divider />

              <MenuItem
                onClick={() => {
                  changeDirection("account");
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <Settings size="20px" />
                </ListItemIcon>
                <Typography variat="p" color={theme.palette.para.main}>
                  {props?.t("Settings")}
                </Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  logout();
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <LogOut size="20px" />
                </ListItemIcon>
                <Typography variat="p" color={theme.palette.para.main}>
                  {props?.t("Logout")}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          borderRight: "none",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        {/******   sidebar in desktop view */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>{" "}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          minHeight: "100vh",
          height: "maxContent",
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          borderRadius: "10px",
        }}
      >
        <Toolbar />
        {props?.children(directory)}
      </Box>
    </Box>
  );
}

export default withTranslation()(DashboardLayout);
